import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import cookies from "js-cookie";

const COLORS = ["#0083b0", "#FFFF"];

const HalfPieChart = ({ title, subtitle }) => {
  const [data, setData] = useState([]);
  const [loanAmount, setLoanAmount] = useState(null);
  const [paidPercentage, setPaidPercentage] = useState(null);
  const [amountReceived, setAmountReceived] = useState(null);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/analytics/paidPrincipal",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const amountReceived = json["Amount Received"];
      const loanAmount = json["Loan Amount"];
      const paidPercentage = `${json["Paid Percentage"]}%`;
      const unpaid = loanAmount - amountReceived;
      const percent = parseFloat(paidPercentage)
      // const percent = paidPercentage * 100;
      setData([{ value: amountReceived }, { value: unpaid }]);
      setLoanAmount(loanAmount);
      console.log(percent)
      setPercentage(percent);
      setAmountReceived(amountReceived);
    };

    fetchData();
  }, []);

  const screenWidth = Math.floor(window.innerWidth / 18.5);

  const outerScreenWidth = Math.floor(window.innerWidth / 15.5);
  const radius = 44; // Radius of the semi-circle
  const circumference = 2 * Math.PI * radius;
  const dashOffset = circumference - (75 / 100 / 2 + 0.5) * circumference;
  const startAngle = -180; // Start angle (top of the circle)
  const endAngle = startAngle + (percentage / 100) * 180; // Calculate the end angle based on percentage

  // Calculate the coordinates of the start and end points of the arc
  const startX = 50 + radius * Math.cos((startAngle * Math.PI) / 180);
  const startY = 50 + radius * Math.sin((startAngle * Math.PI) / 180);
  const endX = 50 + radius * Math.cos((endAngle * Math.PI) / 180);
  const endY = 50 + radius * Math.sin((endAngle * Math.PI) / 180);
  return (
    <div
      style={{
        width: "100%",
        height: "170px",
        border: "1px solid none",
        backgroundColor: "#FFFFFFB3",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "1vh",
        position: "relative",
      }}
    >
      {/* <div>
        <ResponsiveContainer width="100%" height={120}>
          <PieChart>
            <defs>
              <filter id="dropshadow" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                <feOffset dx="2" dy="2" result="offsetblur" />
                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.5" />
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <linearGradient
                id="half-pie-chart-gradient"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
              >
                <stop offset="0%" stopColor="rgba(138, 156, 197, 0.8)" />
                <stop offset="100%" stopColor="rgba(21,87,169,1)" />
              </linearGradient>
            </defs>
            <Pie
              data={data}
              cx="50%"
              cy="100%"
              startAngle={180}
              endAngle={0}
              innerRadius={71}
              outerRadius={81}
              dataKey="value"
              stroke="#707070" // Add the grey border color
              strokeWidth={0.5} // Add the border width
              cornerRadius={2}
              filter="url(#dropshadow)" // Apply the drop shadow filter here
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    index === 0
                      ? "url(#half-pie-chart-gradient)"
                      : COLORS[index]
                  }
                />
              ))}
            </Pie>
            {/* <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div> */}
      <div>
        <svg width="100%" height="190" viewBox="0 0 100 100">
          <path
            d={`M 50 50 m -${radius}, 0 a ${radius},${radius} 0 0,1 ${
              radius * 2
            },0`}
            fill="transparent"
            strokeWidth="10"
            stroke="rgba(0,0,0,0.1)" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
          />
          <path
            transform={`translate(0, 1)`} // Adjust the vertical position here
            d={`M 50 50 m -${radius}, 0 a ${radius},${radius} 0 0,1 ${
              radius * 2
            },0`}
            fill="transparent"
            strokeWidth="7.5"
            stroke="white" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
            filter="url(#blur)" // Apply blur filter to this path
          />

          {/* Define the filter for the inner shadow */}
          <filter id="blur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.1" />
          </filter>

          <path
            d={`M ${startX} ${startY} A ${radius} ${radius} 0 ${
              percentage <= 50 ? "0" : "1"
            } 1 ${endX} ${endY}`}
            fill="transparent"
            strokeWidth="10"
            stroke="#115CA6" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
          />
        </svg>
      </div>

      <div
        style={{
          textAlign: "center",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <h5
          style={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "2.0em",
            fontWeight: "800",
            color: "#142C44",
            letterSpacing: "-1px",
            fontSize: "25px",
          }}
        >
          {amountReceived ? `$${amountReceived.toLocaleString()}` : "-"}
        </h5>
        <p
          style={{
            paddingRight: "0px",
            marginTop: "-7px",
            marginBottom: "5px",
            fontFamily: "Manrope, sans-serif",
            fontSize: "10px",
            fontWeight: "600",
            color: "#00000066",
          }}
        >
          Forecast Collection
        </p>
      </div>
    </div>
  );
};

export default HalfPieChart;
