import React from "react";

const Verifylayout = ({ children }) => {
  return (
    <div className="verify-multi-layout">
      <div className="verify-box">{children}</div>
    </div>
  );
};

export default Verifylayout;
