import React, { useState, useEffect } from "react";
// import { LoadingOutlined } from '@ant-design/icons';
// import { Spin } from 'antd';
import { Switch, Route } from "react-router-dom";
import Topbar from "../../scenes/global/Topbar";
import Sidebar from "../../scenes/global/Sidebar";
import Dashboard from "../../scenes/dashboard";
// import Team from "../../scenes/team";
import LoanRequestList from "../../scenes/loan-request-list";
import Invoices from "../../scenes/invoices";
import Contacts from "../../scenes/contacts";
import Bar from "../../scenes/bar";
import Form from "../../scenes/form";
import Line from "../../scenes/line";
import Pie from "../../scenes/pie";
import FAQ from "../../scenes/faq";
import Geography from "../../scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Calendar from "../../scenes/calendar/calendar";
import Loan from "../../scenes/loan";
import AcceptedLoans from "../../scenes/accepted-loans";
import PendingAcceptance from "../../scenes/pending-acceptance";
import DelayedPayments from "../../scenes/delayed-payments";
import Agreements from "../../scenes/agreements";
import Analytics from "../../scenes/analytics";
import Subscription from "../../scenes/subscription";
import Profile from "../../scenes/profile";
import LoanRequestGrid from "../../scenes/loan-request-grid";
import { Link } from "react-router-dom";
import AdobeSign from "../../scenes/AdobeSign";
import { SearchProvider } from "../../admin/search-context";
import Messaging from "../../borrower/loan/message";
import Cookies from "js-cookie";
import MyProfile from "./myprofile";
import DocumentBuilder from "../../scenes/documentBuilder";

// const antIcon = (
//   <LoadingOutlined
//     style={{
//       fontSize: 24,
//     }}
//     spin
//   />
// );

function DashboardLayout() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = React.useState("");

  // const handleRouteClick = () => {
  //   setIsLoading(true);
  //   // You can add a timeout here to simulate a loading process
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 5000);
  // };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [userDetails, setUserDetails] = useState(null);

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setUserDetails(json.User);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <SearchProvider value={{ search, setSearch }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main
              className="content"
              style={{
                overflowY: screenWidth < 800 ? "hidden" : "auto",
                height: window.innerHeight,
              }}
            >
              <Topbar setIsSidebar={setIsSidebar} userDetails={userDetails} />
              {/* {isLoading && <Spin indicator={antIcon} />} */}
              <Switch>
                {/* <Link to="/dashboard" onClick={handleRouteClick}> */}
                <Route
                  path="/dashboard"
                  component={Dashboard}
                  userDetails={userDetails}
                />
                {/* </Link> */}
                {/* <Link to="/loan" onClick={handleRouteClick}> */}
                <Route path="/loan" component={Loan} />
                {/* </Link> */}
                <Route path="/loan-request" component={LoanRequestList} />
                <Route path="/accepted-loans" component={AcceptedLoans} />
                <Route
                  path="/pending-acceptance"
                  component={PendingAcceptance}
                />
                <Route path="/delayed-payments" component={DelayedPayments} />
                {screenWidth < 800 ? null : (
                  <Route path="/analytics" component={Analytics} />
                )}
                <Route path="/agreements" component={Agreements} />
                <Route path="/subscription" component={Subscription} />
                <Route path="/profile" component={Profile} />
                <Route path="/l-messages" component={Messaging} />
                <Route path="/l-profile" component={MyProfile} />
                <Route path="/l-document-builder" component={DocumentBuilder} />
                <Route path="/loan-request-grid" component={LoanRequestGrid} />
                <Route path="/adobe-sign" component={AdobeSign} />
              </Switch>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SearchProvider>
  );
}

export default DashboardLayout;
