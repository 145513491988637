import { Checkbox, Input } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SCLOGO from "../../assets/img/sc-logo.png";
import GoogleLogin from "react-google-login";
import singpass from "../../assets/img/singpass-login.png";
import lockIcon from "../../assets/img/lock-icon.png";
import google from "../../assets/img/signin-google.png";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [screenWidth, setScreenWidth] = useState("1920");

  // const clientId = 'YOUR_CLIENT_ID_HERE';

  const handleClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const response = await fetch(
      `https://www.smart-lend.com/api/auth/login/google`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    const data = await response.json();
    if (data.status === "success") {
      // Redirect the user to the next step
      window.location.href = data.next_step;
    } else {
      // Handle errors
    }
    Cookies.set("token", data.token);
    Cookies.set("user_account_type", data.user.user_account_type);
    setIsLoading(false);
    console.log(data);
  };

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const mobileRegex = /^\d{10}$/;

  const [email, setEmail] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    } else if (user_account_type === "admin") {
      history.push("/a-users");
    }
  }, [history]);

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe) {
      setRememberMe(rememberMe);
    }
    if (localStorage.getItem("user-info")) {
    }
  }, []);

  async function login(event) {
    event.preventDefault();
    let path = "";
    try {
      if (emailRegex.test(email)) {
        let item = { email, password };
        let response = await fetch(
          "https://www.smart-lend.com/api/auth/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(item),
          }
        );
        if (response.status === 200) {
          let data = await response.json();
          if (rememberMe) {
            localStorage.setItem("rememberMe", rememberMe);
          } else {
            localStorage.removeItem("rememberMe");
          }
          Cookies.remove("expressauth");
          Cookies.set("token", data.token);
          Cookies.set("user_account_type", data.user.user_account_type);
          Cookies.set("singpass_sync", data.user.singpass_sync);
          Cookies.set("email", data.user.email);
          localStorage.setItem("user-info", JSON.stringify(data));
        } else if (response.status === 401) {
          throw new Error("Unauthorized, check your login credentials");
        }
      } else if (mobileRegex.test(mobile_no)) {
        let item = { mobile_no, password };
        let response = await fetch(
          "https://www.smart-lend.com/api/auth/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(item),
          }
        );
        if (response.status === 200) {
          let data = await response.json();
          if (rememberMe) {
            localStorage.setItem("rememberMe", rememberMe);
          } else {
            localStorage.removeItem("rememberMe");
          }
          Cookies.set("token", data.token);
          Cookies.set("user_account_type", data.user.user_account_type);
          localStorage.setItem("user-info", JSON.stringify(data));
        } else if (response.status === 401) {
          throw new Error("Unauthorized, check your login credentials");
        }
      }

      // Add this block to set the path based on user_account_type
      const user_account_type = Cookies.get("user_account_type");
      if (user_account_type === "lender") {
        path = "/dashboard";
      } else if (user_account_type === "borrower") {
        path = "/b-dashboard";
      } else if (user_account_type === "admin") {
        path = "/a-users";
      }

      // redirect to the path
      return history.push(path);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <div>
          <div className="auth-header">
            <h3 className="auth-header-title">
              Welcome Back
              <br />
              to SmartLend
            </h3>
          </div>
          <div className="auth-body">
            <form className="auth-form-validation">
              <div className="input-field">
                <label htmlFor="email" className="input-label">
                  Email / Mobile Number
                </label>
                <input
                  // type="text"
                  value={email || mobile_no}
                  className="input-control"
                  placeholder="Email / Mobile Number"
                  autoComplete="off"
                  onChange={(event) =>
                    setEmail(event.target.value) ||
                    setMobileNo(event.target.value)
                  }
                  required
                />
              </div>
              <div className="input-field">
                <label htmlFor="password" className="input-label">
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  className="input-control"
                  name="password"
                  placeholder="Password"
                  // iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  autoComplete="off"
                  onChange={(event) => setPassword(event.target.value)}
                  required
                />
              </div>
              <div className="flex-end">
                <Checkbox
                  className="checkbox-login"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                >
                  Remember me
                </Checkbox>
                <Link to={"/auth/forgot-password"} className="link-end">
                  <img src={lockIcon} alt="" width={15} height={14} /> Forgot
                  password
                </Link>
              </div>

              <div className="btn-position">
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}
                <button
                  type="submit"
                  className="btn-submit"
                  onClick={login}
                  style={{ width: "100%" }}
                >
                  Sign In
                </button>
              </div>
              {/* { loggedIn ( */}
              <div className="btn-position">
                <div
                  className="btn-submit-default"
                  style={{ border: "1px solid grey" }}
                >
                  <a href="https://www.smart-lend.com/api/auth/login/google">
                    <img
                      src={google}
                      alt="Google Sign-in button"
                      width={518}
                      height={68}
                    />
                  </a>
                </div>
              </div>
              {/* singpass login button */}
              {/* <div className="btn-position">
       <button className="btn-submit-singpass">
         <img src={singpass} alt="" onClick={""} />
       </button>
     </div> */}
            </form>
            <p className="text-center">
              Don't have an account?{" "}
              <Link to={"/auth/signup"} className="link-text-center-signup">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",

              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div style={{ width: "100%" }}>
              <h3 className="auth-header-title" style={{ fontSize: "45px" }}>
                Welcome Back to SmartLend
              </h3>
            </div>
            <div className="auth-body" style={{ width: "100%" }}>
              <form className="auth-form-validation" style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <label htmlFor="email">Email / Mobile Number</label>
                  <input
                    // type="text"
                    value={email || mobile_no}
                    placeholder="Email / Mobile Number"
                    autoComplete="off"
                    onChange={(event) =>
                      setEmail(event.target.value) ||
                      setMobileNo(event.target.value)
                    }
                    required
                    style={{
                      width: "100%",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      border: "1px solid grey",
                      outline: "none",
                    }}
                  />
                </div>
                <div style={{ margin: "10px 0px 20px 0px" }}>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    value={password}
                    name="password"
                    placeholder="Password"
                    // iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    autoComplete="off"
                    onChange={(event) => setPassword(event.target.value)}
                    required
                    style={{
                      width: "100%",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      border: "1px solid grey",
                      outline: "none",
                    }}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Checkbox
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  >
                    Remember me
                  </Checkbox>
                  <Link to={"/auth/forgot-password"}>
                    <img src={lockIcon} alt="" width={15} height={14} /> Forgot
                    password
                  </Link>
                </div>

                <div className="btn-position" style={{ width: "100%" }}>
                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                  <button
                    type="submit"
                    style={{
                      background: "#0e5aa5",
                      color: "white",
                      width: "100%",
                      fontSize: 15,
                      padding: "14px 20px",
                      borderRadius: "10px",
                    }}
                    onClick={login}
                  >
                    Sign In
                  </button>
                </div>
                {/* { loggedIn ( */}
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid grey",
                      borderRadius: "10px",
                    }}
                  >
                    <a
                      href="https://www.smart-lend.com/api/auth/login/google"
                      style={{
                        width: "100%",
                      }}
                    >
                      <img
                        src={google}
                        alt="Google Sign-in button"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          maxHeight: "55px",
                        }}
                      />
                    </a>
                  </div>
                </div>
                {/* singpass login button */}
                {/* <div className="btn-position">
       <button className="btn-submit-singpass">
         <img src={singpass} alt="" onClick={""} />
       </button>
     </div> */}
              </form>
              <p className="text-center">
                Don't have an account?{" "}
                <Link to={"/auth/signup"} className="link-text-center-signup">
                  Sign up
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
// };

export default withRouter(Login);
