import { Form, Input, Select, Button, Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
const { Option } = Select;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const Grants = () => {
  const [grants, setGrants] = useState([]);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        const entityDetail = displaySingpassData.singpass_data.entity;

        // Ensure that you are setting the array of grants
        if (
          entityDetail &&
          entityDetail.grants &&
          Array.isArray(entityDetail.grants["grants-list"])
        ) {
          setGrants(entityDetail.grants["grants-list"]);
          console.log("details", entityDetail.grants["grants-list"]);
        } else {
          // Handle the case where grants data is not an array or not available
          setGrants([]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Form
      style={{ minHeight: "73vh" }}
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {grants.length > 0 ? (
        grants.map((item, index) => (
          <React.Fragment key={index}>
            <Row style={{ gap: 20 }}>
              <Col span={isMobile ? 11 : 6}>
                <Form.Item label="Grant Type">
                  <Input
                    style={{ width: "100%" }}
                    value={
                      item
                        ? item["grant-type"]
                          ? item["grant-type"].code
                            ? item["grant-type"].code
                            : null
                          : null
                        : null
                    }
                    disabled={!!item || !item}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 12}>
                <Form.Item label="Grant Description">
                  <Input
                    style={{ width: "100%" }}
                    value={
                      item
                        ? item["grant-type"]
                          ? item["grant-type"].desc
                            ? item["grant-type"].desc
                            : null
                          : null
                        : null
                    }
                    disabled={!!item || !item}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ gap: 20 }}>
              <Col span={isMobile ? 11 : 6}>
                <Form.Item label="Grant Type">
                  <Input
                    style={{ width: "100%" }}
                    value={
                      item
                        ? item["grant-status"]
                          ? item["grant-status"].desc
                            ? item["grant-status"].desc
                            : null
                          : null
                        : null
                    }
                    disabled={!!item || !item}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 6}>
                <Form.Item label="Functional Area Code">
                  <Input
                    style={{ width: "100%" }}
                    value={
                      item
                        ? item["functional-area"]
                          ? item["functional-area"].code
                            ? item["functional-area"].code
                            : null
                          : null
                        : null
                    }
                    disabled={!!item || !item}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 6}>
                <Form.Item label="Functional Area Description">
                  <Input
                    style={{ width: "100%" }}
                    value={
                      item
                        ? item["functional-area"]
                          ? item["functional-area"].desc
                            ? item["functional-area"].desc
                            : null
                          : null
                        : null
                    }
                    disabled={!!item || !item}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 6}>
                <Form.Item label="Grant Type">
                  <Input
                    style={{ width: "100%" }}
                    value={
                      item
                        ? item["development-category"]
                          ? item["development-category"].code
                            ? item["development-category"].code
                            : null
                          : null
                        : null
                    }
                    disabled={!!item || !item}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={{
                gap: 20,
                borderBottom: "2px solid rgba(0,0,0,0.05",
                marginBottom: 20,
                paddingBottom: 10,
              }}
            >
              <Col span={isMobile ? 11 : 6}>
                <Form.Item label="Grant Type">
                  <Input
                    style={{ width: "100%" }}
                    value={
                      item
                        ? item["development-category"]
                          ? item["development-category"].desc
                            ? item["development-category"].desc
                            : null
                          : null
                        : null
                    }
                    disabled={!!item || !item}
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        ))
      ) : (
        <div
          style={{
            width: "100%",
            padding: "0px 0px",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: "0px 15px",
              background: "rgba(255,255,255,0.7)",
              borderRadius: "10px",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Manrope, sans-serif",
              fontSize: "0.8em",
              fontWeight: "600",
              color: "#00000066",
            }}
          >
            You have no grants
          </div>
        </div>
      )}
      {/* <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div> */}
    </Form>
  );
};

export default Grants;
