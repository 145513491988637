import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockTransactions } from "../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../components/Header";
import LineChartAdmin from "../components/LineChartAdmin";
import LineChartIntrestProfit from "../components/LineChartInterestProfit";
import GeographyChart from "../components/GeographyChart";
import BarChart from "../components/BarChart";
import ExpressBarChart from "../components/ExpressBarChart";
import StatBox from "../components/StatBox";
import Cookies from "js-cookie";
import TinyLineChartAdmin from "../components/TinyLineChartAdmin";
import React, { useEffect, useState } from "react";
import HalfPieChartAdmin from "../components/HalfPieChartAdmin";
import HalfPieChartAdminFee from "../components/HalfPieChartAdminFee";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const AdminAnalytic = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [averagePrincipalAmount, setAvgPrincipalAmount] = useState({});
  const [sortBy, setSortBy] = useState("week");
  const [dateRange, setDateRange] = useState([]);
  const [data, setData] = useState([]);
  const [averagePaidPrincipal, setAveragePaidPrincipal] = useState(null);
  const [averageClients, setAverageClients] = useState(null);
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [interestProfit, setInterestProfit] = useState([]);
  const [screenWidth, setScreenWidth] = useState("1920");

  //For count amount and total loan
  const [totalInterestProfit, setTotalInterestProfit] = useState(0);
  const [averageInterestProfit, setAverageInterestProfit] = useState(0);
  const [tickValues, setTickValues] = useState([]);
  const [dataClient, setClientData] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [averageClientsPerMonth, setAverageClientsPerMonth] = useState(0);

  //For count offered loan
  const [offeredLoan, setOfferedLoan] = useState([]);
  const [totalOfferedLoans, setTotalOfferedLoans] = useState(0);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [totalFilteredLoans, setTotalFilteredLoans] = useState(0);

  //For count offered loan
  const [ongoingLoan, setOngoingLoan] = useState([]);
  const [totalOngoingLoans, setTotalOngoingLoans] = useState(0);
  const [filteredOngoingLoans, setFilteredOngoingLoans] = useState([]);
  const [totalFilteredOngoingLoans, setTotalFilteredOngoingLoans] = useState(0);

  //For count requested loan
  const [dataRequestedLoan, setRequestedLoan] = useState([]);
  const [totalRequestedLoans, setTotalRequestedLoans] = useState(0);
  const [filteredRequestedLoans, setFilteredRequestedLoans] = useState([]);
  const [totalFilteredRequestedLoans, setTotalFilteredRequestedLoans] =
    useState(0);

  //For count offered loan
  const [dataCompletedLoan, setCompletedLoan] = useState([]);
  const [totalCompletedLoans, setTotalCompletedLoans] = useState(0);
  const [filteredCompletedLoans, setFilteredCompletedLoans] = useState([]);
  const [totalFilteredCompletedLoans, setTotalFilteredCompletedLoans] =
    useState(0);

  const handleAverageClientsUpdate = (average) => {
    setAverageClients(average);
  };
  const month = "January"; // Replace this with the desired month
  const averageForSelectedMonth = averageClients ? averageClients[month] : 0;

  //Sort data by Week, Month and Year
  const dataByWeek = mockTransactions.reduce((acc, transaction) => {
    const weekNumber = moment(transaction.date).isoWeek();
    if (!acc[weekNumber]) {
      acc[weekNumber] = 0;
    }
    acc[weekNumber] += transaction.amount;
    return acc;
  }, {});

  const sortedData = Object.keys(dataByWeek)
    .sort((a, b) => a - b)
    .map((weekNumber) => ({
      name: `Week ${weekNumber}`,
      value: dataByWeek[weekNumber],
    }));

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/auth/register/viewUser",
        options
      );
      const json = await response.json();
      setEmail(json.email); // Save the email from the response to the state
      setData(response);
    }
    fetchData();
  }, []);

  // Fetch data useEffect
  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/analytics/countOfferedLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Convert date strings to moment objects
        let offeredLoan = data["offeredLoan"].map((loan) => ({
          ...loan,
          date: moment(loan.date),
        }));

        setOfferedLoan(offeredLoan);
        setTotalOfferedLoans(offeredLoan.length);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  // Filter data useEffect
  useEffect(() => {
    if (offeredLoan.length) {
      const filteredLoans = combineData(dateRange, offeredLoan);
      setFilteredLoans(filteredLoans);
      setTotalFilteredLoans(filteredLoans.length);
    }
  }, [dateRange, offeredLoan]);

  // Fetch data useEffect
  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/analytics/countOngoingLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Convert date strings to moment objects
        let ongoingLoan = data["ongoingLoan"].map((loan) => ({
          ...loan,
          date: moment(loan.date),
        }));

        setOngoingLoan(ongoingLoan);
        setTotalOngoingLoans(ongoingLoan.length);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  // Filter data useEffect
  useEffect(() => {
    if (ongoingLoan.length) {
      const filteredLoans = combineData(dateRange, ongoingLoan);
      setFilteredOngoingLoans(filteredLoans);
      setTotalFilteredOngoingLoans(filteredLoans.length);
    }
  }, [dateRange, ongoingLoan]);

  // Fetch data useEffect
  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/analytics/countRequestedLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Convert date strings to moment objects
        let dataRequestedLoan = data["requestedLoan"].map((loan) => ({
          ...loan,
          date: moment(loan.date),
        }));

        setRequestedLoan(dataRequestedLoan);
        setTotalRequestedLoans(dataRequestedLoan.length);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  // Filter data useEffect
  useEffect(() => {
    if (dataRequestedLoan.length) {
      const filteredLoans = combineData(dateRange, dataRequestedLoan);
      setFilteredRequestedLoans(filteredLoans);
      setTotalFilteredRequestedLoans(filteredLoans.length);
    }
  }, [dateRange, dataRequestedLoan]);

  // Fetch data useEffect
  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/analytics/countCompletedLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Convert date strings to moment objects
        let dataCompletedLoan = data["completedLoan"].map((loan) => ({
          ...loan,
          date: moment(loan.date),
        }));

        setCompletedLoan(dataCompletedLoan);
        setTotalCompletedLoans(dataCompletedLoan.length);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  // Filter data useEffect
  useEffect(() => {
    if (dataCompletedLoan.length) {
      const filteredLoans = combineData(dateRange, dataCompletedLoan);
      setFilteredCompletedLoans(filteredLoans);
      setTotalFilteredCompletedLoans(filteredLoans.length);
    }
  }, [dateRange, dataCompletedLoan]);

  function combineData(dateRange, offeredLoan) {
    const combinedData = offeredLoan.map((loan) => ({
      Date: moment(loan.date).format("YYYY-MM-DD"),
    }));

    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let filteredData;
    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");

      filteredData = combinedData
        .filter((loan) => {
          return moment(loan.Date).isBetween(startDate, endDate, "day", "[]");
        })
        .sort(
          (a, b) => moment(a.Date, "YYYY-MM-DD") - moment(b.Date, "YYYY-MM-DD")
        );
    } else {
      filteredData = offeredLoan;
    }

    return filteredData;
  }

  function exportData(dateRange) {
    // Get the start and end dates from the dateRange prop
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");
    } else {
      startDate = moment().startOf("month").format("YYYY-MM-DD");
      endDate = moment().endOf("month").format("YYYY-MM-DD");
    }

    // Return an object containing both startDate and endDate
    return { startDate, endDate };
  }

  const ContainerRow2 = ({ title, subtitle, label, description }) => (
    <div
      style={{
        width: "100%",
        height: "auto",
        border: "1px solid white",
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div style={{ marginLeft: "15px", marginTop: "7px", marginRight: "1px" }}>
        <h3
          style={{
            display: "inline-block",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "20px",
            marginRight: "80px",
            color: "#142C44CC",
          }}
        >
          {title}
        </h3>
      </div>
      <div style={{ marginLeft: "15px", marginTop: "-8px" }}>
        <p
          style={{
            display: "inline-block",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "10px",
            color: "#142C44CC",
          }}
        >
          {subtitle}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "40px",
            color: "#142C44CC",
            marginBottom: "10px",
          }}
        >
          {label}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "10px",
            color: "#142C44CC",
          }}
        >
          Is the average Express Loan Amount <br />
          per client
        </p>
      </div>
    </div>
  );

  const ContainerRow1 = ({
    onClick,
    icon,
    title,
    subtitle,
    label,
    description,
  }) => (
    <div
      onClick={onClick} // The onClick event handler
      style={{
        width: screenWidth > 1200 ? "50%" : "100%",
        textAlign: "center",
        margin: screenWidth > 1200 ? "0px" : "20px 0px 0px 0px",
        border: "0px solid none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFF",
        height: "170px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "15px",
          marginBottom: "10px",
          marginTop: "7px",
          marginRight: "1px",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "17px",
            color: "#142C44CC",
            textAlign: "left",
            margin: "12px 0px 0px 0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "200",
            fontSize: "8px",
            color: "#142C44CC",
            textAlign: "right",
            margin: "17px 10px 0px 0px",
          }}
        >
          {subtitle}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "0px",
          marginLeft: "15px",
        }}
      >
        <div
          style={{
            width: "70px",
            height: "70px",
            background: "rgba(0,0,0,0.03)",
            borderRadius: "35px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px",
          }}
        >
          <img
            src={icon}
            alt={title}
            style={{
              background: "#f0f0f0",
              width: "40px",
              height: "40px",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            marginLeft: "15px",
            marginBottom: "10px",
            marginTop: "7px",
            marginRight: "1px",
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "800",
              fontSize: "20px",
              color: "#142C44CC",
              textAlign: "left",
              marginBottom: "4px",
              marginTop: "10px",
            }}
          >
            {label}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "500",
              fontSize: "12px",
              color: "#142C44CC",
              textAlign: "left",
              lineHeight: "1",
            }}
          >
            {description}
          </p>
        </div>
      </div>
      {/* <p>{description}</p> */}
    </div>
  );

  const ContainerRow3 = ({ noData }) => (
    <div
      style={{
        width: screenWidth > 1200 ? "50%" : "100%",
        textAlign: "center",
        margin: screenWidth > 1200 ? "0px" : "20px 0px 0px 0px",
        border: "1px solid none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        height: "170px",
      }}
    >
      <p
        style={{
          fontWeight: "600",
          fontSize: "18px",
          color: "#142C44CC",
          textAlign: "center",
          margin: "70px auto",
        }}
      >
        {noData}
      </p>
    </div>
  );

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginRight: "0px",
        borderRadius: "10px",
      }}
    >
      <TinyLineChartAdmin
        subtitle="Is the current market interest rate"
        dateRange={dateRange}
      />
    </div>
  );

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: "170px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "10px",
      }}
    >
      <HalfPieChartAdmin dateRange={dateRange} />
    </div>
  );

  const ContainerPieChart2 = () => (
    <div
      style={{
        width: "100%",
        height: "170px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "10px",
      }}
    >
      <HalfPieChartAdminFee dateRange={dateRange} />
    </div>
  );

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "90%",
        textAlign: "left",
        marginBottom: "22px",
        border: "1px solid",
        borderRadius: "20px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
        <img
          src={icon}
          alt={title}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "10px",
            margin: "10px 10px 10px 10px",
          }}
        />
        <div>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "20px",
              fontWeight: "600",
              marginTop: "8px",
              marginLeft: "10px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "10px",
              fontWeight: "400",
              marginLeft: "10px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  function handleDateRangeChange(dates) {
    setDateRange(dates);
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="0px 20px 20px 20px">
          {/* HEADER */}

          <Box
            gridColumn="span 8"
            gridRow="span 2"
            borderRadius="10px"
            opacity="0.95"
            style={{
              overflowY: "scroll",
              height: "88vh",
              padding: "15px",
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: "0px 0px 10px 0px" }}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  color: "#142C44CC",
                  textAlign: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "50%",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "5px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  View by:
                </label>
                <div style={{ width: "29%", marginRight: "20px" }}>
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      onChange={handleDateRangeChange}
                      value={dateRange}
                    />
                  </Space>
                </div>
                <Button
                  onClick={async () => {
                    try {
                      const { startDate, endDate } = exportData(dateRange);
                      const response = await fetch(
                        `https://www.smart-lend.com/api/analytics/exportExcelAdmin?start_date=${startDate}&end_date=${endDate}`,
                        {
                          method: "GET",
                          headers: {
                            Authorization: "Bearer " + Cookies.get("token"),
                            Accept: "application/json",
                          },
                        }
                      );
                      const blob = await response.blob();
                      const url = URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        `${email}_Analytics_${startDate}_${endDate}.xlsx`
                      );
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  className="analytics_button"
                  style={{
                    backgroundColor: "none",
                    color: "#0E5AA5",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "2px 10px",
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                    textTransform: "none", // Add this line to allow small letters in the button text
                    padding: "3px 20px",
                    transition: "0.3s all ease-in-out",
                  }}
                >
                  <DownloadOutlinedIcon
                    className="analytics_button_icon"
                    style={{
                      transition: "0.3s all ease-in-out",
                    }}
                  />
                  Export
                </Button>
              </div>
            </Box>

            <Box gridColumn="span 8">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                      style={{ marginBottom: "10px" }}
                    >
                      User Count
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "7px",
                          width: "30px",
                          background: "rgb(21, 87, 169, 0.4)",
                          marginLeft: "5px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px 0px 0px 5px",
                        }}
                      >
                        Borrower
                      </div>
                      <div
                        style={{
                          height: "7px",
                          width: "30px",
                          background: "rgb(21, 87, 169)",
                          marginLeft: "5px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px 0px 0px 5px",
                        }}
                      >
                        Lender
                      </div>
                    </div>
                  </div>
                  <Box
                    gridColumn="span 4"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    border="none"
                    flexDirection="column"
                    backgroundColor="#FFFFFF"
                    borderRadius="10px"
                    style={{ padding: "10px" }}
                  >
                    <Box height="300px">
                      <LineChartAdmin
                        isDashboard={true}
                        dateRange={dateRange}
                      />
                    </Box>
                  </Box>
                </div>
              </div>
            </Box>

            <Box gridColumn="span 8" style={{ marginTop: "15px" }}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "15px" }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: "15px",
                      }}
                    >
                      {offeredLoan.length > 0 ? (
                        <ContainerRow1
                          key={0}
                          icon={require("../assets/img/businessanalyticsicon.png")}
                          title="Offered Loan"
                          label={totalFilteredLoans}
                          description={`There is only ${totalFilteredLoans} Offered Loan`}
                        />
                      ) : (
                        <ContainerRow3 noData="No Offered Loan Data" />
                      )}

                      {ongoingLoan.length > 0 ? (
                        <ContainerRow1
                          key={0}
                          icon={require("../assets/img/customersicon.png")}
                          title="Ongoing Loan"
                          label={totalFilteredOngoingLoans}
                          description={`There is only ${totalFilteredOngoingLoans} Ongoing Loan`}
                        />
                      ) : (
                        <ContainerRow3 noData="No Ongoing Loan Data" />
                      )}
                    </div>
                    <div
                      style={{ display: "flex", width: "100%", gap: "15px" }}
                    >
                      {dataRequestedLoan.length > 0 ? (
                        <ContainerRow1
                          icon={require("../assets/img/applicationicon.png")}
                          title="Requested Loan"
                          label={totalFilteredRequestedLoans}
                          description={`There is only ${totalFilteredRequestedLoans} Requested Loan`}
                        />
                      ) : (
                        <ContainerRow3 noData="No Requested Loan Data" />
                      )}
                      {dataCompletedLoan.length > 0 ? (
                        <ContainerRow1
                          icon={require("../assets/img/hourglassicon.png")}
                          title="Completed Loan"
                          label={totalFilteredCompletedLoans}
                          description={`There is only ${totalFilteredCompletedLoans} Completed Loan`}
                        />
                      ) : (
                        <ContainerRow3 noData="No Completed Loan Data" />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                    }}
                  >
                    <Box
                      width="50%"
                      opacity="1.00"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="170px"
                      borderRadius="10px"
                    >
                      <ContainerPieChart
                        dateRange={dateRange}
                      ></ContainerPieChart>
                    </Box>
                    <Box
                      width="50%"
                      opacity="1.00"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="170px"
                      borderRadius="10px"
                    >
                      <ContainerPieChart2
                        dateRange={dateRange}
                      ></ContainerPieChart2>
                    </Box>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: "15px",
                      flexDirection: "row",
                      marginTop: "15px",
                    }}
                  >
                    <Box
                      width="50%"
                      opacity="1.00"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="170px"
                      backgroundColor="#FFFFFF"
                      borderRadius="10px"
                      border="0px solid white"
                    >
                      <Box
                        className="client_bar_chart"
                        height={200}
                        style={{ padding: "15px", width: "100%" }}
                      >
                        <div style={{}}>
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontSize: "18px",
                              fontWeight: "800",
                              color: "#142C44CC",
                              margin: "0px",
                              marginTop: "15px",
                            }}
                          >
                            Express
                          </h3>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "600",
                              fontSize: "11px",
                              color: "rgba(0,0,0,0.4)",
                              margin: "0px",
                            }}
                          >
                            You've gained {averageForSelectedMonth} new express
                            loan of this month
                          </p>
                        </div>

                        {/* <ExpressBarChart
                          isDashboard={true}
                          width="100%"
                          height={150}
                          dateRange={dateRange}
                          onAverageClientsUpdate={handleAverageClientsUpdate}
                        /> */}
                      </Box>
                    </Box>
                    <Box
                      width="50%"
                      opacity="1.00"
                      display="flex"
                      alignItems="left"
                      justifyContent="left"
                      height="170px"
                      backgroundColor="#FFFFFF"
                      borderRadius="10px"
                      border="0px solid white"
                    >
                      <ContainerLineChart
                        dateRange={dateRange}
                      ></ContainerLineChart>
                    </Box>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              padding: "0px 20px",
              height: "100vh",
              borderRadius: "20px",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
                width: "100%",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
              >
                Analytic
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "33%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    htmlFor="sort-by"
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "10px",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    View by:
                  </label>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        onChange={handleDateRangeChange}
                        value={dateRange}
                      />
                    </Space>
                  </div>
                  <Button
                    onClick={async () => {
                      try {
                        const { startDate, endDate } = exportData(dateRange);
                        const response = await fetch(
                          `https://www.smart-lend.com/api/analytics/exportExcel?start_date=${startDate}&end_date=${endDate}`,
                          {
                            method: "GET",
                            headers: {
                              Authorization: "Bearer " + Cookies.get("token"),
                              Accept: "application/json",
                            },
                          }
                        );
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          `${email}_Analytics_${startDate}_${endDate}.xlsx`
                        );
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    sx={{
                      backgroundColor: "none",
                      color: "#0E5AA5",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "2px 10px",
                      border: "1px solid #00000066",
                      marginTop: "5px",
                      width: "100%",
                      borderRadius: "10px",
                      textTransform: "none", // Add this line to allow small letters in the button text
                      "&:hover": {
                        backgroundColor: "#0E5AA5",
                        color: "#ffffff",
                      },
                    }}
                  >
                    <DownloadOutlinedIcon
                      sx={{
                        mr: "5px",
                        transition: "0.1s",
                        "&:hover": { color: "#ffffff" },
                      }}
                    />
                    Export
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
                marginTop="10px"
              >
                User Count
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                border: "1px solid none",
                flexDirection: "column",
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
              }}
            >
              <div>
                <Box height="300px">
                  <LineChartAdmin isDashboard={true} dateRange={dateRange} />
                </Box>
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: "10px" }}>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "auto",
                  marginRight: "5px",
                }}
              >
                {offeredLoan.length > 0 ? (
                  <ContainerRow1
                    key={0}
                    icon={require("../assets/img/businessanalyticsicon.png")}
                    title="Offered Loan"
                    label={totalFilteredLoans}
                    description={`There is only ${totalFilteredLoans} Offered Loan`}
                  />
                ) : (
                  <ContainerRow3 noData="No Offered Loan Data" />
                )}
              </div>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "20px",
                }}
              >
                {ongoingLoan.length > 0 ? (
                  <ContainerRow1
                    key={0}
                    icon={require("../assets/img/customersicon.png")}
                    title="Ongoing Loan"
                    label={totalFilteredOngoingLoans}
                    description={`There is only ${totalFilteredOngoingLoans} Ongoing Loan`}
                  />
                ) : (
                  <ContainerRow3 noData="No Ongoing Loan Data" />
                )}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: "-10px" }}>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  marginRight: "5px",
                }}
              >
                {dataRequestedLoan.length > 0 ? (
                  <ContainerRow1
                    icon={require("../assets/img/applicationicon.png")}
                    title="Requested Loan"
                    label={totalFilteredRequestedLoans}
                    description={`There is only ${totalFilteredRequestedLoans} Requested Loan`}
                  />
                ) : (
                  <ContainerRow3 noData="No Requested Loan Data" />
                )}
              </div>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  borderRadius: "20px",
                }}
              >
                {dataCompletedLoan.length > 0 ? (
                  <ContainerRow1
                    icon={require("../assets/img/hourglassicon.png")}
                    title="Completed Loan"
                    label={totalFilteredCompletedLoans}
                    description={`There is only ${totalFilteredCompletedLoans} Completed Loan`}
                  />
                ) : (
                  <ContainerRow3 noData="No Completed Loan Data" />
                )}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: "10px" }}>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "auto",
                  marginRight: "5px",
                }}
              >
                <ContainerPieChart dateRange={dateRange}></ContainerPieChart>
              </div>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "20px",
                }}
              >
                <ContainerPieChart2 dateRange={dateRange}></ContainerPieChart2>
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: "-20px" }}>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  marginRight: "5px",
                }}
              >
                <Box
                  width="100%"
                  opacity="1.00"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="170px"
                  backgroundColor="#FFFFFF"
                  borderRadius="20px"
                  border="1px solid white"
                >
                  <Box height={170}>
                    <div style={{ padding: "5px 0px 0px 5px" }}>
                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <h3
                          style={{
                            display: "inline-block",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "600",
                            fontSize: "20px",
                            color: "#142C44CC",
                          }}
                        >
                          Express
                        </h3>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginTop: "-8px",
                        marginBottom: "-75px",
                        padding: "5px 0px 0px 5px",
                      }}
                    >
                      <p
                        style={{
                          display: "inline-block",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "600",
                          fontSize: "10px",
                          color: "#142C44CC",
                        }}
                      >
                        You've gained {averageForSelectedMonth} new express loan
                        of this month
                      </p>
                    </div>

                    <ExpressBarChart
                      isDashboard={true}
                      width="100%"
                      height={150}
                      dateRange={dateRange}
                      onAverageClientsUpdate={handleAverageClientsUpdate}
                    />
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  borderRadius: "20px",
                }}
              >
                <ContainerLineChart dateRange={dateRange}></ContainerLineChart>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminAnalytic;
