import React, { useState, useEffect } from "react";
// import { LoadingOutlined } from '@ant-design/icons';
// import { Spin } from 'antd';
import { Switch, Route } from "react-router-dom";
import Topbar from "../../borrower/global/Topbar";
import Sidebar from "../../borrower/global/Sidebar";
import Dashboard from "../../borrower/dashboard";
import ApplyLoan from "../../borrower/loan/apply-loan";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Loan from "../../borrower/loan/loan";
import OngoingLoans from "../../borrower/loan/ongoing-loans";
import RequestedLoans from "../../borrower/loan/pending-loans";
import Agreements from "../../borrower/agreements";
import Transactions from "../../borrower/transactions";
import Profile from "../../borrower/profile";
import { Link } from "react-router-dom";
import AcceptedLoans from "../../borrower/loan/accepted-loan";
import { SearchProvider } from "../../admin/search-context";
import SidebarMobile from "../../borrower/global/SidebarMobile";
import DashboardMobile from "../../borrower/borrower_mobile/dashboard_mobile";
import Cookies from "js-cookie";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ProfileStaging from "../../borrower/profile staging";
import Messaging from "../../borrower/loan/message";
// const antIcon = (
//   <LoadingOutlined
//     style={{
//       fontSize: 24,
//     }}
//     spin
//   />
// );

function DashboardBorrowerLayout() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = React.useState("");
  const [topNotification, setTopNotification] = useState(null);
  const [screenWidth, setScreenWidth] = useState("1920");
  // const handleRouteClic  k = () => {
  //   setIsLoading(true);
  //   // You can add a timeout here to simulate a loading process
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 5000);
  // };
  useEffect(() => {
    console.log("setting token param");
    // Function to get the value of a URL parameter by name
    const getUrlParameter = (name) => {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    // Get the token parameter from the URL
    const tokenParam = getUrlParameter("token");

    // Check if a token is present in the URL
    if (tokenParam) {
      // Store the token in cookies
      console.log(tokenParam);
      Cookies.set("token", tokenParam, { expires: 720 }); // Set an expiration if needed
    }
  }, []);

  const fetchNotification = async (loan_id) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/notification/createLogNotify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();

      if (response.status === 200) {
        const notificationsForTopBar = json["new_notifications"];
        const lateRepayIndex = notificationsForTopBar.findIndex(
          (notification) => notification.type === "late_repay"
        );

        if (lateRepayIndex > -1) {
          setTopNotification(notificationsForTopBar[lateRepayIndex]);
        } else if (notificationsForTopBar.length > 0) {
          setTopNotification(notificationsForTopBar[0]);
        } else {
          setTopNotification(null);
        }

        console.log(json["new_notifications"]);
        console.log(Cookies.get("token"));
      }
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (screenWidth > 1200) {
      fetchNotification();
    }
  }, []);
  useEffect(() => {
    if (screenWidth <= 1200) {
      setTopNotification(null);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [userDetails, setUserDetails] = useState(null);

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setUserDetails(json.User);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <SearchProvider value={{ search, setSearch }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app" style={{ overflow: "hidden" }}>
            <div
              style={{
                position: "relative",
                marginTop: "0px",
              }}
            >
              <Sidebar isSidebar={isSidebar} />
            </div>
            <main
              className="content"
              style={{
                overflowY: screenWidth < 800 ? "hidden" : "auto",
                height: window.innerHeight,
              }}
            >
              {screenWidth > 1200 ? (
                topNotification ? (
                  <div
                    style={{
                      width: "100%",
                      height: "5vh",
                      background:
                        topNotification.color === "red" ? "#80160b" : "#0a58ca",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ErrorOutlineIcon
                      style={{
                        color: "white",
                        fontSize: "22px",
                        margin: "0px 10px 0px 10px",
                      }}
                    />
                    <div
                      style={{
                        fontSize: "12px",
                        color: "white",
                        margin: "0px 10px 0px 0px",
                      }}
                    >
                      {topNotification.message}
                    </div>
                    <a
                      className="all_button"
                      style={{
                        fontSize: "12px",
                        color: "white",
                        border: "1px solid white",
                        borderRadius: "20px",
                        background: "transparent",
                        padding: "3px 22px",
                        margin: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      href={
                        topNotification.type === "late_repay"
                          ? "/b-loan"
                          : topNotification.type === "pending_sign"
                          ? "/b-agreements"
                          : "/b-requested-loans"
                      }
                    >
                      View All
                    </a>
                  </div>
                ) : null
              ) : null}
              <Topbar setIsSidebar={setIsSidebar} userDetails={userDetails} />
              {/* {isLoading && <Spin indicator={antIcon} />} */}
              <Switch>
                <Route path="/b-dashboard" component={Dashboard} />
                <Route path="/b-loan" component={Loan} />
                <Route path="/b-apply-loan" component={ApplyLoan} />
                <Route path="/b-loan-in-progress" component={OngoingLoans} />
                <Route path="/b-requested-loans" component={RequestedLoans} />
                <Route path="/b-accepted-loans" component={AcceptedLoans} />
                <Route path="/b-agreements" component={Agreements} />
                <Route path="/b-transactions" component={Transactions} />
                <Route path="/b-profile" component={ProfileStaging} />
                <Route path="/stg-b-profile" component={ProfileStaging} />
                <Route path="/b-messages" component={Messaging} />
                <Route path="/dashboard-mobile" component={DashboardMobile} />
                <Route path="/sidebar-mobile" component={SidebarMobile} />
              </Switch>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SearchProvider>
  );
}

export default DashboardBorrowerLayout;
