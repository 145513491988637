import React from "react";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

const AdobeSign = () => {
  const history = useHistory();
  const isAuthenticated = Cookies.get('token');
  const user_account_type = Cookies.get('user_account_type');

  console.log("user account type", user_account_type);
  console.log("authenticated", isAuthenticated);

  if (!isAuthenticated) {
    history.push("/auth/signin");
    return null; // This is necessary to prevent rendering of the component before redirect
  } else if (user_account_type !== 'borrower') {
    history.push("/agreement");
    return null; // This is necessary to prevent rendering of the component before redirect
  } else if (user_account_type === 'borrower') {
    history.push("/b-agreement");
    return null; // This is necessary to prevent rendering of the component before redirect
  }

  // If none of the conditions are met, render the component as usual
  return (
    <p>Hello World</p>
  )
}

export default AdobeSign;
