import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Integrated from "./img/integrated_singpass.png";
import Apply from "./img/borrowers_apply.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Typography, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Tooltip } from "react-tooltip";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import "./App.css";
import HomeHeader from "./Headesr";
import tiktokIcon from "./img/tik-tok.png";
import telegramicon from "./img/telegram.png";
import linkedinicon from "./img/linkedin.png";
import whatsappicon from "./img/whatsapp.png";

const Faq = () => {
  const history = useHistory();
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    setPageLoad(true);
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1280);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const faqs = [
    {
      id: 1,
      title: "How do I apply for a Business Loan?",
      answer: (
        <p>
          Get started by clicking on the sign up button above and it will take
          you through a step by step process to sign up for a smartlend account.
          Once you’re done with your sign up, you can head on into the dashboard
          and click on the apply loan button located on the menu bar on the
          left.
        </p>
      ),
    },
    {
      id: 2,
      title: "Can anyone apply for a Business Loan?",
      answer: (
        <p>
          Currently, only Singapore locally incorporated LLP (Limited Liability
          Partnership) or Pte. Ltd. (Private Limited) entities are eligible to
          apply for a business loan. However, if you have a different type of
          corporate entity, please reach out to us at support@smart-lend.com,
          and we'll explore how we might be able to assist you.
        </p>
      ),
    },
    {
      id: 3,
      title: "Why can’t I apply for a loan?",
      answer: (
        <p>
          If you're unable to apply for a loan, it usually means that you
          haven't synchronized your Corp Pass account with your Singpass yet.
          This synchronization process is necessary for accessing and applying
          for loans.
        </p>
      ),
    },
    {
      id: 4,
      title: "Can I speak to the lenders of the platform?",
      answer: (
        <p>
          Yes, you can speak to the lenders on our platform, but only if the
          lender has expressed interest in your loan request. In such cases, a
          chatbot request feature will be enabled on our platform. However,
          please note that we do not provide users with the private contact
          information of our lenders due to our privacy policies.
        </p>
      ),
    },
    {
      id: 5,
      title: "How can I talk to my private lender?",
      answer: (
        <p>
          If you would like to reach your lender on the loan offer that they
          have offered, you can click on the offer in the requested loan page,
          select the lender, and proceed to click on the negotiation button
          where you’ll be taken to the messaging page where you can start a
          conversation with your lender.
        </p>
      ),
    },
    {
      id: 6,
      title: "My loan has disappeared, where did it go?",
      answer: (
        <p>
          If you can't find your loan, don't worry! Once a loan is approved by
          both the borrower and lender, it is moved to the accepted loan page.
          Here, you can check the status of your loan, which will indicate the
          stage it's in before being disbursed. After your loan is disbursed, it
          will be moved to the "loan in progress" page. Here, you'll find
          detailed information about your loan and can make payments
          accordingly.
        </p>
      ),
    },
    {
      id: 7,
      title: "How long does it take for me to get a loan approved?",
      answer: (
        <div>
          <p>
            The approval time for your loan depends on several factors. First,
            you need to receive an offer from a lender. At Smart-Lend, we have a
            wide network of lenders who review your financial profile and the
            documents you've provided before making an offer. This process
            usually takes between 1 to 5 working days.
          </p>
          <p>
            Keep an eye out for our upcoming Service Guarantee feature! Soon,
            borrowers will enjoy even faster turnaround times, with approvals
            possible within 24 hours. Stay tuned for more details when we
            introduce this new feature.
          </p>
        </div>
      ),
    },
    {
      id: 8,
      title: "How long till my loan is disbursed?",
      answer: (
        <p>
          Once a loan is accepted by both the lender and borrower, it enters an
          approval stage where legal loan documents are exchanged. Once this
          process is completed, the loan can proceed to be disbursed. Once the
          offer letter is signed, usually the disbursement period will take
          within 1 or 2 working days or as fast as the day itself!
        </p>
      ),
    },
    {
      id: 9,
      title: "My Lender is not responding?",
      answer: (
        <div>
          <p>
            If you find yourself in a situation where your lender for an
            existing loan is not responding, we recommend taking action
            promptly. Please send an email to support@smart-lend.com, and we
            will intervene by reaching out to the lender on your behalf.
          </p>
          <p>
            Similarly, if a lender has offered you a loan but is unresponsive,
            don't hesitate to contact us. We are here to assist you and will
            work to resolve any issues you encounter.
          </p>
        </div>
      ),
    },
    {
      id: 10,
      title: "ZERO Fee? How can SmartLend. provide this service without a fee?",
      answer: (
        <div>
          <p>
            Yes, it's true! We do not charge borrowers any fees for loans
            initiated through our platform. We understand the challenges faced
            by SMEs, such as navigating through various stakeholders to get a
            loan approved and incurring additional costs by paying out to
            third-party brokers. By offering a comparison of loan offerings from
            different lenders, we empower SMEs to access borrowing options
            without any added fees.
          </p>
          <p>
            At SmartLend., we sustain our operations through referral fees
            received from our lenders for every initiated loan, as well as
            through advertorial fees. These fees enable us to provide our
            services to borrowers at no cost, ensuring accessibility and
            transparency in the lending process.
          </p>
        </div>
      ),
    },
    {
      id: 11,
      title: "Is my Singpass Information safe?",
      answer: (
        <p>
          Yes, all information provided through Singpass, goes through a secured
          line and is only used once upon syncing with your Singpass account.
          The information that is provided follows a strict privacy policy which
          is also stated in our privacy and policy page. As you sync with
          Singpass, you will also be able to see what are the specific
          information that will be sync with Smart-Lend providing you full
          transparency of what is happening.
        </p>
      ),
    },
    {
      id: 12,
      title: "My details in profile is incorrect.",
      answer: (
        <p>
          If your details in your profile page is incorrect, we urge you to
          ensure that your Singpass and Corppass details are up to date, as a
          way to receive credible information, we only update your profile base
          on what is provided through the authentication of your Singpass
          account. Once you have updated your Singpass and Corppass account, you
          can proceed to click on the Sync with Singpass button located on the
          top right of the Profile page.
        </p>
      ),
    },
    {
      id: 13,
      title:
        "Will submitting to multiple lenders affect my credit bureau rating?",
      answer: (
        <p>
          Submitting loan applications to multiple lenders through our platform
          will not impact your credit bureau score, except for Anext and
          Maribank. The other lenders on our platform do not perform a hard
          credit pull. Instead, you can retrieve your credit bureau report for
          free on our platform, and the same report will be shared with all the
          lenders you apply with.
        </p>
      ),
    },
    {
      id: 14,
      title:
        "I'm not comfortable letting all the lenders assess my information? Can i submit to you guys directly for assessment before submitting?",
      answer: (
        <p>
          Certainly, you can submit your information directly to us for
          assessment before applying with specific lenders. However, please note
          that we charge a success-based fee for this concierge service where we
          guide your application through the process. If you would like to
          discuss this option further, please reach out to one of our
          representatives.
        </p>
      ),
    },
  ];
  const filteredFaqs = faqs.filter((faq) =>
    faq.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const FAQItem = ({ id, title, answer }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "0.01rem solid #d5dee0",
          }}
          onClick={handleToggle}
          className="mui-button"
        >
          <IconButton onClick={handleToggle}>
            <ChevronRight
              style={{
                rotate: isCollapsed ? "0deg" : "90deg",
                transition: "all 0.3s ease-in-out",
              }}
            />
          </IconButton>
          <div
            variant="subtitle1"
            style={{
              fontWeight: "600",
              fontSize: screenWidth > 1200 ? "20px" : "14px",
            }}
          >
            {title}
          </div>
        </div>
        {!isCollapsed && (
          <div
            style={{
              marginLeft: "40px",
              paddingTop: "6px",
              fontSize: screenWidth > 1200 ? "15px" : "12px",
              fontWeight: "400",
              paddingBottom: "20px",
            }}
          >
            {answer}
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      {/* Header bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <div
            style={{
              width: "25%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend
          </div>
          <div
            style={{
              width: "75%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight:"10px"
              }}
            >
              Home
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/whysmartlend");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight:"10px"
              }}
            >
              Why SmartLend?
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight:"10px"
              }}
            >
              Partner With Us
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
             
              style={{ fontWeight: "700", color: "#256199", marginRight:"10px" }}
            >
              Frequently Asked Questions
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight:"10px"
              }}
            >
             News & Events
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight:"10px"
              }}
            >
              Contact Us
            </div>
            <hr
              style={{
                width: "2px",
                height: "20px",
                margin: "0px",
                outline: "none",
                border: "none",
                background: "rgba(0,0,0,0.3)",
              }}
            />
             
            <hr
              style={{
                width: "2px",
                height: "20px",
                margin: "0px",
                outline: "none",
                border: "none",
                background: "rgba(0,0,0,0.3)",
              }}
            />
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signup");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#256199",
                border: "2px solid #256199",
                padding: "5px 30px",
                borderRadius: "15px",
              }}
            >
              Sign Up
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "white",
                border: "2px solid #256199",
                background: "#256199",
                padding: "5px 40px",
                borderRadius: "15px",
              }}
            >
              Login
            </div>
          </div>
        </div> */}
        <HomeHeader />
      </div>

      {/* first segment */}
      <div
        style={{
          padding: "35px 0px",
          textAlign: "center",
          background: "#256199",
          marginLeft: pageLoad ? "0px" : "-300px",
          opacity: pageLoad ? "1" : "0",
          transition: "all 0.8s ease-in-out",
        }}
      >
        <div
          style={{
            fontSize: screenWidth > 1200 ? "45px" : "30px",
            fontWeight: "700",
            color: "white",
          }}
        >
          Frequently Asked Questions
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div
            style={{
              fontSize: "15px",
              fontWeight: "400",
              width: screenWidth > 1200 ? "50%" : "80%",
              marginTop: "10px",
              color: "white",
            }}
          >
            Here you will find answers to some of the most commonly asked
            questions about our work management platform, Premelo. If you have
            any additional questions that are not covered here, please do not
            hesitate to contact our support team for further assistance.
          </div>
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="🔍 Search by title, products and more"
          style={{
            border: "none",
            outline: "none",
            padding: "8px 20px",
            textAlign: "center",
            marginTop: "20px",
            borderRadius: "25px",
            height: "30px",
            width: screenWidth > 1200 ? "30%" : "60%",
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "40px",
          fontSize: screenWidth > 1200 ? "inherit" : "14px",
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
          {filteredFaqs.map((faq) => (
            <FAQItem
              key={faq.id}
              id={faq.id}
              title={faq.title}
              answer={faq.answer}
            />
          ))}
        </div>
      </div>

      {/* Footer section */}
      <div
        style={{
          padding: screenWidth > 1200 ? "40px 150px" : "40px 0px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: screenWidth > 1200 ? "left" : "center",
          background: "rgba(0,0,0,0.1)",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
          <div
            style={{
              width: screenWidth > 1200 ? "40%" : "100%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend.
          </div>
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Apply Once, Access All.
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "13px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            SmartLend Financial Pte. Ltd. Is part of Smart Towkay Brand
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "15px" }}
          >
            Singapore HQ
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "3px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            114 Lavender St., #07-77 CT Hub 2, Singapore 338729
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "15px" }}
          >
            Folow us and Stay updated
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: screenWidth > 1200 ? "flex-start" : "center",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <FacebookIcon
              onClick={() => {
                window.location.href =
                  "https://www.facebook.com/smarttowkaysg/";
              }}
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              onClick={() => {
                window.location.href =
                  "https://www.instagram.com/smart-towkay/";
              }}
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            {/* <TwitterIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            /> */}
            <img
              className="menu_button"
              src={tiktokIcon}
              onClick={() => {
                window.location.href = "https://www.tiktok.com/@towkaypeople";
              }}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={telegramicon}
              onClick={() => {
                window.location.href = "https://t.me/smarttowkaysg";
              }}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={linkedinicon}
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/company/smart-towkay/?originalSubdomain=sg";
              }}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={whatsappicon}
              onClick={() => {
                window.location.href = "https://wa.me/6588288147";
              }}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
          </div>
        </div>
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>Sitemap</div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
            >
              Partner With Us
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
            >
              News & Events
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          background: "rgba(0,0,0,0.85)",
          justifyContent: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div
          style={{
            width: screenWidth > 1200 ? "60%" : "80%",
            color: "rgba(256,256,256,.9)",
            fontSize: screenWidth > 1200 ? "14px" : "10px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            textAlign: "center",
          }}
        >
          <div>Terms and Conditions</div>
          <div style={{ width: "10px" }}>•</div>
          <div>© SmartLend Financial Pte. Ltd.</div>
          <div style={{ width: "10px" }}>•</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
