import React, { useEffect, useState } from "react";
import bgvideo from "../src/img/bgvideo.mp4";
import RepeatIcon from "@mui/icons-material/Repeat";
import Checkbox from "@mui/material/Checkbox";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaidIcon from "@mui/icons-material/Paid";

const SuperAdmin = () => {
  const [loanData, setLoanData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [platformStats, setPlatformStats] = useState({
    Collected: 0,
    UnrealizedProfit: 0,
    LoansCreated: 0,
    NumberOfUsers: 0,
  });

  const password = "test123456";
  const [loggedIn, setLoggedIn] = useState(false);
  const [passwordField, setPasswordField] = useState("");
  const [error, setError] = useState(false);
  function getFormattedDate() {
    const date = new Date();

    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  function getFormattedDateWithoutDate() {
    const date = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${year}`;
  }

  useEffect(() => {
    if (loanData[getFormattedDateWithoutDate()]) {
      let collectedProfit = loanData[getFormattedDateWithoutDate()]
        .filter(
          (item) => item.fees_collected === true || item.fees_collected === 1
        )
        .reduce((sum, item) => sum + item.amount, 0);
      let unrealizedProfit = loanData[getFormattedDateWithoutDate()]
        .filter(
          (item) => item.fees_collected === false || item.fees_collected === 0
        )
        .reduce((sum, item) => sum + item.amount, 0);
      let LoansCreated = loanData[getFormattedDateWithoutDate()]?.length;
      let userCreated = userData[getFormattedDateWithoutDate()]?.length;

      setPlatformStats({
        Collected: collectedProfit * 0.015,
        UnrealizedProfit: unrealizedProfit * 0.015,
        LoansCreated: LoansCreated,
        NumberOfUsers: userCreated,
      });
    }
  }, [loanData, userData]);

  const [timerDone, setTimerDone] = useState(0);

  useEffect(() => {
    if (loggedIn) {
      setTimerDone(1);
      // Set a timer for 3 seconds (3000 ms)
      const timer = setTimeout(() => {
        setTimerDone(2); // Change the state to true
      }, 3000);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [loggedIn]);

  // Helper function to format date as "Month Year"
  const formatDateToMonthYear = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options); // E.g., "January 2024"
  };

  // Function to group data by "Month Year"
  const groupByMonthYear = (data) => {
    return data.reduce((acc, curr) => {
      const monthYear = formatDateToMonthYear(curr.created_at);

      // If the monthYear already exists in the accumulator, add the object to the array
      if (acc[monthYear]) {
        acc[monthYear].push(curr);
      } else {
        // Otherwise, create a new array for that monthYear
        acc[monthYear] = [curr];
      }

      return acc;
    }, {}); // Start with an empty object
  };

  const getAllLoans = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loans_for_roy/getAllRoyLoans",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        const grouped = groupByMonthYear(json.data);
        console.log(grouped);
        setLoanData(grouped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllUser = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/superAdminSl/getAllUser",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        const grouped = groupByMonthYear(json.data);
        setUserData(grouped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeFeesCollected = async (loan_id, lender_id, feebool, key) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/superAdminSl/changeFeeCOllected",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            loan_id: loan_id,
            lender_id: lender_id,
            fees_collected: feebool,
          }),
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        console.log(json);
        const updatingLoan = { ...loanData };
        const loanIndex = updatingLoan[key].findIndex(
          (loan) => loan.loan_id === loan_id && loan.lender_id === lender_id
        );
        if (loanIndex > -1) {
          updatingLoan[key][loanIndex].fees_collected =
            json.data.fees_collected;
          setLoanData(updatingLoan);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllUser();
    getAllLoans();
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <video
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          filter: "blur(2px)",
        }}
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={bgvideo} type="video/mp4" />
      </video>
      <div
        style={{
          position: "absolute",
          zIndex: 5,
          background: "rgba(256,256,256, 0.05)",
          backdropFilter: "blur(30px)",
          height: "calc(100% - 100px)",
          width: "calc(100% - 100px)",
          margin: "50px 50px",
          border: "1px solid rgba(256,256,256,0.7)",
          borderRadius: "30px",
          boxShadow:
            "0px 0px 20px rgba(0,0,0,0.2), inset 0px 0px 15px rgba(256,256,256,0.5)",
          padding: "30px 45px",
          color: "white",
          fontWeight: 400,
          fontSize: "13px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            opacity: timerDone === 0 ? 1 : 0,
            transform: "translate(-50%,-50%)",
            left: "50%",
            top: "50%",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (passwordField === password) {
                setError(false);
                setLoggedIn(true);
              } else {
                setError(true);
              }
            }}
            style={{ textAlign: "center" }}
          >
            <div
              style={{
                marginBottom: "10px",
                fontWeight: 700,
                fontSize: "20px",
              }}
            >
              Welcome Roy
            </div>
            <input
              value={passwordField}
              onChange={(e) => {
                setPasswordField(e.target.value);
              }}
              type="password"
              style={{
                background: "rgba(256,256,256,0.1)",
                border: "1px solid rgba(256,256,256,0.7)",
                padding: "8px 20px",
                borderRadius: "6px",
                width: "350px",
                textAlign: "center",
                color: "white",
                outline: "none",
              }}
              placeholder="password"
            />
            {error ? (
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  color: "#ba0e02",
                }}
              >
                The password you've entered is invalid.
              </div>
            ) : null}
          </form>
        </div>
        <div
          style={{
            opacity: timerDone === 1 ? 1 : 0,
            transition: "1s all ease-in-out",
            transitionDelay: "0.5s",
            position: "absolute",
            zIndex: 6,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontWeight: 700, fontSize: "30px" }}>
            Welcome to your Dashboard,
          </div>
          <div style={{ fontSize: "20px" }}>How is your day McRoy?</div>
        </div>
        <div
          style={{
            opacity: timerDone === 2 ? 1 : 0,
            transition: "0.5s all ease-in-out",
            transitionDelay: "0.5s",
            position: "relative",
            zIndex: 7,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "40px",
            }}
          >
            <div>
              <div style={{ fontWeight: 800, fontSize: "20px" }}>
                Welcome McRoy,
              </div>
              <div style={{}}>
                This is your dedicated space to monitor your platform.
              </div>
            </div>
            <div>{getFormattedDate()}</div>
          </div>
          <div style={{ height: "225px", marginTop: "45px" }}>
            <div style={{ fontWeight: 800, fontSize: "16px" }}>
              Platform Stats ({getFormattedDateWithoutDate()})
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "15px",
                gap: "15px",
                overflowY: "hidden",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  height: "150px",
                  width: "25%",
                  borderRadius: "12px",
                  border: "1px solid rgba(256,256,256,0.7)",
                  boxShadow: "inset 0px 0px 10px rgba(256,256,256,0.4)",
                  padding: "20px",
                  background: "rgba(256,256,256,0.1)",
                }}
              >
                <div>Collected Platform Fee.</div>
                <div style={{ fontSize: "35px", fontWeight: 700 }}>
                  {platformStats.Collected?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })}
                </div>
              </div>
              <div
                style={{
                  height: "150px",
                  width: "25%",
                  borderRadius: "12px",
                  border: "1px solid rgba(256,256,256,0.7)",
                  boxShadow: "inset 0px 0px 10px rgba(256,256,256,0.4)",
                  padding: "20px",
                  background: "rgba(256,256,256,0.1)",
                }}
              >
                <div>Unrealized Platform Fee.</div>
                <div style={{ fontSize: "35px", fontWeight: 700 }}>
                  {platformStats.UnrealizedProfit?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })}
                </div>
              </div>
              <div
                style={{
                  height: "150px",
                  width: "25%",
                  borderRadius: "12px",
                  border: "1px solid rgba(256,256,256,0.7)",
                  boxShadow: "inset 0px 0px 10px rgba(256,256,256,0.4)",
                  padding: "20px",
                  background: "rgba(256,256,256,0.1)",
                }}
              >
                <div>Loans Created.</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "7px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "45px",
                      fontWeight: 700,
                      marginBottom: "0px",
                    }}
                  >
                    {platformStats.LoansCreated}
                  </div>
                  <div style={{ marginBottom: "12px" }}>Loans</div>
                </div>
              </div>
              <div
                style={{
                  height: "150px",
                  width: "25%",
                  borderRadius: "12px",
                  border: "1px solid rgba(256,256,256,0.7)",
                  boxShadow: "inset 0px 0px 10px rgba(256,256,256,0.4)",
                  padding: "20px",
                  background: "rgba(256,256,256,0.1)",
                }}
              >
                <div>Number of Users.</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "7px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "45px",
                      fontWeight: 700,
                      marginBottom: "0px",
                    }}
                  >
                    {platformStats.NumberOfUsers}
                  </div>
                  <div style={{ marginBottom: "12px" }}>Users</div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              fontWeight: 800,
              fontSize: "16px",
              marginTop: "20px",
              height: "24px",
            }}
          >
            Loans requested (Platform Collection)
          </div>
          <div
            style={{
              height: "calc(100% - 362px)",
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              marginTop: "8px",
            }}
          >
            {Object.keys(loanData).map((key) => (
              <div
                key={key}
                style={{
                  width: "30%",
                  height: "calc(100% - 24px)",
                  padding: "20px",
                  borderRadius: "12px",
                  border: "1px solid rgba(256,256,256,0.7)",
                  marginTop: "8px",
                  boxShadow: "inset 0px 0px 10px rgba(256,256,256,0.4)",
                  overflowX: "hidden",
                  overflowY: "auto",
                  background: "rgba(256,256,256,0.1)",
                }}
              >
                <div style={{ fontWeight: 800 }}>{key}</div>
                <div>
                  {loanData[key].map((loan, key1) => (
                    <div key={key1}>
                      <div
                        style={{
                          width: "25%",
                          height: "1px",
                          background: "rgba(256,256,256,0.5)",
                          marginTop: "15px",
                          marginBottom: "15px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: "5px",
                            }}
                          >
                            {loan?.lenderDetails?.principal_name}
                          </div>
                          <div style={{ marginLeft: "20px" }}>
                            <RepeatIcon
                              style={{
                                color: "white",
                                fontSize: "20px",
                                transform: "rotate(90deg)",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: "5px",
                            }}
                          >
                            {loan?.borrowerDetails?.entity_name}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              fontSize: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <div>
                              <div>Amount</div>
                              <div>
                                {loan?.amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })}
                              </div>
                            </div>
                            <div>
                              <div>Collection</div>
                              <div>
                                {(loan?.amount * 0.015)?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              </div>
                            </div>
                            <div>
                              <div>Status</div>
                              <div>{loan?.status}</div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            checked={loan?.fees_collected ? true : false}
                            onChange={(e) => {
                              changeFeesCollected(
                                loan?.loan_id,
                                loan?.lender_id,
                                e.target.checked,
                                key
                              );
                            }}
                            icon={
                              <AttachMoneyIcon
                                style={{ color: "white", fontSize: "30px" }}
                              />
                            }
                            checkedIcon={
                              <PaidIcon
                                style={{ color: "white", fontSize: "30px" }}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
