import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Steps } from "antd";
import mailIcon from "../../assets/img/mail-icon.png";
import { MobileStepper } from "@mui/material";
import Cookies from "js-cookie";
import { Button, Modal, message } from "antd";

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [screenWidth, setScreenWidth] = useState("1920");
  const handleSubmitEmail = (event) => {
    event.preventDefault();
    setIsLoading(true);

    fetch("https://www.smart-lend.com/api/auth/register/emailResend", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to resend email");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  let semail = "";
  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/auth/register/viewUser",
        options
      );
      const json = await response.json();
      const email = json.email;
      if (email) {
        console.log(email);
        setEmail(email);
        semail = email;
      }
      setData(response);
    }
    fetchData();
  }, []);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData2 = async () => {
      if (!modalIsOpen) {
        try {
          console.log(semail);
          const response = await fetch(
            "https://www.smart-lend.com/api/registration/checkEmailVerifiedOrNot",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify({ email: semail }),
            }
          );
          if (response.status === 200) {
            Cookies.delete("token");
            setModalIsOpen(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    // Initial fetch when the component mounts
    fetchData2();

    // Set up the interval to fetch data every 10 seconds
    const intervalId = setInterval(() => {
      fetchData2();
    }, 10000); // 10000ms = 10 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Modal
        open={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
          window.location.href = "https://smart-lend.com/auth/signin";
        }}
        footer={null}
      >
        <div
          style={{
            color: "#0e5aa5",
            fontWeight: 800,
            fontFamily: "Manrope, sans-serif",
            fontSize: 22,
          }}
        >
          Email Verified
        </div>
        <div
          style={{
            color: "lighgrey",
            fontWeight: 400,
            fontFamily: "Manrope, sans-serif",
            fontSize: 13,
          }}
        >
          Your registered email has been verified, you may proceed to login to
          the account.
        </div>
        <button
          className="btn-submit-verify"
          style={{ marginTop: "10px" }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "https://smart-lend.com/auth/signin";
          }}
        >
          Proceed
        </button>
      </Modal>
      {screenWidth > 1200 ? (
        <React.Fragment>
          <div className="auth-header">
            <h3 className="auth-header-title-verify">
              <img src={mailIcon} alt="" className="auth-header-mail-logo" />{" "}
              Verify Email
            </h3>
            <br />
            <p className="auth-header-subtitle-verify">
              You're almost there! We sent an email to{" "}
              <span style={{ color: "blue" }}>{email}</span>
              <br />
              <br />
              Just click on the link in the email to complete your sign up.
              <br />
              If you don't see it, you may need to check your spam folder.
              <br />
              <br />
              Still can't find the email?
            </p>
          </div>
          <div className="auth-container">
            <div className="auth-body">
              <form
                className="auth-form-validation"
                onSubmit={handleSubmitEmail}
              >
                <div className="btn-position">
                  <button
                    type="submit"
                    className="btn-submit-verify"
                    disabled={isLoading}
                  >
                    {isLoading ? "Sending..." : "Resend Email"}
                  </button>
                </div>
              </form>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="text-center-verify">
                  Need Help?{" "}
                  <span
                    style={{
                      color: "#0e5aa5",
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.location.href = "https://wa.me/6588288147";
                    }}
                  >
                    Contact Us
                  </span>
                </p>
                <div style={{ width: 10 }}>
                  <MobileStepper
                    variant="dots"
                    steps={6}
                    position="static"
                    activeStep={1}
                    sx={{ maxWidth: 400, flexGrow: 1, marginLeft: -3.2 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div className="auth-header">
              <h3 className="auth-header-title-verify">
                <img src={mailIcon} alt="" className="auth-header-mail-logo" />{" "}
                Verify Email
              </h3>
              <br />
              <p className="auth-header-subtitle-verify">
                You're almost there! We sent an email to{" "}
                <span style={{ color: "blue" }}>{email}</span>
                <br />
                <br />
                Just click on the link in the email to complete your sign up.
                <br />
                If you don't see it, you may need to check your spam folder.
                <br />
                <br />
                Still can't find the email?
              </p>
            </div>
            <div className="auth-container">
              <div className="auth-body">
                <form
                  className="auth-form-validation"
                  onSubmit={handleSubmitEmail}
                >
                  <div className="btn-position">
                    <button
                      type="submit"
                      className="btn-submit-verify"
                      disabled={isLoading}
                    >
                      {isLoading ? "Sending..." : "Resend Email"}
                    </button>
                  </div>
                </form>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        marginLeft: "0px",
                        marginRight: "50px",
                      }}
                    >
                      Need Help? <br />
                      <span
                        style={{
                          color: "#0e5aa5",
                          textDecoration: "underline",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.location.href = "https://wa.me/6588288147";
                        }}
                      >
                        Contact Us
                      </span>
                    </p>
                  </div>
                  <div style={{}}>
                    <MobileStepper
                      variant="dots"
                      steps={6}
                      position="static"
                      activeStep={1}
                      sx={{ maxWidth: 300, flexGrow: 1 }}
                    />
                  </div>
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
   <p className="text-center-verify">
     Need Help?{" "}
              <span
                style={{
                  color: "#0e5aa5",
                  textDecoration: "underline",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href = "https://wa.me/6588288147";
                }}
              >
                Contact Us
              </span>
   </p>
   <div style={{ width: 10, }}>
     <MobileStepper
       variant="dots"
       steps={6}
       position="static"
       activeStep={1}
       sx={{ maxWidth: 400, flexGrow: 1, marginLeft:-3.2 }}
     />
   </div>
   </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
