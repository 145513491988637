import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Icon,
  FormControl,
  FormLabel,
  Slider,
} from "@mui/material";
import { tokens } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import TinyLineChart from "../../components/TinyLineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import { useHistory } from "react-router-dom";
import ProgressCircle from "../../components/ProgressCircle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableContainer,
} from "@material-ui/core";
import HalfPieChart from "../../components/HalfPieChart";
import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";
import { Progress, Button, Modal, message } from "antd";
import TableLoan from "../../components/TableLoan";
import TableDelayedPayment from "../../components/TableDelayedPayment";
import Cookies from "js-cookie";
import { format } from "date-fns";
import CountdownWithHover from "../../components/CountDownWithHover";
import StarIcon from "@mui/icons-material/Star";
import defaultIcon from "../../assets/img/Profile.png";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";

const useStyles = makeStyles({
  formControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ##141414`,
  },
});

const gradient = "linear-gradient(to right, #ffffff, #0083b0)"; // define the gradient as a variable

const ContainerRow2 = ({
  icon,
  title,
  subtitle,
  label,
  description,
  label1,
  label2,
  description1,
  description2,
}) => {
  const connector = <StepConnector />;
  const steps = [label1, label2];

  return (
    <table
      style={{
        width: "400px",
        border: "1px solid white",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
      }}
    >
      <thead>
        <tr>
          <th colSpan="2" style={{ textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={icon}
                alt={title}
                style={{ width: "70px", height: "55px", borderRadius: "10px" }}
              />
              <div
                style={{
                  marginLeft: "15px",
                  marginBottom: "10px",
                  marginTop: "7px",
                  marginRight: "1px",
                }}
              >
                <h3
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  {title}
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "200",
                    fontSize: "11px",
                  }}
                >
                  {subtitle}
                </p>
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th
            style={{
              width: "200px",
              textAlign: "left",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "400",
              fontSize: "13px",
            }}
          >
            Label
          </th>
          <th
            style={{
              width: "200px",
              textAlign: "left",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "15px",
            }}
          >
            Description
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "400",
              fontSize: "13px",
            }}
          >
            {label}
          </td>
          <td
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "15px",
            }}
          >
            {description}
          </td>
        </tr>
        <tr>
          <td
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "400",
              fontSize: "13px",
            }}
          >
            {label1}
          </td>
          <td
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "15px",
            }}
          >
            {description1}
          </td>
        </tr>
        <tr>
          <td
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "400",
              fontSize: "13px",
            }}
          >
            {label2}
          </td>
          <td
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "15px",
            }}
          >
            {description2}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const ContainerTransaction = ({ title, label, description }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "5px 0px",
    }}
  >
    <div style={{ flex: 1, alignItems: "left" }}>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "700",
          fontSize: "13px",
          color: "#142C44CC",
          margin: "0px",
        }}
      >
        {title}
      </h3>
      <p
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "400",
          fontSize: "10px",
          color: "rgba(0,0,0,0.4)",
          margin: "0px",
        }}
      >
        {description}
      </p>
    </div>
    <div style={{}}>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "700",
          fontSize: "13px",
          color: "rgb(58, 175, 76)",
          margin: "0px",
        }}
      >
        {label}
      </h3>
    </div>
  </div>
);

const ContainerLineChart = ({ title, subtitle }) => (
  <div
    style={{
      width: "45%",
      height: "50%",
      margin: "0 auto",
      border: "1px solid black",
    }}
  >
    <TinyLineChart />
    <h3>{title}</h3>
    <p>{subtitle}</p>
  </div>
);

const ContainerPieChart = ({ title, subtitle }) => (
  <div
    style={{
      width: "45%",
      height: "50%",
      margin: "0 auto",
      border: "1px solid black",
    }}
  >
    <HalfPieChart />
    <h3>{title}</h3>
    <p>{subtitle}</p>
  </div>
);

const Loan = () => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dataActivityLog, setActivityLog] = useState([]);
  const [data, setData] = useState([]);
  const [dataPendingAcceptance, setPendingAcceptance] = useState([]);
  const [tickValues, setTickValues] = useState([]);
  const [dataClient, setClientData] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [averageClientsPerMonth, setAverageClientsPerMonth] = useState(0);
  const [interestProfit, setInterestProfit] = useState([]);
  const [dataRequestedLoan, setRequestedLoan] = useState([]);
  const [dataRequestedLoanToday, setRequestedLoanToday] = useState([]);
  const [loanAverage, setLoanAverage] = useState("");
  const [loanPeriod, setLoanPeriod] = useState(0);
  const [type, setLoanType] = useState("simple");
  const [offer_interest_rate, setInterestRate] = useState(0);
  const [offer_processing_fee, setProcessingFee] = useState(0);
  const [total_admin_fee, setTotalAdminFee] = useState(0);
  const [admin_fee, setAdminFee] = useState(0);
  const [offer_amount, setLoanAmount] = useState(0);
  const [offer_period, setSelectedLoanPeriod] = useState(0);
  const [offer_principal, setLoanPrincipal] = useState(0);
  const [offer_monthly_installment, setExpectedMonthlyInstallment] =
    useState(0);
  const [offer_revenue, setGuaranteedRevenue] = useState(0);
  const history = useHistory();
  const [loanDetails, setLoanDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalInterestProfit, setTotalInterestProfit] = useState(0);
  const [averageInterestProfit, setAverageInterestProfit] = useState(0);
  const [targetDate, setTargetDate] = useState(null);
  const [screenWidth, setScreenWidth] = useState("1920");

  const ContainerTransaction2 = ({ title, label, description }) => (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        margin: "30px 1px 1px 30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "left", // added property
          alignItems: "left",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: screenWidth < 800 ? "12px" : "18px",
            color: "#142C44CC",
            marginTop: "0px",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </h3>
      </div>
    </div>
  );

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          fontWeight: "600",
          fontSize: screenWidth < 800 ? "12px" : "18px",
          color: "#142C44CC",
          textAlign: "center",
          margin: "0px",
        }}
      >
        {noData}
      </p>
    </div>
  );

  const handleClickInterestProfit = () => {
    history.push("/analytics");
  };

  const handleClickClient = () => {
    history.push("/analytics");
  };

  const handleClickLoanRequest = () => {
    history.push("/loan-request-grid");
  };

  const handleClickPendingLoan = () => {
    history.push("/pending-acceptance");
  };

  const timerStyle = {
    color: "grey",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: "12px",
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    let timeLeft = "";

    if (completed) {
      return <span style={timerStyle}>Time's up!</span>;
    } else {
      if (days > 0) {
        timeLeft = `${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timeLeft = `${hours}h ${minutes}m ${seconds}s`;
      } else {
        timeLeft = `${minutes}m ${seconds}s`;
      }

      return <span style={timerStyle}>{timeLeft}</span>;
    }
  };

  const calculateCountdown = (expiredDate) => {
    const now = new Date().getTime();
    const targetDate = new Date(expiredDate).getTime();
    const timeDiff = targetDate - now;

    if (timeDiff <= 0) {
      return "Expired";
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}d: ${hours}h: ${minutes}m`;
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const Container = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    index,
    credit_score,
    countDown,
    loan_id,
    createdAt,
    item,
  }) => {
    const shouldAddMarginTop = index >= 4;
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "23.5%" : "calc(50% - 5px)",
            textAlign: "center",
            border:
              subtitle === "Express Applicant"
                ? "1px solid rgb(242, 193, 80)"
                : "none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: screenWidth > 1200 ? "40vh" : "auto",
            padding: "15px",
            position: "relative", // add margin top if index is greater than 3
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              display: subtitle === "Express Applicant" ? "block" : "none",
            }}
          >
            <StarIcon
              style={{ color: "rgba(242, 193, 80)", fontSize: "25px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                background: "rgb(140,140,170)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
                fontSize: 20,
                fontWeight: 600,
                position: "relative"
              }}
              onClick={() =>
                singpassDetails(
                  item.user_id,
                  item.authorization_required,
                  item.loan_id,
                  item.keyman
                )
              }
            >
              {title ? getInitials(title) : "SL"}
              {item.badge_verified ? (
                <div
                  className="tooltip-container"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: "18px",
                    height: "18px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgb(14, 90, 165)",
                  }}
                >
                  <LocalPoliceIcon
                    className="tooltip-target"
                    style={{ color: "white", fontSize: "14px" }}
                  />
                  <span className="tooltip-text">
                    Verified borrowers have undergone SmartLend's authentication
                    process, demonstrating legitimate business operations. This
                    includes providing evidence such as current tenancy
                    agreements, business premises signage, or recent invoices
                    matching their bank statements
                  </span>
                </div>
              ) : null}
            </div>
            <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
              <h3
                style={{
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  color: "#142C44CC",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {title}
              </h3>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {subtitle}
              </p>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
            }}
          >
            {/* <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "10px 0px -5px 0px",
              }}
            >
              {label}
            </p>
            <Progress
              percent={credit_score}
              showInfo={false}
              strokeColor={
                subtitle === "Express Applicant"
                  ? "rgba(242, 193, 80)"
                  : "#1557a9"
              }
            />
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "-5px 0px 10px 0px",
              }}
            >
              {description}
            </p> */}
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
                marginTop: "20px",
              }}
            >
              {label1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Expecting Interest Rate Per Month
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {(item.request_interest_rate * 100).toFixed(1) + " %"}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Expecting Processing Fee
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {(item.admin_fee * 100).toFixed(1) + " %"}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {label2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Validity
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {calculateCountdown(countDown)}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Created At
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {formatDate(createdAt)}
            </p>
          </div>
          {/* <CountdownWithHover targetDate={targetDate} renderer={renderer} /> */}
          <div key={loan_id}>
            <button
              className="all_button"
              onClick={(event) => {
                event.preventDefault();
                const url = `/l-messages?principelname=${item.principal_name}&userid=${item.user_id}&requestamount=${item.request_amount}`;
                history.push(url);
              }}
              style={{
                background: "transparent",
                color: "grey",
                width: "100%",
                border: "2px solid lightgrey",
                borderRadius: "15px",
                padding: "4px 20px",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              Message borrower
            </button>
            <button
              className="all_button"
              onClick={(event) => showModal(loan_id, event)}
              style={{
                background:
                  subtitle === "Express Applicant"
                    ? "rgba(242, 193, 80)"
                    : "#1557a9",
                color: "white",
                width: "100%",
                border: "none",
                borderRadius: "50px",
                padding: "6px 0px",
                fontSize: "12px",
                margin: "10px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Make an offer{" "}
              {subtitle === "Express Applicant" ? (
                <HourglassTopIcon
                  className="rotating_hourglass"
                  style={{
                    color: "white",
                    fontSize: "16px",
                    marginLeft: "5px",
                  }}
                />
              ) : null}
            </button>
          </div>
          <div>
            <button
              className="all_button"
              onClick={(event) => {
                event.preventDefault();
                window.open(item.bank_statement, "_blank");
              }}
              style={{
                background: "transparent",
                color: "grey",
                width: "100%",
                border: "2px solid lightgrey",
                borderRadius: "15px",
                padding: "4px 20px",
                fontSize: "12px",
                margin: "0px",
              }}
            >
              Download Company Financial Information
            </button>
          </div>
        </div>
      </>
    );
  };
  const handleDownloadBankStatement = async (loan_id, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewBankStatement?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      if (data && data.data) {
        window.open(data.data, "_blank"); // open the url in a new tab
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ContainerRow1 = ({
    onClick,
    icon,
    title,
    subtitle,
    label,
    description,
  }) => (
    <div
      onClick={onClick} // The onClick event handler
      style={{
        width: screenWidth > 1200 ? "48%" : "100%",
        textAlign: "center",
        margin: "0px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "rgba(255,255,255,0.7)",
        cursor: "pointer",
        padding: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "17px",
            color: "#142C44CC",
            textAlign: "left",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "200",
            fontSize: "8px",
            color: "#142C44CC",
            textAlign: "right",
            margin: "0px",
          }}
        >
          {subtitle}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginTop: "13px",
        }}
      >
        <div
          style={{
            width: "55px",
            height: "55px",
            background: "rgba(0,0,0,0.03)",
            borderRadius: "35px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px",
          }}
        >
          <img
            src={icon}
            alt={title}
            style={{
              background: "#f0f0f0",
              width: "30px",
              height: "30px",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            width: "calc(100% - 70px)",
            margin: "0px 0px 0px 10px",
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "800",
              fontSize: "20px",
              textAlign: "left",
              color: "#142C44CC",
              margin: "0px",
            }}
          >
            {label}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              textAlign: "left",
              margin: "0px",
              lineHeight: "11px",
            }}
          >
            {description}
          </p>
        </div>
      </div>
      {/* <p>{description}</p> */}
    </div>
  );

  const ContainerRow3 = ({ noData }) => (
    <div
      style={{
        width: screenWidth > 1200 ? "48%" : "100%",
        textAlign: "center",
        margin: screenWidth < 800 ? "0px" : "30px 1px 1px 10px",
        border: "1px solid none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <p
        style={{
          fontWeight: "600",
          fontSize: screenWidth < 800 ? "12px" : "18px",
          color: "#142C44CC",
          textAlign: "center",
          margin: "50px auto",
        }}
      >
        {noData}
      </p>
    </div>
  );

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", color: "grey" }}>
        <img
          src={icon}
          alt={title}
          style={{
            margin: "8px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
            borderRadius: "50%",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        />
        <div style={{ marginLeft: "10px" }}>
          <h3
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              margin: "0px",
            }}
          >
            You made a request of {title} at {subtitle} % interest rate
          </h3>
          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontSize: "10px", fontWeight: "400", marginLeft: "10px" }}>{subtitle}</p> */}
        </div>
      </div>
    </div>
  );

  const Container3 = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "97%" : "100%",
            textAlign: "center",
            height: "240px",
            marginLeft: screenWidth > 1200 ? "25px" : "0px",
            border: "1px solid none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "400",
              fontSize: screenWidth < 800 ? "12px" : "18px",
              color: "#142C44CC",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  function isToday(date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  function isYesterday(date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  function isTwoDaysAgo(date) {
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    return (
      date.getDate() === twoDaysAgo.getDate() &&
      date.getMonth() === twoDaysAgo.getMonth() &&
      date.getFullYear() === twoDaysAgo.getFullYear()
    );
  }

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/analytics/client",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
          }
        );
        const result = await response.json();
        const transformedData = result["Clients"].map((d) => ({
          month: d.month,
          client: d.client,
        }));
        setTickValues([...new Set(transformedData.map((d) => d.client))]);
        setClientData(transformedData);
        setTotalClients(result["Total clients"]);
        setAverageClientsPerMonth(result["Average clients per month"]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewPendingAcceptance",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setPendingAcceptance(data["Pending Loan"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/loanDashboard/viewInterestProfit",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setInterestProfit(data);
        setTotalInterestProfit(data["Total Interest Profit"]);
        setAverageInterestProfit(data["Average"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/lender/viewLatestTransaction",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setData(data["Latest Transaction"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    let value = e.target.value;
    setInterestRate(value);
  };

  const handleLoanPeriodChange = (e) => {
    setSelectedLoanPeriod(e.target.value);
  };

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  useEffect(() => {
    const calculateLoan = () => {
      if (type === "simple") {
        const offer_monthly_installment =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            1.0 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            0.0 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
          0.0;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      } else if (type === "effective") {
        const offer_monthly_installment =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 *
            0.05 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = offer_monthly_installment * 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      }
    };
    calculateLoan();
  }, [
    offer_amount,
    offer_interest_rate,
    offer_period,
    type,
    offer_principal,
    offer_monthly_installment,
    offer_revenue,
    total_admin_fee,
    admin_fee,
  ]);

  const fetchLoanDetails = async (loan_id) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setLoanDetails(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [modalStates, setModalStates] = useState({});

  useEffect(() => {
    const initialStates = {};
    data.forEach((item) => {
      initialStates[item.loan_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };
  const [open, setOpen] = useState(false);
  const showModal = (loanId, event) => {
    event.preventDefault();
    console.log("loanId modal", loanId);
    setModalStates((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleListPath = () => {
    history.push("../loan-request");
  };

  const handleMakeOffer = async (loan_id, express) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    // rest of the code
    try {
      let path = "";
      let item = {
        loan_id,
        express,
        offer_amount: parseFloat(parseFloat(offer_amount).toFixed(2)),
        offer_interest_rate: parseFloat(parseFloat(offer_interest_rate / 100)),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(parseFloat(offer_principal).toFixed(2)),
        offer_monthly_installment: parseFloat(
          parseFloat(offer_monthly_installment).toFixed(2)
        ),
        offer_revenue: parseFloat(parseFloat(offer_revenue).toFixed(2)),
        total_admin_fee: parseFloat(parseFloat(total_admin_fee).toFixed(2)),
        admin_fee: 0,
        processing_fee: parseFloat(offer_processing_fee / 100),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        message.success(data.message || "Loan offer created successfully."); // Display success message
        path = "/pending-acceptance";
        return history.push(path);
      } else if (response.status === 400) {
        message.error(data.message || "Unable to create loan offer."); // Display error message
      } else {
        message.error("An unexpected error has occurred."); // Handle other errors
      }

      console.log("Offer created:", data);
      return history.push(path);
      // Update your UI or close the modal
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };
  const buttonStyleExpress = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background: "#FED065",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const buttonStyleBorrower = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background:
      "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/loanDashboard/viewRequestToday",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setRequestedLoanToday(data["Requested Loan"]);

        // Check if the array is not empty
        if (data["Requested Loan"].length > 0) {
          // Parse the expired_date into a JavaScript Date object and get its timestamp
          const targetDate = new Date(
            data["Requested Loan"][0].expired_date
          ).getTime();
          setTargetDate(targetDate);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  function calculateLoanRequestPercentage(data) {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    let currentMonthCount = 0;
    let lastMonthCount = 0;

    data["Requested Loan"].forEach((loan) => {
      const loanDate = new Date(loan.created_at);
      const loanMonth = loanDate.getMonth();
      const loanYear = loanDate.getFullYear();

      if (loanMonth === currentMonth && loanYear === currentYear) {
        currentMonthCount++;
      } else if (
        (loanMonth === currentMonth - 1 && loanYear === currentYear) ||
        (loanMonth === 11 && loanYear === currentYear - 1)
      ) {
        lastMonthCount++;
      }
    });

    // calculate percentage increase or decrease
    let percentage = 0;
    if (lastMonthCount !== 0) {
      percentage =
        ((currentMonthCount - lastMonthCount) / lastMonthCount) * 100;
      console.log("currentMonthCount:", currentMonthCount);
      console.log("lastMonthCount:", lastMonthCount);
      console.log("percentage:", percentage);
    }

    return percentage;
  }

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewLoanRequest",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setRequestedLoan(data["Requested Loan"]);

        const percentageChange = calculateLoanRequestPercentage(data);
        console.log("Percentage change from last month:", percentageChange);
        setLoanAverage(percentageChange);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setActivityLog(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const convertNumberToInt = (number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "m";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(3).replace(/\.?0+$/, "") + "k";
    } else {
      return number.toString();
    }
  };

  const [dppicture, setdppicture] = useState([]);

  const fillAllLenderPpicture = async (index, lenderId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getLenderPicture?lender_id=${lenderId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);

      setdppicture((prevDppicture) => {
        const updatedDppicture = [...prevDppicture]; // Create a copy of the array
        updatedDppicture[index] = { profilepicture: imageUrl }; // Update the specific index
        console.log(updatedDppicture);
        return updatedDppicture;
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [keyman, setKeyman] = useState("");
  const [singpassData, setSingpassData] = useState(null);
  const [needOtherAuthoriser, setNeedOtherAuthoriser] = useState(false);
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [openDataIndex, setOpenDataIndex] = useState(null);

  const singpassDetails = async (
    user,
    other_authorization,
    loan_id,
    keyman
  ) => {
    try {
      setKeyman(keyman);
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getBorrowerSingppass?user_id=${user}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      console.log(json);
      setDataSingpassModal(true);
      if (other_authorization) {
        console.log("need other authorizer");
        setNeedOtherAuthoriser(true);
        try {
          const response2 = await fetch(
            `https://www.smart-lend.com/api/loanDetails/getLoanAuthorisers`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify({ loan_id: loan_id }),
            }
          );

          const json2 = await response2.json();
          setOtherAuthoriserDetails(json2.authorisers);
          console.log(json2.authorisers);
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("Doesnt need other authoriser");
        setNeedOtherAuthoriser(false);
        setOtherAuthoriserDetails(null);
      }
      if (json.singpass_data) {
        setSingpassData(json.singpass_data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="4px 20px 20px 20px">
          <Modal
            width="60%"
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            open={dataSingpassModal}
            onCancel={() => {
              setDataSingpassModal(false);
              setSingpassData(null);
              setOpenDataIndex(null);
              setKeyman("");
            }}
            footer={null}
          >
            {singpassData ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "20px",
                    color: "rgba(20, 44, 68, 0.8)",
                    fontWeight: 900,
                    fontFamily: "Manrope, sans-serif",
                    fontSize: 20,
                  }}
                >
                  <h2> Borrower information</h2>{" "}
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      background: "rgba(14, 90, 165, 0.1)",
                      color: "rgba(20, 44, 68, 0.8)",
                      fontWeight: 800,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: 16,
                      padding: "3px 15px",
                      borderRadius: 7,
                    }}
                  >
                    Loan keyman: {keyman ? keyman : ""}
                  </div>
                </div>
                <div
                  style={{
                    color: "rgba(0,0,0,0.4)",
                    marginBottom: 5,
                    marginTop: 25,
                  }}
                >
                  Applicant Information
                </div>
                <div
                  style={{
                    width: "100%",
                    background:
                      openDataIndex === 0 ? "none" : "rgba(14, 90, 165, 0.1)",
                    borderRadius: 10,
                    padding: openDataIndex === 0 ? 0 : "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (openDataIndex !== 0) {
                      setOpenDataIndex(0);
                    } else {
                      setOpenDataIndex(null);
                    }
                  }}
                >
                  {openDataIndex === 0 ? (
                    <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
                      <div
                        style={{
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 16,
                          padding: "10px 20px",
                        }}
                      >
                        {singpassData?.person?.name?.value}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          fontFamily: "Manrope, sans-serif",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50%",
                            height: "100%",
                            borderRadius: "10px",
                            flexDirection: "column",
                            padding: "10px 10px 10px 10px",
                            marginRight: "20px",
                            background: "#0e5aa51A",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              background: "white",
                              textAlign: "center",
                              marginBottom: "15px",
                              padding: "10px",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 20,
                              borderRadius: 10,
                            }}
                          >
                            Personal Information
                          </div>
                          <div style={{ padding: "0px 15px" }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                NRIC
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  min={0}
                                  // className={nricError ? "error-border" : ""}
                                  value={singpassData?.person?.uinfin?.value}
                                />
                                {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Full Name
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  type="text"
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  value={singpassData?.person?.name?.value}
                                  // required
                                />
                                {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Sex
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  value={singpassData?.person?.sex?.desc}
                                  // required
                                />
                                {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Race
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={singpassData?.person?.race?.desc}
                                />
                                {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Nationality
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.nationality?.desc
                                  }
                                />
                                {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Date of Birth
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="date"
                                  // required
                                  value={singpassData?.person?.dob?.value}
                                />
                                {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Country of Birth
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.birthcountry?.desc
                                  }
                                />
                                {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Residential Status
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.residentialstatus
                                      ?.desc
                                  }
                                />
                                {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Pass type
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={singpassData?.person?.passtype?.desc}
                                />
                                {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Pass Status
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.passstatus?.value
                                  }
                                />
                                {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Pass Expiry Date
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="date"
                                  // required
                                  value={
                                    singpassData?.person?.passexpirydate?.value
                                  }
                                />
                                {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Ownership of Private Property Status
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.ownerprivate?.value
                                  }
                                />
                                {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Email
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  type="email"
                                  disabled
                                  // required
                                  value={singpassData?.person?.email?.value}
                                />
                                {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Mobile Number
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="number"
                                  // required
                                  value={
                                    singpassData?.person?.mobileno?.nbr?.value
                                  }
                                />
                                {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Registered Address
                              </div>
                              <div style={{ width: "60%" }}>
                                <textarea
                                  rows="2"
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  // required
                                  value={
                                    singpassData?.person?.regadd
                                      ? "Block " +
                                        singpassData?.person?.regadd?.block
                                          ?.value +
                                        ", Floor " +
                                        singpassData?.person?.regadd?.floor
                                          ?.value +
                                        ", Unit " +
                                        singpassData?.person?.regadd?.unit
                                          ?.value +
                                        ", " +
                                        singpassData?.person?.regadd?.building
                                          ?.value +
                                        ", " +
                                        singpassData?.person?.regadd?.street
                                          ?.value +
                                        ", " +
                                        singpassData?.person?.regadd?.country
                                          ?.desc +
                                        ", " +
                                        singpassData?.person?.regadd?.postal
                                          ?.value
                                      : ""
                                  }
                                ></textarea>
                                {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Housing Type
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.housingtype?.desc
                                  }
                                />
                                {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Marital Status
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  value={singpassData?.person?.marital?.desc}
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Occupation
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.occupation?.value
                                  }
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Employer's name
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={""}
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Employment Sector
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={""}
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                HDB Type
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={singpassData?.person?.hdbtype?.desc}
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Housing Type
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.housingtype?.desc
                                  }
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                HDB Ownership - Address
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.regadd
                                      ? "Block " +
                                        singpassData?.person?.regadd?.block
                                          ?.value +
                                        ", Floor " +
                                        singpassData?.person?.regadd?.floor
                                          ?.value +
                                        ", Unit " +
                                        singpassData?.person?.regadd?.unit
                                          ?.value +
                                        ", " +
                                        singpassData?.person?.regadd?.building
                                          ?.value +
                                        ", " +
                                        singpassData?.person?.regadd?.street
                                          ?.value +
                                        ", " +
                                        singpassData?.person?.regadd?.country
                                          ?.desc +
                                        ", " +
                                        singpassData?.person?.regadd?.postal
                                          ?.value
                                      : ""
                                  }
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                HDB Ownership - Outstanding HDB Loan Balance
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={singpassData?.person?.hdbownership[0]?.outstandingloanbalance?.value?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "SGD",
                                    }
                                  )}
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                HDB Ownership - Type of HDB Dwelling
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.hdbownership[0]
                                      ?.hdbtype?.desc
                                  }
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                HDB Ownership - Monthly Loan Installment
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={singpassData?.person?.hdbownership[0]?.monthlyloaninstalment?.value?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "SGD",
                                    }
                                  )}
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Vehicles - Vehicles Model
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.vehicles[0]?.model
                                      ?.value
                                  }
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Vehicles - Vehicles Make
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.person?.vehicles[0]?.make
                                      ?.value
                                  }
                                />
                                {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50%",
                            height: "100%",
                            borderRadius: "10px",
                            flexDirection: "column",
                            padding: "10px 10px 10px 10px",
                            background: "#0e5aa51A",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              background: "white",
                              textAlign: "center",
                              marginBottom: "15px",
                              padding: "10px",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 20,
                              borderRadius: 10,
                            }}
                          >
                            Corporate Information
                          </div>
                          <div
                            style={{
                              padding: "0px 15px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                UEN
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.entity["basic-profile"]?.uen
                                      ?.value
                                  }
                                />
                                {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Entity Name
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.entity["basic-profile"][
                                      "entity-name"
                                    ]?.value
                                  }
                                />
                                {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Entity Type
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.entity["basic-profile"][
                                      "entity-type"
                                    ]?.value
                                  }
                                />
                                {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Entity Status
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.entity["basic-profile"][
                                      "entity-status"
                                    ]?.value
                                  }
                                />
                                {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Registered Address
                              </div>
                              <div style={{ width: "60%" }}>
                                <textarea
                                  rows="2"
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                    resize: "none",
                                  }}
                                  disabled
                                  // required
                                  value={
                                    singpassData?.entity?.addresses[
                                      "addresses-list"
                                    ][0]
                                      ? "Block " +
                                        singpassData?.entity?.addresses[
                                          "addresses-list"
                                        ][0]?.block?.value +
                                        ", Floor " +
                                        singpassData?.entity?.addresses[
                                          "addresses-list"
                                        ][0]?.floor?.value +
                                        ", Unit " +
                                        singpassData?.entity?.addresses[
                                          "addresses-list"
                                        ][0]?.unit?.value +
                                        ", " +
                                        singpassData?.entity?.addresses[
                                          "addresses-list"
                                        ][0]?.building?.value +
                                        ", " +
                                        singpassData?.entity?.addresses[
                                          "addresses-list"
                                        ][0]?.street?.value +
                                        ", " +
                                        singpassData?.entity?.addresses[
                                          "addresses-list"
                                        ][0]?.country?.desc +
                                        ", " +
                                        singpassData?.entity?.addresses[
                                          "addresses-list"
                                        ][0]?.postal?.value
                                      : ""
                                  }
                                ></textarea>
                                {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Registration Date
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="date"
                                  // required
                                  value={
                                    singpassData?.entity["basic-profile"][
                                      "registration-date"
                                    ]?.value
                                  }
                                />
                                {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Primary SSIC
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.entity["basic-profile"][
                                      "primary-activity"
                                    ]?.desc
                                  }
                                />
                                {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Secondary SSIC
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  // required
                                  value={
                                    singpassData?.entity["basic-profile"][
                                      "secondary-activity"
                                    ]?.desc
                                  }
                                />
                                {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                              </div>
                            </div>
                            {singpassData?.entity?.appointments[
                              "appointments-list"
                            ]?.map((appointment, index) => (
                              <div style={{ width: "100%" }} key={index}>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    marginBottom: 10,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "40%",
                                      color: "rgba(20, 44, 68, 0.8)",
                                      fontWeight: 800,
                                      fontFamily: "Manrope, sans-serif",
                                      fontSize: 14,
                                    }}
                                  >
                                    Appointment {index + 1} - Position
                                  </div>
                                  <div style={{ width: "60%" }}>
                                    <input
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        border: "none",
                                        outline: "none",
                                        padding: 7,
                                      }}
                                      disabled
                                      type="text"
                                      value={appointment.position.desc}
                                      // Add onChange logic if needed
                                    />
                                  </div>
                                </div>
                                {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                                <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"].idno.value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"].uen.value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"][
                                      "person-name"
                                    ].value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"][
                                      "entity-name"
                                    ].value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    marginBottom: 10,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "40%",
                                      color: "rgba(20, 44, 68, 0.8)",
                                      fontWeight: 800,
                                      fontFamily: "Manrope, sans-serif",
                                      fontSize: 14,
                                    }}
                                  >
                                    Appointment {index + 1} - Appointment Date
                                  </div>
                                  <div style={{ width: "60%" }}>
                                    <input
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        border: "none",
                                        outline: "none",
                                        padding: 7,
                                      }}
                                      disabled
                                      type="date"
                                      value={
                                        appointment["appointment-date"].value
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <h5>Noa History</h5>
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "separate",
                            borderRadius: "10px",
                            overflow: "hidden",
                            padding: "10px",
                            borderSpacing: "0px",
                            background: "#0e5aa51A",
                          }}
                        >
                          <thead
                            style={{
                              borderRadius: "15px 15px 0px 0px",
                              border: "none",
                              borderRadius: "8px",
                            }}
                          >
                            <tr
                              style={{
                                border: "none",
                                borderRadius: "8px",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 12,
                                borderRadius: 8,
                              }}
                            >
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Category
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Employment
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Interest
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Rent
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Tax Clearance
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Trade
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Years of Assessment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {singpassData?.person?.noahistory?.noas?.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.amount.value?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "SGD",
                                      }
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.category.value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.employment.value?.toLocaleString(
                                      "en-US"
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.interest.value?.toLocaleString(
                                      "en-US"
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.rent.value?.toLocaleString("en-US")}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.taxclearance.value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.trade.value?.toLocaleString("en-US")}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.yearofassessment.value}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <h5>Capitals</h5>
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "separate",
                            borderRadius: "10px",
                            overflow: "hidden",
                            padding: "10px",
                            borderSpacing: "0px",
                            background: "#0e5aa51A",
                          }}
                        >
                          <thead
                            style={{
                              borderRadius: "15px 15px 0px 0px",
                              border: "none",
                              borderRadius: "8px",
                            }}
                          >
                            <tr
                              style={{
                                border: "none",
                                borderRadius: "8px",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 12,
                                borderRadius: 8,
                              }}
                            >
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Capital Type
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Currency
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Issued Capital Amount
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Paid Up Capital Amount
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Share Alloted Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {singpassData?.entity?.capitals[
                              "capitals-list"
                            ]?.map((item, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item["capital-type"].desc}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item.currency.desc}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item[
                                    "issued-capital-amount"
                                  ].value?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item[
                                    "paid-up-capital-amount"
                                  ].value?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item[
                                    "share-allotted-amount"
                                  ].value?.toLocaleString("en-US")}
                                </td>
                                {/* Add other data cells */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <h5>Financials</h5>
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "separate",
                            borderRadius: "10px",
                            overflow: "hidden",
                            padding: "10px",
                            borderSpacing: "0px",
                            background: "#0e5aa51A",
                          }}
                        >
                          <thead
                            style={{
                              borderRadius: "15px 15px 0px 0px",
                              border: "none",
                              borderRadius: "8px",
                            }}
                          >
                            <tr
                              style={{
                                border: "none",
                                borderRadius: "8px",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 12,
                                borderRadius: 8,
                              }}
                            >
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Company Profit Loss After Tax
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Company Profit Loss Before Tax
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Company Revenue
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Currency
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Current Period End Date
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Current Period Start Date
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Group Capital Paid Up Capital Amount
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Group Profit Loss After Tax
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Group Profit Loss Before Tax
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Group Revenue
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Is Audited
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {singpassData?.entity?.financials?.highlights?.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {
                                      item["company-profit-loss-after-tax"]
                                        .value
                                    }
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {
                                      item["company-profit-loss-before-tax"]
                                        .value
                                    }
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["company-revenue"].value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.currency.desc}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["current-period-end-date"].value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["current-period-start-date"].value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {
                                      item[
                                        "group-capital-paid-up-capital-amount"
                                      ].value
                                    }
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["group-profit-loss-after-tax"].value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["group-profit-loss-before-tax"].value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["group-revenue"].value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["is-audited"].value}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <h5>Shareholders</h5>
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "separate",
                            borderRadius: "10px",
                            overflow: "hidden",
                            padding: "10px",
                            borderSpacing: "0px",
                            background: "#0e5aa51A",
                          }}
                        >
                          <thead
                            style={{
                              borderRadius: "15px 15px 0px 0px",
                              border: "none",
                              borderRadius: "8px",
                            }}
                          >
                            <tr
                              style={{
                                border: "none",
                                borderRadius: "8px",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 12,
                                borderRadius: 8,
                              }}
                            >
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Allocation
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Category
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Name
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                NRIC
                              </th>
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Currency
                              </th>
                              {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
          <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
          <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                              <th
                                style={{
                                  padding: "5px 10px",
                                }}
                              >
                                Share Type
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {singpassData?.entity?.shareholders[
                              "shareholders-list"
                            ] ? (
                              singpassData?.entity?.shareholders[
                                "shareholders-list"
                              ]?.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.allocation.value?.toLocaleString(
                                      "en-US"
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.category.desc}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {
                                      item["person-reference"]["person-name"]
                                        ?.value
                                    }
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["person-reference"]?.idno?.value}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item.currency.desc}
                                  </td>
                                  {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"].idno.value}</td>
            <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"].nationality.desc}</td>
            <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"]["person-name"].value}</td> */}
                                  <td
                                    style={{
                                      borderTop: "1px solid rgba(0,0,0,0.6)",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    {item["share-type"].desc}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    textAlign: "center",
                                    padding: "5px 10px",
                                  }}
                                  colSpan="11"
                                >
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 16,
                      }}
                    >
                      {singpassData?.person?.name?.value}
                    </div>
                  )}
                </div>
                {needOtherAuthoriser ? (
                  <div style={{ width: "100%", marginTop: 25 }}>
                    <div style={{ color: "rgba(0,0,0,0.4)", marginBottom: 5 }}>
                      Other Shareholders Information
                    </div>
                    {otherAuthoriserDetails
                      ? otherAuthoriserDetails.map((aut, index) => (
                          <div
                            style={{
                              width: "100%",
                              background:
                                openDataIndex === index + 1
                                  ? "none"
                                  : "rgba(14, 90, 165, 0.1)",
                              borderRadius: 10,
                              padding:
                                openDataIndex === index + 1 ? 0 : "10px 20px",
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (openDataIndex === index + 1) {
                                setOpenDataIndex(null);
                              } else {
                                setOpenDataIndex(index + 1);
                              }
                            }}
                          >
                            {openDataIndex === index + 1 ? (
                              <div
                                style={{ maxHeight: "60vh", overflowY: "auto" }}
                              >
                                <div
                                  style={{
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 16,
                                    padding: "10px 20px",
                                  }}
                                >
                                  {aut.director_name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    fontFamily: "Manrope, sans-serif",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "50%",
                                      height: "100%",
                                      borderRadius: "10px",
                                      flexDirection: "column",
                                      padding: "10px 10px 10px 10px",
                                      marginRight: "20px",
                                      background: "#0e5aa51A",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        background: "white",
                                        textAlign: "center",
                                        marginBottom: "15px",
                                        padding: "10px",
                                        color: "rgba(20, 44, 68, 0.8)",
                                        fontWeight: 800,
                                        fontFamily: "Manrope, sans-serif",
                                        fontSize: 20,
                                        borderRadius: 10,
                                      }}
                                    >
                                      Personal Information
                                    </div>
                                    <div style={{ padding: "0px 15px" }}>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          NRIC
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            min={0}
                                            // className={nricError ? "error-border" : ""}
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.uinfin?.value
                                            }
                                          />
                                          {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Full Name
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            type="text"
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.name?.value
                                            }
                                            // required
                                          />
                                          {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Sex
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.sex?.desc
                                            }
                                            // required
                                          />
                                          {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Race
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.race?.desc
                                            }
                                          />
                                          {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Nationality
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.nationality?.desc
                                            }
                                          />
                                          {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Date of Birth
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="date"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.dob?.value
                                            }
                                          />
                                          {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Country of Birth
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.birthcountry?.desc
                                            }
                                          />
                                          {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Residential Status
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.residentialstatus
                                                ?.desc
                                            }
                                          />
                                          {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Pass type
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.passtype?.desc
                                            }
                                          />
                                          {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Pass Status
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.passstatus?.value
                                            }
                                          />
                                          {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Pass Expiry Date
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="date"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.passexpirydate?.value
                                            }
                                          />
                                          {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Ownership of Private Property Status
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.ownerprivate?.value
                                            }
                                          />
                                          {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Email
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            type="email"
                                            disabled
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.email?.value
                                            }
                                          />
                                          {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Mobile Number
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="number"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.mobileno?.nbr?.value
                                            }
                                          />
                                          {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Registered Address
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <textarea
                                            rows="2"
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.regadd
                                                ? "Block " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.block
                                                    ?.value +
                                                  ", Floor " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.floor
                                                    ?.value +
                                                  ", Unit " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.unit
                                                    ?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.building
                                                    ?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.street
                                                    ?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.country
                                                    ?.desc +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.postal
                                                    ?.value
                                                : ""
                                            }
                                          ></textarea>
                                          {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Housing Type
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.housingtype?.desc
                                            }
                                          />
                                          {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Marital Status
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.marital?.desc
                                            }
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Occupation
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.occupation?.value
                                            }
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Employer's name
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={""}
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Employment Sector
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={""}
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          HDB Type
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.hdbtype?.desc
                                            }
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Housing Type
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.housingtype?.desc
                                            }
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          HDB Ownership - Address
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.regadd
                                                ? "Block " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.block
                                                    ?.value +
                                                  ", Floor " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.floor
                                                    ?.value +
                                                  ", Unit " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.unit
                                                    ?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.building
                                                    ?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.street
                                                    ?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.country
                                                    ?.desc +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.person?.regadd?.postal
                                                    ?.value
                                                : ""
                                            }
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          HDB Ownership - Outstanding HDB Loan
                                          Balance
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={JSON.parse(
                                              aut.singpass_data
                                            )?.person?.hdbownership[0]?.outstandingloanbalance?.value?.toLocaleString(
                                              "en-US",
                                              {
                                                style: "currency",
                                                currency: "SGD",
                                              }
                                            )}
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          HDB Ownership - Type of HDB Dwelling
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.hdbownership[0]
                                                ?.hdbtype?.desc
                                            }
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          HDB Ownership - Monthly Loan
                                          Installment
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={JSON.parse(
                                              aut.singpass_data
                                            )?.person?.hdbownership[0]?.monthlyloaninstalment?.value?.toLocaleString(
                                              "en-US",
                                              {
                                                style: "currency",
                                                currency: "SGD",
                                              }
                                            )}
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Vehicles - Vehicles Model
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.vehicles[0]?.model
                                                ?.value
                                            }
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Vehicles - Vehicles Make
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.person?.vehicles[0]?.make
                                                ?.value
                                            }
                                          />
                                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "50%",
                                      height: "100%",
                                      borderRadius: "10px",
                                      flexDirection: "column",
                                      padding: "10px 10px 10px 10px",
                                      background: "#0e5aa51A",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        background: "white",
                                        textAlign: "center",
                                        marginBottom: "15px",
                                        padding: "10px",
                                        color: "rgba(20, 44, 68, 0.8)",
                                        fontWeight: 800,
                                        fontFamily: "Manrope, sans-serif",
                                        fontSize: 20,
                                        borderRadius: 10,
                                      }}
                                    >
                                      Corporate Information
                                    </div>
                                    <div
                                      style={{
                                        padding: "0px 15px",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          UEN
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.entity["basic-profile"]?.uen
                                                ?.value
                                            }
                                          />
                                          {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Entity Name
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.entity["basic-profile"][
                                                "entity-name"
                                              ]?.value
                                            }
                                          />
                                          {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Entity Type
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.entity["basic-profile"][
                                                "entity-type"
                                              ]?.value
                                            }
                                          />
                                          {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Entity Status
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.entity["basic-profile"][
                                                "entity-status"
                                              ]?.value
                                            }
                                          />
                                          {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Registered Address
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <textarea
                                            rows="2"
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                              resize: "none",
                                            }}
                                            disabled
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.entity?.addresses[
                                                "addresses-list"
                                              ][0]
                                                ? "Block " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.entity?.addresses[
                                                    "addresses-list"
                                                  ][0]?.block?.value +
                                                  ", Floor " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.entity?.addresses[
                                                    "addresses-list"
                                                  ][0]?.floor?.value +
                                                  ", Unit " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.entity?.addresses[
                                                    "addresses-list"
                                                  ][0]?.unit?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.entity?.addresses[
                                                    "addresses-list"
                                                  ][0]?.building?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.entity?.addresses[
                                                    "addresses-list"
                                                  ][0]?.street?.value +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.entity?.addresses[
                                                    "addresses-list"
                                                  ][0]?.country?.desc +
                                                  ", " +
                                                  JSON.parse(aut.singpass_data)
                                                    ?.entity?.addresses[
                                                    "addresses-list"
                                                  ][0]?.postal?.value
                                                : ""
                                            }
                                          ></textarea>
                                          {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Registration Date
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="date"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.entity["basic-profile"][
                                                "registration-date"
                                              ]?.value
                                            }
                                          />
                                          {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Primary SSIC
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.entity["basic-profile"][
                                                "primary-activity"
                                              ]?.desc
                                            }
                                          />
                                          {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          marginBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "40%",
                                            color: "rgba(20, 44, 68, 0.8)",
                                            fontWeight: 800,
                                            fontFamily: "Manrope, sans-serif",
                                            fontSize: 14,
                                          }}
                                        >
                                          Secondary SSIC
                                        </div>
                                        <div style={{ width: "60%" }}>
                                          <input
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              border: "none",
                                              outline: "none",
                                              padding: 7,
                                            }}
                                            disabled
                                            type="text"
                                            // required
                                            value={
                                              JSON.parse(aut.singpass_data)
                                                ?.entity["basic-profile"][
                                                "secondary-activity"
                                              ]?.desc
                                            }
                                          />
                                          {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                                        </div>
                                      </div>
                                      {JSON.parse(
                                        aut.singpass_data
                                      )?.entity?.appointments[
                                        "appointments-list"
                                      ]?.map((appointment, index) => (
                                        <div
                                          style={{ width: "100%" }}
                                          key={index}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              marginBottom: 10,
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "40%",
                                                color: "rgba(20, 44, 68, 0.8)",
                                                fontWeight: 800,
                                                fontFamily:
                                                  "Manrope, sans-serif",
                                                fontSize: 14,
                                              }}
                                            >
                                              Appointment {index + 1} - Position
                                            </div>
                                            <div style={{ width: "60%" }}>
                                              <input
                                                style={{
                                                  width: "100%",
                                                  borderRadius: "10px",
                                                  border: "none",
                                                  outline: "none",
                                                  padding: 7,
                                                }}
                                                disabled
                                                type="text"
                                                value={
                                                  appointment.position.desc
                                                }
                                                // Add onChange logic if needed
                                              />
                                            </div>
                                          </div>
                                          {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                                          <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"].idno.value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"].uen.value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"][
                                      "person-name"
                                    ].value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"][
                                      "entity-name"
                                    ].value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              marginBottom: 10,
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "40%",
                                                color: "rgba(20, 44, 68, 0.8)",
                                                fontWeight: 800,
                                                fontFamily:
                                                  "Manrope, sans-serif",
                                                fontSize: 14,
                                              }}
                                            >
                                              Appointment {index + 1} -
                                              Appointment Date
                                            </div>
                                            <div style={{ width: "60%" }}>
                                              <input
                                                style={{
                                                  width: "100%",
                                                  borderRadius: "10px",
                                                  border: "none",
                                                  outline: "none",
                                                  padding: 7,
                                                }}
                                                disabled
                                                type="date"
                                                value={
                                                  appointment[
                                                    "appointment-date"
                                                  ].value
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  <h5>Noa History</h5>
                                  <table
                                    style={{
                                      width: "100%",
                                      borderCollapse: "separate",
                                      borderRadius: "10px",
                                      overflow: "hidden",
                                      padding: "10px",
                                      borderSpacing: "0px",
                                      background: "#0e5aa51A",
                                    }}
                                  >
                                    <thead
                                      style={{
                                        borderRadius: "15px 15px 0px 0px",
                                        border: "none",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <tr
                                        style={{
                                          border: "none",
                                          borderRadius: "8px",
                                          color: "rgba(20, 44, 68, 0.8)",
                                          fontWeight: 800,
                                          fontFamily: "Manrope, sans-serif",
                                          fontSize: 12,
                                          borderRadius: 8,
                                        }}
                                      >
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Amount
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Category
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Employment
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Interest
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Rent
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Tax Clearance
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Trade
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Years of Assessment
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {JSON.parse(
                                        aut.singpass_data
                                      )?.person?.noahistory?.noas?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.amount.value?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.category.value}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.employment.value?.toLocaleString(
                                                "en-US"
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.interest.value?.toLocaleString(
                                                "en-US"
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.rent.value?.toLocaleString(
                                                "en-US"
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.taxclearance.value}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.trade.value?.toLocaleString(
                                                "en-US"
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.yearofassessment.value}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  <h5>Capitals</h5>
                                  <table
                                    style={{
                                      width: "100%",
                                      borderCollapse: "separate",
                                      borderRadius: "10px",
                                      overflow: "hidden",
                                      padding: "10px",
                                      borderSpacing: "0px",
                                      background: "#0e5aa51A",
                                    }}
                                  >
                                    <thead
                                      style={{
                                        borderRadius: "15px 15px 0px 0px",
                                        border: "none",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <tr
                                        style={{
                                          border: "none",
                                          borderRadius: "8px",
                                          color: "rgba(20, 44, 68, 0.8)",
                                          fontWeight: 800,
                                          fontFamily: "Manrope, sans-serif",
                                          fontSize: 12,
                                          borderRadius: 8,
                                        }}
                                      >
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Capital Type
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Currency
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Issued Capital Amount
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Paid Up Capital Amount
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Share Alloted Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {JSON.parse(
                                        aut.singpass_data
                                      )?.entity?.capitals["capitals-list"]?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item["capital-type"].desc}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.currency.desc}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item[
                                                "issued-capital-amount"
                                              ].value?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "SGD",
                                              })}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item[
                                                "paid-up-capital-amount"
                                              ].value?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "SGD",
                                              })}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item[
                                                "share-allotted-amount"
                                              ].value?.toLocaleString("en-US")}
                                            </td>
                                            {/* Add other data cells */}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  <h5>Financials</h5>
                                  <table
                                    style={{
                                      width: "100%",
                                      borderCollapse: "separate",
                                      borderRadius: "10px",
                                      overflow: "hidden",
                                      padding: "10px",
                                      borderSpacing: "0px",
                                      background: "#0e5aa51A",
                                    }}
                                  >
                                    <thead
                                      style={{
                                        borderRadius: "15px 15px 0px 0px",
                                        border: "none",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <tr
                                        style={{
                                          border: "none",
                                          borderRadius: "8px",
                                          color: "rgba(20, 44, 68, 0.8)",
                                          fontWeight: 800,
                                          fontFamily: "Manrope, sans-serif",
                                          fontSize: 12,
                                          borderRadius: 8,
                                        }}
                                      >
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Company Profit Loss After Tax
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Company Profit Loss Before Tax
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Company Revenue
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Currency
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Current Period End Date
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Current Period Start Date
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Group Capital Paid Up Capital Amount
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Group Profit Loss After Tax
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Group Profit Loss Before Tax
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Group Revenue
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Is Audited
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {JSON.parse(
                                        aut.singpass_data
                                      )?.entity?.financials?.highlights?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item[
                                                  "company-profit-loss-after-tax"
                                                ].value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item[
                                                  "company-profit-loss-before-tax"
                                                ].value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item["company-revenue"].value}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.currency.desc}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item["current-period-end-date"]
                                                  .value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item[
                                                  "current-period-start-date"
                                                ].value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item[
                                                  "group-capital-paid-up-capital-amount"
                                                ].value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item[
                                                  "group-profit-loss-after-tax"
                                                ].value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item[
                                                  "group-profit-loss-before-tax"
                                                ].value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item["group-revenue"].value}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item["is-audited"].value}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  <h5>Shareholders</h5>
                                  <table
                                    style={{
                                      width: "100%",
                                      borderCollapse: "separate",
                                      borderRadius: "10px",
                                      overflow: "hidden",
                                      padding: "10px",
                                      borderSpacing: "0px",
                                      background: "#0e5aa51A",
                                    }}
                                  >
                                    <thead
                                      style={{
                                        borderRadius: "15px 15px 0px 0px",
                                        border: "none",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <tr
                                        style={{
                                          border: "none",
                                          borderRadius: "8px",
                                          color: "rgba(20, 44, 68, 0.8)",
                                          fontWeight: 800,
                                          fontFamily: "Manrope, sans-serif",
                                          fontSize: 12,
                                          borderRadius: 8,
                                        }}
                                      >
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Allocation
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Category
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Name
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          NRIC
                                        </th>
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Currency
                                        </th>
                                        {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
          <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
          <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                                        <th
                                          style={{
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Share Type
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {JSON.parse(aut.singpass_data)?.entity
                                        ?.shareholders["shareholders-list"] ? (
                                        JSON.parse(
                                          aut.singpass_data
                                        )?.entity?.shareholders[
                                          "shareholders-list"
                                        ]?.map((item, index) => (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.allocation.value?.toLocaleString(
                                                "en-US"
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.category.desc}
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item["person-reference"][
                                                  "person-name"
                                                ]?.value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {
                                                item["person-reference"]?.idno
                                                  ?.value
                                              }
                                            </td>
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item.currency.desc}
                                            </td>
                                            {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"].idno.value}</td>
            <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"].nationality.desc}</td>
            <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"]["person-name"].value}</td> */}
                                            <td
                                              style={{
                                                borderTop:
                                                  "1px solid rgba(0,0,0,0.6)",
                                                padding: "5px 10px",
                                              }}
                                            >
                                              {item["share-type"].desc}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            style={{
                                              borderTop:
                                                "1px solid rgba(0,0,0,0.6)",
                                              textAlign: "center",
                                              padding: "5px 10px",
                                            }}
                                            colSpan="11"
                                          >
                                            No Data
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 16,
                                }}
                              >
                                {aut.director_name}
                              </div>
                            )}
                          </div>
                        ))
                      : null}
                  </div>
                ) : null}
              </>
            ) : (
              <div></div>
            )}
          </Modal>
          {/* HEADER */}
          {/* <Box display="flex" justifyContent="space-between" alignItems="center">
<Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

</Box> */}

          {/* GRID & CHARTS */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
            borderRadius="10px"
          >
            {/* ROW 1 */}

            {/* ROW 2 */}
            <Box
              gridColumn="span 9"
              gridRow="span 2"
              borderRadius="10px"
              border="0px solid white"
              paddingBottom="50px"
              opacity="0.95"
              style={{
                overflowY: "scroll",
                height: "90vh",
                overflowX: "hidden",
                padding: "20px",
                background:
                  "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <Box gridColumn="span 8">
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "15px" }}
                >
                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        gap: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: "15px",
                        }}
                      >
                        {interestProfit &&
                        "Total Interest Profit" in interestProfit ? (
                          <ContainerRow1
                            onClick={handleClickInterestProfit}
                            key={0}
                            icon={require("../../assets/img/businessanalyticsicon.png")}
                            title="Interest Profit"
                            label={
                              totalInterestProfit.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              }) || "-"
                            }
                            description={
                              "Average of " +
                              averageInterestProfit.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              }) +
                              " per month"
                            }
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 0px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                padding: "0px 15px",
                                background: "rgba(255,255,255,0.7)",
                                borderRadius: "10px",
                                height: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "Manrope, sans-serif",
                                fontSize: "0.8em",
                                fontWeight: "600",
                                color: "#00000066",
                              }}
                            >
                              No Interest records to display
                            </div>
                          </div>
                        )}
                        {dataClient && dataClient.length > 0 ? (
                          <ContainerRow1
                            onClick={handleClickClient}
                            icon={require("../../assets/img/customersicon.png")}
                            title="Clients"
                            label={totalClients + " Clients" || "-"}
                            description={`Average of ${averageClientsPerMonth.toFixed(
                              0
                            )} clients per month`}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 0px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                padding: "0px 15px",
                                background: "rgba(255,255,255,0.7)",
                                borderRadius: "10px",
                                height: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "Manrope, sans-serif",
                                fontSize: "0.8em",
                                fontWeight: "600",
                                color: "#00000066",
                              }}
                            >
                              No Client records to display
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        style={{ display: "flex", width: "100%", gap: "15px" }}
                      >
                        {dataRequestedLoan && dataRequestedLoan.length > 0 ? (
                          <ContainerRow1
                            onClick={handleClickLoanRequest}
                            icon={require("../../assets/img/applicationicon.png")}
                            title="Loan Request"
                            label={dataRequestedLoan.length} // replace the hard-coded value with the length of the array
                            description={`Average up by ${loanAverage / 100}%`}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 0px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                padding: "0px 15px",
                                background: "rgba(255,255,255,0.7)",
                                borderRadius: "10px",
                                height: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "Manrope, sans-serif",
                                fontSize: "0.8em",
                                fontWeight: "600",
                                color: "#00000066",
                              }}
                            >
                              No loan request records to display
                            </div>
                          </div>
                        )}
                        {dataPendingAcceptance &&
                        dataPendingAcceptance.length > 0 ? (
                          <ContainerRow1
                            onClick={handleClickPendingLoan}
                            icon={require("../../assets/img/hourglassicon.png")}
                            title="Pending Loan"
                            label={dataPendingAcceptance.length}
                            description="Loan awaiting approval"
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 0px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                padding: "0px 15px",
                                background: "rgba(255,255,255,0.7)",
                                borderRadius: "10px",
                                height: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "Manrope, sans-serif",
                                fontSize: "0.8em",
                                fontWeight: "600",
                                color: "#00000066",
                              }}
                            >
                              No pending loan request records to display
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      colors={colors.grey[100]}
                      style={{ marginBottom: "15px" }}
                    >
                      <Typography
                        color={colors.grey[920]}
                        variant="h5"
                        fontWeight="800"
                        fontFamily="Manrope, sans-serif"
                        fontSize="18px"
                      >
                        Pending Loans
                      </Typography>
                    </Box>
                    {dataPendingAcceptance &&
                    dataPendingAcceptance.length > 0 ? (
                      dataPendingAcceptance.slice(0, 3).map((item, index) => (
                        <Box
                          gridColumn="span 1"
                          display="flex"
                          alignItems="left"
                          justifyContent="left"
                        >
                          <ContainerActivity
                            key={index}
                            icon="https://www.gizmochina.com/wp-content/uploads/2018/01/Logo-Sony.jpg"
                            title={
                              item.offer_amount
                                ? item.offer_amount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })
                                : "-"
                            }
                            subtitle={item.offer_interest_rate || "-"}
                            index={index}
                          />
                        </Box>
                      ))
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 0px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "0px 15px",
                            background: "rgba(255,255,255,0.7)",
                            borderRadius: "10px",
                            height: "275px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Manrope, sans-serif",
                            fontSize: "0.8em",
                            fontWeight: "600",
                            color: "#00000066",
                          }}
                        >
                          No pending loan request records to display
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Box>

              <Box
                gridColumn="span 4"
                gridRow="span 2"
                background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
                border-radius="20px"
                opacity="0.95"
              >
                <Box
                  display="flex"
                  flexDirection="row" // set flexDirection to row
                  justifyContent="space-between"
                  alignItems="center"
                  opacity="1.00"
                  style={{ gap: "15px", margin: "30px 0px 15px 0px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                    >
                      Upcoming Payments
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flex: 1,
                      marginRight: "20px",
                    }}
                  >
                    {/* <p style={{ color: "#142C4466", fontSize: "8px", marginLeft: "8px", textAlign: 'right', marginTop: "15px" }}>View All &gt;</p> */}
                  </div>
                  <div
                    style={{ display: "flex", alignItems: "center", flex: 1 }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                    >
                      Delayed Payments
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flex: 1,
                    }}
                  >
                    <p
                      style={{
                        color: "#142C4466",
                        fontSize: "9px",
                        margin: "0px",
                      }}
                    >
                      {" "}
                      {
                        <a
                          style={{ color: "#142C44CC", textDecoration: "none" }}
                          href="/delayed-payments"
                        >
                          View All &gt;
                        </a>
                      }{" "}
                    </p>
                  </div>
                </Box>

                <Box display="flex" flexDirection="row" style={{ gap: "15px" }}>
                  <Box gridColumn="span 4" width="50%">
                    <TableLoan />
                  </Box>

                  <Box gridColumn="span 4" width="50%">
                    <TableDelayedPayment />
                  </Box>
                </Box>
                <Box
                  gridColumn="span 8"
                  gridRow="span 2"
                  // background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
                  border-radius="10px"
                  opacity="0.95"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    // borderBottom={`4px solid ${colors.primary[500]}`}
                    colors={colors.grey[910]}
                    opacity="1.00"
                    style={{ margin: "30px 0px 15px 0px" }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                    >
                      Request of the day
                    </Typography>
                  </Box>

                  <Box
                    gridColumn="span 4"
                    // backgroundColor={colors.grey[910]}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        color: "grey",
                        gap: "15px",
                        marginTop: "15px",
                      }}
                    >
                      {dataRequestedLoanToday &&
                      dataRequestedLoanToday.length > 0 ? (
                        dataRequestedLoanToday.map((item, index) =>
                          item.visibility ? (
                            <Container
                              key={index}
                              icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                              title={item.principal_name || "-"}
                              subtitle={
                                item.express === 1
                                  ? "Express Applicant"
                                  : "Approved Borrower"
                              }
                              label="Credit Score"
                              credit_score={item.credit_score}
                              description={item.credit_score || "-"}
                              label1="Loan Amount"
                              description1={
                                item.request_amount
                                  ? item.request_amount.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "SGD",
                                      }
                                    )
                                  : "-"
                              }
                              label2="Period"
                              description2={
                                item.request_period + " Months" || "-"
                              }
                              createdAt={item.created_at}
                              countDown={item.expired_date} // pass the value of expired_date
                              index={index} // pass the index prop to each Container instance
                              progressPercent={item.credit_score} // set the progress percentage
                              loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                              open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                              onClickModal={(event) =>
                                showModal(item.loan_id, event)
                              } // pass the showModal function to each Container instance
                              cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                              item={item}
                            />
                          ) : null
                        )
                      ) : (
                        <div style={{ width: "100%", padding: "0px 0px" }}>
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 15px",
                              background: "rgba(255,255,255,0.7)",
                              borderRadius: "10px",
                              height: "150px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "Manrope, sans-serif",
                              fontSize: "0.8em",
                              fontWeight: "600",
                              color: "#00000066",
                            }}
                          >
                            No Loan Requested Today
                          </div>
                        </div>
                      )}
                      {dataRequestedLoanToday &&
                        dataRequestedLoanToday.length > 0 &&
                        dataRequestedLoanToday.map((item, index) => (
                          <Modal
                            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                            open={modalStates[item.loan_id]}
                            onCancel={() =>
                              setModalStates((prevStates) => ({
                                ...prevStates,
                                [item.loan_id]: false,
                              }))
                            }
                            footer={[
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: 16,
                                }}
                              >
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    handleMakeOffer(item.loan_id, item.express)
                                  }
                                >
                                  Make Offer
                                </Button>
                              </div>,
                            ]}
                          >
                            
                          <div>
                            <div
                              style={{
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 700,
                                fontSize: 20,
                              }}
                            >
                              Loan offer to {item.principal_name || "-"}
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                              <div
                                style={{
                                  background: "rgba(153, 178, 205, 0.67)",
                                  padding: "5px 15px",
                                  borderRadius: "6px",
                                  color: "white",
                                  fontSize: 12,
                                  fontWeight: 400,
                                }}
                              >
                                Loan ID: {item.loan_id}
                              </div>
                            </div>
                          </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 16,
                                borderRadius: "10px",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label htmlFor="offer_amount">
                                  Loan Amount
                                </label>
                                <br />
                                <input
                                  type="number"
                                  id="offer_amount"
                                  name="offer_amount"
                                  style={{
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                    border: "2px solid lightgrey",
                                  }}
                                  onChange={handleLoanAmountChange}
                                />
                              </div>
                              <div style={{ flex: 1 }}>
                                <label htmlFor="offer_interest_rate">
                                  Interest Rate (%)
                                </label>
                                <br />
                                <input
                                  type="number"
                                  id="offer_interest_rate"
                                  name="offer_interest_rate"
                                  style={{
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                    border: "2px solid lightgrey",
                                  }}
                                  min={0}
                                  max={100}
                                  onChange={handleInterestRateChange}
                                />
                                {" %"}
                                {errorMessage && (
                                  <p style={{ color: "red" }}>{errorMessage}</p>
                                )}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 16,
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label htmlFor="offer_period">
                                  Loan Period (Month)
                                </label>
                                <br />
                                <input
                                  type="number"
                                  id="offer_period"
                                  name="offer_period"
                                  style={{
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                    border: "2px solid lightgrey",
                                  }}
                                  onChange={handleLoanPeriodChange}
                                />
                              </div>
                              <div style={{ flex: 1 }}>
                                <label htmlFor="offer_processing_fee">
                                  Processing Fee (%)
                                </label>
                                <br />
                                <input
                                  type="number"
                                  id="offer_processing_fee"
                                  name="offer_processing_fee"
                                  style={{
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                    border: "2px solid lightgrey",
                                  }}
                                  value={parseFloat(offer_processing_fee)}
                                  onChange={(e) =>
                                    setProcessingFee(e.target.value)
                                  }
                                  min={0}
                                  max={100}
                                />{" "}
                                %
                                {errorMessage && (
                                  <p style={{ color: "red" }}>{errorMessage}</p>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 16,
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label htmlFor="type">Loan Type</label>
                                <br />
                                <select
                                  id="type"
                                  name="type"
                                  style={{ borderRadius: "10px", width: "73%",
                                    border: "2px solid lightgrey", }}
                                  onChange={handleLoanTypeChange}
                                >
                                  <option value="simple">
                                    Unsecured Business Term Loan
                                  </option>
                                  {/* <option value="effective">
                                  Effective Loan
                                </option> */}
                                </select>
                              </div>
                            </div>

                            <hr />

                            <p>
                              Processing Fees:{" "}
                              {Number(
                                offer_amount * (offer_processing_fee / 100)
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </p>
                            <p>
                              Monthly Instalment payable:{" "}
                              {Number(offer_monthly_installment).toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "SGD",
                                }
                              )}
                            </p>
                            <p>
                              Guaranteed Revenue:{" "}
                              {Number(
                                offer_revenue +
                                  offer_amount * (offer_processing_fee / 100)
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </p>
                            <p>
                              Platform Fee:{" "}
                              {Number(offer_amount * 0.015).toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "SGD",
                                }
                              )}
                            </p>
                          </Modal>
                        ))}
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn="span 3"
              gridRow="span 2"
              backgroundColor="rgba(153, 178, 205, 0)"
              height="90vh"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginBottom: "10px", alignItems: "center" }}
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Recent Transactions
                </Typography>
                <p
                  style={{
                    color: "#142C4466",
                    fontSize: "8px",
                    margin: "0px",
                  }}
                >
                  {
                    <a
                      style={{ color: "#142C44CC", textDecoration: "none" }}
                      href="/dashboard"
                    >
                      View All &gt;
                    </a>
                  }
                </p>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                backgroundColor="rgba(255,255,255,0.7)"
                borderRadius="10px"
                p="15px"
                style={
                  {
                    // overflowX: "scroll",
                  }
                }
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start" // Updated to align to the top
                  colors={colors.grey[910]}
                  // borderBottom={`1px solid ${colors.primary[500]}`}
                  width="100%"
                  textAlign="center"
                  flexDirection="column"
                >
                  <Typography
                    color={colors.grey[920]}
                    variant="h5"
                    fontWeight="800"
                    fontFamily="Manrope, sans-serif"
                    fontSize="18px"
                    style={{ marginBottom: "5px" }}
                  >
                    Today
                  </Typography>
                  <div
                    style={{
                      borderBottom: `2px solid ${colors.grey[920]}`,
                      width: "100%",
                      opacity: "0.3",
                    }}
                  />
                </Box>
                {data &&
                  data
                    .filter((item) => isToday(new Date(item.repay_date)))
                    .map((item, index) => (
                      <Box
                        key={`today-${index}`}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        colors={colors.grey[910]}
                      >
                        <ContainerTransaction
                          title={item.principal_name || "-"}
                          subtitle="View All >"
                          label={
                            item.repay_amount
                              ? item.repay_amount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"
                          }
                          description={formatDate(item.repay_date) || "-"}
                        />
                      </Box>
                    ))}
                {!data ||
                  (data.filter((item) => isToday(new Date(item.repay_date)))
                    .length === 0 && (
                    <div
                      style={{
                        color: colors.grey[920],
                        fontSize: "20px",
                        width: "100%",
                        height: "120px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "600",
                        opacity: "0.3",
                        lineHeight: "22px",
                      }}
                    >
                      <div>No Transactions</div>
                      <div>Today.</div>
                    </div>
                  ))}

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start" // Updated to align to the top
                  colors={colors.grey[910]}
                  // borderBottom={`1px solid ${colors.primary[500]}`}
                  width="100%"
                  textAlign="center"
                  flexDirection="column"
                >
                  <Typography
                    color={colors.grey[920]}
                    variant="h5"
                    fontWeight="800"
                    fontFamily="Manrope, sans-serif"
                    fontSize="18px"
                    style={{ marginBottom: "5px" }}
                  >
                    Yesterday
                  </Typography>
                  <div
                    style={{
                      borderBottom: `2px solid ${colors.grey[920]}`,
                      width: "100%",
                      opacity: "0.3",
                    }}
                  />
                </Box>
                {data &&
                  data
                    .filter((item) => isYesterday(new Date(item.repay_date)))
                    .map((item, index) => (
                      <Box
                        key={`yesterday-${index}`}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        colors={colors.grey[910]}
                      >
                        <ContainerTransaction
                          title={item.principal_name || "-"}
                          subtitle="View All >"
                          label={
                            item.repay_amount
                              ? item.repay_amount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"
                          }
                          description={formatDate(item.repay_date) || "-"}
                        />
                      </Box>
                    ))}
                {!data ||
                  (data.filter((item) => isYesterday(new Date(item.repay_date)))
                    .length === 0 && (
                    <div
                      style={{
                        color: colors.grey[920],
                        fontSize: "20px",
                        width: "100%",
                        height: "120px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "600",
                        opacity: "0.3",
                        lineHeight: "22px",
                      }}
                    >
                      <div>No Transactions</div>
                      <div>Yesterday.</div>
                    </div>
                  ))}

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start" // Updated to align to the top
                  colors={colors.grey[910]}
                  // borderBottom={`1px solid ${colors.primary[500]}`}
                  width="100%"
                  textAlign="center"
                  flexDirection="column"
                >
                  <Typography
                    color={colors.grey[920]}
                    variant="h5"
                    fontWeight="800"
                    fontFamily="Manrope, sans-serif"
                    fontSize="18px"
                    style={{ marginBottom: "5px" }}
                  >
                    Two Days Ago
                  </Typography>
                  <div
                    style={{
                      borderBottom: `2px solid ${colors.grey[920]}`,
                      width: "100%",
                      opacity: "0.3",
                    }}
                  />
                </Box>
                {data &&
                  data
                    .filter((item) => isTwoDaysAgo(new Date(item.repay_date)))
                    .map((item, index) => (
                      <Box
                        key={`twoDaysAgo-${index}`}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        colors={colors.grey[910]}
                      >
                        <ContainerTransaction
                          title={item.principal_name || "-"}
                          subtitle="View All >"
                          label={
                            item.repay_amount
                              ? item.repay_amount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"
                          }
                          description={formatDate(item.repay_date) || "-"}
                        />
                      </Box>
                    ))}
                {!data ||
                  (data.filter((item) =>
                    isTwoDaysAgo(new Date(item.repay_date))
                  ).length === 0 && (
                    <div
                      style={{
                        color: colors.grey[920],
                        fontSize: "20px",
                        width: "100%",
                        height: "120px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "600",
                        opacity: "0.3",
                        lineHeight: "22px",
                      }}
                    >
                      <div>No Transactions</div>
                    </div>
                  ))}
              </Box>

              {/* {mockTransactions.map((transaction, i) => (
             <Box
                 key={`${transaction.txId}-${i}`}
                 display="flex"
                 justifyContent="space-between"
                 alignItems="center"
                 // borderBottom={`4px solid ${colors.primary[500]}`}
                 backgroundColor="white"
                 borderRadius="20px"
                 p="15px"
             >
                 <Box>
                     <Typography
                         color={colors.greenAccent[500]}
                         variant="h5"
                         fontWeight="600"
                     >
                         {transaction.txId}
                     </Typography>
                     <Typography color={colors.grey[100]}>
                         {transaction.user}
                     </Typography>
                 </Box>
                 <Box color={colors.grey[100]}>{transaction.date}</Box>
                 <Box
                     backgroundColor={colors.greenAccent[500]}
                     p="5px 10px"
                     borderRadius="4px"
                 >
                     ${transaction.cost}
                 </Box>
             </Box>
         ))} */}
            </Box>
          </Box>
        </Box>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              height: "80vh",
              borderRadius: "10px",
              width: "95%",
              marginLeft: "10px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "calc(100% - 20px)",
                marginLeft: "10px",
                marginTop: "15px",
                gap: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                {interestProfit && "Total Interest Profit" in interestProfit ? (
                  <ContainerRow1
                    onClick={handleClickInterestProfit}
                    key={0}
                    icon={require("../../assets/img/businessanalyticsicon.png")}
                    title="Interest Profit"
                    label={"$" + convertNumberToInt(totalInterestProfit) || "-"}
                  />
                ) : (
                  <ContainerRow3 noData="No Interest Profit Data" />
                )}
              </div>
              <div style={{ width: "50%" }}>
                {dataClient && dataClient.length > 0 ? (
                  <ContainerRow1
                    onClick={handleClickClient}
                    icon={require("../../assets/img/customersicon.png")}
                    title="Clients"
                    label={totalClients + " Clients" || "-"}
                  />
                ) : (
                  <ContainerRow3 noData="No Client Data" />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "calc(100% - 20px)",
                marginLeft: "10px",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                {dataRequestedLoan && dataRequestedLoan.length > 0 ? (
                  <ContainerRow1
                    onClick={handleClickLoanRequest}
                    icon={require("../../assets/img/applicationicon.png")}
                    title="Loan Request"
                    label={dataRequestedLoan.length} // replace the hard-coded value with the length of the array
                    description={`Average up by ${loanAverage / 100}%`}
                  />
                ) : (
                  <ContainerRow3 noData="No Loan Request Data" />
                )}
              </div>
              <div style={{ width: "50%" }}>
                {dataPendingAcceptance && dataPendingAcceptance.length > 0 ? (
                  <ContainerRow1
                    onClick={handleClickPendingLoan}
                    icon={require("../../assets/img/hourglassicon.png")}
                    title="Pending Loan"
                    label={dataPendingAcceptance.length}
                    description="Loan awaiting approval"
                  />
                ) : (
                  <ContainerRow3 noData="No Pending Loan Data" />
                )}
              </div>
            </div>
            <div style={{ padding: "20px 0px 10px 10px" }}>
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
                marginTop="10px"
              >
                Pending Loans
              </Typography>
            </div>
            <div style={{ width: "calc(100% - 20px)", marginLeft: "10px" }}>
              {dataPendingAcceptance && dataPendingAcceptance.length > 0 ? (
                dataPendingAcceptance.slice(0, 3).map((item, index) => (
                  <Box
                    gridColumn="span 1"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                  >
                    <ContainerActivity
                      key={index}
                      icon="https://www.gizmochina.com/wp-content/uploads/2018/01/Logo-Sony.jpg"
                      title={
                        item.offer_amount
                          ? item.offer_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                      subtitle={item.offer_interest_rate || "-"}
                      index={index}
                    />
                  </Box>
                ))
              ) : (
                <Box
                  gridColumn="span 1"
                  display="flex"
                  alignItems="left"
                  justifyContent="left"
                >
                  <ContainerActivity2 noData="No Pending Loans Data" />
                </Box>
              )}
            </div>
            <div style={{ padding: "20px 0px 10px 10px" }}>
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="600"
                style={{ textAlign: "left" }}
                marginTop="10px"
                fontSize={20}
              >
                Upcoming Payments
              </Typography>
            </div>
            <div style={{ width: "calc(100% - 20px)", marginLeft: "10px" }}>
              <TableDelayedPayment />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 0px 10px 10px",
                width: "calc(100% - 20px)",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="600"
                fontSize={20}
                style={{ textAlign: "left" }}
              >
                Delayed Payments
              </Typography>
            </div>
            <div style={{ width: "calc(100% - 20px)", marginLeft: "10px" }}>
              <TableDelayedPayment />
            </div>
            <div
              style={{
                margin: "20px 0px 10px 10px",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="600"
                fontSize={20}
              >
                Request of the day
              </Typography>
            </div>
            <div
              style={{
                width: "calc(100% - 20px)",
                marginLeft: "10px",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {dataRequestedLoanToday && dataRequestedLoanToday.length > 0 ? (
                dataRequestedLoanToday.map((item, index) => (
                  <Container
                    key={index}
                    icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                    title={item.principal_name || "-"}
                    subtitle={
                      item.express === 1
                        ? "Express Applicant"
                        : "Approved Borrower"
                    }
                    label="Credit Score"
                    credit_score={item.credit_score}
                    description={item.credit_score || "-"}
                    label1="Loan Amount"
                    description1={
                      item.request_amount
                        ? item.request_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })
                        : "-"
                    }
                    label2="Period"
                    countDown={item.expired_date} // pass the value of expired_date
                    description2={item.request_period + " Months" || "-"}
                    createdAt={item.created_at}
                    index={index} // pass the index prop to each Container instance
                    progressPercent={item.credit_score} // set the progress percentage
                    loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                    open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                    onClickModal={(event) => showModal(item.loan_id, event)} // pass the showModal function to each Container instance
                    cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                    item={item}
                  />
                ))
              ) : (
                <Container3 noData="No Loans requested today" />
              )}
              {dataRequestedLoanToday &&
                dataRequestedLoanToday.length > 0 &&
                dataRequestedLoanToday.map((item, index) => (
                  <Modal
                    style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                    open={modalStates[item.loan_id]}
                    onCancel={() =>
                      setModalStates((prevStates) => ({
                        ...prevStates,
                        [item.loan_id]: false,
                      }))
                    }
                    footer={[
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 16,
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() =>
                            handleMakeOffer(item.loan_id, item.express)
                          }
                        >
                          Make Offer
                        </Button>
                      </div>,
                    ]}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ flex: 1 }}>
                        <p>Borrower: {item.principal_name || "-"} </p>
                      </div>
                      <div style={{ flex: 1 }}>
                        <p>Loan ID: {item.loan_id} </p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 16,
                        borderRadius: "10px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <label htmlFor="offer_amount">Loan Amount</label>
                        <br />
                        <input
                          type="number"
                          id="offer_amount"
                          name="offer_amount"
                          style={{
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            width: "80%",
                          }}
                          onChange={handleLoanAmountChange}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label htmlFor="offer_interest_rate">
                          Interest Rate (%)
                        </label>
                        <br />
                        <input
                          type="number"
                          id="offer_interest_rate"
                          name="offer_interest_rate"
                          style={{
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            width: "80%",
                          }}
                          onChange={handleInterestRateChange}
                        />
                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 16,
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <label htmlFor="offer_period">
                          Loan Period (Month)
                        </label>
                        <br />
                        <input
                          type="number"
                          id="offer_period"
                          name="offer_period"
                          style={{
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            width: "80%",
                          }}
                          onChange={handleLoanPeriodChange}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label htmlFor="type">Loan Type</label>
                        <br />
                        <select
                          id="type"
                          name="type"
                          style={{ borderRadius: "10px", width: "73%" }}
                          onChange={handleLoanTypeChange}
                        >
                          <option value="simple">Simple Loan</option>
                          <option value="effective">Effective Loan</option>
                        </select>
                      </div>
                    </div>

                    <hr />

                    <p>
                      Total cost of Financing:{" "}
                      {Number(offer_principal).toFixed(2)}
                    </p>
                    <p>
                      Expected Monthly Installment:{" "}
                      {Number(offer_monthly_installment).toFixed(2)}
                    </p>
                    <p>
                      Guaranteed Revenue: {Number(offer_revenue).toFixed(2)}
                    </p>
                    <p>Total Admin Fee: {Number(total_admin_fee).toFixed(2)}</p>
                  </Modal>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loan;
