import React from "react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Box, useTheme } from "@mui/material";
import adspot_ico from "../../../src/assets/img/businessman-receiving-money-after-contract-signing.png";
import { Typography, notification, Upload } from "antd";
import { tokens } from "../../../src/theme";
import { Progress, Button, message, Avatar, Modal } from "antd";
import {
  UserOutlined,
  UploadOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

const Profile = () => {
  const [dataActivityLog, setActivityLog] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeData, setActiveData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [displayAds, setDisplayAds] = useState([]);
  const [adImage, setAdImage] = useState(null);

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "7px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon ? (
          <img
            src={icon}
            style={{
              margin: "8px",
              width: "35px",
              height: "35px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        ) : (
          <Avatar
            style={{
              margin: "8px",
              width: "35px",
              height: "35px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
            icon={<UserOutlined />}
          />
        )}
        <div>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const processedData = await Promise.all(
          data.map(async (item) => {
            const profilePictureResponse = await fetch(
              `https://www.smart-lend.com/api/loan/loanRequest/viewListProfilePicture?profile_picture=${item.profile_picture}`,
              options
            );

            if (!profilePictureResponse.ok) {
              throw new Error("Failed to fetch profile picture data");
            }

            const blob = await profilePictureResponse.blob();
            const imageUrl = URL.createObjectURL(blob);

            return {
              ...item,
              icon: imageUrl, // use the blob URL as the icon
            };
          })
        );
        const reversedData = processedData.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/ads/getAdsByScreen?screen=bProfile",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const adsId = data.ads[0].ads_id;

        const adsPictureResponse = await fetch(
          `https://www.smart-lend.com/api/ads/viewAdImage/${adsId}`,
          options
        );

        if (!adsPictureResponse.ok) {
          throw new Error("Failed to fetch ad image data");
        }

        const blob = await adsPictureResponse.blob();
        const imageUrl = URL.createObjectURL(blob);

        setDisplayAds(data["ads"]);
        setAdImage(imageUrl);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSingpassData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          // Handle HTTP error responses (e.g., 404, 500)
          throw new Error(`HTTP Error: ${response.status}`);
        }

        const json = await response.json();
        console.log(json);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchActiveData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/getUserActive",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP Error: ${response.status}`);
        }
        const json = await response.json();
        console.log(json.data);
        setActiveData(json.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchActiveData();
    fetchSingpassData();
  }, []);

  const handleUpdateActiveData = async () => {
    try {
      const formData = new FormData();
      formData.append("active_email", activeData.active_email);
      formData.append("active_mobile_no", activeData.active_mobile_no);

      const response = await fetch(
        "https://www.smart-lend.com/api/profile/updateUserTable",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData,
        }
      );

      const json = await response.json();
      console.log(json);

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const profilePictureUrl =
        (await loadprofilepicture()) || Cookies.get("profile_picture");
      if (profilePictureUrl) {
        setImageUrl(profilePictureUrl);
      }
    }
    fetchData();
  }, []);

  const handleUploadProfilePicture = async (file) => {
    console.log("uploading profile picture");
    try {
      const formData = new FormData();
      formData.append("profile_picture", file);
      console.log("uploading", formData);
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/uploadProfilePicture",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData,
        }
      );

      const data = await response.json();

      console.log("Profile picture created", data);

      // Update the imageUrl state with the new image URL
      if (data.profile_picture) {
        setImageUrl(data.profile_picture);
        Cookies.set("profile_picture", data.profile_picture);
        message.success("Profile picture updated");
        // notification.open({
        //   message: "Notification Title",
        //   description:
        //     "I will never close automatically. This is a purposely very very long description that has many many characters and words.",
        //   duration: 4,
        // });
      } else if (data.error && data.error.profile_picture) {
        // If the profile picture failed to upload
        throw new Error(data.error.profile_picture[0]);
      }
    } catch (error) {
      console.error("Error Uploading Picture", error);
      setErrorMessage(error.message);
    }
  };

  const [imageUrl, setImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleBeforeUpload = (file) => {
    const imageUrl = URL.createObjectURL(file);
    setImageUrl(imageUrl);
    console.log("Photo ready to upload", file);
    setSelectedFile(file);
    return false; // Prevent file from being uploaded automatically
  };

  const handleConfirm = () => {
    if (selectedFile) {
      handleUploadProfilePicture(selectedFile);
      console.log("selectedFile exists", selectedFile);
      setSelectedFile(null); // Reset the selected file after uploading
    }
  };

  const loadprofilepicture = async () => {
    console.log("getting profile picture");
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/viewInfoProfilePicture",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch profile picture");
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      console.log("Profile picture retrieved", imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error retrieving profile picture", error);
      return null;
    }
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth < 800 ? (
        <div
          style={{
            width: "100%",
            height: screenWidth < 800 ? "80vh" : "91vh",
            display: "flex",
            justifyContent: "space-betweeen",
            flexDirection: screenWidth < 800 ? "column" : "row",
            gap: screenWidth < 800 ? "0px" : "20px",
          }}
        >
          <div
            style={{
              width: screenWidth < 800 ? "calc(100% - 20px)" : "75%",
              height: "100%",
              marginLeft: screenWidth < 800 ? "10px" : "0px",
            }}
          >
            <div
              style={{
                height: "100%",
                borderRadius: "10px",
                background:
                  "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
                padding: "20px",
              }}
            >
              <div
                style={{
                  color: "#142C44CC",
                  fontSize: "18px",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                }}
              >
                User Profile:
              </div>
              <div style={{ margin: "15px 0px 0px 0px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <div
                style={{
                  background: "rgba(0,0,0,0.2)",
                  height: "80px",
                  width: "80px",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UserOutlined style={{ fontSize: "40px" }} />
              </div> */}
                  <div
                  // style={{
                  //     background: "rgba(0,0,0,0.2)",
                  //     height: "80px",
                  //     width: "80px",
                  //     borderRadius: "50px",
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //   }}
                  >
                    <Upload
                      beforeUpload={handleBeforeUpload}
                      showUploadList={false}
                      fileList={selectedFile ? [selectedFile] : []}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          // alt="Profile picture"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            // marginTop:"-3vh",
                            // marginBottom:"0.5vh"
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <Avatar size={64} icon={<UserOutlined />} />
                      )}
                    </Upload>
                    <br />
                    {errorMessage && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                  </div>
                  <div style={{ margin: "0px 0px 0px 10px" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        color: "#142C44CC",
                        fontWeight: "800",
                        fontFamily: "Manrope, sans-serif",
                        margin: "0px",
                      }}
                    >
                      Borrower 21
                    </div>
                    <div
                      style={{
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.5)",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      Account type: Borrower
                    </div>
                  </div>
                </div>
                <div
                  className="all_button"
                  onClick={handleConfirm}
                  style={{
                    background: "#0a58ca",
                    cursor: "pointer",
                    padding: "8px 25px",
                    borderRadius: "10px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Confirm
                </div>

                {activeData ? (
                  <>
                    <div
                      style={{
                        color: "#142C44CC",
                        fontSize: "18px",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                        margin: "25px 0px 10px 0px",
                      }}
                    >
                      Active contact details:
                    </div>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <div
                        style={{
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.5)",
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          margin: "5px 0px 0px 0px",
                          width: "50%",
                        }}
                      >
                        <div>Active phone number:</div>
                        <input
                          value={activeData.active_mobile_no}
                          onChange={(e) => {
                            const changingActiveData = { ...activeData };
                            changingActiveData.active_mobile_no =
                              e.target.value;
                            setActiveData(changingActiveData);
                            console.log(activeData);
                          }}
                          placeholder="Phone number"
                          style={{
                            width: "100%",
                            padding: "8px 15px",
                            borderRadius: "10px",
                            border: "none",
                            outline: "1px solid lightgrey",
                            margin: "8px 0px 0px 0px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.5)",
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          margin: "5px 0px 0px 0px",
                          width: "50%",
                        }}
                      >
                        <div>Active email address:</div>
                        <input
                          value={activeData.active_email}
                          onChange={(e) => {
                            const changingActiveData = { ...activeData };
                            changingActiveData.active_email = e.target.value;
                            setActiveData(changingActiveData);
                            console.log(activeData);
                          }}
                          placeholder="email address"
                          style={{
                            width: "100%",
                            padding: "8px 15px",
                            borderRadius: "10px",
                            border: "none",
                            outline: "1px solid lightgrey",
                            margin: "8px 0px 0px 0px",
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", margin: "15px 0px 0px 0px" }}
                    >
                      <div
                        className="all_button"
                        onClick={() => {
                          handleUpdateActiveData();
                        }}
                        style={{
                          background: "#0a58ca",
                          cursor: "pointer",
                          padding: "8px 25px",
                          borderRadius: "10px",
                          textAlign: "center",
                          marginTop: "10px",
                          width: "100%",
                        }}
                      >
                        Update active details
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: screenWidth < 800 ? "80vh" : "91vh",
            display: "flex",
            justifyContent: "space-betweeen",
            flexDirection: screenWidth < 800 ? "column" : "row",
            gap: screenWidth < 800 ? "0px" : "20px",
          }}
        >
          <div
            style={{
              width: screenWidth < 800 ? "calc(100% - 20px)" : "75%",
              height: "100%",
              marginLeft: screenWidth < 800 ? "10px" : "0px",
            }}
          >
            <div
              style={{
                height: "100%",
                borderRadius: "10px",
                background:
                  "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
                padding: "20px",
              }}
            >
              <div
                style={{
                  color: "#142C44CC",
                  fontSize: "18px",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                }}
              >
                User Profile:
              </div>
              <div style={{ margin: "15px 0px 0px 0px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <div
                style={{
                  background: "rgba(0,0,0,0.2)",
                  height: "80px",
                  width: "80px",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UserOutlined style={{ fontSize: "40px" }} />
              </div> */}
                  <div
                  // style={{
                  //     background: "rgba(0,0,0,0.2)",
                  //     height: "80px",
                  //     width: "80px",
                  //     borderRadius: "50px",
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //   }}
                  >
                    <Upload
                      beforeUpload={handleBeforeUpload}
                      showUploadList={false}
                      fileList={selectedFile ? [selectedFile] : []}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          // alt="Profile picture"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            // marginTop:"-3vh",
                            // marginBottom:"0.5vh"
                          }}
                        />
                      ) : (
                        <Avatar size={64} icon={<UserOutlined />} />
                      )}
                    </Upload>
                    <br />
                    {errorMessage && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                  </div>
                  <div style={{ margin: "0px 0px 0px 10px" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        color: "#142C44CC",
                        fontWeight: "800",
                        fontFamily: "Manrope, sans-serif",
                        margin: "0px",
                      }}
                    >
                      Borrower 21
                    </div>
                    <div
                      style={{
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.5)",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      Account type: Borrower
                    </div>
                  </div>
                </div>
                <div
                  className="all_button"
                  onClick={handleConfirm}
                  style={{
                    background: "#0a58ca",
                    cursor: "pointer",
                    padding: "8px 25px",
                    borderRadius: "10px",
                    textAlign: "center",
                    width: "20%",
                    marginTop: "10px",
                  }}
                >
                  Confirm
                </div>

                {activeData ? (
                  <>
                    <div
                      style={{
                        color: "#142C44CC",
                        fontSize: "18px",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                        margin: "25px 0px 10px 0px",
                      }}
                    >
                      Active contact details:
                    </div>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <div
                        style={{
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.5)",
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          margin: "5px 0px 0px 0px",
                          width: "24%",
                        }}
                      >
                        <div>Active phone number:</div>
                        <input
                          value={activeData.active_mobile_no}
                          onChange={(e) => {
                            const changingActiveData = { ...activeData };
                            changingActiveData.active_mobile_no =
                              e.target.value;
                            setActiveData(changingActiveData);
                            console.log(activeData);
                          }}
                          placeholder="Phone number"
                          style={{
                            width: "100%",
                            padding: "8px 15px",
                            borderRadius: "10px",
                            border: "none",
                            outline: "1px solid lightgrey",
                            margin: "8px 0px 0px 0px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.5)",
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          margin: "5px 0px 0px 0px",
                          width: "24%",
                        }}
                      >
                        <div>Active email address:</div>
                        <input
                          value={activeData.active_email}
                          onChange={(e) => {
                            const changingActiveData = { ...activeData };
                            changingActiveData.active_email = e.target.value;
                            setActiveData(changingActiveData);
                            console.log(activeData);
                          }}
                          placeholder="email address"
                          style={{
                            width: "100%",
                            padding: "8px 15px",
                            borderRadius: "10px",
                            border: "none",
                            outline: "1px solid lightgrey",
                            margin: "8px 0px 0px 0px",
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: "flex", margin: "15px 0px 0px 0px" }}
                    >
                      <div
                        className="all_button"
                        onClick={() => {
                          handleUpdateActiveData();
                        }}
                        style={{
                          background: "#0a58ca",
                          cursor: "pointer",
                          padding: "8px 25px",
                          borderRadius: "10px",
                          textAlign: "center",
                        }}
                      >
                        Update active details
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "25%",
              height: "100%",
              padding: "4px 20px 20px 0px",
            }}
          >
            <Box
              gridColumn="span 3"
              gridRow="span 2"
              overflow="auto"
              height="100vh"
            >
              <Box
                gridColumn="span 2"
                opacity="1.00"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="34%"
                position="relative"
              >
                <img
                  src={adImage}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "1",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                backgroundColor="rgba(153, 178, 205, 0)"
                overflow="auto"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[100]}
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "#142C44CC",
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      margin: "0px",
                      margin: "0px 0px 10px 10px",
                    }}
                  >
                    Recent Activities
                  </Typography>
                </Box>
                {dataActivityLog && dataActivityLog.length > 0 ? (
                  dataActivityLog.slice(0, 6).map((item, index) => (
                    <div
                      style={{
                        opacity:
                          index === 4
                            ? 0.6
                            : index === 5
                            ? 0.2
                            : index === 3
                            ? 0.9
                            : 1,
                        transitionDelay: `${index * 0.1}s`,
                      }}
                    >
                      <ContainerActivity
                        key={index}
                        icon={item.icon}
                        title={item.principal_name || "-"}
                        subtitle={item.description || "-"}
                        index={index}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      No recent activities
                    </div>
                  </div>
                )}
              </Box>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
