import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Curve,
  Symbol,
} from "recharts";
import cookies from "js-cookie";
import moment from "moment";

const COLORS = ["#0083b0", "#FFFF"];

const HalfPieChartAdminFee = ({ title, subtitle, dateRange }) => {
  const [data, setData] = useState([]);
  const [loanAmount, setLoanAmount] = useState(null);
  const [paidPercentage, setPaidPercentage] = useState(null);
  const [amountReceived, setAmountReceived] = useState(null);
  const [totalLoanAmount, setTotalLoanAmount] = useState("");
  const [updatedLoans, setUpdatedLoans] = useState([]);
  const [totalRepayPrincipal, setTotalRepayPrincipal] = useState(0);
  const [screenWidth, setScreenWidth] = useState("1920");
  const [percentage, setPercentage] = useState(0);

  const CustomShape = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  }) => {
    const path = Curve.getCurvePath(
      { x: cx, y: cy },
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      0
    );
    const curveSize = 10; // Adjust this value to change the size of the curve

    const endPoint = Curve.getPolarPoint(
      { x: cx, y: cy },
      outerRadius + curveSize,
      endAngle
    );
    const startPoint = Curve.getPolarPoint(
      { x: cx, y: cy },
      outerRadius + curveSize,
      startAngle
    );

    const linePath = `M${endPoint.x},${endPoint.y}Q${cx},${cy} ${startPoint.x},${startPoint.y}`;

    return (
      <g>
        <path d={path} fill={fill} />
        <path d={linePath} fill={fill} />
      </g>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/analytics/countAdminFee",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();

      // Calculate total interest
      const totalAdminFee = json.total_admin_fee.reduce(
        (acc, curr) => acc + curr.total_admin_fee,
        0
      );

      // Calculate total repaid interest
      const totalPaidAdminFee = json.paid_admin_fee.reduce(
        (acc, curr) => acc + curr.repay_admin_fee,
        0
      );

      // Calculate remaining interest
      const remainingInterest = totalAdminFee - totalPaidAdminFee;

      // Calculate paid percentage
      const paidPercentage = (totalPaidAdminFee / totalAdminFee) * 100;

      // Prepare data for Pie chart
      setData([{ value: totalPaidAdminFee }, { value: remainingInterest }]);

      setTotalLoanAmount(totalAdminFee);
      setTotalRepayPrincipal(totalPaidAdminFee);
      setPaidPercentage(paidPercentage);
      setPercentage(paidPercentage);
    };

    fetchData();
  }, [dateRange]);

  function combineData(dateRange, amountReceived, loanAmount) {
    // Create a new array of objects that includes both amount_receive and amount properties
    const combinedData = [];

    amountReceived.forEach((item) => {
      combinedData.push({
        Date: moment(item.Date).format("YYYY-MM-DD"),
        repay_principal: item.repay_principal,
      });
    });

    loanAmount.forEach((item) => {
      combinedData.push({
        Date: moment(item.Date).format("YYYY-MM-DD"),
        repay_amount: 0,
        amount: item.amount,
      });
    });

    // Get the start and end dates from the dateRange prop
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let filteredData;
    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");

      // Filter the combined data based on the date range
      filteredData = combinedData
        .filter((item) => {
          return moment(item.Date).isBetween(startDate, endDate, "day", "[]");
        })
        .sort(
          (a, b) => moment(a.Date, "YYYY-MM-DD") - moment(b.Date, "YYYY-MM-DD")
        );
    } else {
      filteredData = combinedData;
      const totalLoanAmount = loanAmount.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);
      setTotalLoanAmount(totalLoanAmount);
    }

    return filteredData;
  }

  function calculateLoanAmounts(loanAmounts, amountReceived) {
    const updatedLoanAmounts = loanAmounts.map((loan) => {
      const totalRepayPrincipal = amountReceived.reduce(
        (accumulator, current) => {
          if (current.loan_id === loan.loan_id) {
            return accumulator + current.repay_principal;
          }
          return accumulator;
        },
        0
      );

      return {
        ...loan,
        amount: loan.amount - totalRepayPrincipal,
      };
    });

    return updatedLoanAmounts;
  }

  function calculateTotalAmount(loans) {
    return loans.reduce((accumulator, loan) => {
      return accumulator + loan.amount;
    }, 0);
  }

  function calculateRepayPercentage(amountReceived, loanAmount) {
    const totalRepayPrincipal = amountReceived.reduce(
      (accumulator, current) => {
        return accumulator + current.repay_principal;
      },
      0
    );

    const totalLoanAmount = loanAmount.reduce((accumulator, current) => {
      return accumulator + current.amount;
    }, 0);

    const repayPercentage = (totalRepayPrincipal / totalLoanAmount) * 100;

    return repayPercentage; // You can adjust the number of decimal places by changing the value inside toFixed()
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const radius = 44; // Radius of the semi-circle
  const circumference = 2 * Math.PI * radius;
  const dashOffset = circumference - (75 / 100 / 2 + 0.5) * circumference;
  const startAngle = -180; // Start angle (top of the circle)
  const endAngle = startAngle + (percentage / 100) * 180; // Calculate the end angle based on percentage

  // Calculate the coordinates of the start and end points of the arc
  const startX = 50 + radius * Math.cos((startAngle * Math.PI) / 180);
  const startY = 50 + radius * Math.sin((startAngle * Math.PI) / 180);
  const endX = 50 + radius * Math.cos((endAngle * Math.PI) / 180);
  const endY = 50 + radius * Math.sin((endAngle * Math.PI) / 180);

  return (
    <div
      style={{
        width: "100%",
        height: screenWidth > 1200 ? "170px" : "170px",
        border: "1px solid none",
        backgroundColor: "#FFFF",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
        position: "relative",
        padding: "15px",
      }}
    >
      <div style={{}}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
            color: "#142C44CC",
            margin: "0px",
          }}
        >
          Admin Fee
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          You has a total of{" "}
          {totalLoanAmount !== null && totalLoanAmount !== undefined
            ? `$${totalLoanAmount.toLocaleString()}`
            : "-"}{" "}
          of admin fee
        </p>
      </div>
      {/* <div>
  <ResponsiveContainer width="100%" height={120}>
    <PieChart>
      <defs>
        <filter id="inset-shadow">
          <feOffset dx="0" dy="0" />
          <feGaussianBlur stdDeviation="1" result="offset-blur" />
          <feComposite
            operator="out"
            in="SourceGraphic"
            in2="offset-blur"
            result="inverse"
          />
          <feFlood
            flood-color="black"
            flood-opacity=".95"
            result="color"
          />
          <feComposite
            operator="in"
            in="color"
            in2="inverse"
            result="shadow"
          />
          <feComposite operator="over" in="shadow" in2="SourceGraphic" />
        </filter>
        <filter
          id="innershadow"
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
        >
          <feDropShadow
            dx="-1"
            dy="-1"
            stdDeviation="5"
            floodColor="#00000
                  0"
            floodOpacity="0.5"
          />

          {/* <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                          <feOnset dx="1" dy="1" result="onsetblur" />
                          <feComponentTransfer>
                              <feFuncA type="linear" slope="0.5" />
                          </feComponentTransfer>
                          <feMerge>
                              <feMergeNode />
                              <feMergeNode in="SourceGraphic" />
                          </feMerge>
        </filter>
        <linearGradient
          id="half-pie-chart-gradient"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
        >
          <stop offset="0%" stopColor="rgba(138, 156, 197, 0.8)" />
          <stop offset="100%" stopColor="rgba(21,87,169,1)" />
        </linearGradient>
      </defs>
      <Pie
        data={data}
        cx="50%"
        cy="91%"
        startAngle={180}
        endAngle={0}
        innerRadius={91}
        outerRadius={101}
        dataKey="value"
        stroke="#707070" // Add the grey border color
        strokeWidth={0.5} // Add the border width
        cornerRadius={2}
        filter="url(#innershadow)" // Apply the drop shadow filter here
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              index === 0
                ? "url(#half-pie-chart-gradient)"
                : COLORS[index]
            }
          />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  </ResponsiveContainer>
</div> */}
      <div>
        <svg
          width="100%"
          height="180"
          viewBox="0 0 100 100"
        >
          <path
            d={`M 50 50 m -${radius}, 0 a ${radius},${radius} 0 0,1 ${
              radius * 2
            },0`}
            fill="transparent"
            strokeWidth="10"
            stroke="rgba(0,0,0,0.1)" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
          />
          <path
            transform={`translate(0, 1)`} // Adjust the vertical position here
            d={`M 50 50 m -${radius}, 0 a ${radius},${radius} 0 0,1 ${
              radius * 2
            },0`}
            fill="transparent"
            strokeWidth="7.5"
            stroke="white" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
            filter="url(#blur)" // Apply blur filter to this path
          />

          {/* Define the filter for the inner shadow */}
          <filter id="blur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.1" />
          </filter>

          <path
            d={`M ${startX} ${startY} A ${radius} ${radius} 0 ${
              percentage <= 50 ? "0" : "1"
            } 1 ${endX} ${endY}`}
            fill="transparent"
            strokeWidth="10"
            stroke="#115CA6" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
          />
        </svg>
      </div>
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          bottom: 15,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h5
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "22px",
            fontWeight: "800",
            color: "#142C44CC",
            margin: "0px",
          }}
        >
          {totalRepayPrincipal
            ? `$${totalRepayPrincipal.toLocaleString()}`
            : "-"}
        </h5>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            width: "140px",
            margin: "0px",
            lineHeight: "12px",
          }}
        >
          {paidPercentage ? `${paidPercentage.toFixed(0)}%` : "-"} of your admin
          fee received
        </p>
      </div>
    </div>
  );
};

export default HalfPieChartAdminFee;
