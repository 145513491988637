import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Icon,
  FormControl,
  FormLabel,
  Slider,
} from "@mui/material";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TinyLineChart from "../../components/TinyLineChart";
import {
  UserOutlined,
  PlusOutlined,
  MinusOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import HalfPieChart from "../../components/HalfPieChart";
import { format } from "date-fns";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { useHistory } from "react-router-dom";
import CountdownWithHover from "../../components/CountDownWithHover";
import TableRepaymentLender from "../../components/TableRepaymentLender";
import StarIcon from "@mui/icons-material/Star";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import defaultIcon from "../../assets/img/Profile.png";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/img/SL-01.png";
import boldFont from "../../assets/font/Poppins-SemiBold.ttf";
import AdminDashboardAds from "../../pages/admin-dashboard/admindashboardads";

Font.register({
  family: "Poppins",
  fonts: [
    { src: boldFont, fontWeight: 700 }, // Bold (700)
  ],
});

const useStyles = makeStyles({
  formControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ##141414`,
  },
});

const getInitials = (name) => {
  const textToSplit = name.split(" ");
  const initials =
    textToSplit?.[0]?.[0].toUpperCase() +
    (textToSplit?.[1]?.[0].toUpperCase() || "");
  return initials;
};

const ContainerActivity = ({ title, subtitle }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "7px",
      border: "none",
      borderRadius: "10px",
      backgroundColor: "#FFFFFFB3",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
      }}
    >
      <div
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          background: "rgb(140,140,170)",
          marginLeft: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          color: "white",
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        {title ? getInitials(title) : "SL"}
      </div>
      <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const ContainerAgreement = ({
  title,
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
}) => (
  <div
    style={{
      width: "45%",
      margin: "30px 1px 1px 30px",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        flex: 1,
        marginBottom: "20px",
      }}
    >
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "400",
          fontSize: "14px",
          color: "#606264",

          flex: "0 0 auto",
        }}
      >
        {title}
      </h3>

      <h3
        style={{
          fontFamily: "Manrope, sans-serif",
          fontWeight: "600",
          fontSize: "30px",
          color: "#3E5367",
          // flex: 1,
          marginBottom: "15px",
          marginLeft: "10px",
          flex: "0 0 auto",
        }}
      >
        {title1}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",
          // flex: 1,
          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title2}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
          marginTop: "3px",
        }}
      >
        {title3}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#B13434",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title4}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title5}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title6}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title7}
      </h3>
    </div>
    <div
      style={{
        marginTop: "-40px",
      }}
    ></div>
  </div>
);

const Dashboard = ({ userDetails }) => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [dataRepaymentSchedule, setDataRepaymentSchedule] = useState([]);
  const [dataRequestedLoan, setRequestedLoan] = useState([]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const [dataSummary, setSummary] = useState([]);
  const [loanPeriod, setLoanPeriod] = useState(0);
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const [offer_amount, setLoanAmount] = useState(0);
  const [offer_period, setSelectedLoanPeriod] = useState(0);
  const [offer_principal, setLoanPrincipal] = useState(0);
  const [offer_monthly_installment, setExpectedMonthlyInstallment] =
    useState(0);
  const [offer_revenue, setGuaranteedRevenue] = useState(0);
  const [type, setLoanType] = useState("simple");
  const [offer_interest_rate, setInterestRate] = useState(0);
  const [offer_processing_fee, setProcessingFee] = useState(0);
  const [total_admin_fee, setTotalAdminFee] = useState(0);
  const [admin_fee, setAdminFee] = useState(0);
  const history = useHistory();
  const [loanDetails, setLoanDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [targetDate, setTargetDate] = useState(null);
  const [downloadBankStatement, setDownloadBankStatement] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const [express, setExpress] = useState(null);
  const [add_document_description, setAddDocumentDescription] = useState(null);
  const [notInterestedIds, setNotInterestedIds] = useState([]);
  const [countLoanOffer, setCountLoanOffer] = useState([]);
  const { Panel } = Collapse;
  const [documentDescriptions, setDocumentDescriptions] = useState([""]);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  const [nric2, setNRIC2] = useState("");
  const [nric, setNRIC] = useState("");
  const [fullName2, setFullName2] = useState("");
  const [fullName, setFullName] = useState("");
  const [sex, setSex] = useState("");
  const [sex2, setSex2] = useState("");
  const [race, setRace] = useState("");
  const [race2, setRace2] = useState("");
  const [nationality, setNationality] = useState("");
  const [nationality2, setNationality2] = useState("");
  const [dob, setDOB] = useState("");
  const [dob2, setDOB2] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [countryOfBirth2, setCountryOfBirth2] = useState("");
  const [residentialStatus, setResidentialStatus] = useState("");
  const [residentialStatus2, setResidentialStatus2] = useState("");
  const [passType, setPassType] = useState("");
  const [passType2, setPassType2] = useState("");
  const [passStatus, setPassStatus] = useState("");
  const [passStatus2, setPassStatus2] = useState("");
  const [passExpiryDate, setPassExpiryDate] = useState("");
  const [passExpiryDate2, setPassExpiryDate2] = useState("");
  const [privatePropertyStatus, setPrivatePropertyStatus] = useState("");
  const [privatePropertyStatus2, setPrivatePropertyStatus2] = useState("");
  const [occupation, setOccupation] = useState("");
  const [occupation2, setOccupation2] = useState("");
  const [employerName, setEmployerName] = useState("");
  const [employerName2, setEmployerName2] = useState("");
  const [employmentSector, setEmploymentSector] = useState("");
  const [employmentSector2, setEmploymentSector2] = useState("");
  const [hdbType, setHDBType] = useState("");
  const [hdbType2, setHDBType2] = useState("");
  const [hdbAddress, setHDBAddress] = useState("");
  const [hdbAddress2, setHDBAddress2] = useState("");
  const [hdbOutstandingLoanBalance, setHDBOutstandingLoanBalance] =
    useState("");
  const [hdbOutstandingLoanBalance2, setHDBOutstandingLoanBalance2] =
    useState("");
  const [hdbMonthlyLoanInstallment, setHDBMonthlyLoanInstallment] =
    useState("");
  const [hdbMonthlyLoanInstallment2, setHDBMonthlyLoanInstallment2] =
    useState("");
  const [hdbTypeDwelling, setHDBTypeDwelling] = useState("");
  const [hdbTypeDwelling2, setHDBTypeDwelling2] = useState("");
  const [vehiclesModel, setVehiclesModel] = useState("");
  const [vehiclesModel2, setVehiclesModel2] = useState("");
  const [vehiclesMake, setVehiclesMake] = useState("");
  const [vehiclesMake2, setVehiclesMake2] = useState("");
  const [noaHistory, setNoaHistory] = useState([]);

  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [mobile_number2, setMobileNumber2] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [maritalStatus2, setMaritalStatus2] = useState("");
  const [uen, setUEN] = useState("");
  const [uen2, setUEN2] = useState("");
  const [entityName, setEntityName] = useState("");
  const [entityName2, setEntityName2] = useState("");
  const [entityType, setEntityType] = useState("");
  const [entityType2, setEntityType2] = useState("");
  const [entityStatus, setEntityStatus] = useState("");
  const [entityStatus2, setEntityStatus2] = useState("");
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [registeredAddress2, setRegisteredAddress2] = useState("");
  const [registeredAddressEntity, setRegisteredAddressEntity] = useState("");
  const [registeredAddressEntity2, setRegisteredAddressEntity2] = useState("");
  const [housingType, setHousingType] = useState("");
  const [housingType2, setHousingType2] = useState("");
  const [highestEducationLevel, setHighestEducationLevel] = useState("");
  const [highestEducationLevel2, setHighestEducationLevel2] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [registrationDate2, setRegistrationDate2] = useState("");
  const [primarySSIC, setPrimarySSIC] = useState("");
  const [primarySSIC2, setPrimarySSIC2] = useState("");
  const [secondarySSIC, setSecondarySSIC] = useState("");
  const [secondarySSIC2, setSecondarySSIC2] = useState("");
  const [appointment1Position, setAppointment1Position] = useState("");
  const [appointment1Position2, setAppointment1Position2] = useState("");
  const [appointment1NRIC, setAppointment1NRIC] = useState("");
  const [appointment1NRIC2, setAppointment1NRIC2] = useState("");
  const [appointment1FullName, setAppointment1FullName] = useState("");
  const [appointment1FullName2, setAppointment1FullName2] = useState("");
  const [appointment1AppointmentDate, setAppointment1AppointmentDate] =
    useState("");
  const [appointment1AppointmentDate2, setAppointment1AppointmentDate2] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [capitals, setCapitals] = useState([]);
  const [financials, setFinancials] = useState([]);
  const [grants, setGrants] = useState([]);
  const [previousUens, setPreviousUens] = useState([]);
  const [shareholders, setShareholders] = useState([]);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      console.log("Token from cookie:", token);
      // Now you can use the token for making authenticated API requests,
      // storing it in your app's state, etc.
    }
  }, []);

  const handleChange = (event, index) => {
    const newDescriptions = [...documentDescriptions];
    newDescriptions[index] = event.target.value;
    setDocumentDescriptions(newDescriptions);
  };

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: screenWidth > 1200 ? "200px" : "160px",
        marginRight: "0px",
        borderRadius: "20px",
      }}
    >
      <TinyLineChart title="$12,000" subtitle="Net profit to date" />
    </div>
  );

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: screenWidth > 1200 ? "200px" : "160px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "20px",
      }}
    >
      <HalfPieChart title="$802,000" subtitle="Forecast collection" />
    </div>
  );

  const addMore = () => {
    setDocumentDescriptions([...documentDescriptions, ""]);
  };

  const remove = (indexToRemove) => {
    setDocumentDescriptions(
      documentDescriptions.filter((_, index) => index !== indexToRemove)
    );
  };
  const [extraRequestInformation, setExtraRequestInformation] = useState({});
  const [user_id, setUserId] = useState(null);
  const [principal_name, setPrincipalName] = useState(null);

  const showModalRequestDocument = (
    loan_id,
    express,
    user_id,
    principal_name,
    created_at
  ) => {
    setIsModalOpen(true);
    setLoanId(loan_id);
    setExpress(express);
    setUserId(user_id);
    setPrincipalName(principal_name);
    setExtraRequestInformation({ created_at: created_at });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClickSummary = () => {
    history.push("/analytics");
  };

  const handleViewRepaymentSchedule = async (loan_id, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/dashboard/lender/viewRepaymentSchedule?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setDataRepaymentSchedule(data["repayment_schedule"]);
    } catch (error) {
      console.error(error);
    }
  };

  const timerStyle = {
    color: "grey",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: "12px",
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    let timeLeft = "";

    if (completed) {
      return <span style={timerStyle}>Time's up!</span>;
    } else {
      if (days > 0) {
        timeLeft = `${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timeLeft = `${hours}h ${minutes}m ${seconds}s`;
      } else {
        timeLeft = `${minutes}m ${seconds}s`;
      }

      return <span style={timerStyle}>{timeLeft}</span>;
    }
  };

  const Container = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    index,
    credit_score,
    loan_id,
    loanOfferCount,
    reject_reason,
    countDown,
    onClickModal,
    createdAt,
    item,
  }) => {
    const shouldAddMarginTop = index >= 4;
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "23.5%" : "calc(50% - 10px)",
            textAlign: "center",
            border:
              subtitle === "Express Applicant"
                ? "1px solid rgb(242, 193, 80)"
                : "none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: screenWidth > 1200 ? "40vh" : "auto",
            padding: "15px",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              display: subtitle === "Express Applicant" ? "block" : "none",
            }}
          >
            <StarIcon
              style={{ color: "rgba(242, 193, 80)", fontSize: "25px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                background: "rgb(140,140,170)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
                fontSize: 20,
                fontWeight: 600,
                position: "relative",
              }}
              onClick={() => {
                console.log(item);
                setBadgeVerified(item.badge_verified);
                const dataToPush = [
                  {
                    name: JSON.parse(item.singpass_data)?.person?.name?.value,
                    cbs_data: item.credit_report_data,
                  },
                ];
                for (const dat of item.loan_authorizations) {
                  dataToPush.push({
                    name: dat.director_name,
                    cbs_data: dat.credit_report_data,
                  });
                }
                console.log(dataToPush);
                setAllCBSData(dataToPush);
                singpassDetails(
                  item.user_id,
                  item.authorization_required,
                  item.loan_id,
                  item.keyman
                );
              }}
            >
              {title ? getInitials(title) : "SL"}
              {item.badge_verified ? (
                <div
                  className="tooltip-container"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: "18px",
                    height: "18px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgb(14, 90, 165)",
                  }}
                >
                  <LocalPoliceIcon
                    className="tooltip-target"
                    style={{ color: "white", fontSize: "14px" }}
                  />
                  <span className="tooltip-text">
                    Verified borrowers have undergone SmartLend's authentication
                    process, demonstrating legitimate business operations. This
                    includes providing evidence such as current tenancy
                    agreements, business premises signage, or recent invoices
                    matching their bank statements
                  </span>
                </div>
              ) : null}
            </div>
            <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
              <h3
                style={{
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  color: "#142C44CC",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {title.length > 20 ? title.slice(0, 20) + "..." : title}
              </h3>
              <tooltip
                placement="topRight"
                title="This application qualifies for our 24-hour service 
guarantee based on our credit scoring. Be the first to fund them within 24 hours!"
              >
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                    margin: "0px",
                  }}
                >
                  {item.badge_verified ? subtitle : null}
                </p>
              </tooltip>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
            }}
          >
            {/* <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "10px 0px -5px 0px",
              }}
            >
              {label}
            </p>
            <Progress
              percent={credit_score}
              showInfo={false}
              strokeColor={
                subtitle === "Express Applicant"
                  ? "rgba(242, 193, 80)"
                  : "#1557a9"
              }
            />
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "-5px 0px 10px 0px",
              }}
            >
              {description}
            </p> */}
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
                marginTop: "20px",
              }}
            >
              {label1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: screenWidth < 800 ? "14px" : "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Expecting Interest Rate Per Month
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {item.request_interest_rate === 2
                ? "1.1% - 2%"
                : item.request_interest_rate === 3
                ? "2.1% - 3%"
                : "3.1% - 5%"}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Expecting Processing Fee
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {item.admin_fee === 3
                ? "1% - 3%"
                : item.admin_fee === 5
                ? "3.1% - 5%"
                : "5.1% - 7%"}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {label2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: screenWidth < 800 ? "14px" : "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Validity
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: screenWidth < 800 ? "14px" : "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {calculateCountdown(countDown)}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Created At
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: screenWidth < 800 ? "14px" : "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {formatDate(createdAt)}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Offers
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: screenWidth < 800 ? "14px" : "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 20px 0px",
              }}
            >
              {loanOfferCount}
            </p>
          </div>
          {/* <div style={{ marginTop: "-10px", marginBottom: "5px" }}>
            <CountdownWithHover targetDate={targetDate} renderer={renderer} />
          </div> */}
          <div key={loan_id} style={{ paddingBottom: "10px" }}>
            <button
              className="all_button"
              onClick={(event) => {
                event.preventDefault();
                console.log(
                  item.principal_name,
                  item.user_id,
                  item.request_amount
                );
                const url = `/l-messages?principelname=${item.principal_name}&userid=${item.user_id}&requestamount=${item.request_amount}`;
                history.push(url);
              }}
              style={{
                background: "transparent",
                color: "grey",
                width: "100%",
                border: "2px solid lightgrey",
                borderRadius: "15px",
                padding: "4px 20px",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              Message borrower
            </button>
            <button
              className="all_button"
              onClick={(event) => {
                event.preventDefault();
                const onGoingLoan = item.loan_offers.filter(
                  (offer) => offer.status === "accepted"
                );
                const totalLoanedAmount = item.loan_offers
                  .filter((offer) => offer.status === "accepted")
                  .reduce((sum, offer) => sum + offer.offer_amount, 0);
                showModal(loan_id, totalLoanedAmount);
              }}
              style={{
                background:
                  subtitle === "Express Applicant"
                    ? "rgba(242, 193, 80)"
                    : "#1557a9",
                color: "white",
                width: "100%",
                border: "none",
                borderRadius: "50px",
                padding: "6px 0px",
                fontSize: "12px",
                margin: "10px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Make an offer{" "}
              {subtitle === "Express Applicant" ? (
                <HourglassTopIcon
                  className="rotating_hourglass"
                  style={{
                    color: "white",
                    fontSize: "16px",
                    marginLeft: "5px",
                  }}
                />
              ) : null}
            </button>
            {(reject_reason === null || reject_reason === "") && (
              <button
                type="default"
                style={{
                  width: "60%",
                }}
                onClick={(event) => showModal2(loan_id, event)}
                className="buttonNotInterested"
              >
                Not Interested?
              </button>
            )}

            <button
              className="all_button"
              onClick={(event) => {
                event.preventDefault();
                window.open(item.bank_statement, "_blank");
              }}
              style={{
                background: "transparent",
                color: "grey",
                width: "100%",
                border: "2px solid lightgrey",
                borderRadius: "15px",
                padding: "4px 20px",
                fontSize: "12px",
                margin: "0px",
              }}
            >
              Download Company Financial Information
            </button>
          </div>
        </div>
      </>
    );
  };

  const reformatDateString = (originalDateString) => {
    // Create a Date object from the original date string
    const originalDate = new Date(originalDateString);

    // Define options for formatting the date
    const options = { year: "numeric", month: "short", day: "numeric" };

    // Format the date into "Mon dd, yyyy" format
    const formattedDate = originalDate.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const calculateCountdown = (expiredDate) => {
    const now = new Date().getTime();
    const targetDate = new Date(expiredDate).getTime();
    const timeDiff = targetDate - now;

    if (timeDiff <= 0) {
      return "Expired";
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}d: ${hours}h: ${minutes}m`;
  };

  const Container2 = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    item,
    label3,
    description3,
    index,
    loan_id,
  }) => {
    const shouldAddMarginTop = index > 3;
    return (
      <>
        <div
          style={{
            width: screenWidth < 1200 ? "calc(50% - 10px)" : "23.5%",
            textAlign: "center",
            border: "1px solid none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
            padding: "15px",
          }}
          onClick={(event) => {
            event.preventDefault();
            const text = "summary";
            showModal(loan_id, text);
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                background: "rgb(140,140,170)",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {title ? getInitials(title) : "SL"}
            </div>
            <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
              <h3
                style={{
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  color: "#142C44CC",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {!isMobile
                  ? title
                  : title.length > 23
                  ? title.slice(0, 23) + "..."
                  : title}
              </h3>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {subtitle}
              </p>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              margin: "20px 0px 0px 0px",
            }}
          >
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {label1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "13px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {description1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "10px 0px 0px 0px",
              }}
            >
              {label2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "13px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {description2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "10px 0px 0px 0px",
              }}
            >
              {label3}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "13px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {description3}
            </p>

            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "10px 0px 0px 0px",
              }}
            >
              {label}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "13px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {reformatDateString(description) === "Invalid Date"
                ? "None"
                : reformatDateString(description)}
            </p>

            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "10px 0px 0px 0px",
                display: "flex",
              }}
            >
              Next Payment Date
              <tooltip
                style={{
                  border: "2px solid lightgrey",
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "5px",
                  padding: "2px",
                  width: "15px",
                  height: "15px",
                }}
                placement="topRight"
                title="Borrowers are informed 2 weeks prior to the due date to ensure that they are well aware of their next repayment date."
              >
                <QuestionOutlined style={{ fontSize: "12px" }} />
              </tooltip>
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "13px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {reformatDateString(
                item.pendingRepayments?.[0]?.repay_due_date
              ) === "Invalid Date"
                ? "None"
                : reformatDateString(
                    item.pendingRepayments?.[0]?.repay_due_date
                  )}
            </p>
          </div>
        </div>
      </>
    );
  };

  const Container2Summary = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: "23.5%",
            textAlign: "center",
            marginRight: "0px",
            marginLeft: "15px",
            border: "1px solid none",
            borderRadius: "20px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const Container3 = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: screenWidth > 800 ? "96%" : "100%",
            textAlign: "center",
            height: "240px",
            marginRight: screenWidth < 800 ? "0px" : "-15px",
            marginLeft: screenWidth < 800 ? "0px" : "25px",
            border: "1px solid none",
            borderRadius: screenWidth > 800 ? "10px" : "10px 10px 10px 10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: screenWidth < 800 ? "12px" : "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const Container3Transaction = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "96%" : "100%",
            textAlign: "center",
            height: "240px",
            marginRight: screenWidth > 1200 ? "-15px" : "0px",
            marginLeft: screenWidth > 1200 ? "25px" : "0px",
            border: "1px solid none",
            borderRadius: screenWidth > 1200 ? "20px" : "0px 0px 20px 20px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: screenWidth < 800 ? "12px" : "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid none",
        borderRadius: "20px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <p
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "16px",
          color: "#142C44CC",
          textAlign: "center",
          paddingTop: "15px",
        }}
      >
        {noData}
      </p>
    </div>
  );

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/lender/viewLatestTransaction",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setData(data["Latest Transaction"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/loanDashboard/viewRequestToday",
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      const parsedData = data["Requested Loan"].map((item) => {
        const updatedItem = {
          ...item,
          credit_report_data: JSON.parse(item.credit_report_data),
        };

        // Parse credit_report_data in loan_authorizations array
        const updatedLoanAuthorizations = item.loan_authorizations.map(
          (authorization) => ({
            ...authorization,
            credit_report_data: JSON.parse(authorization.credit_report_data),
          })
        );

        // Update the item with the updated loan_authorizations array
        updatedItem.loan_authorizations = updatedLoanAuthorizations;

        return updatedItem;
      });

      setRequestedLoan(parsedData);

      // Check if the array is not empty
      if (data["Requested Loan"].length > 0) {
        // Parse the expired_date into a JavaScript Date object and get its timestamp
        const targetDate = new Date(
          data["Requested Loan"][0].expired_date
        ).getTime();
        setTargetDate(targetDate);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const reversedData = data.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/lender/viewSummary",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setSummary(data["Summary"]);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    let value = e.target.value;
    setInterestRate(value);
  };

  const handleLoanPeriodChange = (e) => {
    setSelectedLoanPeriod(e.target.value);
  };

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const handleDownloadBankStatement = async (loan_id, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewBankStatement?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      if (data && data.data) {
        window.open(data.data, "_blank"); // open the url in a new tab
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const calculateLoan = () => {
      if (type === "simple") {
        const offer_monthly_installment =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            1.0 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            0.0 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
          0.0;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      } else if (type === "effective") {
        const offer_monthly_installment =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 *
            0.05 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = offer_monthly_installment * 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      }
    };
    calculateLoan();
  }, [
    offer_amount,
    offer_interest_rate,
    offer_period,
    type,
    offer_principal,
    offer_monthly_installment,
    offer_revenue,
    total_admin_fee,
    admin_fee,
  ]);

  const fetchLoanDetails = async (loan_id) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setLoanDetails(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [modalStates, setModalStates] = useState({});
  const [modalStates2, setModalStates2] = useState({});
  const [modalStates3, setModalStates3] = useState({});

  useEffect(() => {
    const initialStates = {};
    const initialStates2 = {};
    const initialStates3 = {};

    data.forEach((item) => {
      initialStates[item.loan_id] = false;
      initialStates2[item.loan_id] = false;
      initialStates3[item.loan_id] = false;
    });
    setModalStates(initialStates);
    setModalStates2(initialStates2);
    setModalStates3(initialStates3);
  }, [data]);

  const [floatingLoanAmount, setFloatingLoanAmount] = useState({
    loanId: "",
    totalLoanedAmount: "",
  });
  const [warningLenderModal, setWarningLenderModal] = useState(false);

  const openMakeAnOfferModal = () => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [floatingLoanAmount.loanId]: true,
    }));
  };

  const showModal = (loanId, totalLoanedAmount) => {
    if (totalLoanedAmount !== 0 && totalLoanedAmount !== "summary") {
      console.log();
      setFloatingLoanAmount({
        loanId: loanId,
        totalLoanedAmount: totalLoanedAmount,
      });
      setWarningLenderModal(true);
    } else {
      setModalStates((prevStates) => ({
        ...prevStates,
        [loanId]: true,
      }));
    }
  };

  const showModal2 = (loanId, event) => {
    event.preventDefault();
    console.log("loanId modal2", loanId);
    setModalStates2((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const showModal3 = (loanId) => {
    console.log("loanId modal3", loanId);
    setModalStates3((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleGridPath = () => {
    // history.push('../loan-request-grid')
  };

  const handleListPath = () => {
    history.push("../loan-request");
  };

  const filteredData = data
    ? data.filter(
        (item) =>
          item.principal_name.toLowerCase().includes(search.toLowerCase()) ||
          item.status.toLowerCase().includes(search.toLowerCase())
      )
    : [];

  const handleMakeOffer = async (
    loan_id,
    express,
    user_id,
    principal_name,
    created_at
  ) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    // rest of the code
    try {
      let path = "";
      let item = {
        loan_id,
        express,
        offer_amount: parseFloat(parseFloat(offer_amount).toFixed(2)),
        offer_interest_rate: parseFloat(parseFloat(offer_interest_rate / 100)),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(parseFloat(offer_principal).toFixed(2)),
        offer_monthly_installment: parseFloat(
          parseFloat(offer_monthly_installment).toFixed(2)
        ),
        offer_revenue: parseFloat(parseFloat(offer_revenue).toFixed(2)),
        total_admin_fee: parseFloat(parseFloat(total_admin_fee).toFixed(2)),
        admin_fee: 0,
        processing_fee: parseFloat(offer_processing_fee / 100),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();

      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/profile/createActivityLog`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: JSON.stringify({
              user_id: user_id,
              user_name: principal_name,
              user_account_type: "borrower",
              description: `You've received a loan offer on your loan request ${loan_id} that you've made on ${formatDate(
                created_at ? created_at : null
              )}`,
            }),
          }
        );
        const json = await response.json();

        console.log(json);
        console.log("Offer created:", data);
      } catch (error) {
        console.error("Error creating offer:", error);
      }

      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/profile/createActivityLogForSelf`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: JSON.stringify({
              description: `You've made a loan offer on loan ${loan_id} from ${principal_name}.`,
            }),
          }
        );
        const data = await response.json();
        console.log("Offer created:", data);
      } catch (error) {
        console.error("Error creating offer:", error);
      }
      if (response.status === 200) {
        message.success(data.message || "Loan offer created successfully."); // Display success message
        path = "/pending-acceptance";
        return history.push(path);
      } else if (response.status === 400) {
        message.error(data.message || "Unable to create loan offer."); // Display error message
      } else {
        message.error("An unexpected error has occurred."); // Handle other errors
      }

      console.log("Offer created:", data);
      return history.push(path);
      // Update your UI or close the modal
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  const handleRequestMoreDocument = async (loan_id, express) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    // rest of the code
    try {
      let path = "";

      let add_documents = documentDescriptions.map((description, index) => ({
        document_name: description,
        document: "",
      }));

      let item = {
        loan_id,
        express,
        offer_amount: parseFloat(parseFloat(offer_amount).toFixed(2)),
        offer_interest_rate: parseFloat(parseFloat(offer_interest_rate / 100)),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(parseFloat(offer_principal).toFixed(2)),
        offer_monthly_installment: parseFloat(
          parseFloat(offer_monthly_installment).toFixed(2)
        ),
        offer_revenue: parseFloat(parseFloat(offer_revenue).toFixed(2)),
        total_admin_fee: parseFloat(parseFloat(total_admin_fee).toFixed(2)),
        admin_fee: 0,
        processing_fee: parseFloat(offer_processing_fee / 100),
        add_document: JSON.stringify(add_documents),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();

      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/profile/createActivityLog`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: JSON.stringify({
              user_id: user_id,
              user_name: principal_name,
              user_account_type: "borrower",
              description: `You've received a request for more document on your loan request ${loan_id} that you've made on ${formatDate(
                extraRequestInformation.created_at
                  ? extraRequestInformation.created_at
                  : null
              )}`,
            }),
          }
        );
        const data = await response.json();
        console.log("Offer created:", data);
      } catch (error) {
        console.error("Error creating offer:", error);
      }

      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/profile/createActivityLogForSelf`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: JSON.stringify({
              description: `You've requested for more document on loan ${loan_id} from ${principal_name}.`,
            }),
          }
        );
        const data = await response.json();
        console.log("Offer created:", data);
      } catch (error) {
        console.error("Error creating offer:", error);
      }

      if (response.status === 200) {
        message.success(data.message || "Loan offer created successfully."); // Display success message
        path = "/pending-acceptance";
        return history.push(path);
      } else if (response.status === 400) {
        message.error(data.message || "Unable to create loan offer."); // Display error message
      } else {
        message.error("An unexpected error has occurred."); // Handle other errors
      }

      console.log("Offer created:", data);
      return history.push(path);
      // Update your UI or close the modal
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  const handleNotInterested = async (loan_id, selectedValue) => {
    console.log("Not interested");
    const updatedNotInterestedIds = [...notInterestedIds, loan_id];

    // Filter out the loan with the provided loan_id
    const selectedLoan = data.filter((loan) => loan.loan_id === loan_id);

    // Filter out the other loans
    const remainingLoans = data.filter((loan) => loan.loan_id !== loan_id);

    // Concatenate the arrays to move the selected loan to the end
    const updatedLoans = [...remainingLoans, ...selectedLoan];

    // Update the data array and not interested ids
    setData(updatedLoans);
    setNotInterestedIds(updatedNotInterestedIds);

    // Store the updated data in the cookies
    Cookies.set("notInterestedIds", JSON.stringify(updatedNotInterestedIds));
    Cookies.set("loans", JSON.stringify(updatedLoans));

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/notInterestedLoan?loan_id=${loan_id}&reject_reason=${selectedValue}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
    }

    try {
      let item = {
        user_id: "A00001",
        channel: "system",
        subject: `Not interested Loan`,
        message: `Lender had select an option for not interested with loan ${loan_id}`,
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/user/notification/sendNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      console.log("Offer created:", data);
    } catch (error) {
      console.error("Error creating offer:", error);
    }

    // Fetch data again
    fetchData();
  };

  const [showOtherInput, setShowOtherInput] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleNotInterestedModal = (event) => {
    console.log("Event: ", event);
    const value = event.target.value;
    console.log("Value: ", value);
    setSelectedValue(value);
    setShowOtherInput(value === "others");
  };

  const buttonStyleExpress = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background: "#FED065",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const buttonStyleBorrower = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background:
      "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [needOtherAuthoriser, setNeedOtherAuthoriser] = useState(false);
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [openDataIndex, setOpenDataIndex] = useState(null);
  const [keyman, setKeyman] = useState("");
  const [viewingUser, setViewingUser] = useState(0);
  const [viewingTab, setViewingTab] = useState(0);
  const [liveUserData, setLiveUserData] = useState(null);
  const [singpassData, setSingpassData] = useState(null);
  const [combinedSingpassData, setCombinedSingpassData] = useState([]);
  const [allCBSData, setAllCBSData] = useState([]);
  const [badgeVerified, setBadgeVerified] = useState(false);
  const [keymanDetails, setKeymanDetails] = useState({
    contact: "",
    email: "",
  });

  const availableTab = [
    { name: "Personal Information" },
    { name: "Corporate Information" },
    { name: "NOA History" },
    { name: "Capitals" },
    { name: "Financials" },
    { name: "Shareholders" },
    { name: "Borrower Report" },
  ];
  const singpassDetails = async (
    user,
    other_authorization,
    loan_id,
    keyman
  ) => {
    try {
      setKeyman(keyman);
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getBorrowerSingppass?user_id=${user}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      console.log(json);
      setKeymanDetails({
        contact: json.company_contact,
        email: json.company_email,
      });
      let allSingpassData = [json.singpass_data];
      setDataSingpassModal(true);
      if (other_authorization) {
        console.log("need other authorizer");
        setNeedOtherAuthoriser(true);
        try {
          const response2 = await fetch(
            `https://www.smart-lend.com/api/loanDetails/getLoanAuthorisers`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify({ loan_id: loan_id }),
            }
          );

          const json2 = await response2.json();
          setOtherAuthoriserDetails(json2.authorisers);
          console.log(JSON.parse(json2.authorisers[0].singpass_data));
          console.log(json.singpass_data);
          setLiveUserData(json.singpass_data);
          console.log(json2.authorisers);

          for (const single of json2.authorisers) {
            allSingpassData.push(JSON.parse(single.singpass_data));
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("Doesnt need other authoriser");
        setLiveUserData(json.singpass_data);
        setNeedOtherAuthoriser(false);
        setOtherAuthoriserDetails(null);
      }
      if (json.singpass_data) {
        setSingpassData(json.singpass_data);
        console.log(allSingpassData);
        setCombinedSingpassData(allSingpassData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getShareholderAddress = (data) => {
    const allSingpassData = [singpassData];
    if (otherAuthoriserDetails !== null && otherAuthoriserDetails.length > 0) {
      for (const single of otherAuthoriserDetails) {
        allSingpassData.push(JSON.parse(single.singpass_data));
      }
      const toPush = allSingpassData.filter(
        (sd) => sd?.person?.name?.value === data
      );
      // console.log(toPush);
      return toPush;
    } else {
      return allSingpassData;
    }
  };

  return (
    <Box m="4px 20px 20px 20px">
      <Modal
        open={warningLenderModal}
        onCancel={() => {
          setWarningLenderModal(false);
        }}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setWarningLenderModal(false);
                openMakeAnOfferModal();
              }}
            >
              Proceed
            </Button>
            <Button type="default" onClick={() => setWarningLenderModal(false)}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: 800,
            color: "#142C44CC",
            fontFamily: "Manrope, sans-serif",
          }}
        >
          Loan Offer Notice
        </div>
        <div style={{ marginTop: "5px" }}>
          The borrower currently have accepted loans totaling{" "}
          {floatingLoanAmount.totalLoanedAmount.toLocaleString("en-us", {
            style: "currency",
            currency: "SGD",
          })}{" "}
          on this loan request, would you like to proceed making a loan offer to
          this borrower?
        </div>
      </Modal>
      <Modal
        width={isMobile ? "100%" : "60%"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={dataSingpassModal}
        onCancel={() => {
          setDataSingpassModal(false);
          setSingpassData(null);
          setOpenDataIndex(null);
          setKeyman("");
        }}
        footer={null}
      >
        {singpassData ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px",
                color: "rgba(20, 44, 68, 0.8)",
                fontWeight: 900,
                fontFamily: "Manrope, sans-serif",
                fontSize: 20,
              }}
            >
              <h2> Borrower information</h2>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  background: "rgba(14, 90, 165, 0.1)",
                  color: "rgba(20, 44, 68, 0.8)",
                  fontWeight: 800,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: 16,
                  padding: "3px 15px",
                  borderRadius: 7,
                }}
              >
                Loan keyman: {keyman ? keyman : ""}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderTop: "1px solid lightgrey",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 25,
                gap: "20px",
                padding: "10px 0px",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              <div
                className="all_button"
                style={{
                  cursor: "pointer",
                  color:
                    viewingUser === 0
                      ? "rgba(20, 44, 68, 0.8)"
                      : "rgba(0,0,0,0.4)",
                  fontSize: viewingUser === 0 ? "14px" : "12px",
                  fontWeight: viewingUser === 0 ? "800" : "400",
                  transition: "0.3s all ease-in-out",
                }}
                onClick={() => {
                  setViewingUser(0);
                  console.log(singpassData);
                  setLiveUserData(singpassData);
                }}
              >
                {singpassData?.person?.name?.value !== undefined
                  ? singpassData?.person?.name?.value
                  : null}
              </div>

              {otherAuthoriserDetails
                ? otherAuthoriserDetails.map((aut, index) => (
                    <div
                      className="all_button"
                      style={{
                        cursor: "pointer",
                        color:
                          viewingUser === index + 1
                            ? "rgba(20, 44, 68, 0.8)"
                            : "rgba(0,0,0,0.4)",
                        fontSize: viewingUser === index + 1 ? "14px" : "12px",
                        fontWeight: viewingUser === index + 1 ? "800" : "400",
                        transition: "0.3s all ease-in-out",
                      }}
                      onClick={() => {
                        setViewingUser(index + 1);
                        console.log(JSON.parse(aut.singpass_data));
                        setLiveUserData(JSON.parse(aut.singpass_data));
                      }}
                      key={index}
                    >
                      {aut.director_name}
                    </div>
                  ))
                : null}
            </div>
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "20px",
                padding: "10px 0px",
                width: "100%",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              {availableTab.map((view, index) => (
                <div
                  onClick={() => {
                    setViewingTab(index);
                  }}
                  className="all_button"
                  style={{
                    cursor: "pointer",
                    color:
                      viewingTab === index
                        ? "rgba(20, 44, 68, 0.8)"
                        : "rgba(0,0,0,0.4)",
                    fontSize: viewingTab === index ? "13px" : "12px",
                    fontWeight: viewingTab === index ? "700" : "400",
                    transition: "0.3s all ease-in-out",
                  }}
                  key={index}
                >
                  {view.name}
                </div>
              ))}
            </div>
            <div
              style={{
                padding: "15px 0px",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              {liveUserData ? (
                viewingTab === 0 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        NRIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          min={0}
                          // className={nricError ? "error-border" : ""}
                          value={liveUserData?.person?.uinfin?.value}
                        />
                        {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Full Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          value={liveUserData?.person?.name?.value}
                          // required
                        />
                        {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Sex
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.sex?.desc}
                          // required
                        />
                        {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Race
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.race?.desc}
                        />
                        {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Nationality
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.nationality?.desc}
                        />
                        {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Date of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.dob?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Country of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.birthcountry?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Residential Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.residentialstatus?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passtype?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passstatus?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Expiry Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.passexpirydate?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Ownership of Private Property Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.ownerprivate?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Email
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          type="email"
                          disabled
                          // required
                          value={liveUserData?.person?.email?.value}
                        />
                        {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Mobile Number
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="number"
                          // required
                          value={liveUserData?.person?.mobileno?.nbr?.value}
                        />
                        {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.person?.regadd
                              ? (liveUserData?.person?.regadd?.unit?.value !==
                                null
                                  ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.floor?.value !==
                                null
                                  ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.block?.value !==
                                null
                                  ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.street?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.street?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.postal?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.country?.desc !==
                                null
                                  ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Marital Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.marital?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Occupation
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.occupation?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employer's name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employment Sector
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.regadd
                              ? (liveUserData?.person?.regadd?.unit?.value !==
                                null
                                  ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.floor?.value !==
                                null
                                  ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.block?.value !==
                                null
                                  ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.street?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.street?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.postal?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.country?.desc !==
                                null
                                  ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Outstanding HDB Loan Balance
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbownership[0]?.outstandingloanbalance?.value?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Type of HDB Dwelling
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.hdbownership[0]?.hdbtype?.desc
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Monthly Loan Installment
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbownership[0]?.monthlyloaninstalment?.value?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Model
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.vehicles[0]?.model?.value
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Make
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.vehicles[0]?.make?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                  </div>
                ) : viewingTab === 1 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        UEN
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]?.uen?.value
                          }
                        />
                        {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-name"]
                              ?.value
                          }
                        />
                        {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-type"]
                              ?.value
                          }
                        />
                        {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "entity-status"
                            ]?.value
                          }
                        />
                        {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                            resize: "none",
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.entity?.addresses["addresses-list"][0]
                              ? (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.unit?.value !== undefined
                                  ? `Unit ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.floor?.value !== undefined
                                  ? `Floor ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.block?.value !== undefined
                                  ? `Block ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.street?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.postal?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.country?.desc !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registration Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "registration-date"
                            ]?.value
                          }
                        />
                        {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Primary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "primary-activity"
                            ]?.desc
                          }
                        />
                        {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Secondary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "secondary-activity"
                            ]?.desc
                          }
                        />
                        {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                      </div>
                    </div>
                    {liveUserData?.entity?.appointments[
                      "appointments-list"
                    ]?.map((appointment, index) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "2px 20px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Position
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={appointment.position.desc}
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"].idno.value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"].uen.value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"][
                                      "person-name"
                                    ].value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"][
                                      "entity-name"
                                    ].value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Appointment Date
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              value={appointment["appointment-date"].value}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : viewingTab === 2 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Employment
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Interest
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Rent
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Tax Clearance
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Trade
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Years of Assessment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.person?.noahistory?.noas?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.amount?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.employment?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.interest?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.rent?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.taxclearance?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.trade?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.yearofassessment?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 3 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Capital Type
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Issued Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Alloted Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.capitals["capitals-list"]?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["capital-type"]?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "issued-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "paid-up-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "share-allotted-amount"
                              ]?.value?.toLocaleString("en-US")}
                            </td>
                            {/* Add other data cells */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 4 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period End Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period Start Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Capital Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Is Audited
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.financials?.highlights?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-end-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-start-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["group-capital-paid-up-capital-amount"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["is-audited"]?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 5 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Allocation
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          NRIC
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.shareholders[
                        "shareholders-list"
                      ] ? (
                        liveUserData?.entity?.shareholders[
                          "shareholders-list"
                        ]?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.allocation?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["person-reference"]?.["person-name"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["person-reference"]?.idno?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].idno.value}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].nationality.desc}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"]["person-name"].value}</td> */}
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["share-type"]?.desc}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              textAlign: "center",
                              padding: "5px 10px",
                            }}
                            colSpan="11"
                          >
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                    <PDFViewer style={{ width: "100%", height: "80vh" }}>
                      <Document>
                        <Page size="A4">
                          {/**Letterhead section */}
                          <View
                            style={{
                              paddingVertical: 15,
                            }}
                          >
                            <View
                              style={{
                                paddingHorizontal: 40,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: 10,
                                // borderBottom: 1,
                                // borderBottomColor: "grey",
                                paddingBottom: 10,
                              }}
                            >
                              <View
                                style={{
                                  padding: 8,
                                  borderWidth: 3,
                                  borderColor: "#2881c4",
                                  borderRadius: "50%",
                                  marginTop: 15,
                                }}
                              >
                                <Image
                                  source={logo}
                                  style={{ width: 50, objectFit: "contain" }}
                                />
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 18,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  SmartLend Financial Pte. Ltd.
                                </Text>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 4,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: -3,
                                  }}
                                >
                                  <Text style={{ fontSize: 7 }}>
                                    UEN: 202327568G
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Tel: +65 88288147
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Email: support@smart-lend.com
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                backgroundColor: "lightgrey",
                                height: 2,
                                width: "100%",
                                marginTop: 20,
                              }}
                            />
                            <View
                              style={{
                                backgroundColor: "#2881c4",
                                height: 4,
                                width: "76%",
                                marginTop: -3,
                                marginLeft: "12%",
                                marginBottom: 15,
                              }}
                            />
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Company Profile
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  padding: 8,
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                }}
                              >
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Name:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "entity-name"
                                        ]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      UEN:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"]
                                          ?.uen?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Registration Date:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["registration-date"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Primary Activity:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "primary-activity"
                                        ]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Ownership:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["ownership"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Status:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["entity-status"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Constitution:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["company-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{ flexDirection: "row", gap: 10 }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Verification By Smartlend:
                                    </Text>
                                    <Text style={{ fontSize: 7 }}>
                                      {badgeVerified
                                        ? "Verfied"
                                        : "Un-verified"}
                                    </Text>
                                  </View>
                                </View>
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      ACRA Address:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.addresses[
                                        "addresses-list"
                                      ][0]
                                        ? (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.unit?.value !== undefined
                                            ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.floor?.value !== undefined
                                            ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.block?.value !== undefined
                                            ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.street?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.postal?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.country?.desc !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                            : "")
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Capital Type:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {
                                        singpassData?.entity?.["capitals"]?.[
                                          "capitals-list"
                                        ]?.[0]?.["capital-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Paid Up Capital:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "paid-up-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Share Allocated Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "share-allotted-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Issued Capital Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "issued-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Email:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {getShareholderAddress(keyman)?.[0]
                                        ?.person?.email?.value !== undefined
                                        ? getShareholderAddress(keyman)?.[0]
                                            ?.person?.email?.value
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Mobile:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {getShareholderAddress(keyman)?.[0]
                                        ?.person?.mobileno?.nbr?.value !==
                                      undefined
                                        ? getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.prefix?.value +
                                          getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.areacode
                                            ?.value +
                                          "-" +
                                          getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.nbr?.value
                                        : ""}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Financial Highlight
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Revenue</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit pre-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit post-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Start date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>End date</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.financials?.highlights?.map(
                                  (data, index) => (
                                    <View
                                      key={index}
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor:
                                          index % 2
                                            ? "rgba(199, 220, 236, 0.2)"
                                            : "transparent",
                                        padding: 4,
                                      }}
                                    >
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-revenue"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-before-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-after-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-start-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-end-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                  )
                                )}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Grants
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Category</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Functional Area</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Status</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Approved Amount</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.grants?.[
                                  "grants-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["development-category"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["functional-area"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>{data?.["grant-type"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["grant-status"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.[
                                          "approved-amount"
                                        ]?.value?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Officer(S) / Owners
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Officer Name</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Position</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Appointment Date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.appointments?.[
                                  "appointments-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "25%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.["idno"]
                                            ?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>{data?.["position"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["appointment-date"]?.value}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Shareholders
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Shareholder Name / Address</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "18%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Share Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>No of Share</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Date of birth</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Corppass Contact</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.shareholders?.[
                                  "shareholders-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "25%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.[
                                          "person-name"
                                        ]?.value
                                          ? data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value +
                                            " (" +
                                            getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.sex?.code +
                                            ")"
                                          : data?.["entity-reference"]?.[
                                              "entity-name"
                                            ]?.value + " (Corporate)"}
                                      </Text>
                                      <Text>
                                        {getShareholderAddress(
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        )?.[0]?.person?.regadd
                                          ? (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.unit
                                              ?.value !== null
                                              ? `Unit ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.unit
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.floor
                                              ?.value !== null
                                              ? `Floor ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.floor
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.block
                                              ?.value !== null
                                              ? `Block ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.block
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.street
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.street
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.postal
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.postal
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.country
                                              ?.desc !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd
                                                    ?.country?.desc
                                                }.`
                                              : "")
                                          : ""}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.["idno"]
                                          ?.value
                                          ? data?.["person-reference"]?.["idno"]
                                              ?.value
                                          : data?.["entity-reference"]?.["uen"]
                                              ?.value + " (UEN)"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "18%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["share-type"]?.desc}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["allocation"]?.value}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "15%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.dob?.value
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.email?.value
                                        }
                                      </Text>
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.mobileno?.nbr?.value
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                          </View>
                          <View></View>
                        </Page>
                        {combinedSingpassData
                          ? combinedSingpassData.map((data, index) => (
                              <Page key={index} size="A4">
                                {/**Letterhead section */}
                                <View
                                  style={{
                                    paddingVertical: 15,
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Personal Information"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        padding: 8,
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                      }}
                                    >
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Ownership of Private Residential
                                            Property:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {data?.person?.hdbownership
                                              ? data?.person?.hdbownership
                                                  ?.length > 0
                                                ? "Yes"
                                                : "No"
                                              : "No"}
                                          </Text>
                                        </View>
                                        {data?.person?.hdbownership
                                          ? data?.person?.hdbownership?.length >
                                            0
                                            ? data?.person?.hdbownership?.map(
                                                (hdb, index3) => (
                                                  <View key={index3}>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                        marginTop: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB {index3 + 1}
                                                      </Text>
                                                    </View>

                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB Ownership:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.address
                                                          ? (hdb?.address?.unit
                                                              ?.value !== null
                                                              ? `Unit ${hdb?.address?.unit?.value}, `
                                                              : "") +
                                                            (hdb?.address?.floor
                                                              ?.value !== null
                                                              ? `Floor ${hdb?.address?.floor?.value}, `
                                                              : "") +
                                                            (hdb?.address?.block
                                                              ?.value !== null
                                                              ? `Block ${hdb?.address?.block?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.street
                                                              ?.value !== null
                                                              ? `${hdb?.address?.street?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.postal
                                                              ?.value !== null
                                                              ? `${hdb?.address?.postal?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.country
                                                              ?.desc !== null
                                                              ? `${hdb?.address?.country?.desc}.`
                                                              : "")
                                                          : ""}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Outstanding HDB Loan
                                                        Balance:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {hdb?.outstandingloanbalance?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Type of HDB:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.hdbtype?.desc}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Monthly Loan
                                                        Installment:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )
                                              )
                                            : "No"
                                          : "No"}
                                      </View>
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Contact:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.mobileno?.nbr?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Email:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.email?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Marital Status:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.marital?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Vehicles:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.vehicles?.length > 0
                                              ? data?.person?.vehicles?.map(
                                                  (veh, index4) => (
                                                    <Text
                                                      key={index4}
                                                      style={{
                                                        fontSize: 7,
                                                        width: "65%",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {veh?.make?.value +
                                                          ": " +
                                                          veh?.model?.value}
                                                      </Text>
                                                    </Text>
                                                  )
                                                )
                                              : "None"}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Notice of Assessments"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                        width: "100%",
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor: "#c7dcec",
                                          opacity: 0.7,
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Year of Assesment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Amount</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Employment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Interest</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Rent</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Trade</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Tax Clearance</Text>
                                        </View>
                                      </View>
                                      {data?.person?.noahistory?.noas?.map(
                                        (data2, index2) => (
                                          <View
                                            key={index2}
                                            style={{
                                              flexDirection: "row",
                                              width: "100%",
                                              backgroundColor:
                                                index2 % 2
                                                  ? "rgba(199, 220, 236, 0.2)"
                                                  : "transparent",
                                              padding: 4,
                                              gap: 10,
                                            }}
                                          >
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {
                                                  data2?.["yearofassessment"]
                                                    ?.value
                                                }
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "amount"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "employment"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "interest"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "rent"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "trade"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.["taxclearance"]?.value}
                                              </Text>
                                            </View>
                                          </View>
                                        )
                                      )}
                                    </View>
                                  </View>
                                  {allCBSData?.filter(
                                    (dat2) =>
                                      dat2?.name === data?.person?.name?.value
                                  )?.[0] ? (
                                    <View
                                      style={{
                                        marginTop: 15,
                                        paddingHorizontal: 40,
                                      }}
                                    >
                                      <View
                                        style={{
                                          backgroundColor: "#c7dcec",
                                          alignItems: "center",
                                          padding: 6,
                                          borderTopLeftRadius: 7,
                                          borderTopRightRadius: 7,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 10,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {data?.person?.name?.value +
                                            " | Credit Bureau | Personal Commitement"}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Credit Score:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.CreditScore
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Probability of Default:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.ProbabilityofDefault
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(Number(
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.UnsecuredCreditUtilizationinpercentage
                                              ) || 0) + "%"}
                                            </Text>
                                          </View>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Risk Grade:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.RiskGrade
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(Number(
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.SecuredCreditUtilizationinpercentage
                                              ) || 0) + "%"}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Monthly Commitment/Installment
                                              Secured:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(
                                                Number(
                                                  allCBSData?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]?.cbs_data
                                                    ?.MonthlyCommitment
                                                ) || 0
                                              )?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "SGD",
                                              })}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            backgroundColor: "#c7dcec",
                                            opacity: 0.7,
                                            padding: 4,
                                            gap: 10,
                                          }}
                                        >
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Loan / Product Type</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Grantor / Bank</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Outstanding Balance</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Monthly Installment</Text>
                                          </View>
                                        </View>

                                        {allCBSData
                                          ?.filter(
                                            (dat2) =>
                                              dat2?.name ===
                                              data?.person?.name?.value
                                          )?.[0]
                                          ?.cbs_data?.NoteworthyOngoingLoans?.map(
                                            (imp, index10) => (
                                              <View
                                                key={index10}
                                                style={{
                                                  flexDirection: "row",
                                                  width: "100%",
                                                  backgroundColor:
                                                    index10 % 2
                                                      ? "rgba(199, 220, 236, 0.4)"
                                                      : "rgba(199, 220, 236, 0.15)",
                                                  padding: 4,
                                                  gap: 10,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp.includes(
                                                      "private residential purchase",
                                                      ""
                                                    )
                                                      ? "Private Residential Purchase"
                                                      : imp.includes(
                                                          "motor vehicle loan",
                                                          ""
                                                        )
                                                      ? "Motor Vehicle Loan"
                                                      : imp.includes(
                                                          "hdb loan",
                                                          ""
                                                        )
                                                      ? "HDB Loan"
                                                      : ""}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp
                                                      .replace(
                                                        "private residential purchase",
                                                        ""
                                                      )
                                                      .replace(
                                                        "motor vehicle loan",
                                                        ""
                                                      )
                                                      .replace("hdb loan", "")
                                                      .trim()
                                                      .split(" ")[0]
                                                      .toUpperCase()}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {parseFloat(
                                                      imp
                                                        .split("   ")
                                                        [
                                                          imp.split("   ")
                                                            .length - 4
                                                        ].replace(/,/g, "")
                                                    ).toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency: "SGD",
                                                    })}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.CBSImpLoanSupplementaryInstallment?.[
                                                      index10
                                                    ]
                                                      ? (
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 4
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          ) +
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 3
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          )
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )
                                                      : null}
                                                  </Text>
                                                </View>
                                              </View>
                                            )
                                          )}
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                          borderBottomWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Last 6 Months (Unsecured) Credit
                                            Utilization Graph:
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "100%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <View
                                              style={{
                                                width: "5%",
                                                position: "relative",
                                              }}
                                            >
                                              <View style={{ height: "15px" }}>
                                                <Text
                                                  style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.Last6MonthsGraphChartMaxData?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{ height: "220px" }}
                                              ></View>
                                            </View>
                                            <View
                                              style={{
                                                width: "95%",
                                              }}
                                            >
                                              <View
                                                style={{
                                                  width: "100%",
                                                  flexDirection: "row",
                                                  marginTop: "25px",
                                                  borderBottom:
                                                    "1px solid lightgrey",
                                                  height: "200px",
                                                  alignItems: "flex-end",
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    height: "200px",
                                                    borderLeft:
                                                      "1px solid lightgrey",
                                                  }}
                                                />
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat30, index30) => (
                                                      <View
                                                        key={index30}
                                                        style={{
                                                          marginLeft: "4%",
                                                          marginRight: "4%",
                                                          width: "8%",
                                                          borderTopLeftRadius:
                                                            "4px",
                                                          borderTopRightRadius:
                                                            "4px",
                                                          height: `${
                                                            (parseFloat(
                                                              dat30.value.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                            ) /
                                                              allCBSData?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]?.cbs_data
                                                                ?.Last6MonthsGraphChartMaxData) *
                                                            100
                                                          }%`,
                                                          backgroundColor:
                                                            "rgba(217, 238, 255, 1)",
                                                        }}
                                                      ></View>
                                                    )
                                                  )}
                                              </View>
                                              <View
                                                style={{
                                                  marginTop: "8px",
                                                  flexDirection: "row",
                                                  fontSize: 7,
                                                }}
                                              >
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat, index) => (
                                                      <View
                                                        key={index}
                                                        style={{
                                                          width: "16%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {dat.date
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            dat.date.slice(1)}
                                                        </Text>
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {parseFloat(
                                                            dat.value.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ).toLocaleString(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "SGD",
                                                            }
                                                          )}
                                                        </Text>
                                                      </View>
                                                    )
                                                  )}
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  ) : null}
                                </View>
                              </Page>
                            ))
                          : null}
                      </Document>
                    </PDFViewer>
                  </div>
                )
              ) : null}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Modal>
      {/* HEADER */}
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
   <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

 </Box> */}

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}

        {/* ROW 2 */}
        <Box
          gridColumn={isMobile ? "span 12" : "span 9"}
          gridRow="span 2"
          borderRadius="10px"
          border="0px solid transparent"
          opacity="0.95"
          style={{
            overflowY: "scroll",
            height: isMobile ? "80vh" : "90vh",
            overflowX: "hidden",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: isMobile ? "20px" : "20px 20px 120px 20px",
          }}
        >
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            // background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
            border-radius="10px"
            opacity="0.95"
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="18px"
            >
              Request of the day
            </Typography>

            <Box
              gridColumn="span 4"
              // backgroundColor={colors.grey[910]}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "grey",
                  gap: "15px",
                  marginTop: "15px",
                }}
              >
                {dataRequestedLoan && dataRequestedLoan.length > 0 ? (
                  dataRequestedLoan.slice(0, 4).map((item, index) =>
                    item.visibility &&
                    !item.make_hidden &&
                    !item.admin_removal ? (
                      <Container
                        key={index}
                        title={item.principal_name || "-"}
                        subtitle={
                          item.express === 1
                            ? "Express Applicant"
                            : "Approved Borrower"
                        }
                        loanOfferCount={item.loan_offer_count + " Offering"} // use the countLoanOffer object to access the count for each loan_id
                        label="Credit Score"
                        credit_score={item.credit_score}
                        description={item.credit_score || "-"}
                        createdAt={item.created_at}
                        label1="Loan Amount"
                        description1={
                          item.request_amount
                            ? item.request_amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label2="Period"
                        description2={item.request_period + " Months" || "-"}
                        countDown={item.expired_date} // pass the value of expired_date
                        index={index} // pass the index prop to each Container instance
                        progressPercent={item.credit_score} // set the progress percentage
                        loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                        open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                        onClickModal={(event) => showModal(item.loan_id, event)} // pass the showModal function to each Container instance
                        cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                        item={item}
                      />
                    ) : null
                  )
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      No Loans Requested Today
                    </div>
                  </div>
                  // <Container3 noData="No Today Requested Loan" />
                )}
                {dataRequestedLoan &&
                  dataRequestedLoan.length > 0 &&
                  dataRequestedLoan.map((item, index) => (
                    <Modal
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                      open={modalStates[item.loan_id]}
                      onCancel={() =>
                        setModalStates((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      footer={[
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 16,
                          }}
                        >
                          {offer_amount.length > 0 &&
                            offer_interest_rate.length > 0 &&
                            offer_period.length > 0 &&
                            type.length > 0 && (
                              <div>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    handleMakeOffer(
                                      item.loan_id,
                                      item.express,
                                      item.user_id,
                                      item.principal_name,
                                      item.created_at
                                    )
                                  }
                                >
                                  Make Offer
                                </Button>
                                <Button
                                  type="default"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() =>
                                    showModalRequestDocument(
                                      item.loan_id,
                                      item.express,
                                      item.user_id,
                                      item.principal_name,
                                      item.created_at
                                    )
                                  }
                                >
                                  Request More Document
                                </Button>
                              </div>
                            )}
                        </div>,
                      ]}
                    >
                      <div>
                        <div
                          style={{
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 700,
                            fontSize: 20,
                          }}
                        >
                          Loan offer to {item.principal_name || "-"}
                        </div>
                        <div style={{ display: "flex", marginTop: 10 }}>
                          <div
                            style={{
                              background: "rgba(153, 178, 205, 0.67)",
                              padding: "5px 15px",
                              borderRadius: "6px",
                              color: "white",
                              fontSize: 12,
                              fontWeight: 400,
                            }}
                          >
                            Loan ID: {item.loan_id}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_amount">Loan Amount</label>
                          <br />
                          <input
                            type="number"
                            id="offer_amount"
                            name="offer_amount"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanAmountChange}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_interest_rate">
                            Interest Rate (%)
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_interest_rate"
                            name="offer_interest_rate"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            min={0}
                            max={100}
                            onChange={handleInterestRateChange}
                          />
                          {" %"}
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_period">
                            Loan Period (Month)
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_period"
                            name="offer_period"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanPeriodChange}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_processing_fee">
                            Processing Fee (%)
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_processing_fee"
                            name="offer_processing_fee"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            value={parseFloat(offer_processing_fee)}
                            onChange={(e) => setProcessingFee(e.target.value)}
                            min={0}
                            max={100}
                          />{" "}
                          %
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="type">Loan Type</label>
                          <br />
                          <select
                            id="type"
                            name="type"
                            style={{
                              borderRadius: "10px",
                              width: "73%",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanTypeChange}
                          >
                            <option value="simple">
                              Unsecured Business Term Loan
                            </option>
                            {/* <option value="effective">
                                  Effective Loan
                                </option> */}
                          </select>
                        </div>
                      </div>

                      <hr />

                      <p>
                        Processing Fees:{" "}
                        {Number(
                          offer_amount * (offer_processing_fee / 100)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                      <p>
                        Monthly Instalment payable:{" "}
                        {Number(offer_monthly_installment).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "SGD",
                          }
                        )}
                      </p>
                      <p>
                        Guaranteed Revenue:{" "}
                        {Number(
                          offer_revenue +
                            offer_amount * (offer_processing_fee / 100)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                      <p>
                        Platform Fee:{" "}
                        {Number(offer_amount * 0.015).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                    </Modal>
                  ))}
                {dataRequestedLoan &&
                  dataRequestedLoan.length > 0 &&
                  dataRequestedLoan.map((item, index) => {
                    return (
                      <Modal
                        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                        open={modalStates3[item.loan_id]}
                        onCancel={() =>
                          setModalStates3((prevStates) => ({
                            ...prevStates,
                            [item.loan_id]: false,
                          }))
                        }
                        footer={[
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 16,
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={() =>
                                handleNotInterested(item.loan_id, selectedValue)
                              }
                            >
                              Submit
                            </Button>
                          </div>,
                        ]}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 16,
                            borderRadius: "10px",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="offer_amount"
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "600",
                                fontSize: "18px",
                                opacity: ".6",
                              }}
                            >
                              Are you not interested with this loan?{" "}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 16,
                            borderRadius: "10px",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="offer_amount"
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "600",
                                fontSize: "14px",
                                opacity: ".6",
                              }}
                            >
                              Please Select one of the option :{" "}
                            </label>

                            <br />
                            <select
                              id="type"
                              name="type"
                              style={{
                                borderRadius: "10px",
                                width: "auto",
                                marginLeft: "0px",
                              }}
                              onChange={handleNotInterestedModal}
                            >
                              <option value="interest_rate_is_too_low">
                                The interest rate is too low
                              </option>
                              <option value="loan_period_is_too_long">
                                The loan period is too long
                              </option>
                              <option value="credit_score_borrower_not_meet_expectation">
                                The credit score of the borrower does not meet
                                my expectation
                              </option>
                              <option value="others">Others</option>
                            </select>
                          </div>
                        </div>
                        {showOtherInput && (
                          <div style={{ marginTop: "16px" }}>
                            <label
                              htmlFor="other_reason"
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "600",
                                fontSize: "14px",
                                opacity: ".6",
                              }}
                            >
                              Please specify:
                            </label>
                            <br />
                            <input
                              type="text"
                              id="other_reason"
                              name="other_reason"
                              style={{
                                borderRadius: "10px",
                                width: "90%",
                                paddingLeft: "10px",
                              }}
                              onChange={(event) =>
                                setSelectedValue(event.target.value)
                              }
                              value={selectedValue}
                            />
                          </div>
                        )}

                        <hr />
                      </Modal>
                    );
                  })}
              </div>
            </Box>
            <Modal
              footer={[
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 16,
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() =>
                      handleRequestMoreDocument(
                        loanId,
                        express,
                        user_id,
                        principal_name
                      )
                    }
                  >
                    Submit
                  </Button>
                  <Button type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>,
              ]}
              open={isModalOpen}
              // onOk={handleOk}
              onCancel={handleCancel}
            >
              {" "}
              <div
                style={{
                  color: "rgba(0,0,0,0.5)",
                  fontWeight: "700",
                  marginTop: "10px",
                }}
              >
                Please Insert The Document Name
              </div>
              <div>
                {documentDescriptions.map((description, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      width: "90%",
                      marginTop: "15px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <div
                        style={{
                          color: "rgba(0,0,0,0.5)",
                          fontWeight: "700",
                          marginTop: "10px",
                        }}
                      >
                        Document Name
                      </div>
                    </div>
                    <div style={{ width: "60%" }}>
                      <div>
                        <input
                          style={{
                            width: "90%",
                            padding: "8px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            outline: "none",
                            border: "1px solid rgba(0,0,0,0.2)",
                          }}
                          value={description}
                          placeholder="Document Name"
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                    </div>
                    {documentDescriptions.length > 1 && (
                      <div style={{ width: "10%", textAlign: "right" }}>
                        <Button
                          type="default"
                          onClick={() => remove(index)}
                          style={{
                            backgroundColor: "none",
                            border: "none",
                            color: "#FF0000",
                            boxShadow: "none",
                            marginTop: "10px",
                          }}
                          icon={<MinusOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
                <div>
                  <Button
                    type="default"
                    onClick={addMore}
                    style={{
                      backgroundColor: "none",
                      border: "none",
                      color: "#0E5AA5",
                      marginLeft: "25%",
                      boxShadow: "none",
                      marginTop: "5px",
                    }}
                    icon={<PlusOutlined style={{ marginTop: "-10px" }} />}
                  >
                    Add more
                  </Button>
                </div>
              </div>
            </Modal>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            colors={colors.grey[910]}
            opacity="1.00"
            p="30px 0px 15px 0px"
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="18px"
            >
              Latest Transaction
            </Typography>
          </Box>
          <Box mt="10px">
            <Box
              display="flex"
              width="100%"
              borderRadius="10px"
              justifyContent="left"
            >
              <Box
                display="flex"
                flexDirection="column"
                backgroundColor="rgba(255,255,255,0.7)"
                padding="1%"
                borderRadius="10px"
                marginTop="-10px"
                width="100%"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center", // use "center" to vertically center the titles
                    colors: colors.grey[910],
                    backgroundColor: "#ffff",
                    width: "100%",
                    borderRadius: "10px",
                    padding: "12px",
                    marginBottom: "7px",
                  }}
                >
                  <div style={{ textAlign: "left", width: "36%" }}>
                    <h3
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: "13px",
                        color: "#435669",
                        margin: "0px",
                      }}
                    >
                      Borrower Name
                    </h3>
                  </div>
                  <div style={{ textAlign: "center", width: "25%" }}>
                    <h3
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: "13px",
                        color: "#435669",
                        margin: "0px",
                      }}
                    >
                      Amount
                    </h3>
                  </div>
                  <div style={{ textAlign: "center", width: "25%" }}>
                    <h3
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: "13px",
                        color: "#435669",
                        margin: "0px",
                      }}
                    >
                      Status
                    </h3>
                  </div>
                </div>

                {data && data.length > 0 ? (
                  (showAllTransactions
                    ? filteredData
                    : filteredData.slice(0, 3)
                  ).map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center", // use "center" to vertically center the titles
                        colors: colors.grey[910],
                        width: "100%",
                        padding: "8px 12px",
                      }}
                    >
                      <div style={{ textAlign: "left", width: "36%" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(event) => showModal2(item.loan_id, event)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "left",
                              }}
                            >
                              <h3
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 700,
                                  fontSize: "13px",
                                  color: "#435669",
                                  margin: "0px",
                                }}
                              >
                                {isMobile
                                  ? item.principal_name
                                    ? item.principal_name.length > 23
                                      ? item.principal_name.slice(0, 23) + "..."
                                      : item.principal_name
                                    : "-"
                                  : item.principal_name || "-"}
                              </h3>
                            </div>
                            <p
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "500",
                                fontSize: "11px",
                                textAlign: "left",
                                margin: "0px",
                                opacity: ".6",
                                color: "#435669",
                              }}
                            >
                              {item.repay_date || "-"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div style={{ textAlign: "center", width: "25%" }}>
                        <h3
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#435669",
                            margin: "0px",
                          }}
                        >
                          {item.repay_amount
                            ? item.repay_amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"}
                        </h3>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          width: "25%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              item.status === "done" ? "#D6F2D8" : "#B13434",
                            color:
                              item.status === "done" ? "#139F2ACC" : "#FFFFFF",
                            border: "1px solid",
                            borderColor:
                              item.status === "done" ? "#D6F2D8" : "#B13434",
                            borderRadius: "10px",
                            width: "40%",
                            height: "30px",

                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 700,
                            fontSize: "13px",
                            margin: "0px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        // background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      There is currently no transactions in record
                    </div>
                  </div>
                )}

                {data && data.length > 3 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center", // use "center" to vertically center the titles
                      colors: colors.grey[910],
                      backgroundColor: "#ffff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setShowAllTransactions(showAllTransactions ? false : true)
                    }
                  >
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h5
                        className="all_button"
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: "13px",
                          color: "#435669",
                          margin: "0px",
                        }}
                      >
                        {showAllTransactions
                          ? "View Less Transactions"
                          : "View All Transactions"}
                      </h5>
                    </div>
                  </div>
                )}

                {data &&
                  data.length > 0 &&
                  (showAllTransactions
                    ? filteredData
                    : filteredData.slice(0, 3)
                  ).map((item, index) => (
                    <Modal
                      open={modalStates2[item.loan_id]}
                      onCancel={() =>
                        setModalStates2((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      footer={[]}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Company Name
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {item.company_name || "-"}{" "}
                            {item.company_type || "-"}
                          </p>
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan ID
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {item.loan_id || "-"}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Amount
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {item.repay_amount
                              ? item.repay_amount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"}
                          </p>
                        </div>
                        {/* <div style={{ flex: 1 }}>
                     <label htmlFor="offer_amount" style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Interest Rate</label>
                     <br />
                     <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_interest_rate + "%" || "-"}</p>                        
                   </div> */}
                        {/* <div style={{ flex: 1 }}>
                     <label htmlFor="offer_interest_rate" style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Period</label>
                     <br />
                     <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_period + " Month" || "-"}</p> 
                   </div> */}
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Date Disbursed
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {formatDate(item.repay_date) || "-"}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Status
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {item.status
                              ? item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)
                              : "-"}
                          </p>
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Period
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {item.period + "Months" || "-"}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Monthly Installment
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {item.monthly_installment
                              ? item.monthly_installment.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )
                              : "-"}
                          </p>
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Payment Due Date
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {formatDate(item.repay_due_date) || "-"}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Outstanding Amount
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "grey",
                            }}
                          >
                            {item.loan_balance
                              ? item.loan_balance.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </Modal>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            // background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
            border-radius="20px"
            opacity="0.95"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              opacity="1.00"
              style={{ margin: "30px 0px 15px 0px" }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Summary
              </Typography>
            </Box>

            <Box
              gridColumn="span 4"
              // backgroundColor={colors.grey[910]}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "grey",
                  width: "auto",
                  gap: "15px",
                }}
              >
                {dataSummary && dataSummary.length > 0 ? (
                  dataSummary.map((item, index) => (
                    <Container2
                      key={index}
                      icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                      title={item.borrower_name || "-"}
                      subtitle={
                        item.express === 1
                          ? "Express Applicant"
                          : "Approved Borrower"
                      }
                      label="Last Payment Date"
                      description={formatDate(item.repay)}
                      label1="Loan Amount"
                      description1={
                        item.amount
                          ? item.amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                      item={item}
                      label2="Period"
                      description2={item.period + " Months" || "-"}
                      label3="Interest Rate (month)"
                      description3={(item.rate * 100).toFixed(1) + " %" || "-"}
                      index={index}
                      // pass the index prop to each Container instance
                      loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                      open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                      onClickModal={(event) => showModal(item.loan_id, event)} // pass the showModal function to each Container instance
                      cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                    />
                  ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      No Summary Data
                    </div>
                  </div>
                )}
                {dataSummary &&
                  dataSummary.length > 0 &&
                  dataSummary.map((item, index) => (
                    <Modal
                      width={isMobile ? "100%" : "66vw"}
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                      open={modalStates[item.loan_id]}
                      onCancel={() =>
                        setModalStates((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      footer={
                        [
                          // <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                          //   <Button type="primary" onClick={() => handleMakeOffer(item.loan_id)}>Make Offer</Button>
                          // </div>
                        ]
                      }
                    >
                      <div
                        style={{
                          color: "#142C44CC",
                          fontFamily: "Manrope,sans-serif",
                          fontSize: "18px",
                          fontWeight: 800,
                        }}
                      >
                        Borrower's Summary
                      </div>

                      <hr />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "100%",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Name
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.borrower_name || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan ID
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.loan_id || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Interest Rate
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {(item.rate * 100).toFixed(1) + "%" || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Period
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.period + " Month" || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Amount
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.amount
                              ? item.amount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"}
                          </p>
                        </div>
                      </div>

                      <hr />
                      <div style={{ width: "100%" }}>
                        <Collapse defaultActiveKey={["0"]} ghost>
                          <Panel
                            header="View your Repayment Schedule"
                            key="1"
                            onClick={(event) =>
                              handleViewRepaymentSchedule(item.loan_id, event)
                            }
                          >
                            <TableRepaymentLender
                              dataRepaymentSchedule={dataRepaymentSchedule}
                              setDataRepaymentSchedule={
                                setDataRepaymentSchedule
                              }
                            />
                          </Panel>
                        </Collapse>
                      </div>
                    </Modal>
                  ))}
              </div>
            </Box>
          </Box>
        </Box>
        {!isMobile ? (
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            overflow="auto"
            height="90vh"
          >
            <Box
              gridColumn="span 2"
              opacity="1.00"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="34%"
              position="relative" // ensure the Box is relatively positioned
            >
              {/* <img
              src={adImage}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                position: "absolute", // make the image positioned absolutely
                top: "0",
                left: "0",
                zIndex: "1", // layer the image below the button
                objectFit: "cover",
              }}
            /> */}
              <AdminDashboardAds />

              {/* <Button
            style={{
              position: "absolute", // This makes the button float on top of the image
              top: "280px", // position it at the top of the box
              zIndex: "2", // layer the button above the image
              borderRadius: "20px",
              fontSize: "18px",
              fontWeight: "400",
              fontFamily: "Manrope, sans-serif",
              color: "#115CA6",
            }}
            onClick={(event) => handleApplyLoan(event)}
          >
            Apply Now
          </Button> */}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Analytics
              </Typography>
            </Box>
            <Box
              gridColumn="span 2"
              opacity="1.00"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="200px"
              marginBottom="-2.5vh"
            >
              <Box
                width="49%"
                opacity="1.00"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="170px"
              >
                <ContainerLineChart></ContainerLineChart>
              </Box>
              <Box
                width="49%"
                opacity="1.00"
                display="flex"
                alignItems="left"
                justifyContent="left"
                height="200px"
                marginLeft="10px"
              >
                <ContainerPieChart></ContainerPieChart>
              </Box>
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor="rgba(153, 178, 205, 0)"
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[100]}
                p="5px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="20px"
                >
                  Activity Log
                </Typography>
              </Box>
              {dataActivityLog && dataActivityLog.length > 0 ? (
                dataActivityLog.slice(0, 6).map((item, index) => (
                  <Box
                    gridColumn="span 1"
                    // backgroundColor={colors.grey[910]}
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    style={{
                      opacity:
                        index === 4
                          ? 0.6
                          : index === 5
                          ? 0.2
                          : index === 3
                          ? 0.9
                          : 1,
                      transitionDelay: `${index * 0.1}s`,
                    }}
                  >
                    <ContainerActivity
                      key={index}
                      title={item.principal_name || "-"}
                      subtitle={item.description || "-"}
                      index={index}
                    />
                  </Box>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "0px 0px",
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "48vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No Activity Log
                  </div>
                </div>
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Dashboard;
