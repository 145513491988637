import React, { useState } from "react";
import Countdown from "react-countdown";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
      color: "grey",
      marginRight: "10px",
    }}
    spin
  />
);

const CountdownWithHover = ({ targetDate, renderer }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Spin indicator={antIcon} />
        <div
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            textAlign: "left",
            margin: "0px",
          }}
        >
          {targetDate && <Countdown date={targetDate} renderer={renderer} />}
        </div>
      </div>
    </div>
  );
};

export default CountdownWithHover;
