import { Form, Input, Select, Button, Col, Row, Avatar, Upload } from "antd";
import { DatePicker, Space, notification, message } from "antd";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import profile_ico from "../../assets/img/Profile.png";
import moment from "moment";

const { Option } = Select;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const BasicInformation = (props) => {
  const [nric, setNric] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [principalname, setPrincipalName] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [residentialstatus, setResidentialStatus] = useState("");
  const [nationality, setNationality] = useState("");
  const [countryofbirth, setCountryOfBirth] = useState("");
  const [employmentsector, setEmploymentSector] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [registeredaddress, setRegisteredAddress] = useState("");
  const [data, setData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [errorMessage, setErrorMessage] = useState("");

  const handleUploadProfilePicture = async (file) => {
    console.log("uploading profile picture");
    try {
      const formData = new FormData();
      formData.append("profile_picture", file);
      console.log("uploading", formData);
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/uploadProfilePicture",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData,
        }
      );

      const data = await response.json();

      console.log("Profile picture created", data);

      // Update the imageUrl state with the new image URL
      if (data.profile_picture) {
        setImageUrl(data.profile_picture);
        Cookies.setItem("profile_picture", data.profile_picture);
        notification.open({
          message: "Notification Title",
          description:
            "I will never close automatically. This is a purposely very very long description that has many many characters and words.",
          duration: 4,
        });
      } else if (data.error && data.error.profile_picture) {
        // If the profile picture failed to upload
        throw new Error(data.error.profile_picture[0]);
      }
    } catch (error) {
      console.error("Error Uploading Picture", error);
      setErrorMessage(error.message);
    }
  };


const handleSave = async () => {
    try {
        // Assuming singpassData is an array with only one object
        let updatedSingpassData = {...props.singpassData};
        if (updatedSingpassData.singpass_data && updatedSingpassData.singpass_data.length > 0) {
            updatedSingpassData.singpass_data[0].email = email; // Add email
            updatedSingpassData.singpass_data[0].phone = phone; // Add phone
        }

        let item = {
            singpassData: updatedSingpassData,
        };

        const response = await fetch(
            `https://www.smart-lend.com/api/singpass/updateSingpassData`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get('token'),
                },
                body: JSON.stringify(item),
            }
        );
       
        const jsonSingpassData = await response.json();
        console.log("jsonSingpassData:", jsonSingpassData);
        if(response.status === 200){
            message.success("Your Data Successfully Saved");
        } else {
            message.error("Your Data Unable to Save");
        }
    } catch (err) {
        message.error("Your Data Unable to Save");
        console.log("err handleSave:", err);
    }
}


useEffect (() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
              method: "GET",
              headers: headers,
            };

      try{
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

      if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        const personDetail = displaySingpassData.singpass_data[0].person;
        const entityDetail = displaySingpassData.singpass_data[0].entity;
        setNric(personDetail.uinfin.value);
        setBirthdate(personDetail.dob.value);
        setPrincipalName(personDetail.name.value);
        setGender(personDetail.sex.desc);
        setRace(personDetail.race.desc);
        setCountryOfBirth(personDetail.birthcountry.desc);
        setEmploymentSector(personDetail.employment.value);
        setResidentialStatus(personDetail.residentialstatus.desc);
        setNationality(personDetail.nationality.desc);
       
        console.log("value nric2:", personDetail);
      } catch (error) {
        console.error(error);
      }

    }
    fetchData();
  }, []);

  function handleNricChange(e) {
    setNric(e.target.value);
}

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewPersonal?type=Basic",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setData(data["Basic Data"]);
        // Set state variables when data is fetched
        if (data["Basic Data"] && data["Basic Data"][0]) {
          const item = data["Basic Data"][0];
          console.log("data item", item);
          setNric(item.nric.toString()); // Convert NRIC to string
          setBirthdate(item.date_of_birth);
          setGender(item.sex);
          setRace(item.race);
          setResidentialStatus(item.residential_status);
          setNationality(item.nationality);
          setCountryOfBirth(item.country_of_birth);
          setEmploymentSector(item.employment_sector);
          setEmail(item.email);
          setPhone(item.mobile_no);
          setRegisteredAddress(item.registered_address);
        }
      } catch (error) {
        console.error(error);
      }

      const profilePictureUrl =
        Cookies.get("profile_picture") || (await loadprofilepicture());
      if (profilePictureUrl) {
        setImageUrl(profilePictureUrl);
      }
    }
    fetchData();
  }, []);

  const [imageUrl, setImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleBeforeUpload = (file) => {
    const imageUrl = URL.createObjectURL(file);
    setImageUrl(imageUrl);
    console.log("Photo ready to upload", file);
    setSelectedFile(file);
    return false; // Prevent file from being uploaded automatically
  };

  const handleConfirm = () => {
    if (selectedFile) {
      handleUploadProfilePicture(selectedFile);
      console.log("selectedFile exists", selectedFile);
      setSelectedFile(null); // Reset the selected file after uploading
    }
  };

  const loadprofilepicture = async () => {
    console.log("getting profile picture");
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/viewInfoProfilePicture",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch profile picture");
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      console.log("Profile picture retrieved", imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error retrieving profile picture", error);
      return null;
    }
  };

  // const handleNricChange = (event) => {
  //   setNric(event.target.value);
  // };

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [screenWidth, setScreenWidth] = useState("1920");

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      // Call handleResize initially to set the initial screen width
      handleResize();
  
      // Add event listener for resize
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  return (
    <>
        
    {screenWidth > 1200 ? (
        <Form style={{ minHeight: "73vh" }}
        {...layout}
        name="basic"
        initialValues={{
            // nric: nric2,
            // birthdate: birthdate ? moment(birthdate, "YYYY-MM-DD") : null,
            // gender: "Male",
            // race: race,
            // residentialstatus: residentialstatus,
            // nationality: nationality,
            // countryofbirth: countryofbirth,
            // employmentsector: employmentsector,
            // email: email,
            // phone: phone,
            // registeredaddress: registeredaddress,
            // remember: true,
        }}

        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
    >
         <Row>
    <Col
      span={12}
      style={{
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
      }}
    >
      <div>
        <Form.Item label="Profile Picture" name="profile"></Form.Item>
        <Upload
          beforeUpload={handleBeforeUpload}
          showUploadList={false}
          fileList={selectedFile ? [selectedFile] : []}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="Profile picture"
              style={{
                width: "64px",
                height: "64px",
                borderRadius: "50%",
                marginTop:"-3vh",
                marginBottom:"0.5vh"
              }}
            />
          ) : (
            <Avatar size={64} icon={<UserOutlined />} />
          )}
        </Upload>
        <br />
        {errorMessage && (
          <div className="error-message">{errorMessage}</div>
        )}
        <Space style={{marginBottom:"2vh"}}>
          <Button type="primary" size="small" onClick={handleConfirm}>
            Confirm
          </Button>
        </Space>
      </div>
    </Col>
  </Row>
        <Row>
            <Col span={12}>
                <Form.Item
                    label="NRIC"
                    // rules={[{ required: true, message: "Please input your NRIC" }]}
                >
                    <Input style={{ width: "100%" }} value={nric} onChange={handleNricChange} disabled={!!nric || !nric}
/>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Space style={{ marginLeft: '10%' }}>
                    <Form.Item label="Birth Date">
                        <DatePicker value={birthdate ? moment(birthdate, "YYYY-MM-DD") : null} onChange={(date, dateString) => setBirthdate(dateString)} disabled={!!birthdate || !birthdate} />
                    </Form.Item>
                </Space>
            </Col>
        </Row>


        <Row>
            <Col span={12}>
                <Form.Item
                    label="Principal Name"
                   
                >
                    <Input style={{ width: '100%' }} value={principalname} onChange={(e) => setPrincipalName(e.target.value)} disabled={!!principalname || !principalname}/>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item style={{ marginLeft: '10%' }}
                    label="Sex/Gender"
                   
                >
                    <Select value={gender}  onChange={(value) => setGender(value)} disabled={!!gender || !gender}>
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={8}>
                <Form.Item
                    label="Race"
                    
                >
                    <Select value={race} style={{ width: '90%' }} onChange={(value) => setRace(value) } disabled={!!race || !race}>
                        <Option value="John Smith">John Smith</Option>
                        <Option value="Jane Doe">Jane Doe</Option>
                        <Option value="Bob Johnson">Bob Johnson</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label="Residential Status"
                    
                >
                    <Select value={residentialstatus} style={{ width: '90%' }} onChange={(value) => setResidentialStatus(value)} disabled={!!residentialstatus || !residentialstatus}>
                        <Option value="Singapore Citizen">Singapore Citizen</Option>
                        <Option value="Permanent Resident">Permanent Resident</Option>
                        <Option value="Foreigner">Foreigner</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label="Nationality"
                    
                >
                    <Select value={nationality} style={{ width: '90%' }} onChange={(value) => setNationality(value)} disabled={!!nationality || !nationality}>
                        <Option value="Singaporean">Singaporean</Option>
                        <Option value="Malaysian">Malaysian</Option>
                        <Option value="Chinese">Chinese</Option>
                        <Option value="Indian">Indian</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={12}>
                <Form.Item
                    label="Country of Birth"
                    
                >
                    <Select value={countryofbirth} style={{ width: '100%' }} onChange={(value) => setCountryOfBirth(value)} disabled={!!countryofbirth || !countryofbirth}>
                        <Option value="Singapore">Singapore</Option>
                        <Option value="Malaysia">Malaysia</Option>
                        <Option value="China">China</Option>
                        <Option value="India">India</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item style={{ marginLeft: '10%' }}
                    label="Employment Sector"
                    
                >
                    <Select value={employmentsector} onChange={(value) => setEmploymentSector(value)} disabled={!!employmentsector || !employmentsector}>
                        <Option value="Finance">Finance</Option>
                        <Option value="Technology">Technology</Option>
                        <Option value="Government">Government</Option>
                        <Option value="Healthcare">Healthcare</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={12}>
                <Form.Item
                    label="E-mail"
                   
                >
                    <Input type="email"  style={{ width: '100%' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item style={{ marginLeft: '10%' }}
                    label="Phone"
                    name="phone"
                >
                    <Input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </Form.Item>
            </Col>
        </Row>
        {/* <Row>
            <Col span={12}>
                <Form.Item
                    label="Registered Address"
                    name="registeredaddress"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your registered address!',
                        },
                    ]}
                >
                    <Input style={{ width: '100%' }} value={registeredaddress} onChange={(e) => setRegisteredAddress(e.target.value)} />
                </Form.Item>
            </Col>
        </Row> */}

        <Form.Item {...tailLayout}>
            {/* <Button type="default" htmlType="button" style={{ color: "#0E5AA5", borderRadius: "20px", border: "1px solid #0E5AA5", width: screenWidth > 1200 ? "7%" :"20%" }}>
                Edit
            </Button> */}
            <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: '10px', borderRadius: "20px",  width: screenWidth > 1200 ? "7%" :"20%" }}
                onClick={(event) => handleSave(event)}>
                Save
            </Button>
        </Form.Item>
    </Form>
    ) : (
<Form style={{ minHeight: "73vh" }}
        {...layout}
        name="basic"
        initialValues={{
            // nric: nric,
            // birthdate: birthdate ? moment(birthdate, "YYYY-MM-DD") : null,
            // gender: gender,
            // race: race,
            // residentialstatus: residentialstatus,
            // nationality: nationality,
            // countryofbirth: countryofbirth,
            // employmentsector: employmentsector,
            // email: email,
            // phone: phone,
            // registeredaddress: registeredaddress,
            // remember: true,
        }}

        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
    >
         <Row>
    <Col
      span={12}
      style={{
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
      }}
    >
      <div>
        <Form.Item label="Profile Picture" name="profile"></Form.Item>
        <Upload
          beforeUpload={handleBeforeUpload}
          showUploadList={false}
          fileList={selectedFile ? [selectedFile] : []}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="Profile picture"
              style={{
                width: "64px",
                height: "64px",
                borderRadius: "50%",
                marginTop:"-3vh",
                marginBottom:"0.5vh"
              }}
            />
          ) : (
            <Avatar size={64} icon={<UserOutlined />} />
          )}
        </Upload>
        <br />
        {errorMessage && (
          <div className="error-message">{errorMessage}</div>
        )}
        <Space style={{marginBottom:"2vh"}}>
          <Button type="primary" size="small" onClick={handleConfirm}>
            Confirm
          </Button>
        </Space>
      </div>
    </Col>
  </Row>
        <Row>
            <Col span={24}>
                <Form.Item
                    label="NRIC"
                   
                >
<Input style={{ width: "100%" }} value={nric} onChange={handleNricChange} disabled={!!nric || !nric}
/>                    </Form.Item>
            </Col>
          
        </Row>
        <Row>
        <Col span={24}>
                <Space style={{ width:"100%" }}>
                <Form.Item label="Birth Date">
                        <DatePicker value={birthdate ? moment(birthdate, "YYYY-MM-DD") : null} onChange={(date, dateString) => setBirthdate(dateString)} disabled={!!birthdate || !birthdate} />
                    </Form.Item>
                </Space>
            </Col>
        </Row>


        <Row>
            <Col span={24}>
            <Form.Item
                    label="Principal Name"
                   
                >
                    <Input style={{ width: '100%' }} value={principalname} onChange={(e) => setPrincipalName(e.target.value)} disabled={!!principalname || !principalname}/>
                </Form.Item>
            </Col>
           
        </Row>
        <Row>
        <Col span={24}>
        <Form.Item 
                    label="Sex/Gender"
                   
                >
                    <Select value={gender}  onChange={(value) => setGender(value)} disabled={!!gender || !gender}>
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                    </Select>
                </Form.Item>
            </Col>
            </Row>
        <Row>
            <Col span={24}>
            <Form.Item
                    label="Race"
                    
                >
                    <Select value={race}  onChange={(value) => setRace(value) } disabled={!!race || !race}>
                        <Option value="John Smith">John Smith</Option>
                        <Option value="Jane Doe">Jane Doe</Option>
                        <Option value="Bob Johnson">Bob Johnson</Option>
                    </Select>
                </Form.Item>
            </Col>
          
        
        </Row>
        <Row>
        <Col span={24}>
        <Form.Item
                    label="Residential Status"
                    
                >
                    <Select value={residentialstatus}  onChange={(value) => setResidentialStatus(value)} disabled={!!residentialstatus || !residentialstatus}>
                        <Option value="Singapore Citizen">Singapore Citizen</Option>
                        <Option value="Permanent Resident">Permanent Resident</Option>
                        <Option value="Foreigner">Foreigner</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
        <Col span={24}>
        <Form.Item
                    label="Nationality"
                    
                >
                    <Select value={nationality}  onChange={(value) => setNationality(value)} disabled={!!nationality || !nationality}>
                        <Option value="Singaporean">Singaporean</Option>
                        <Option value="Malaysian">Malaysian</Option>
                        <Option value="Chinese">Chinese</Option>
                        <Option value="Indian">Indian</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>

        <Row>
            <Col span={24}>
            <Form.Item
                    label="Country of Birth"
                    
                >
                    <Select value={countryofbirth} style={{ width: '100%' }} onChange={(value) => setCountryOfBirth(value)} disabled={!!countryofbirth || !countryofbirth}>
                        <Option value="Singapore">Singapore</Option>
                        <Option value="Malaysia">Malaysia</Option>
                        <Option value="China">China</Option>
                        <Option value="India">India</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                </Form.Item>
            </Col>
        
        </Row>
        <Row>
        <Col span={24}>
        <Form.Item 
                    label="Employment Sector"
                    
                >
                    <Select value={employmentsector} onChange={(value) => setEmploymentSector(value)} disabled={!!employmentsector || !employmentsector}>
                        <Option value="Finance">Finance</Option>
                        <Option value="Technology">Technology</Option>
                        <Option value="Government">Government</Option>
                        <Option value="Healthcare">Healthcare</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Form.Item
                    label="E-mail"
                    
                >
                    <Input type="email"  style={{ width: '100%' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                </Form.Item>
            </Col>
          
        </Row>
        <Row>
        <Col span={24}>
                <Form.Item
                    label="Phone"
                   
                >
                    <Input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </Form.Item>
            </Col>
        </Row>
        {/* <Row>
            <Col span={24}>
                <Form.Item
                    label="Registered Address"
                  
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please input your registered address!',
                    //     },
                    // ]}
                >
                    <Input style={{ width: '90%' }} value={registeredaddress} onChange={(e) => setRegisteredAddress(e.target.value)} />
                </Form.Item>
            </Col>
        </Row> */}

        <Form.Item {...tailLayout}>
            {/* <Button type="default" htmlType="button" style={{ color: "#0E5AA5", borderRadius: "20px", border: "1px solid #0E5AA5", width: screenWidth > 1200 ? "7%" :"20%" }}>
                Edit
            </Button> */}
          <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: '10px', borderRadius: "20px",  width: screenWidth > 1200 ? "7%" :"20%" }}
                onClick={(event) => handleSave(event)}>
                Save
            </Button>
        </Form.Item>
    </Form>
    )}
    </>
  );
};

export default BasicInformation;
