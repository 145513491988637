import React from "react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Modal from "antd/es/modal/Modal";
import { Collapse, message } from "antd";

const TestCharge = () => {
  const [chargeData, setChargeData] = useState({
    payerSwiftBic: "",
    payerSegment: "",
    payerName: "",
    precheckMinAmount: 1,
    precheckMaxAmount: 0,
    precheckCurrency: "SGD",
    endDate: "",
    returnUrl: "https://smart-lend.com/a-test-charge",
  });

  const [authURL, setAuthURL] = useState("");

  const createddauth = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/ddauth/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(chargeData),
        }
      );
      const json = await response.json();
      console.log(json);
      if (response.status === 201) {
        message.success(json.message);
        setAuthURL(json?.api_response?.authorizeUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "91vh",
        display: "flex",
        justifyContent: "space-betweeen",
        gap: "20px",
        color: "black",
      }}
    >
      <div
        style={{
          width: "75%",
          height: "100%",
          padding: "4px 0px 20px 20px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            Acme API test sandbox
          </div>
          <div>This form is specifically for test purposes.</div>

          <div style={{ marginTop: "15px" }}>
            <div style={{ width: "100%" }}>
              <div>Select Bank Account Type</div>
              <select
                value={chargeData.payerSegment}
                onChange={(e) => {
                  setChargeData({
                    ...chargeData,
                    payerSegment: e.target.value,
                  });
                  console.log(chargeData);
                }}
                style={{
                  width: "50%",
                  borderRadius: "7px",
                  outline: "none",
                  border: "1px solid lightgrey",
                  padding: "5px 8px",
                }}
              >
                <option value={""}>Bank Account Type</option>
                {/* <option value={"RETAIL"}>Retail</option> */}
                <option value={"CORPORATE"}>Corporate</option>
              </select>
            </div>
            {chargeData.payerSegment === "RETAIL" ? (
              <div style={{ width: "100%", marginTop: "10px" }}>
                <div>Select Bank</div>
                <select
                  value={chargeData.payerSwiftBic}
                  onChange={(e) => {
                    setChargeData({
                      ...chargeData,
                      payerSwiftBic: e.target.value,
                    });
                    console.log(chargeData);
                  }}
                  style={{
                    width: "50%",
                    borderRadius: "7px",
                    outline: "none",
                    border: "1px solid lightgrey",
                    padding: "5px 8px",
                  }}
                >
                  <option value={""}>Select Bank</option>
                  <option value={"BKCHSGSGXXX"}>Bank of China</option>
                  <option value={"DBSSSGSGXXX"}>DBS Bank</option>
                  <option value={"HSBCSGS2XXX"}>HSBC Bank</option>
                  <option value={"ICBKSGSGXXX"}>
                    Industrial & Commercial Bank of China
                  </option>
                  <option value={"MBBESGS2XXX"}>Maybank</option>
                  <option value={"OCBCSGSGXXX"}>
                    Oversea-Chinese Banking Corporation
                  </option>
                  <option value={"SCBLSG22XXX"}>Standard Chartered Bank</option>
                  <option value={"UOVBSGSGXXX"}>United Overseas Bank</option>
                </select>
              </div>
            ) : null}
            {chargeData.payerSegment === "CORPORATE" ? (
              <div style={{ width: "100%", marginTop: "10px" }}>
                <div>Select Bank</div>
                <select
                  value={chargeData.payerSwiftBic}
                  onChange={(e) => {
                    setChargeData({
                      ...chargeData,
                      payerSwiftBic: e.target.value,
                    });
                    console.log(chargeData);
                  }}
                  style={{
                    width: "50%",
                    borderRadius: "7px",
                    outline: "none",
                    border: "1px solid lightgrey",
                    padding: "5px 8px",
                  }}
                >
                  <option value={""}>Select Bank</option>
                  <option value={"BKCHSGSGXXX"}>Bank of China</option>
                  <option value={"DBSSSGSGXXX"}>DBS Bank</option>
                  <option value={"MBBESGS2XXX"}>Maybank</option>
                  <option value={"OCBCSGSGXXX"}>
                    Oversea-Chinese Banking Corporation
                  </option>
                  <option value={"UOVBSGSGXXX"}>United Overseas Bank</option>
                </select>
              </div>
            ) : null}
            <div style={{ width: "100%", marginTop: "10px" }}>
              <div>Account Holder Name</div>
              <input
                placeholder="Account Holder Name"
                value={chargeData.payerName}
                onChange={(e) => {
                  setChargeData({ ...chargeData, payerName: e.target.value });
                  console.log(chargeData);
                }}
                style={{
                  width: "50%",
                  borderRadius: "7px",
                  outline: "none",
                  border: "1px solid lightgrey",
                  padding: "5px 8px",
                }}
              />
            </div>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <div>Payment End Date</div>
              <input
                type="date"
                value={chargeData.endDate}
                onChange={(e) => {
                  setChargeData({ ...chargeData, endDate: e.target.value });
                  console.log(chargeData);
                }}
                style={{
                  width: "50%",
                  borderRadius: "7px",
                  outline: "none",
                  border: "1px solid lightgrey",
                  padding: "5px 8px",
                }}
              />
            </div>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <div>Sample Charge Amount</div>
              <input
                type="number"
                value={chargeData.precheckMaxAmount}
                min={1}
                max={1000000}
                onChange={(e) => {
                  setChargeData({
                    ...chargeData,
                    precheckMaxAmount: parseFloat(e.target.value),
                  });
                  console.log(chargeData);
                }}
                style={{
                  width: "50%",
                  borderRadius: "7px",
                  outline: "none",
                  border: "1px solid lightgrey",
                  padding: "5px 8px",
                }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => {
                  createddauth();
                }}
                style={{
                  background: "rgb(17, 92, 166)",
                  color: "white",
                  padding: "7px 30px",
                  border: "none",
                  marginTop: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Authorize
              </div>
            </div>
            {authURL !== "" ? (
              <div
                style={{
                  marginTop: "15px",
                }}
              >
                Authorisation Url:{" "}
                <span
                  onClick={() => {
                    window.location.href = authURL;
                  }}
                  style={{
                    color: "rgb(17, 92, 166)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {authURL}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestCharge;
