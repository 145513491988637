import { Form, Input, Select, Button, Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
const { Option } = Select;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const EntityAddress = () => {
  const [entityLocalAddress, setEntityLocalAddress] = useState("");
  const [entityForeignAddress, setEntityForeignAddress] = useState("");

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        // const personDetail = displaySingpassData.singpass_data[0].person;
        // const entityDetail = displaySingpassData.singpass_data[0].entity;

        const personDetail = displaySingpassData.singpass_data.person;
        const entityDetail = displaySingpassData.singpass_data.entity;
        console.log("entityDetail value:", entityDetail);
        // setEntityLocalAddress(entityDetail.addresses["addresses-list"][0].block.value);
        // setEntityForeignAddress(entityDetailBasicProfile["primary-activity"].desc);

        setEntityLocalAddress(entityDetail.addresses["addresses-list"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Form
      style={{ minHeight: "73vh" }}
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Postal Code">
            <Input
              style={{ width: "100%" }}
              value={
                entityLocalAddress[0]
                  ? entityLocalAddress[0].postal
                    ? entityLocalAddress[0].postal.value
                      ? entityLocalAddress[0].postal.value
                      : null
                    : null
                  : null
              }
              disabled={!!entityLocalAddress || !entityLocalAddress}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Block">
            <Input
              style={{ width: "100%" }}
              value={
                entityLocalAddress[0]
                  ? entityLocalAddress[0].block
                    ? entityLocalAddress[0].block.value
                      ? entityLocalAddress[0].block.value
                      : null
                    : null
                  : null
              }
              disabled={!!entityLocalAddress || !entityLocalAddress}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Street">
            <Input
              style={{ width: "100%" }}
              value={
                entityLocalAddress[0]
                  ? entityLocalAddress[0].street
                    ? entityLocalAddress[0].street.value
                      ? entityLocalAddress[0].street.value
                      : null
                    : null
                  : null
              }
              disabled={!!entityLocalAddress || !entityLocalAddress}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Building">
            <Input
              style={{ width: "100%" }}
              value={
                entityLocalAddress[0]
                  ? entityLocalAddress[0].building
                    ? entityLocalAddress[0].building.value
                      ? entityLocalAddress[0].building.value
                      : null
                    : null
                  : null
              }
              disabled={!!entityLocalAddress || !entityLocalAddress}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Floor">
            <Input
              style={{ width: "100%" }}
              value={
                entityLocalAddress[0]
                  ? entityLocalAddress[0].floor
                    ? entityLocalAddress[0].floor.value
                      ? entityLocalAddress[0].floor.value
                      : null
                    : null
                  : null
              }
              disabled={!!entityLocalAddress || !entityLocalAddress}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Unit">
            <Input
              style={{ width: "100%" }}
              value={
                entityLocalAddress[0]
                  ? entityLocalAddress[0].unit
                    ? entityLocalAddress[0].unit.value
                      ? entityLocalAddress[0].unit.value
                      : null
                    : null
                  : null
              }
              disabled={!!entityLocalAddress || !entityLocalAddress}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Country">
            <Input
              style={{ width: "100%" }}
              value={
                entityLocalAddress[0]
                  ? entityLocalAddress[0].country
                    ? entityLocalAddress[0].country.desc
                      ? entityLocalAddress[0].country.desc
                      : null
                    : null
                  : null
              }
              disabled={!!entityLocalAddress || !entityLocalAddress}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div> */}

      {/* <Row>
 <Col span={24}>
         <Form.Item   
             label="Entity Foreign Address"
            
          
         >
             <Input style={{ width: '100%' }} value={entityForeignAddress} disabled={!!entityForeignAddress || !entityForeignAddress}/>
         </Form.Item>
     </Col>
  
   
 </Row> */}
    </Form>
  );
};

export default EntityAddress;
