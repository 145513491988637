import { Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MobileStepper } from "@mui/material";
import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
function PhoneNumber() {
  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      console.log("Token from cookie:", token);
      // Now you can use the token for making authenticated API requests,
      // storing it in your app's state, etc.
    }
  }, []);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [screenWidth, setScreenWidth] = useState("1920");
  const history = useHistory();

  const handleSendOtp = (event) => {
    event.preventDefault();
    // Your API endpoint
    const url = "https://www.smart-lend.com/api/auth/register/mobile/sendOTP";
    // Data to send in the request body
    const data = { mobile_no: "65" + phoneNumber };

    // Fetch call to send the OTP
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((response) => {
        if (response.status === 302) {
          // Get the redirect location
          const redirectUrl = response.headers.get("Location");
          // Follow the redirect by making another fetch call to the new location
          return fetch(redirectUrl);
        } else if (response.status === 200) {
          history.push("/auth/otp-code");
        }
        return response.json();
      })
      .then((data) => {
        // Store the new value from the API in the cookies
        Cookies.set("session", data.data.session);
        Cookies.set("phoneNo", data.Api.to);
        console.log("value of data session", data.data.session);
        console.log("value of phoneNo", data.Api.to);
      })
      .catch((error) => {
        console.log("error value", error);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <React.Fragment>
          <div className="auth-header">
            <h3 className="auth-header-title-phone">You're almost there!</h3>
            <p className="auth-header-subtitle-phone">
              Please enter your phone number for verification
            </p>
          </div>
          <div className="auth-body">
            <form className="auth-form-validation">
              <div className="input-field">
                <label htmlFor="password" className="input-label">
                  Phone Number
                </label>
                <input
                  // type="text"
                  value={"+65" + phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value.substring(3))}
                  className="input-control"
                  placeholder=" Phone Number"
                  autoComplete="off"
                />
              </div>
              <div className="btn-position">
                <button
                  type="submit"
                  className="btn-submit"
                  onClick={handleSendOtp}
                >
                  Send OTP
                </button>
              </div>
            </form>
            <br />
            <div className="flex-end-phone">
              <MobileStepper
                variant="dots"
                steps={6}
                position="left"
                activeStep={2}
                sx={{
                  maxWidth: 400,
                  flexGrow: 1,
                  marginLeft: 8.1,
                  marginTop: 0,
                }}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div className="auth-header">
              <h3 className="auth-header-title-phone">You're almost there!</h3>
              <p className="auth-header-subtitle-phone">
                Please enter your phone number for verification
              </p>
            </div>
            <div className="auth-body">
              <form className="auth-form-validation">
                <div className="input-field">
                  <label htmlFor="password" className="input-label">
                    Phone Number
                  </label>
                  <input
                    // type="text"
                    value={"+65" + phoneNumber}
                    onChange={(e) =>
                      setPhoneNumber(e.target.value.substring(3))
                    }
                    className="input-control"
                    placeholder=" Phone Number"
                    autoComplete="off"
                  />
                </div>
                <div className="btn-position">
                  <button
                    type="submit"
                    className="btn-submit"
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </button>
                </div>
              </form>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <MobileStepper
                  variant="dots"
                  steps={6}
                  position="left"
                  activeStep={2}
                  sx={{
                    maxWidth: 400,
                    flexGrow: 1,
                    marginTop: 0,
                    marginRight: "100px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PhoneNumber;
