import { Space, Table, Tag, Radio, Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const TableApproveAgreement = ({ onRowClick, onSelectionChange }) => {
  const [data, setData] = useState([]);
  const [modalStates, setModalStates] = useState({});
  const [modalType, setModalType] = useState(""); // New state for modal type
  const [screenWidth, setScreenWidth] = useState("1920");


  useEffect(() => {
    const initialStates = {};

    data.forEach((item) => {
      initialStates[item.loan_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const showModal = (loan_id, type) => {
    console.log("loan_id", loan_id);
    setModalType(type); // Set modal type when showModal is called
    setModalStates((prevStates) => ({
      ...prevStates,
      [loan_id]: true,
    }));
  };

  const handleDownloadDocument = async (userId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=delete`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh your table data here
      console.log("User deleted successfully");
    } catch (error) {
      console.log("Failed to delete user", error);
    }
  };

  async function handleApproveAgreement(loan_id) {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/agreement/adobeSign/approvedAgreement?loan_id=${loan_id}&status=approve`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.status === 200) {
        // Close the modal here
        setModalStates((prevStates) => ({
          ...prevStates,
          [loan_id]: false,
        }));
        message.success("Agreement Approved");
      } else if (response.status === 400) {
        message.error("Both lender and borrower must sign the agreement");
      }

      const agreementData = await response.json();
    } catch (error) {
      console.error("Error fetching loan data:", error);
    }
  }

  async function handleRejectAgreement(loan_id) {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/agreement/adobeSign/approvedAgreement?loan_id=${loan_id}&status=reject`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.status === 200) {
        // Close the modal here
        setModalStates((prevStates) => ({
          ...prevStates,
          [loan_id]: false,
        }));
        message.success("Agreement Rejected");
      } else if (response.status === 400) {
        message.error("Both lender and borrower must sign the agreement");
      }

      const agreementData = await response.json();
    } catch (error) {
      console.error("Error fetching loan data:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/agreement/viewListAgreement?status=pending",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        setData(json["Accepted Loan"]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Loan ID
        </span>
      ),
      dataIndex: "loan_id",
      key: "loan_id",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Lender Name
        </span>
      ),
      dataIndex: "lender",
      key: "lender",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Borrower Name
        </span>
      ),
      dataIndex: "borrower",
      key: "borrower",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Lender Sign
        </span>
      ),
      dataIndex: "lender_sign",
      key: "lender_sign",
      align: "center",
      render: (text) =>
        text === 1 ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: "24px" }} />
        ) : text === 0 ? (
          <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
        ) : (
          <span
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            -
          </span>
        ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Borrower Sign
        </span>
      ),
      dataIndex: "borrower_sign",
      key: "borrower_sign",
      align: "center",
      render: (text) =>
        text === 1 ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: "24px" }} />
        ) : text === 0 ? (
          <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
        ) : (
          <span
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            -
          </span>
        ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Description
        </span>
      ),
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    {
        key: "action",
        width: 38,
       
        render: (text, record) => (
          <Button
            type="primary"
            onClick={() => showModal(record.loan_id, "approve")} // Pass the type when showModal is called
            disabled={
              record.lender_sign === 1 && record.borrower_sign === 1
                ? false
                : true
            }
          >
            Approve
          </Button>
        ),
      },

    {
        key: "action",
        width: 38,
       
        render: (text, record) => (
          <Button type="primary" onClick={() => showModal(record.loan_id, "reject")} danger> 
            Reject
          </Button>
        ),
      },
  ];

  return (
    <div>
      <Table
        rowKey="loan_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        scroll={{
          x: 1000,
          
        }}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              onRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200  ?'0px 20px' : "0px",
          width: screenWidth > 1200 ? 'auto' : '100%',
        }}
      />
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates[item.loan_id]}
            onCancel={() =>
              setModalStates((prevStates) => ({
                ...prevStates,
                [item.loan_id]: false,
              }))
            }
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() =>
                    modalType === "reject"
                      ? handleRejectAgreement(item.loan_id)
                      : handleApproveAgreement(item.loan_id)
                  } // Call different functions depending on modalType
                >
                  Yes
                </Button>
                <Button
                  type="default"
                  onClick={() =>
                    setModalStates((prevStates) => ({
                      ...prevStates,
                      [item.loan_id]: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>,
            ]}
          >
            {modalType === "reject"
              ? "Are you sure you want to reject this agreement?"
              : "Are you sure you want to approve this agreement?"}{" "}
            
          </Modal>
        ))}
    </div>
  );
};

export default TableApproveAgreement;
