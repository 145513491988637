import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Integrated from "./img/integrated_singpass.png";
import Apply from "./img/borrowers_apply.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Typography, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Tooltip } from "react-tooltip";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import "./App.css";
import HomeHeader from "./Headesr";
import tiktokIcon from "./img/tik-tok.png";
import telegramicon from "./img/telegram.png";
import linkedinicon from "./img/linkedin.png";
import whatsappicon from "./img/whatsapp.png";
import Cookies from "js-cookie";
import { Modal } from "antd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const NewsEvents = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    setPageLoad(true);
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1280);

  const [newsIsOpen, setNewsIsOpen] = useState(false);
  const [activeNews, setActiveNews] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const faqs = [
    {
      id: 1,
      title: "Who is Premelo for?",
      answer:
        "Premelo is designed for anyone who needs to manage tasks, projects, and teams, including freelancers, small businesses, and large enterprises.",
    },
    {
      id: 2,
      title: "How do I get started with Premelo?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 3,
      title: "Can I use Premelo on my mobile device?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 4,
      title: "Do you offer customer support?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 5,
      title: "How will Premelo help me in managing my work in a project?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 6,
      title:
        "What is the difference of Premelo and other task management platform",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 7,
      title: "How much does Premelo cost?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 8,
      title: "What is Workflow?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 9,
      title: "What is Platform Customization?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 10,
      title: "What does the custom domain and hosting do?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 11,
      title: "11.	What is Social Media connect?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 12,
      title: "What is Centralized Messaging system meant?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 13,
      title: "How many projects can I create from Premelo and Premelo Plus? ",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 14,
      title: "How many tasks can I create per project?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 15,
      title: "How many assignees can I assign per task? ",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 16,
      title: "I can’t use the workflow function why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 17,
      title: "My calendar is not getting updated with the task. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 18,
      title: "I am not getting notify by my notification. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 19,
      title: "My profile is not being updated. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 20,
      title: "I am unable to add attachments to a task. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
  ];
  const [newsandevents, setnewsandevents] = useState([]);

  const getAllNews = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/news_and_events/getAll",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setnewsandevents(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllNews();
  }, []);
  function removeHTMLTags(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  }

  function formatDate(inputDate) {
    // Create a Date object from the inputDate string
    const date = new Date(inputDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // Array of month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year from the date object
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Return formatted date string
    return `${day} ${month} ${year}`;
  }

  const FAQItem1 = ({
    id,
    content_title,
    content,
    thumbnail,
    publish_date,
    type,
  }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    const [hoveredState, setHoveredState] = useState(false);

    return (
      <div
        onMouseOver={() => setHoveredState(true)}
        onMouseLeave={() => setHoveredState(false)}
        style={{
          width: screenWidth > 1200 ? "20vw" : "48%",
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          marginRight: screenWidth > 1200 ? "2.5%" : "2%",
          marginBottom: "2.5%",
          cursor: "pointer",
        }}
      >
        <div
          style={{}}
          onClick={() => {
            setActiveNews({
              title: content_title,
              content: content,
              date: publish_date,
            });
            setNewsIsOpen(true);
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // This makes the scroll behavior smooth
            });
          }}
          className="mui-button"
        >
          {/* <IconButton onClick={handleToggle}>   
            {isCollapsed ? <ChevronRight /> : <ExpandMore />}
          </IconButton> */}
          <img
            src={thumbnail}
            style={{
              width: "20vw",
              height: "15vw",
              borderRadius: "20px 20px 0px 0px",
              outline: "none",
              border: "none",
              objectFit: "cover",
            }}
          />
          <div
            style={{
              padding: "0% 5%",
              fontWeight: "bold",
              margin: "10px 0px -5px 0px",
              fontSize: "20px",
            }}
          >
            {content_title}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "300",
              padding: "0% 5%",
              marginTop: "10px",
              marginBottom: "20px",
              width: "90%",
            }}
          >
            {screenWidth > 1200
              ? content.length > 200
                ? removeHTMLTags(content.substring(0, 200)) + " ..."
                : removeHTMLTags(content)
              : content.length > 100
              ? removeHTMLTags(content.substring(0, 100)) + " ..."
              : removeHTMLTags(content)}
          </div>
        </div>
        {screenWidth > 1200 ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "300",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "20px",
                textAlign: "right",
                //   opacity: hoveredState ? 1 : 0,
                background: "#256199",
                color: "white",
                borderRadius: "10px",
                padding: "7px 15px",
                //   transition: "all 0.3s ease-in-out",
              }}
            >
              {type}
            </div>
            <div
              style={{
                marginTop: "15px",
                fontSize: "12px",
                fontWeight: "300",
                marginRight: "5%",
              }}
            >
              {formatDate(publish_date)}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const FAQItem = ({ id, title, answer, image }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    const [hoveredState, setHoveredState] = useState(false);

    return (
      <div
        onMouseOver={() => setHoveredState(true)}
        onMouseLeave={() => setHoveredState(false)}
        style={{
          width: "17%",
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          marginRight: "2.5%",
          marginBottom: "2.5%",
          cursor: "pointer",
        }}
      >
        <div style={{}} onClick={handleToggle} className="mui-button">
          {/* <IconButton onClick={handleToggle}>   
            {isCollapsed ? <ChevronRight /> : <ExpandMore />}
          </IconButton> */}
          <img
            src={image}
            style={{
              width: "100%",
              height: "200px",
              borderRadius: "20px 20px 0px 0px",
              outline: "none",
              border: "none",
            }}
          />
          <div
            style={{
              padding: "0% 5%",
              fontWeight: "bold",
              fontSize: "20px",
              margin: "10px 0px -5px 0px",
            }}
          >
            {title}
          </div>
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "300",
            padding: "0% 5%",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          {answer.length > 200 ? answer.substring(0, 200) + " ..." : answer}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "5%",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              fontWeight: "300",
              marginTop: "10px",
              marginBottom: "20px",
              textAlign: "right",
              opacity: hoveredState ? 1 : 0,
              background: "#256199",
              color: "white",
              borderRadius: "10px",
              padding: "7px 15px",
              transition: "all 0.3s ease-in-out",
            }}
          >
            Read more ...
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      {/* Header bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <div
            style={{
              width: "25%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend
          </div>
          <div
            style={{
              width: "75%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Home
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/whysmartlend");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Why SmartLend?
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Partner With Us
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "700",
                color: "#256199",
                marginRight: "10px",
              }}
            >
              News & Events
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Contact Us
            </div>

            <hr
              style={{
                width: "2px",
                height: "20px",
                margin: "0px",
                outline: "none",
                border: "none",
                background: "rgba(0,0,0,0.3)",
              }}
            />
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signup");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#256199",
                border: "2px solid #256199",
                padding: "5px 30px",
                borderRadius: "15px",
              }}
            >
              Sign Up
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "white",
                border: "2px solid #256199",
                background: "#256199",
                padding: "5px 40px",
                borderRadius: "15px",
              }}
            >
              Login
            </div>
          </div>
        </div> */}
        <HomeHeader />
      </div>
      {newsIsOpen ? (
        <div
          style={{
            padding:
              screenWidth > 1200 ? "100px 0px 50px 0px" : "30px 20px 0px 20px",
            marginLeft: pageLoad ? "0px" : "-300px",
            opacity: pageLoad ? "1" : "0",
            transition: "all 0.8s ease-in-out",
            width: "80%",
            marginLeft: "10%",
            minHeight: "60vh",
          }}
        >
          <div
            style={{
              fontSize: 15,
              color: "rgb(37, 97, 153)",
              display: "flex",
              gap: 10,
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setActiveNews(null);
              setNewsIsOpen(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth", // This makes the scroll behavior smooth
              });
            }}
          >
            <ArrowBackIcon
              style={{ fontSize: 20, color: "rgb(37, 97, 153)" }}
            />{" "}
            Back to news
          </div>
          <div style={{ fontWeight: 600, fontSize: 35 }}>
            {activeNews.title}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: activeNews.content }}
            className="pop_up_image"
            style={{ width: "100%", maxWidth: "100%" }}
          ></div>
        </div>
      ) : (
        <>
          {/* First Blog section */}
          <div
            style={{
              padding:
                screenWidth > 1200
                  ? "100px 0px 50px 0px"
                  : "30px 20px 0px 20px",
              marginLeft: pageLoad ? "0px" : "-300px",
              opacity: pageLoad ? "1" : "0",
              transition: "all 0.8s ease-in-out",
            }}
          >
            <div
              style={{
                textAlign: screenWidth > 1200 ? "center" : "left",
                fontSize: screenWidth > 1200 ? "55px" : "35px",
                fontWeight: "700",
                color: "rgba(0,0,0,0.8)",
                lineHeight: screenWidth > 1200 ? "100px" : "35px",
              }}
            >
              News & Events
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                padding:
                  screenWidth > 1200
                    ? "30px 0px 30px 40px"
                    : "20px 0px 20px 0px",
              }}
            >
              <div
                style={{
                  width: screenWidth > 1200 ? "90%" : "100%",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {newsandevents
                  .slice(0, 6)
                  .sort((a, b) => {
                    // Convert the publish_date strings to Date objects for comparison
                    const dateA = new Date(a.publish_date);
                    const dateB = new Date(b.publish_date);
                    // Sort by descending order (latest date first)
                    return dateB - dateA;
                  })
                  .map((faq) =>
                    faq.type_of_content === "News" && faq.status ? (
                      <FAQItem1
                        key={faq.id}
                        id={faq.id}
                        content_title={faq.content_title}
                        content={faq.content}
                        thumbnail={faq.thumbnail}
                        publish_date={faq.publish_date}
                        type={faq.type_of_content}
                      />
                    ) : null
                  )}
              </div>
            </div>
          </div>

          {/* Second Blog section */}
          {screenWidth > 1200 ? (
            <div style={{ padding: "50px 0px 100px 0px" }}>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "55px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Past Events
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  padding: "40px 0px 40px 40px",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {newsandevents
                    .slice(0, 6)
                    .sort((a, b) => {
                      // Convert the publish_date strings to Date objects for comparison
                      const dateA = new Date(a.publish_date);
                      const dateB = new Date(b.publish_date);
                      // Sort by descending order (latest date first)
                      return dateB - dateA;
                    })
                    .map((faq) =>
                      faq.type_of_content === "Events" && faq.status ? (
                        <FAQItem1
                          key={faq.id}
                          id={faq.id}
                          content_title={faq.content_title}
                          content={faq.content}
                          thumbnail={faq.thumbnail}
                          publish_date={faq.publish_date}
                          type={faq.type_of_content}
                        />
                      ) : null
                    )}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
      {/* Footer section */}
      <div
        style={{
          padding: screenWidth > 1200 ? "40px 150px" : "40px 0px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: screenWidth > 1200 ? "left" : "center",
          background: "rgba(0,0,0,0.1)",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
          <div
            style={{
              width: screenWidth > 1200 ? "40%" : "100%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend.
          </div>
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Apply Once, Access All.
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "13px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            SmartLend Financial Pte. Ltd. Is part of Smart Towkay Brand
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "15px" }}
          >
            Singapore HQ
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "3px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            114 Lavender St., #07-77 CT Hub 2, Singapore 338729
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "15px" }}
          >
            Folow us and Stay updated
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: screenWidth > 1200 ? "flex-start" : "center",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <FacebookIcon
              onClick={() => {
                window.location.href =
                  "https://www.facebook.com/smarttowkaysg/";
              }}
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              onClick={() => {
                window.location.href =
                  "https://www.instagram.com/smart-towkay/";
              }}
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            {/* <TwitterIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            /> */}
            <img
              className="menu_button"
              src={tiktokIcon}
              onClick={() => {
                window.location.href = "https://www.tiktok.com/@towkaypeople";
              }}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={telegramicon}
              onClick={() => {
                window.location.href = "https://t.me/smarttowkaysg";
              }}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={linkedinicon}
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/company/smart-towkay/?originalSubdomain=sg";
              }}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={whatsappicon}
              onClick={() => {
                window.location.href = "https://wa.me/6588288147";
              }}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
          </div>
        </div>
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>Sitemap</div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
            >
              Partner With Us
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
            >
              News & Events
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          background: "rgba(0,0,0,0.85)",
          justifyContent: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div
          style={{
            width: screenWidth > 1200 ? "60%" : "80%",
            color: "rgba(256,256,256,.9)",
            fontSize: screenWidth > 1200 ? "14px" : "10px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            textAlign: "center",
          }}
        >
          <div>Terms and Conditions</div>
          <div style={{ width: "10px" }}>•</div>
          <div>© SmartLend Financial Pte. Ltd.</div>
          <div style={{ width: "10px" }}>•</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};
export default NewsEvents;
