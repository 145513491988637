import React from "react";
import bgImage from "../../src/assets/img/login_background_latest.png";

const Authlayout = ({ children }) => {
  return (
    <div className="auth-multi-layout" style={{ position: "relative" }}>
      <img
        src={bgImage}
        style={{ position: "absolute", height: "100vh", zIndex: 1, left: 1 }}
      />
      <div className="auth-box" style={{ position: "relative", zIndex: 2 }}>
        {children}
      </div>
    </div>
  );
};

export default Authlayout;
