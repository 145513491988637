import React, { useEffect, useState } from "react";
import bgvideo from "../src/img/bgvideo.mp4";
import StarIcon from "@mui/icons-material/Star";
import ArticleIcon from "@mui/icons-material/Article";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import tiktokIcon from "./img/tik-tok.png";
import telegramicon from "./img/telegram.png";
import linkedinicon from "./img/linkedin.png";
import whatsappicon from "./img/whatsapp.png";

const RevampHomepage = () => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    // Create an interval that adds 1 to the counter every second
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 300);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs once on mount

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div
      style={{
        background: "black",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <video
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          filter: "blur(2px)",
          opacity: counter >= 15 ? 0.65 : 0,
          transition: "2s all ease-in-out",
        }}
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={bgvideo} type="video/mp4" />
      </video>
      <div
        style={{
          position: "fixed",
          zIndex: 30,
          top: "20px",
          right: "20px",
          cursor: "pointer",
          height: "2.3rem",
          width: "2.3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(256,256,256,0.1)",
          boxShadow:
            "0px 0px 20px rgba(0,0,0,0.2), inset 0px 0px 15px rgba(256,256,256,0.25)",
          borderRadius: "10px",
          backdropFilter: "blur(30px)",
          opacity: menuIsOpen ? 0 : 1,
          transition: "0.4s all ease-in-out",
        }}
        onClick={() => setMenuIsOpen(true)}
      >
        <MenuIcon style={{ color: "white", fontSize: "1.2rem" }} />
      </div>
      <div
        style={{
          position: "fixed",
          right: menuIsOpen ? "0%" : "-30vw",
          height: "100vh",
          width: "20vw",
          background: "rgba(256,256,256,0.1)",
          boxShadow:
            "0px 0px 20px rgba(0,0,0,0.2), inset 0px 0px 15px rgba(256,256,256,0.1)",
          backdropFilter: "blur(30px)",
          zIndex: 30,
          transition: "0.4s all ease-in-out",
          padding: "6rem 3rem",
          color: "white",
          fontSize: "0.8rem",
          lineHeight: "0.8rem",
          fontWeight: 400,
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 30,
            top: "20px",
            right: "20px",
            cursor: "pointer",
            height: "2.3rem",
            width: "2.3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(256,256,256,0)",
            borderRadius: "10px",
            backdropFilter: "blur(30px)",
          }}
          onClick={() => setMenuIsOpen(false)}
        >
          <CloseIcon style={{ color: "white", fontSize: "1.2rem" }} />
        </div>
        <div
          style={{ marginTop: "1.1rem", fontSize: "1.6rem", fontWeight: 700 }}
        >
          SmartLend.
        </div>
        <div
          style={{
            fontSize: "0.6rem",
            marginTop: "1.1rem",
            lineHeight: "1rem",
            color: "rgba(256,256,256,0.7)",
            fontWeight: 300,
          }}
        >
          Apply Once, Access All. Smart Towkay Ventures Pte. Ltd., SmartLend
          Financial Pte. Ltd., are part of the Smart Towkay Brand.
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "15px",
            alignItems: "center",
          }}
        >
          <FacebookIcon
            className="menu_button"
            style={{
              marginRight: "12px",
              color: "white",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          />
          <InstagramIcon
            className="menu_button"
            style={{
              marginRight: "12px",
              color: "white",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          />
          <TwitterIcon
            className="menu_button"
            style={{
              marginRight: "12px",
              color: "white",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          />
        </div>
        <div
          style={{
            width: "60%",
            height: "1.5px",
            background: "rgba(256,256,256,0.3)",
            borderRadius: "20px",
            marginTop: "1.1rem",
          }}
        />
        <div style={{ marginTop: "1.1rem", cursor: "pointer" }}>Home</div>
        <div style={{ marginTop: "1.1rem", cursor: "pointer" }}>
          Partner With Us
        </div>
        <div style={{ marginTop: "1.1rem", cursor: "pointer" }}>
          Frequently Asked Questions
        </div>
        <div style={{ marginTop: "1.1rem", cursor: "pointer" }}>Roadmap</div>
        <div style={{ marginTop: "1.1rem", cursor: "pointer" }}>
          News & Events
        </div>
        <div style={{ marginTop: "1.1rem", cursor: "pointer" }}>Contact Us</div>
        <div
          style={{
            width: "60%",
            height: "1.5px",
            background: "rgba(256,256,256,0.3)",
            borderRadius: "20px",
            marginTop: "1.1rem",
          }}
        />
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <div
            style={{
              marginTop: "1.1rem",
              cursor: "pointer",
              background: "#33a3ff",
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
              borderRadius: "20px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "white",
                marginLeft: "30px",
                marginRight: "15px",
                fontWeight: 600,
              }}
            >
              Sign In
            </div>
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                background: "white",
                color: "#33a3ff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
                lineHeight: "18px",
              }}
            >
              {">"}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <div
            style={{
              marginTop: "0.8rem",
              cursor: "pointer",
              color: "white",
              border: "2px solid white",
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
              borderRadius: "20px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "white",
                marginLeft: "30px",
                marginRight: "15px",
                fontWeight: 600,
              }}
            >
              Register
            </div>
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                background: "white",
                color: "rgba(0,0,0,0.7)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
                lineHeight: "18px",
              }}
            >
              {">"}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          opacity: counter >= 15 ? 1 : 0,
          transition: "opacity 2s ease-in-out",
          zIndex: 2,
          overflowX: "hidden",
          overflowY: "auto",
          color: "white",
          fontSize: "0.8rem",
          lineHeight: "0.8rem",
          fontWeight: 400,
        }}
      >
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 2,
              background: "rgba(256,256,256,0.1)",
              boxShadow:
                "0px 0px 20px rgba(0,0,0,0.2), inset 0px 0px 15px rgba(256,256,256,0.15)",
              backdropFilter: "blur(30px)",
              height: "calc(100% - 40px)",
              width: "calc(60% - 40px)",
              top: "20px",
              left: "20px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: "5vw",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 35,
                left: 35,
                fontSize: "1.6rem",
                fontWeight: 700,
              }}
            >
              SmartLend.
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "4.5rem",
                margin: 0,
                lineHeight: "4.5rem",
              }}
            >
              Need a
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "4.5rem",
                margin: 0,
                lineHeight: "4.5rem",
                color: "#33a3ff",
              }}
            >
              Business Loan,
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "2.5rem",
                lineHeight: "2.5rem",
                marginTop: "1.2rem",
              }}
            >
              We Bring The Capital To You.
            </div>
            <div
              style={{
                marginTop: "1.5rem",
              }}
            >
              Smart financing for smart businesses. Find your ideal lender with
              SmartLend.
            </div>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <div
                style={{
                  marginTop: "1.7rem",
                  cursor: "pointer",
                  background: "#33a3ff",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px",
                  borderRadius: "20px",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "white",
                    marginLeft: "30px",
                    marginRight: "15px",
                    fontWeight: 600,
                  }}
                >
                  Apply Now
                </div>
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    background: "white",
                    color: "#33a3ff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "18px",
                    lineHeight: "18px",
                  }}
                >
                  {">"}
                </div>
              </div>
              <div
                style={{
                  marginTop: "1.7rem",
                  cursor: "pointer",
                  color: "white",
                  border: "2px solid white",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px",
                  borderRadius: "20px",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "white",
                    marginLeft: "30px",
                    marginRight: "15px",
                    fontWeight: 600,
                  }}
                >
                  Talk to an Expert
                </div>
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    background: "white",
                    color: "rgba(0,0,0,0.7)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "18px",
                    lineHeight: "18px",
                  }}
                >
                  {">"}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "1.5rem",
              }}
            >
              No Hidden Platform Fees | 70% of borrower receives an offer within
              48 hours
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 1) 3%, rgba(0,0,0,0) 100%)",
            }}
          />
        </div>
        <div
          style={{
            background: "black",
            position: "relative",
            height: "13rem",
            paddingTop: "2rem",
          }}
        >
          <div
            style={{
              width: "100%",
              background: "rgba(256,256,256,0.1)",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
              padding: "40px",
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              alignItems: "flex-start",
              justifyContent: "center",
              position: "relative",
              height: "11rem",
              textAlign: "left",
              gap: "5vw",
            }}
          >
            <div style={{ width: "12vw" }}>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "3rem",
                  lineHeight: "3rem",
                  alignItems: "flex-end",
                  height: "4rem",
                }}
              >
                20+
              </div>
              <div style={{ width: "10vw", color: "rgba(256,256,256,0.6)" }}>
                And more alternative lenders on-board
              </div>
            </div>
            <div style={{ width: "12vw" }}>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "3rem",
                  lineHeight: "3rem",
                  alignItems: "flex-end",
                  height: "4rem",
                }}
              >
                2000+
              </div>
              <div style={{ width: "10vw", color: "rgba(256,256,256,0.6)" }}>
                SME's Success Stories
              </div>
            </div>
            <div style={{ width: "12vw" }}>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "3rem",
                  lineHeight: "3rem",
                  display: "flex",
                  alignItems: "flex-start",
                  height: "4rem",
                }}
              >
                5<StarIcon style={{ color: "#edb600", fontSize: "2.8rem" }} />
              </div>
              <div style={{ width: "10vw", color: "rgba(256,256,256,0.6)" }}>
                Google Reviews
              </div>
            </div>
            <div style={{ width: "12vw" }}>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "3rem",
                  lineHeight: "3rem",
                  alignItems: "flex-start",
                  height: "4rem",
                }}
              >
                <ArticleIcon style={{ color: "white", fontSize: "2.8rem" }} />
              </div>
              <div style={{ width: "10vw", color: "rgba(256,256,256,0.6)" }}>
                Complementary CBS Report & Free Home Valuation
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: "6rem",
            background: "black",
          }}
        >
          <div
            style={{
              fontWeight: 400,
              fontSize: "1.2rem",
              margin: 0,
              lineHeight: "1.2rem",
            }}
          >
            And most importantly, it's
          </div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "4.5rem",
              marginTop: "0.5rem",
              lineHeight: "4.5rem",
            }}
          >
            Completely Free!
          </div>
          <div
            style={{
              fontWeight: 400,
              fontSize: "1.2rem",
              lineHeight: "1.2rem",
              marginTop: "1.1rem",
            }}
          >
            Your{" "}
            <span style={{ color: "#33a3ff", fontWeight: 800 }}>
              Financial Partner
            </span>{" "}
            Not Just{" "}
            <span style={{ color: "#33a3ff", fontWeight: 800 }}>
              {" "}
              Matching.
            </span>
          </div>
          <div
            style={{
              fontWeight: 400,
              fontSize: "0.7rem",
              marginTop: "1.1rem",
              lineHeight: "1.3rem",
              width: "55%",
              marginLeft: "22.5%",
              color: "rgba(256,256,256,0.7)",
            }}
          >
            At SmartLend, we're creating an ecosystem to empower SMEs with
            essential tools and resources. Our innovative Business Loan
            Aggregator addresses your financing needs, helping you achieve your
            goals faster. This is just the start—exciting new products are on
            the horizon!
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                marginTop: "1.7rem",
                cursor: "pointer",
                background: "#33a3ff",
                display: "flex",
                justifyContent: "space-between",
                padding: "8px",
                borderRadius: "20px",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "white",
                  marginLeft: "30px",
                  marginRight: "15px",
                  fontWeight: 600,
                }}
              >
                Apply Now
              </div>
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  background: "white",
                  color: "#33a3ff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "18px",
                  lineHeight: "18px",
                }}
              >
                {">"}
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", background: "black" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "rgba(256,256,256,0.1)",
              padding: "20px 0px",
              position: "relative",
              zIndex: 201,
            }}
          >
            <div
              className="move-right"
              style={{
                display: "flex",
                width: "200%",
                fontWeight: 800,
                fontSize: "20px",
              }}
            >
              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>
              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>

              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>

              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>

              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>

              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>

              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>

              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>

              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>

              <div style={{ color: "white", marginRight: 7 }}>
                Apply Once, Access All.{" "}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            background:
              "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0, 0, 0, 1) 100%)",
            padding: "6rem",
            width: "100%",
          }}
        >
          <div
            style={{
              fontWeight: 700,
              fontSize: "2.5rem",
              lineHeight: "2.5rem",
              marginTop: "0.5rem",
              textAlign: "center",
            }}
          >
            Smart Borrowing, without the Hassle.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vw",
              width: "80%",
              marginLeft: "10%",
              marginTop: "4rem",
            }}
          >
            <div
              style={{
                width: "45%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                background: "rgba(256,256,256,0.1)",
                backdropFilter: "blur(30px)",
                borderRadius: "10px",
                boxShadow:
                  "0px 0px 20px rgba(0,0,0,0.2), inset 0px 0px 15px rgba(256,256,256,0.15)",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  background: "rgba(0,0,0,0.6)",
                  borderRadius: "0px 0px 10px 10px",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%,0)",
                  padding: "1rem",
                  width: "17vw",
                  height: "17vw",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "1vw", width: "100%" }}>
                  <div
                    style={{
                      background: "rgba(256,256,256,0.1)",
                      height: "3.5vw",
                      width: "3.5vw",
                      borderRadius: "50%",
                    }}
                  />
                  <div style={{ width: "calc(100% - 5vw)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.4rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "60%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.4rem",
                      }}
                    />
                    <div style={{ display: "flex", marginTop: "0.4rem" }}>
                      <div
                        style={{
                          color: "rgba(256,256,256,0.6)",
                          padding: "0.3rem 0.9rem",
                          fontSize: "0.6rem",
                          background: "rgba(256,256,256,0.1)",
                          borderRadius: "4rem",
                        }}
                      >
                        Accept Offer
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1vw",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <div
                    style={{
                      background: "rgba(256,256,256,0.1)",
                      height: "3.5vw",
                      width: "3.5vw",
                      borderRadius: "50%",
                    }}
                  />
                  <div style={{ width: "calc(100% - 5vw)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.4rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "60%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.4rem",
                      }}
                    />
                    <div style={{ display: "flex", marginTop: "0.4rem" }}>
                      <div
                        style={{
                          color: "rgba(256,256,256,0.6)",
                          padding: "0.3rem 0.9rem",
                          fontSize: "0.6rem",
                          background: "rgba(256,256,256,0.1)",
                          borderRadius: "4rem",
                        }}
                      >
                        Accept Offer
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1vw",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <div
                    style={{
                      background: "rgba(256,256,256,0.1)",
                      height: "3.5vw",
                      width: "3.5vw",
                      borderRadius: "50%",
                    }}
                  />
                  <div style={{ width: "calc(100% - 5vw)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.4rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "60%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.4rem",
                      }}
                    />
                    <div style={{ display: "flex", marginTop: "0.4rem" }}>
                      <div
                        style={{
                          color: "rgba(256,256,256,0.6)",
                          padding: "0.3rem 0.9rem",
                          fontSize: "0.6rem",
                          background: "rgba(256,256,256,0.1)",
                          borderRadius: "4rem",
                        }}
                      >
                        Accept Offer
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  background: "#33a3ff",
                  borderRadius: "50%",
                  fontSize: "2rem",
                  lineHeight: "2rem",
                  height: "3rem",
                  width: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                1
              </div>
            </div>
            <div
              style={{
                width: "45%",
                height: "100%",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  fontSize: "1.2rem",
                  lineHeight: "1.4rem",
                }}
              >
                Unlock the Power of Smart Borrowing
              </div>
              <div
                style={{
                  fontSize: "0.6rem",
                  marginTop: "1.1rem",
                  lineHeight: "1rem",
                  color: "rgba(256,256,256,0.7)",
                  fontWeight: 300,
                }}
              >
                Say goodbye to the headaches of dealing with Relationship
                Managers and brokers for your business loan needs. We get it –
                navigating the financing landscape can be a maze. How can you be
                certain you're securing the best deals?
                <br /> <br /> Introducing SMARTLEND. , your compass in the world
                of SME financing. We've revolutionized the lending game,
                matching you with alternative lending options tailored for your
                business. No more guessing, no more endless negotiations.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vw",
              width: "80%",
              marginLeft: "10%",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                width: "45%",
                height: "100%",
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "right",
              }}
            >
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  lineHeight: "1.4rem",
                }}
              >
                Empowering Your Business: Bringing Negotiating Power Back to
                You!
              </div>
              <div
                style={{
                  fontSize: "0.6rem",
                  marginTop: "1.1rem",
                  lineHeight: "1rem",
                  color: "rgba(256,256,256,0.7)",
                  fontWeight: 300,
                }}
              >
                Tired of feeling at the mercy of lenders and brokers? We hear
                you. It's time to take control of your business financing.
                <br />
                <br />
                SMARTLEND. is here to revolutionize your borrowing experience,
                putting the negotiating power back where it belongs – in your
                hands. No more one-sided deals, no more uncertainty.
              </div>
            </div>
            <div
              style={{
                width: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  background: "#33a3ff",
                  borderRadius: "50%",
                  fontSize: "2rem",
                  lineHeight: "2rem",
                  height: "3rem",
                  width: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                2
              </div>
            </div>
            <div
              style={{
                width: "45%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                background: "rgba(256,256,256,0.1)",
                backdropFilter: "blur(30px)",
                borderRadius: "10px",
                boxShadow:
                  "0px 0px 20px rgba(0,0,0,0.2), inset 0px 0px 15px rgba(256,256,256,0.15)",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  background: "rgba(0,0,0,0.6)",
                  borderRadius: "0px 0px 10px 10px",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%,0)",
                  padding: "1rem",
                  width: "17vw",
                  height: "17vw",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "rgba(256,256,256,0.6)",
                    fontSize: "0.8rem",
                  }}
                >
                  Loan Offer Details
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                    width: "100%",
                    marginTop: "0.5rem",
                  }}
                >
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "40%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                  </div>
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "40%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "40%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                  </div>
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "40%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "40%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                  </div>
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "40%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "40%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                  </div>
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "40%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                      }}
                    />
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    color: "rgba(256,256,256,0.6)",
                    padding: "0.3rem 0.9rem",
                    fontSize: "0.6rem",
                    background: "rgba(256,256,256,0.1)",
                    borderRadius: "4rem",
                    textAlign: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  Negotiate Offer
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vw",
              width: "80%",
              marginLeft: "10%",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                width: "45%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                background: "rgba(256,256,256,0.1)",
                backdropFilter: "blur(30px)",
                borderRadius: "10px",
                boxShadow:
                  "0px 0px 20px rgba(0,0,0,0.2), inset 0px 0px 15px rgba(256,256,256,0.15)",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  top: "10%",
                  left: "50%",
                  position: "absolute",
                  transform: "translate(-50%,0)",
                  background: "rgba(0,0,0,0.6)",
                  borderRadius: "10px",
                  lineHeight: "0.6rem",
                  fontSize: "0.6rem",
                  padding: "0.5rem 1.3rem",
                  borderRadius: "4rem",
                }}
              >
                Ongoing Loans
              </div>

              <div
                style={{
                  background: "rgba(0,0,0,0.6)",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "calc(10% + 2.5rem)",
                  left: "calc(25% - 1.5rem",
                  transform: "translate(-50%,0)",
                  padding: "1rem",
                  width: "17vw",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
                  <div
                    style={{
                      background: "rgba(256,256,256,0.1)",
                      height: "3.5vw",
                      width: "3.5vw",
                      borderRadius: "50%",
                    }}
                  />
                  <div style={{ width: "calc(100% - 5rem)" }}>
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        fontSize: "0.5rem",
                        textAlign: "left",
                      }}
                    >
                      Loan Amount
                    </div>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        fontSize: "0.5rem",
                        textAlign: "left",
                        marginTop: "0.5rem",
                      }}
                    >
                      Loan Principal
                    </div>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        fontSize: "0.5rem",
                        textAlign: "left",
                        marginTop: "0.5rem",
                      }}
                    >
                      Loan Balance
                    </div>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        fontSize: "0.5rem",
                        textAlign: "left",
                        marginTop: "0.5rem",
                      }}
                    >
                      Loan Payment Due Date
                    </div>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        padding: "0.3rem 0.9rem",
                        fontSize: "0.6rem",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "4rem",
                        textAlign: "center",
                        marginTop: "0.9rem",
                      }}
                    >
                      Pay now
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "rgba(0,0,0,0.6)",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "calc(10% + 2.5rem)",
                  left: "calc(75% + 1.5rem",
                  transform: "translate(-50%,0)",
                  padding: "1rem",
                  width: "17vw",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
                  <div
                    style={{
                      background: "rgba(256,256,256,0.1)",
                      height: "3.5vw",
                      width: "3.5vw",
                      borderRadius: "50%",
                    }}
                  />
                  <div style={{ width: "calc(100% - 5rem)" }}>
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        fontSize: "0.5rem",
                        textAlign: "left",
                      }}
                    >
                      Loan Amount
                    </div>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        fontSize: "0.5rem",
                        textAlign: "left",
                        marginTop: "0.5rem",
                      }}
                    >
                      Loan Principal
                    </div>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        fontSize: "0.5rem",
                        textAlign: "left",
                        marginTop: "0.5rem",
                      }}
                    >
                      Loan Balance
                    </div>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        fontSize: "0.5rem",
                        textAlign: "left",
                        marginTop: "0.5rem",
                      }}
                    >
                      Loan Payment Due Date
                    </div>
                    <div
                      style={{
                        height: "0.4vw",
                        width: "100%",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "2rem",
                        marginTop: "0.2rem",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(256,256,256,0.6)",
                        padding: "0.3rem 0.9rem",
                        fontSize: "0.6rem",
                        background: "rgba(256,256,256,0.1)",
                        borderRadius: "4rem",
                        textAlign: "center",
                        marginTop: "0.9rem",
                      }}
                    >
                      Pay now
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  background: "#33a3ff",
                  borderRadius: "50%",
                  fontSize: "2rem",
                  lineHeight: "2rem",
                  height: "3rem",
                  width: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                3
              </div>
            </div>
            <div
              style={{
                width: "45%",
                height: "100%",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  fontSize: "1.2rem",
                  lineHeight: "1.4rem",
                }}
              >
                Mutual Agreement
              </div>
              <div
                style={{
                  fontSize: "0.6rem",
                  marginTop: "1.1rem",
                  lineHeight: "1rem",
                  color: "rgba(256,256,256,0.7)",
                  fontWeight: 300,
                }}
              >
                Your Loan Experience Elevated.
                <br /> <br />
                Introducing our revolutionary single dashboard, designed to
                simplify and elevate your loan journey. Now, effortlessly track
                your loan application progress, monitor monthly installments,
                and enjoy the convenience of timely reminders for upcoming
                repayments.
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.4)",
            backdropFilter: "blur(30px)",
            padding: "6rem 15%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "55%", textAlign: "left" }}>
            <div
              style={{
                fontWeight: 700,
                fontSize: "2.5rem",
                lineHeight: "2.5rem",
                marginTop: "0.5rem",
              }}
            >
              How SmartLend Works
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: "1.2rem",
                marginTop: "1.1rem",
              }}
            >
              <span style={{ color: "#33a3ff", fontWeight: 800 }}> 5 Step </span>
              &{" "}
              <span style={{ color: "#33a3ff", fontWeight: 800 }}>
                under 5 minutes
              </span>{" "}
              Seamless application. Our goal is to reduce the need for users to
              manually input information that can be obtained through Myinfo
              Business.
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div
        style={{
          fontSize: "10rem",
          color: "white",
          fontWeight: 600,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform:
            counter === 1 || counter === 2
              ? "translate(-50%,-50%) scale(1.5)"
              : "translate(-50%,-50%) scale(0.5)",
          opacity: counter === 1 || counter === 2 ? 1 : 0,
          transition: "1s all ease-in-out",
        }}
      >
        WE
      </div>

      <div
        style={{
          fontSize: "10rem",
          color: "white",
          fontWeight: 600,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform:
            counter === 3 || counter === 4
              ? "translate(-50%,-50%) scale(1.5)"
              : "translate(-50%,-50%) scale(0.5)",
          opacity: counter === 3 || counter === 4 ? 1 : 0,
          transition: "1s all ease-in-out",
        }}
      >
        BRING
      </div>
      <div
        style={{
          fontSize: "10rem",
          color: "white",
          fontWeight: 600,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform:
            counter === 5 || counter === 6
              ? "translate(-50%,-50%) scale(1.5)"
              : "translate(-50%,-50%) scale(0.5)",
          opacity: counter === 5 || counter === 6 ? 1 : 0,
          transition: "1s all ease-in-out",
        }}
      >
        THE
      </div>
      <div
        style={{
          fontSize: "10rem",
          color: "white",
          fontWeight: 600,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform:
            counter === 7 || counter === 8
              ? "translate(-50%,-50%) scale(1.5)"
              : "translate(-50%,-50%) scale(0.5)",
          opacity: counter === 7 || counter === 8 ? 1 : 0,
          transition: "1s all ease-in-out",
        }}
      >
        CAPITAL
      </div>
      <div
        style={{
          fontSize: "10rem",
          color: "white",
          fontWeight: 600,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform:
            counter === 9 || counter === 10
              ? "translate(-50%,-50%) scale(1.5)"
              : "translate(-50%,-50%) scale(0.5)",
          opacity: counter === 9 || counter === 10 ? 1 : 0,
          transition: "1s all ease-in-out",
        }}
      >
        TO
      </div>
      <div
        style={{
          fontSize: "10rem",
          color: "white",
          fontWeight: 600,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform:
            counter === 11 || counter === 12 || counter === 13 || counter === 14
              ? "translate(-50%,-50%) scale(2)"
              : "translate(-50%,-50%) scale(0.5)",
          opacity:
            counter === 11 || counter === 12 || counter === 13 || counter === 14
              ? 1
              : 0,
          transition: "1s all ease-in-out",
        }}
      >
        YOU
      </div>
    </div>
  );
};

export default RevampHomepage;
