import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  useHistory,
  Redirect,
} from "react-router-dom";
import {
  adminRoutes,
  authRoutes,
  verifyRoutes,
  dashboardRoutes,
  verticalRoutes,
  borrowerDashboardRoutes,
  adminDashboardRoutes,
  landingPageRoutes,
} from "./routes/routes";
import Authlayout from "./layout/Authlayout";
import Verifylayout from "./layout/Verifylayout";
// import Adminlayout from "./layout/Adminlayout";
// import Dashboardlayout from "./layout/Dashboardlayout";
import VerticalLayout from "./layout/VerticalLayout";
import DashboardLayout from "./pages/dashboard/dashboard";
import DashboardBorrowerLayout from "./pages/borrower-dashboard/borrower-dashboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import RequestLoanTable from "./scenes/loan-request-list";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import DashboardAdminLayout from "./pages/admin-dashboard/admin-dashboard";
import LandingPagelayout from "./layout/LandingPagelayout";

library.add(faSearch);
// Multi-layout function
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props}></Component>
      </Layout>
    )}
    exact
  ></Route>
);
function App() {
  // const [theme, colorMode] = useMode();
  // const [isSidebar, setIsSidebar] = useState(true);
  const urlPram = useHistory();

  useEffect(() => {
    // Function to get the value of a URL parameter by name
    const getUrlParameter = (name) => {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    // Get the token parameter from the URL
    const tokenParam = getUrlParameter("token");

    // Check if a token is present in the URL
    if (tokenParam) {
      // Store the token in cookies
      console.log("token", tokenParam);
      Cookies.set("token", tokenParam, { expires: 7 }); // Set an expiration if needed

      // Remove the token parameter from the URL
      const urlWithoutToken =
        window.location.pathname +
        window.location.search
          .replace(/([&?])token=[^&]+(&|$)/, "$1")
          .replace(/(&|\?)$/, "");

      // Replace the current URL without adding a new entry to the browser urlPram
      console.log(urlPram, urlWithoutToken);
      if (urlPram) {
        urlPram.replace(urlWithoutToken);
      }
    }
  }, [urlPram]);

  return (
    <Router>
      <Switch>
        {authRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            path={route.path}
            component={route.component}
            layout={Authlayout}
          />
        ))}
        {landingPageRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            path={route.path}
            component={route.component}
            layout={LandingPagelayout}
          />
        ))}
        {verifyRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            path={route.path}
            component={route.component}
            layout={Verifylayout}
          />
        ))}
        {dashboardRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            path={route.path}
            component={route.component}
            layout={DashboardLayout}
          />
        ))}
        {borrowerDashboardRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            path={route.path}
            component={route.component}
            layout={DashboardBorrowerLayout}
          />
        ))}
        {adminDashboardRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            path={route.path}
            component={route.component}
            layout={DashboardAdminLayout}
          />
        ))}
        {verticalRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            path={route.path}
            component={route.component}
            layout={VerticalLayout}
          />
        ))}
        <Redirect strict from="/" to="/home" />
      </Switch>
    </Router>
  );
}

export default App;
