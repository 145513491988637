import { Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { MobileStepper } from "@mui/material";
import Cookies from "js-cookie";

import mailIcon from "../../assets/img/mail-icon.png";
const ConfirmEmail = () => {
  const [emailDisplay, setEmailDisplay] = useState("");
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/auth/register/viewUser",
        options
      )
        // const json = await response.json();
        .then((res) => res.json())
        .then((data) => {
          setEmailDisplay(data.email);
        });
      setData(response);
    }
    fetchData();
  }, []);

  const handleClick = async (event) => {
    event.preventDefault();
    history.push("/auth/phone-number");
  };

  const handleClickNotEmail = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/register/deleteUser`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      const data = await response.json();
      console.log(data, "delete successfully");
      history.push("/auth/signup");
    } catch (error) {
      console.error(error, "delete unsuccessful");
    }
  };

  return (
    <React.Fragment>
      <div className="auth-header">
        {/* <div className="auth-header-logo">
          <img src={SCLOGO} alt="" className="auth-header-logo-img" />
        </div> */}
        <h3 className="email-header-title">
          <img src={mailIcon} alt="" className="auth-header-logo-img" />
          Confirm Email
        </h3>
        <p className="email-header-subtitle">
          You're almost there! Is this your email?
        </p>
        <br />
        <h3 className="auth-header-email">
          <span style={{ color: "blue" }}>{emailDisplay}</span>
        </h3>
      </div>
      <br />
      <div className="auth-body">
        <form className="auth-form-validation">
          <div className="btn-position-email">
            <button
              type="submit"
              className="btn-submit-email"
              onClick={handleClick}
            >
              Yes, this is my email
            </button>
            <button
              type="submit"
              className="btn-submit-default-email"
              onClick={handleClickNotEmail}
            >
              No, this is not my email
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="text-center-email">
              Need Help?{" "}
              <span
                style={{
                  color: "#0e5aa5",
                  textDecoration: "underline",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href = "https://wa.me/6588288147";
                }}
              >
                Contact Us
              </span>
            </p>
          </div>
        </form>
        {/* Add a new container with 'display: flex' and 'alignItems: center' */}
        <div className="flex-end">
          <div style={{ width: 10 }}>
            <MobileStepper
              variant="dots"
              steps={6}
              position="static"
              activeStep={2}
              sx={{
                maxWidth: 400,
                flexGrow: 1,
                marginLeft: 41.0,
                marginTop: -7.5,
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfirmEmail;
