import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { mockTransactions } from "../../data/mockData";
import TableDelayedRequest from "../../components/DelayedPaymentsTable";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import warn_ico from "../../assets/img/exclamation.png";
import { Modal, Input, Button } from "antd";
import { useHistory } from "react-router-dom";

const DelayedPayments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [channel, setChannel] = useState("system");
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { TextArea } = Input;
  const [selectedBorrowerIds, setselectedBorrowerIds] = useState([]);
  const history = useHistory();
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  const handleRowClick = async (record) => {
    const repayId = record.repay_id;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewDelayedPaymentDetail?repay_id=${repayId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      setData(json["Loan Detail"]);
      console.log(json["Loan Detail"]);
      setselectedBorrowerIds([json["Loan Detail"][0].borrower_id]);
    } catch (error) {
      console.error("Error retrieving loan detail:", error);
    }
  };

  const handleSelectionChange = (selectedBorrowerIds) => {
    console.log("Selected loan IDs:", selectedBorrowerIds);
    setselectedBorrowerIds(selectedBorrowerIds);
  };

  const handleSubmit = async (borrower_id) => {
    console.log("loanIds value in handleSubmit", borrower_id); // Debug statement

    try {
      let item = {
        user_id: borrower_id.join(","),
        channel: channel,
        subject: subject,
        message: message,
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/user/notification/sendNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      console.log("Offer created:", data);
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    const initialStates = {};
    data.forEach((item) => {
      initialStates[item.borrower_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const [modalStates, setModalStates] = useState({});
  const [open, setOpen] = useState(false);
  const [openBorrowerIds, setOpenBorrowerIds] = useState([]);

  const showModal = (selectedBorrowerIds, event) => {
    event.preventDefault();
    console.log("loanIds modal", selectedBorrowerIds);
    setOpenBorrowerIds((prevOpenBorrowerIds) => [
      ...prevOpenBorrowerIds,
      ...selectedBorrowerIds,
    ]);
  };

  const handleClose = (selectedBorrowerIds) => {
    setOpenBorrowerIds((prevOpenBorrowerIds) =>
      prevOpenBorrowerIds.filter((id) => id !== selectedBorrowerIds)
    );
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "grey",
          height: "60px",
        }}
      >
        <img
          src={icon}
          alt={title}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "40px",
            margin: "0px 10px",
          }}
        />
        <div>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "18px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "10px",
              fontWeight: "400",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        width: screenWidth > 1200 ? "100%" : "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "10px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            flex: 1,
            margin: "0px",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "18px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box m="0px 20px 20px 20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="10px"
      >
        <Box
          gridColumn={isMobile ? "span 12" : "span 9"}
          gridRow="span 2"
          height="90vh"
          width="100%"
          borderRadius="10px"
          border="none"
          opacity="0.95"
          style={{
            overflowY: "scroll",
            height: isMobile ? "80vh" : "90vh",
            overflowX: "hidden",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px 15px 50px 15px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ width: "100%", marginBottom: "15px" }}
            borderRadius="10px"
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="18px"
            >
              Delayed Payments
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: isMobile ? "auto" :"400px",
              }}
            >
              <label
                htmlFor="sort-by"
                style={{
                  color: "#142C44CC",
                  fontWeight: "800",
                  fontSize: "13px",
                  fontFamily: "Manrope, sans-serif",
                }}
              >
                Sort by:
              </label>
              <div style={{ width: "40%", margin: "0px 10px" }}>
                <select
                  id="sort-by"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  style={{
                    width: "100%",
                    borderRadius: "30px",
                    color: "#0E5AA5",
                    backgroundColor: "#ECF0F5",
                    border: "1px solid #3E536733",
                    fontWeight: "600",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                    padding: "3px 15px",
                  }}
                >
                  <option
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                    value="default"
                  >
                    Date & Time
                  </option>
                  <option
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                    value="loan-amount"
                  >
                    Loan Amount
                  </option>
                  <option
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                    value="date"
                  >
                    Date
                  </option>
                </select>
              </div>
            </div>
          </Box>

          <Box>
            <TableDelayedRequest
              onRowClick={handleRowClick}
              onSelectionChange={handleSelectionChange}
            />
          </Box>
          <Box>
            <Modal
              style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
              open={selectedBorrowerIds.some((borrowerId) =>
                openBorrowerIds.includes(borrowerId)
              )}
              onCancel={() =>
                selectedBorrowerIds.forEach((borrowerId) =>
                  handleClose(borrowerId)
                )
              }
              footer={[
                <div
                  style={{
                    display: "flex",
                    justifyContent: "Right",
                    marginTop: 16,
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => handleSubmit(selectedBorrowerIds)}
                  >
                    Submit
                  </Button>
                </div>,
              ]}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 16,
                  borderRadius: "10px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="offer_amount"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                      fontSize: "10px",
                      opacity: ".6",
                    }}
                  >
                    Channel
                  </label>
                  <br />
                  {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.principal_name || "-"}</p>
                   */}
                  <select
                    id="channel"
                    value={channel}
                    onChange={(e) => setChannel(e.target.value)}
                    style={{
                      width: "70%",
                    }}
                  >
                    <option value="system">Notification</option>
                    <option value="email">Email</option>
                    <option value="both">Both</option>
                  </select>
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="offer_interest_rate"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                      fontSize: "10px",
                      opacity: ".6",
                    }}
                  >
                    Subject
                  </label>
                  <br />
                  {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.loan_id || "-"}</p>                        */}
                  <input
                    type="text"
                    style={{ width: "70%" }}
                    id="subject"
                    placeholder="Subject"
                    autoComplete="off"
                    onChange={(event) => setSubject(event.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 16,
                  borderRadius: "10px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="offer_amount"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                      fontSize: "10px",
                      opacity: ".6",
                    }}
                  >
                    Message
                  </label>
                  <br />
                  {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_interest_rate + "%" || "-"}</p>
                   */}
                  <TextArea
                    style={{ width: "85%" }}
                    rows={2}
                    onChange={(event) => setMessage(event.target.value)}
                    id="message"
                    placeholder="Please Insert Your Message"
                  />
                </div>
              </div>
              <br />
            </Modal>
          </Box>
        </Box>

        { !isMobile ? <Box
          gridColumn="span 3"
          gridRow="span 2"
          height="90vh"
          borderRadius="20px"
          border="0px solid white"
          style={{
            background:
              "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "0px 15px",
          }}
        >
          <div>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "18px",
              }}
            >
              Loan Details
            </p>
          </div>

          <div
            style={{
              borderRadius: "10px",
              padding: "20px",
              marginTop: "15px",
            }}
          >
            {data && data.length > 0 ? (
              data.map((item) => (
                <Box
                  key={item.id}
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start" // Updated to align to the top
                  colors={colors.grey[910]}
                  textAlign="center"
                  flexDirection="column"
                >
                  <ContainerActivity
                    icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
                    title={item.principal_name || "Hello World"}
                    subtitle={item.category || "-"}
                  />
                  <div
                    style={{
                      borderBottom: "2px solid black",
                      width: "100%",
                      opacity: "0.3",
                    }}
                  />
                </Box>
              ))
            ) : (
              <div
                style={{
                  height: "80vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={warn_ico}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "25px",
                  }}
                />
                <div
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    fontSize: "18px",
                    lineHeight: "20px",
                    textAlign: "center",
                    color: "rgba(20, 44, 68, 0.8)",
                    width: "90%",
                    marginTop: "15px",
                  }}
                >
                  Select a record to view details.
                </div>
              </div>
            )}
            {!data && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                colors={colors.grey[910]}
                width="100%"
                textAlign="center"
                margin="1px 1px 1px 30px"
                flexDirection="column"
              >
                <ContainerActivity
                  icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
                  title="NO VALUE HERE"
                  subtitle="NO VALUE HERE"
                />
                <div
                  style={{
                    borderBottom: "2px solid black",
                    width: "100%",
                    opacity: "0.3",
                  }}
                />
              </Box>
            )}

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              colors={colors.grey[910]}
              marginLeft="0px"
            >
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <ContainerTransaction
                    title="Loan Amount"
                    label={`$ ${item.amount.toFixed(2)}` || "-"}
                  />
                ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              colors={colors.grey[910]}
              marginLeft="0px"
              marginTop="0px"
            >
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <ContainerTransaction
                    title="Monthly Payment"
                    label={`$ ${item.monthly_installment.toFixed(2)}` || "-"}
                  />
                ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              colors={colors.grey[910]}
              marginLeft="0px"
              marginTop="0px"
            >
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <ContainerTransaction
                    title="Principal Amount"
                    label={`$ ${item.repay_principal.toFixed(2)}` || "-"}
                  />
                ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              colors={colors.grey[910]}
              marginLeft="0px"
              marginTop="0px"
            >
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <ContainerTransaction
                    title="Interest Rate"
                    label={item.interest_rate + " %" || "-"}
                  />
                ))}
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              colors={colors.grey[910]}
              marginLeft="0px"
              marginTop="0px"
            >
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <ContainerTransaction
                    title="Loan Period"
                    label={item.period + " Months" || "-"}
                  />
                ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              colors={colors.grey[910]}
              marginTop="0px"
            >
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <ContainerTransaction
                    title="Payment Due Date"
                    label={item.repay_due_date || "-"}
                  />
                ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              colors={colors.grey[910]}
              marginLeft="0px"
              marginTop="0px"
            >
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <ContainerTransaction
                    title="Delayed by"
                    label={item.late_day + " Days" || "-"}
                  />
                ))}
            </Box>
            {data && data.length > 0 ? (
              <>
                <div
                  style={{
                    borderBottom: "2px solid black",
                    width: "100%",
                    opacity: "0.3",
                    margin: "10px 0px",
                  }}
                />
                <button
                  className="all_button"
                  onClick={(event) => {
                    console.log(selectedBorrowerIds, event);
                    showModal(selectedBorrowerIds, event);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: "40px",
                    color: "#B13434",
                    border: "1px solid #B13434",
                    fontWeight: "600",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                    padding: "3px 30px",
                  }}
                >
                  Warn Borrower
                </button>
              </>
            ) : null}
          </div>
        </Box> : null}
      </Box>
    </Box>
  );
};

export default DelayedPayments;
