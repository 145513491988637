import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import TableAdminLoanRequest from "../../components/TableAdminLoanRequest";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import warn_ico from "../../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Avatar,
  Upload,
  Card,
  Tabs,
  notification,
  DatePicker,
  Space,
  InputNumber,
  Modal,
  Button,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { message } from "antd";
import { format } from "date-fns";
import TableAdminCorporateLoanRequest from "../../components/TableAdminCorporateLoanRequest";

const CorporateStructureLoan = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [channel, setChannel] = useState("system");
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { TextArea } = Input;
  const [selectedBorrowerIds, setselectedBorrowerIds] = useState([]);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [error, setError] = useState("");
  const [screenWidth, setScreenWidth] = useState("1920");

  const handleRowClick = async (record) => {
    const loan_id = record.loan_id;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      setData(json["Loan Detail"]);
    } catch (error) {
      console.error("Error retrieving Loan Detail Data:", error);
    }
  };

  const handleSelectionChange = (selectedBorrowerIds) => {
    console.log("Selected loan IDs:", selectedBorrowerIds);
    setselectedBorrowerIds(selectedBorrowerIds);
  };

  const formatDate = (dateString) => {
    try {
      if (!dateString) throw new Error("No date string provided");

      const date = new Date(dateString);
      if (isNaN(date.getTime())) throw new Error("Invalid date string");

      const formattedDate = format(date, "dd MMMM yyyy");

      return formattedDate;
    } catch (error) {
      console.error(error);
      return "-";
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 16,
    },
    vertical: true,
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 9,
      span: 24,
    },
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
        <img
          src={icon}
          alt={title}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            margin: "10px 10px 10px 10px",
          }}
        />
        <div
          style={{
            height: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "5px",
          }}
        >
          <h3
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
              fontSize: "18px",
              textAlign: "left",
              color: "rgba(20, 44, 68, 0.8)",
              margin: "0px 0px 0px 0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              textAlign: "left",
              margin: "0px 0px 0px 0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "12px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "rgba(20, 44, 68, 0.8)",
            margin: "0px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box m="4px 20px 20px 20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          height="100vh"
          width="100%"
          borderRadius="10px"
          border="0px solid "
          opacity="0.95"
          style={{
            overflowY: "scroll",
            overflowX: "scroll",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            borderRadius="10px"
            margin="20px 20px 20px 20px"
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="18px"
            >
              Corporate Structured Loan Request
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "38px",
              }}
            >
              <label
                htmlFor="sort-by"
                style={{
                  width: "50%",
                  color: "#142C44CC",
                  fontWeight: "800",
                  fontSize: "13px",
                  fontFamily: "Manrope, sans-serif",
                  marginRight: "-5px",
                }}
              >
                Sort by:
              </label>
              <div style={{ width: "80%", marginRight: "10px" }}>
                <select
                  id="sort-by"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  style={{
                    width: "100%",
                    borderRadius: "30px",
                    color: "#0E5AA5",
                    backgroundColor: "#ECF0F5",
                    border: "1px solid #3E536733",
                    marginRight: "15px",
                    fontWeight: "600",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  }}
                >
                  <option
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                    value="default"
                  >
                    Date & Time
                  </option>
                  <option
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                    value="loan-amount"
                  >
                    Loan Amount
                  </option>
                  <option
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                    value="date"
                  >
                    Date
                  </option>
                </select>
              </div>
              {/* <button onClick={(event) => showModal(selectedBorrowerIds, event)} style={{ backgroundColor: "white", borderRadius: "10px", color: "#B13434", border: "1px solid #B13434", fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px", width:"80%" }}>Warn Borrower</button> */}
            </div>
          </Box>

          <Box>
            <TableAdminCorporateLoanRequest
              onRowClick={handleRowClick}
              onSelectionChange={handleSelectionChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CorporateStructureLoan;
