import { useState, useEffect } from "react";
import React from "react";
import FeedIcon from "@mui/icons-material/Feed";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message, Modal } from "antd";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";

const ModalApplicationExpress = ({
  loanType,
  loanCategory,
  requestingLoanAmount,
  expectingInterestRate,
  loanPeriod,
  request_principal,
  request_monthly_installment,
  total_admin_fee_by_month,
  total_admin_fee,
  request_revenue,
}) => {
  const [data, setData] = useState([]);
  const [directorData, setDirectorData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [residentialStatus, setResidentalStatus] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [registeredBusinessName, setRegisteredBusinessName] = useState("");
  const [countryOfIncorporation, setCountryOfIncorporation] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [UEN, setUEN] = useState("");
  const [registeredBusinessAddress, setRegisteredBusinessAddress] =
    useState("");
  const [companyContactNo, setCompanyContactNo] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [shareHolderContactNo, setDirectorShareholderPhoneNo] = useState("");
  const [shareHolderName, setDirectorShareholderName] = useState("");
  const [shareHolderEmail, setDirectorShareholderEmail] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [fileList4, setFileList4] = useState([]);
  const [fileList5, setFileList5] = useState([]);
  const [fileList6, setFileList6] = useState([]);
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const props = {
    action: "https://smart-lend.com/b-apply-loan",
    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
        console.log(file, fileList);
      }
    },
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewApplicantDetail",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setData(data["applicantInfo"]);
        setDirectorData(data["relationPersonelInfo"]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo, history]);

  const [activeTab, setActiveTab] = useState("Application Information");

  const handleSubmit = async (companyContactNo, companyEmail) => {
    try {
      if (
        ![
          fileList,
          fileList2,
          fileList3,
          fileList4,
          fileList5,
          fileList6,
        ].every((fileList) => fileList.length > 0)
      ) {
        message.error("All files must be uploaded before proceeding");
        return;
      } else {
        message.success("Your loan application are being processed");

        let item = {
          express: 1,
          type: loanType,
          category: loanCategory,
          request_amount: parseFloat(requestingLoanAmount),
          request_interest_rate: parseFloat(expectingInterestRate) / 100,
          request_period: parseInt(loanPeriod),
          request_principal: parseFloat(request_principal),
          request_monthly_installment: parseFloat(request_monthly_installment),
          admin_fee: parseFloat(total_admin_fee_by_month),
          total_admin_fee: parseFloat(total_admin_fee),
          total_interest: parseFloat(request_revenue),
          visibility: 0,
        };
        if (requestingLoanAmount < 500000) {
          item.visibility = 1;
        }
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/createLoan",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: JSON.stringify(item),
          }
        );
        const data = await response.json();

        const zip = new JSZip();

        // concatenate all file lists
        const allFiles = fileList.concat(
          fileList2,
          fileList3,
          fileList4,
          fileList5,
          fileList6
        );

        allFiles.forEach((file, index) => {
          zip.file(file.name, file.originFileObj);
        });

        zip.generateAsync({ type: "blob" }).then(async function (content) {
          const formData = new FormData();
          formData.append("bank_statement", content, "bank statement.zip");
          formData.append("loan_id", data.loan_request.loan_id); // Access loan_id from data.loan_request.loan_id

          try {
            const uploadResponse = await fetch(
              "https://www.smart-lend.com/api/loan/uploadBankStatement",
              {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + Cookies.get("token"),
                },
                body: formData,
              }
            );

            const uploadData = await uploadResponse.json(); // 'data' is renamed to 'uploadData'

            if (response.status === 200) {
              message.success("Loan application submitted successfully!");
              message.success("Bank statement uploaded successfully.");
            }
          } catch (error) {
            console.error("Error uploading bank statement:", error);
            message.error("Error submitted loan request");
            message.error("Error uploading bank statement");
          }

          if (response.status !== 200) {
            message.error(
              "Error submitted loan request, please sync your singpass "
            );
          }

          if (response.status === 200) {
            try {
              const response = await fetch(
                `https://www.smart-lend.com/api/profile/createActivityLog?description=You had create a loan application`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("token"),
                  },
                }
              );
              const data = await response.json();
              console.log("Offer created:", data);
            } catch (error) {
              console.error("Error creating offer:", error);
            }
          }

          console.log("Offer created:", data);
          setRedirectTo("/b-requested-loans"); // set the path to the state variable

          if (companyEmail || companyContactNo !== null) {
            try {
              const response = await fetch(
                `https://www.smart-lend.com/api/loan/updateApplicantDetail?company_email=${companyEmail}&company_contact=${companyContactNo}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("token"),
                  },
                }
              );
              const data = await response.json();
              message.success("Applicant Detail updated!");
              console.log("update applicant detail", data);
            } catch (error) {
              console.log("error to upadate applicant detail", error);
            }
          }
        });
      }
    } catch (error) {
      console.error("Error creating offer:", error);
      message.error("Error submitted loan request, please sync your singpass ");
    }
  };

  const handleNextTab = () => {
    if (activeTab === "Application Information") {
      setActiveTab("Financial Profile");
    }
    // if (activeTab === "Financial Profile") {
    //   setActiveTab("Related Personal Information");
    // }
    if (activeTab === "Financial Profile") {
      setActiveTab("Authorization");
    }
    // if (activeTab === "Related Personal Information") {
    //   setActiveTab("Authorization");
    // }
    // if (activeTab === "Tax Declaration") {
    //   setActiveTab("Loan Request");
    // }
    // if (activeTab === "Loan Request") {
    //   setActiveTab("Authorization");
    // }
  };

  const handleBackTab = () => {
    if (activeTab === "Financial Profile") {
      setActiveTab("Application Information");
    }
    // if (activeTab === "Related Personal Information") {
    //   setActiveTab("Financial Profile");
    // }
    // if (activeTab === "Tax Declaration") {
    //   setActiveTab("Related Personal Information");
    // }
    // if (activeTab === "Loan Request") {
    //   setActiveTab("Related Personal Information");
    // }
     // if (activeTab === "Authorization") {
    //   setActiveTab("Related Personal Information");
    // }
    if (activeTab === "Authorization") {
      setActiveTab("Financial Profile");
    }
  };

  useEffect(() => {
    if (data[0]?.borrower_name) {
      const nameParts = data[0]?.borrower_name.split(" ");

      if (nameParts.length === 2) {
        setFirstName(nameParts[0]);
        setLastName(nameParts[1]);
      }
    }
  }, [data]);

  const ApplicationInformation = (
    <>
      {screenWidth > 1200 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            padding: "2.5% 5%",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "50%" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              First Name
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={firstName}
                disabled={!!data[0]?.borrower_name}
                placeholder="First Name"
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Last Name
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={lastName}
                disabled={!!data[0]?.borrower_name}
                placeholder="Last Name"
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Residential Status
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.residential_status || residentialStatus}
                disabled={!!data[0]?.residential_status}
                placeholder="Residential Status"
                onChange={(event) => setResidentalStatus(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Nationality
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.nationality || nationality}
                disabled={!!data[0]?.nationality}
                placeholder="Nationality"
                onChange={(event) => setNationality(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Email
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.email || email}
                disabled={!!data[0]?.email}
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Contact No.
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                type="number"
                value={data[0]?.contact_no || contactNo}
                disabled={!!data[0]?.contact_no}
                placeholder="Contact No."
                onChange={(event) => {
                  // Prevent negative values and limit to 10 digits
                  const inputValue = event.target.value;
                  if (inputValue >= 0 && String(inputValue).length <= 10) {
                    setContactNo(inputValue);
                  }
                }}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Address
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.registered_address || registeredAddress}
                disabled={!!data[0]?.registered_address}
                placeholder="Registered Address"
                onChange={(event) => setRegisteredAddress(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Business Name
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={
                  data[0]?.registered_business_name || registeredBusinessName
                }
                disabled={!!data[0]?.registered_business_name}
                placeholder="Registered Business Name"
                onChange={(event) =>
                  setRegisteredBusinessName(event.target.value)
                }
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Country of Incorporation
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={
                  data[0]?.country_of_incorporation || countryOfIncorporation
                }
                disabled={!!data[0]?.country_of_incorporation}
                placeholder="Country of Incorporation"
                onChange={(event) =>
                  setCountryOfIncorporation(event.target.value)
                }
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registration Date
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.registration_date || registrationDate}
                disabled={!!data[0]?.registration_date}
                placeholder="Registration Date"
                onChange={(event) => setRegistrationDate(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              UEN
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.uen || UEN}
                disabled={!!data[0]?.uen}
                placeholder="UEN"
                onChange={(event) => setUEN(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Business Address
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={
                  data[0]?.registered_business_address ||
                  registeredBusinessAddress
                }
                disabled={!!data[0]?.registered_business_address}
                placeholder="Registered Business Address"
                onChange={(event) =>
                  setRegisteredBusinessAddress(event.target.value)
                }
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Company Contact No.
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                type="number"
                value={companyContactNo || data[0]?.company_contact}
                placeholder="Company Contact No."
                onChange={(event) => {
                  // Prevent negative values and limit to 10 digits
                  const inputValue = event.target.value;
                  if (inputValue >= 0 && String(inputValue).length <= 10) {
                    setCompanyContactNo(inputValue);
                  }
                }}
              />
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Company Email
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                type="email"
                value={companyEmail || data[0]?.company_email}
                placeholder="Company Email"
                onChange={(event) => setCompanyEmail(event.target.value)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            padding: "2.5% 5%",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              First Name
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={firstName}
                disabled={!!data[0]?.borrower_name}
                placeholder="First Name"
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Last Name
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={lastName}
                disabled={!!data[0]?.borrower_name}
                placeholder="Last Name"
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Residential Status
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.residential_status || residentialStatus}
                disabled={!!data[0]?.residential_status}
                placeholder="Residential Status"
                onChange={(event) => setResidentalStatus(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Nationality
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.nationality || nationality}
                disabled={!!data[0]?.nationality}
                placeholder="Nationality"
                onChange={(event) => setNationality(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Email
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.email || email}
                disabled={!!data[0]?.email}
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Contact No.
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                type="number"
                value={data[0]?.contact_no || contactNo}
                disabled={!!data[0]?.contact_no}
                placeholder="Contact No."
                onChange={(event) => {
                  // Prevent negative values and limit to 10 digits
                  const inputValue = event.target.value;
                  if (inputValue >= 0 && String(inputValue).length <= 10) {
                    setContactNo(inputValue);
                  }
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Address
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.registered_address || registeredAddress}
                disabled={!!data[0]?.registered_address}
                placeholder="Registered Address"
                onChange={(event) => setRegisteredAddress(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Business Name
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={
                  data[0]?.registered_business_name || registeredBusinessName
                }
                disabled={!!data[0]?.registered_business_name}
                placeholder="Registered Business Name"
                onChange={(event) =>
                  setRegisteredBusinessName(event.target.value)
                }
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Country of Incorporation
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={
                  data[0]?.country_of_incorporation || countryOfIncorporation
                }
                disabled={!!data[0]?.country_of_incorporation}
                placeholder="Country of Incorporation"
                onChange={(event) =>
                  setCountryOfIncorporation(event.target.value)
                }
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registration Date
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.registration_date || registrationDate}
                disabled={!!data[0]?.registration_date}
                placeholder="Registration Date"
                onChange={(event) => setRegistrationDate(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              UEN
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={data[0]?.uen || UEN}
                disabled={!!data[0]?.uen}
                placeholder="UEN"
                onChange={(event) => setUEN(event.target.value)}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Business Address
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                value={
                  data[0]?.registered_business_address ||
                  registeredBusinessAddress
                }
                disabled={!!data[0]?.registered_business_address}
                placeholder="Registered Business Address"
                onChange={(event) =>
                  setRegisteredBusinessAddress(event.target.value)
                }
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Company Contact No.
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                type="number"
                value={companyContactNo || data[0]?.company_contact}
                placeholder="Company Contact No."
                onChange={(event) => {
                  // Prevent negative values and limit to 10 digits
                  const inputValue = event.target.value;
                  if (inputValue >= 0 && String(inputValue).length <= 10) {
                    setCompanyContactNo(inputValue);
                  }
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Company Email
            </div>
            <div>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "12px",
                  marginTop: "5px",
                  outline: "none",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                type="email"
                value={companyEmail || data[0]?.company_email}
                placeholder="Company Email"
                onChange={(event) => setCompanyEmail(event.target.value)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );

  const FinancialProfile = (
    <>
      {screenWidth > 1200 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            padding: "2.5% 5%",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Please Upload Your Latest 6 Month Bank Statement
            </div>
          </div>

          <div style={{ width: "20%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              First Month:
            </div>
          </div>
          <div style={{ width: "80%", marginTop: "25px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              <Upload
                fileList={fileList}
                beforeUpload={(file) => {
                  setFileList([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "20%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Second Month:
            </div>
          </div>
          <div style={{ width: "80%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList2}
                beforeUpload={(file) => {
                  setFileList2([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList2([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "20%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Third Month:
            </div>
          </div>
          <div style={{ width: "80%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList3}
                beforeUpload={(file) => {
                  setFileList3([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList3([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "20%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Fourth Month:
            </div>
          </div>
          <div style={{ width: "80%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList4}
                beforeUpload={(file) => {
                  setFileList4([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList4([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "20%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Fifth Month:
            </div>
          </div>
          <div style={{ width: "80%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList5}
                beforeUpload={(file) => {
                  setFileList5([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList5([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "20%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Sixth Month:
            </div>
          </div>
          <div style={{ width: "80%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList6}
                beforeUpload={(file) => {
                  setFileList6([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList6([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            padding: "2.5% 5%",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Please Upload Your Latest 6 Month Bank Statement
            </div>
          </div>

          <div style={{ width: "50%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              First Month:
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              <Upload
                fileList={fileList}
                beforeUpload={(file) => {
                  setFileList([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Second Month:
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList2}
                beforeUpload={(file) => {
                  setFileList2([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList2([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Third Month:
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList3}
                beforeUpload={(file) => {
                  setFileList3([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList3([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Fourth Month:
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList4}
                beforeUpload={(file) => {
                  setFileList4([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList4([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Fifth Month:
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList5}
                beforeUpload={(file) => {
                  setFileList5([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList5([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Sixth Month:
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <div>
              <Upload
                fileList={fileList6}
                beforeUpload={(file) => {
                  setFileList6([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                    },
                  ]);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList6([]); // This assumes you're only handling one file at a time.
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      )}
    </>
  );

  // const RelatedPersonalInformation = (
  //   <>
  //     {screenWidth > 1200 ? (
  //       directorData && directorData.length > 0 ? (
  //         directorData.map((item, index) => (
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "space-between",
  //               width: "90%",
  //               padding: "2.5% 5%",
  //               flexWrap: "wrap",
  //               position: "relative",
  //             }}
  //             key={index}
  //           >
  //             <div style={{ width: "50%" }}>
  //               <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //                 Director Shareholder Name
  //               </div>
  //               <div>
  //                 <input
  //                   style={{
  //                     width: "90%",
  //                     padding: "8px",
  //                     borderRadius: "12px",
  //                     marginTop: "5px",
  //                     outline: "none",
  //                     border: "1px solid rgba(0,0,0,0.2)",
  //                   }}
  //                   type="text"
  //                   value={item.shareholder_entity_name || shareHolderName}
  //                   disabled={!!item.shareholder_entity_name}
  //                   placeholder="Director Shareholder Name"
  //                   onChange={(event) =>
  //                     setDirectorShareholderName(event.target.value)
  //                   }
  //                 />
  //               </div>
  //             </div>
  //             <div style={{ width: "50%" }}>
  //               <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //                 Director Shareholder Phone No.
  //               </div>
  //               <div>
  //                 <input
  //                   style={{
  //                     width: "90%",
  //                     padding: "8px",
  //                     borderRadius: "12px",
  //                     marginTop: "5px",
  //                     outline: "none",
  //                     border: "1px solid rgba(0,0,0,0.2)",
  //                   }}
  //                   type="number"
  //                   value={item.corppass_mobile_no || shareHolderContactNo}
  //                   disabled={!!item.corppass_mobile_no}
  //                   placeholder="Director Shareholder Phone No."
  //                   onChange={(event) =>
  //                     setDirectorShareholderPhoneNo(event.target.value)
  //                   }
  //                 />
  //               </div>
  //             </div>

  //             <div style={{ width: "50%", marginTop: "15px" }}>
  //               <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //                 Director Shareholder Email
  //               </div>
  //               <div>
  //                 <input
  //                   style={{
  //                     width: "90%",
  //                     padding: "8px",
  //                     borderRadius: "12px",
  //                     marginTop: "5px",
  //                     outline: "none",
  //                     border: "1px solid rgba(0,0,0,0.2)",
  //                   }}
  //                   type="email"
  //                   value={item.corppass_email || shareHolderEmail}
  //                   disabled={!!item.corppass_email}
  //                   placeholder="Director Shareholder Email"
  //                   onChange={(event) =>
  //                     setDirectorShareholderEmail(event.target.value)
  //                   }
  //                 />
  //               </div>
  //             </div>
  //             <div
  //               style={{
  //                 borderBottom: "1px solid grey",
  //                 width: "84%", // change this to adjust the length of the 'border'
  //                 position: "absolute",
  //                 bottom: 0,
  //                 left: 70, // or change 'right' to 'left' to move the 'border' to the left
  //               }}
  //             />
  //           </div>
  //         ))
  //       ) : (
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "space-between",
  //             width: "90%",
  //             padding: "2.5% 5%",
  //             flexWrap: "wrap",
  //           }}
  //         >
  //           <div style={{ width: "50%" }}>
  //             <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //               Director Shareholder Name
  //             </div>
  //             <div>
  //               <input
  //                 style={{
  //                   width: "90%",
  //                   padding: "8px",
  //                   borderRadius: "12px",
  //                   marginTop: "5px",
  //                   outline: "none",
  //                   border: "1px solid rgba(0,0,0,0.2)",
  //                 }}
  //                 type="text"
  //                 value={
  //                   directorData[0]?.shareholder_entity_name || shareHolderName
  //                 }
  //                 disabled={!!directorData[0]?.shareholder_entity_name}
  //                 placeholder="Director Shareholder Name"
  //                 onChange={(event) =>
  //                   setDirectorShareholderName(event.target.value)
  //                 }
  //               />
  //             </div>
  //           </div>
  //           <div style={{ width: "50%" }}>
  //             <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //               Director Shareholder Phone No.
  //             </div>
  //             <div>
  //               <input
  //                 style={{
  //                   width: "90%",
  //                   padding: "8px",
  //                   borderRadius: "12px",
  //                   marginTop: "5px",
  //                   outline: "none",
  //                   border: "1px solid rgba(0,0,0,0.2)",
  //                 }}
  //                 type="number"
  //                 value={
  //                   directorData[0]?.corppass_mobile_no || shareHolderContactNo
  //                 }
  //                 disabled={!!directorData[0]}
  //                 placeholder="Director Shareholder Phone No."
  //                 onChange={(event) =>
  //                   setDirectorShareholderPhoneNo(event.target.value)
  //                 }
  //               />
  //             </div>
  //           </div>
  //           <div style={{ width: "50%", marginTop: "15px" }}>
  //             <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //               Director Shareholder Email
  //             </div>
  //             <div>
  //               <input
  //                 style={{
  //                   width: "90%",
  //                   padding: "8px",
  //                   borderRadius: "12px",
  //                   marginTop: "5px",
  //                   outline: "none",
  //                   border: "1px solid rgba(0,0,0,0.2)",
  //                 }}
  //                 type="email"
  //                 value={directorData[0]?.corppass_email || shareHolderEmail}
  //                 disabled={!!directorData[0]}
  //                 placeholder="Director Shareholder Email"
  //                 onChange={(event) =>
  //                   setDirectorShareholderEmail(event.target.value)
  //                 }
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       )
  //     ) : directorData && directorData.length > 0 ? (
  //       directorData.map((item, index) => (
  //         <div
  //           style={{
  //             width: "90%",
  //             padding: "2.5% 5%",
  //           }}
  //           key={index}
  //         >
  //           <div style={{ width: "100%" }}>
  //             <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //               Director Shareholder Name
  //             </div>
  //             <div>
  //               <input
  //                 style={{
  //                   width: "90%",
  //                   padding: "8px",
  //                   borderRadius: "12px",
  //                   marginTop: "5px",
  //                   outline: "none",
  //                   border: "1px solid rgba(0,0,0,0.2)",
  //                 }}
  //                 type="text"
  //                 value={item.shareholder_entity_name || shareHolderName}
  //                 disabled={!!item.shareholder_entity_name}
  //                 placeholder="Director Shareholder Name"
  //                 onChange={(event) =>
  //                   setDirectorShareholderName(event.target.value)
  //                 }
  //               />
  //             </div>
  //           </div>
  //           <div style={{ width: "100%", marginTop: "10px" }}>
  //             <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //               Director Shareholder Phone No.
  //             </div>
  //             <div>
  //               <input
  //                 style={{
  //                   width: "90%",
  //                   padding: "8px",
  //                   borderRadius: "12px",
  //                   marginTop: "5px",
  //                   outline: "none",
  //                   border: "1px solid rgba(0,0,0,0.2)",
  //                 }}
  //                 type="number"
  //                 value={item.corppass_mobile_no || shareHolderContactNo}
  //                 disabled={!!item.corppass_mobile_no}
  //                 placeholder="Director Shareholder Phone No."
  //                 onChange={(event) =>
  //                   setDirectorShareholderPhoneNo(event.target.value)
  //                 }
  //               />
  //             </div>
  //           </div>

  //           <div style={{ width: "100%", marginTop: "10px" }}>
  //             <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //               Director Shareholder Email
  //             </div>
  //             <div>
  //               <input
  //                 style={{
  //                   width: "90%",
  //                   padding: "8px",
  //                   borderRadius: "12px",
  //                   marginTop: "5px",
  //                   outline: "none",
  //                   border: "1px solid rgba(0,0,0,0.2)",
  //                 }}
  //                 type="email"
  //                 value={item.corppass_email || shareHolderEmail}
  //                 disabled={!!item.corppass_email}
  //                 placeholder="Director Shareholder Email"
  //                 onChange={(event) =>
  //                   setDirectorShareholderEmail(event.target.value)
  //                 }
  //               />
  //             </div>
  //           </div>
  //           <div
  //             style={{
  //               borderBottom: "1px solid grey",
  //               width: "84%", // change this to adjust the length of the 'border'
  //               position: "absolute",
  //               bottom: 0,
  //               left: 70, // or change 'right' to 'left' to move the 'border' to the left
  //             }}
  //           />
  //         </div>
  //       ))
  //     ) : (
  //       <div
  //         style={{
  //           width: "90%",
  //           padding: "2.5% 5%",
  //         }}
  //       >
  //         <div style={{ width: "100%" }}>
  //           <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //             Director Shareholder Name
  //           </div>
  //           <div>
  //             <input
  //               style={{
  //                 width: "90%",
  //                 padding: "8px",
  //                 borderRadius: "12px",
  //                 marginTop: "5px",
  //                 outline: "none",
  //                 border: "1px solid rgba(0,0,0,0.2)",
  //               }}
  //               type="text"
  //               value={
  //                 directorData[0]?.shareholder_entity_name || shareHolderName
  //               }
  //               disabled={!!directorData[0]?.shareholder_entity_name}
  //               placeholder="Director Shareholder Name"
  //               onChange={(event) =>
  //                 setDirectorShareholderName(event.target.value)
  //               }
  //             />
  //           </div>
  //         </div>
  //         <div style={{ width: "100%", marginTop: "10px" }}>
  //           <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //             Director Shareholder Phone No.
  //           </div>
  //           <div>
  //             <input
  //               style={{
  //                 width: "90%",
  //                 padding: "8px",
  //                 borderRadius: "12px",
  //                 marginTop: "5px",
  //                 outline: "none",
  //                 border: "1px solid rgba(0,0,0,0.2)",
  //               }}
  //               type="number"
  //               value={
  //                 directorData[0]?.corppass_mobile_no || shareHolderContactNo
  //               }
  //               disabled={!!directorData[0]}
  //               placeholder="Director Shareholder Phone No."
  //               onChange={(event) =>
  //                 setDirectorShareholderPhoneNo(event.target.value)
  //               }
  //             />
  //           </div>
  //         </div>
  //         <div style={{ width: "100%", marginTop: "10px" }}>
  //           <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //             Director Shareholder Email
  //           </div>
  //           <div>
  //             <input
  //               style={{
  //                 width: "90%",
  //                 padding: "8px",
  //                 borderRadius: "12px",
  //                 marginTop: "5px",
  //                 outline: "none",
  //                 border: "1px solid rgba(0,0,0,0.2)",
  //               }}
  //               type="email"
  //               value={directorData[0]?.corppass_email || shareHolderEmail}
  //               disabled={!!directorData[0]}
  //               placeholder="Director Shareholder Email"
  //               onChange={(event) =>
  //                 setDirectorShareholderEmail(event.target.value)
  //               }
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </>
  // );

  // const TaxDeclaration = (
  //   <div
  //     style={{
  //       display: "flex",
  //       justifyContent: "space-between",
  //       width: "90%",
  //       padding: "2.5% 5%",
  //       flexWrap: "wrap",
  //     }}
  //   >
  //     <div style={{ width: "50%" }}>
  //       <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Label</div>
  //       <div>
  //         <input
  //           style={{
  //             width: "90%",
  //             padding: "8px",
  //             borderRadius: "12px",
  //             marginTop: "5px",
  //             outline: "none",
  //             border: "1px solid rgba(0,0,0,0.2)",
  //           }}
  //           placeholder="This is Tax Declaration form"
  //           onChange={(event) => console.log(event.target.value)}
  //         />
  //       </div>
  //     </div>
  //     <div style={{ width: "50%" }}>
  //       <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Label</div>
  //       <div>
  //         <input
  //           style={{
  //             width: "90%",
  //             padding: "8px",
  //             borderRadius: "12px",
  //             marginTop: "5px",
  //             outline: "none",
  //             border: "1px solid rgba(0,0,0,0.2)",
  //           }}
  //           placeholder="This is Tax Declaration form"
  //           onChange={(event) => console.log(event.target.value)}
  //         />
  //       </div>
  //     </div>
  //     <div style={{ width: "50%", marginTop: "20px" }}>
  //       <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Label</div>
  //       <div>
  //         <input
  //           style={{
  //             width: "90%",
  //             padding: "8px",
  //             borderRadius: "12px",
  //             marginTop: "5px",
  //             outline: "none",
  //             border: "1px solid rgba(0,0,0,0.2)",
  //           }}
  //           placeholder="This is Tax Declaration form"
  //           onChange={(event) => console.log(event.target.value)}
  //         />
  //       </div>
  //     </div>
  //     <div style={{ width: "50%", marginTop: "20px" }}>
  //       <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Label</div>
  //       <div>
  //         <input
  //           style={{
  //             width: "90%",
  //             padding: "8px",
  //             borderRadius: "12px",
  //             marginTop: "5px",
  //             outline: "none",
  //             border: "1px solid rgba(0,0,0,0.2)",
  //           }}
  //           placeholder="This is Tax Declaration form"
  //           onChange={(event) => console.log(event.target.value)}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );

  // const LoanRequest = (
  //   <div
  //     style={{
  //       display: "flex",
  //       justifyContent: "space-between",
  //       width: "90%",
  //       padding: "2.5% 5%",
  //       flexWrap: "wrap",
  //     }}
  //   >
  //     <div style={{ width: "50%" }}>
  //       <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
  //         Loan Request Label 1
  //       </div>
  //       <div>
  //         <input
  //           style={{
  //             width: "90%",
  //             padding: "8px",
  //             borderRadius: "12px",
  //             marginTop: "5px",
  //             outline: "none",
  //             border: "1px solid rgba(0,0,0,0.2)",
  //           }}
  //           placeholder="This is Loan Request form"
  //           onChange={(event) => console.log(event.target.value)}
  //         />
  //       </div>
  //     </div>
  //     <div style={{ width: "50%" }}>
  //       <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Label</div>
  //       <div>
  //         <input
  //           style={{
  //             width: "90%",
  //             padding: "8px",
  //             borderRadius: "12px",
  //             marginTop: "5px",
  //             outline: "none",
  //             border: "1px solid rgba(0,0,0,0.2)",
  //           }}
  //           placeholder="This is Loan Request form"
  //           onChange={(event) => console.log(event.target.value)}
  //         />
  //       </div>
  //     </div>
  //     <div style={{ width: "50%", marginTop: "20px" }}>
  //       <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Label</div>
  //       <div>
  //         <input
  //           style={{
  //             width: "90%",
  //             padding: "8px",
  //             borderRadius: "12px",
  //             marginTop: "5px",
  //             outline: "none",
  //             border: "1px solid rgba(0,0,0,0.2)",
  //           }}
  //           placeholder="This is Loan Request form"
  //           onChange={(event) => console.log(event.target.value)}
  //         />
  //       </div>
  //     </div>
  //     <div style={{ width: "50%", marginTop: "20px" }}>
  //       <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Label</div>
  //       <div>
  //         <input
  //           style={{
  //             width: "90%",
  //             padding: "8px",
  //             borderRadius: "12px",
  //             marginTop: "5px",
  //             outline: "none",
  //             border: "1px solid rgba(0,0,0,0.2)",
  //           }}
  //           placeholder="This is Loan Request form"
  //           onChange={(event) => console.log(event.target.value)}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );

  const Authorization = (
    <>
      {screenWidth > 1200 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "2.5% 5%",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                textAlign: "center",
                fontSize: "23px",
              }}
            >
              Please Review The Loan Application Details Before Confirming
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Application Information
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              First Name
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.borrower_name || firstName || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Last Name
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.borrower_name || lastName || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Residential Status
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.residential_status || residentialStatus || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Nationality
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.nationality || nationality || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Email
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.email || email || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Contact No.
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.contact_no || contactNo || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Address
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.registered_address || registeredAddress || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Business Name
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.registered_business_name ||
                registeredBusinessName ||
                "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Country of Incorporation
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.country_of_incorporation ||
                countryOfIncorporation ||
                "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registration Date
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.registration_date || registrationDate || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              UEN
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.uen || UEN || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Business Address
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.registered_business_address ||
                registeredBusinessAddress ||
                "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Company Contact No.
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.company_contact || companyContactNo || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Company Email
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.company_email || companyEmail || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Financial Profile
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ width: "50%", display: "flex" }}>
                <div style={{ width: "20%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    First Month:
                  </div>
                </div>
                <div style={{ width: "80%", marginTop: "25px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    <Upload
                      fileList={fileList}
                      beforeUpload={(file) => {
                        setFileList([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>

              <div style={{ width: "50%", display: "flex" }}>
                <div style={{ width: "20%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Second Month:
                  </div>
                </div>
                <div style={{ width: "80%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList2}
                      beforeUpload={(file) => {
                        setFileList2([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList2([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "50%", display: "flex" }}>
                <div style={{ width: "20%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Third Month:
                  </div>
                </div>
                <div style={{ width: "80%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList3}
                      beforeUpload={(file) => {
                        setFileList3([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList3([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "50%", display: "flex" }}>
                <div style={{ width: "20%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Fourth Month:
                  </div>
                </div>
                <div style={{ width: "80%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList4}
                      beforeUpload={(file) => {
                        setFileList4([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList4([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "50%", display: "flex" }}>
                <div style={{ width: "20%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Fifth Month:
                  </div>
                </div>
                <div style={{ width: "80%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList5}
                      beforeUpload={(file) => {
                        setFileList5([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList5([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "50%", display: "flex" }}>
                <div style={{ width: "20%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Sixth Month:
                  </div>
                </div>
                <div style={{ width: "80%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList6}
                      beforeUpload={(file) => {
                        setFileList6([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList6([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Related Personal Information
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ width: "50%", marginTop: "20px" }}>
                <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                  Director Shareholder Phone No.
                </div>
                <div
                  style={{
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {directorData[0]?.corppass_mobile_no ||
                    shareHolderContactNo ||
                    "-"}
                </div>
              </div>
              <div style={{ width: "50%", marginTop: "20px" }}>
                <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                  Director Shareholder Email
                </div>
                <div
                  style={{
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {directorData[0]?.corppass_email || shareHolderEmail || "-"}
                </div>
              </div>
            </div>
          </div> */}
          <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Loan Request
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Loan Type
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {loanType || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Loan Category
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {loanCategory || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Requesting Loan Amount
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {requestingLoanAmount || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Expecting Interest Rate
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {expectingInterestRate || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Loan Period
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {loanPeriod || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Request Principal
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {request_principal || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              {" "}
              Request Monthly Installment
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {request_monthly_installment || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Total Admin Fee By Month
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {total_admin_fee_by_month || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Total Admin Fee
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {total_admin_fee || "-"}
            </div>
          </div>
          <div style={{ width: "50%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Request Revenue
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {request_revenue || "-"}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            padding: "2.5% 5%",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Please Review The Loan Application Details Before Confirming
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Application Information
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              First Name
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.borrower_name || firstName || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Last Name
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.borrower_name || lastName || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Residential Status
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.residential_status || residentialStatus || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Nationality
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.nationality || nationality || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Email
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.email || email || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Contact No.
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.contact_no || contactNo || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Address
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.registered_address || registeredAddress || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Business Name
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.registered_business_name ||
                registeredBusinessName ||
                "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Country of Incorporation
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.country_of_incorporation ||
                countryOfIncorporation ||
                "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registration Date
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.registration_date || registrationDate || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              UEN
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.uen || UEN || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Registered Business Address
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.registered_business_address ||
                registeredBusinessAddress ||
                "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Company Contact No.
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.company_contact || companyContactNo || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Company Email
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {data[0]?.company_email || companyEmail || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Financial Profile
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    First Month:
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    <Upload
                      fileList={fileList}
                      beforeUpload={(file) => {
                        setFileList([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>

              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Second Month:
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList2}
                      beforeUpload={(file) => {
                        setFileList2([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList2([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Third Month:
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList3}
                      beforeUpload={(file) => {
                        setFileList3([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList3([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Fourth Month:
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList4}
                      beforeUpload={(file) => {
                        setFileList4([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList4([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Fifth Month:
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList5}
                      beforeUpload={(file) => {
                        setFileList5([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList5([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    Sixth Month:
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList6}
                      beforeUpload={(file) => {
                        setFileList6([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList6([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Related Personal Information
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <div style={{ width: "100%", marginTop: "20px" }}>
                <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                  Director Shareholder Phone No.
                </div>
                <div
                  style={{
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {directorData[0]?.corppass_mobile_no ||
                    shareHolderContactNo ||
                    "-"}
                </div>
              </div>
              <div style={{ width: "100%", marginTop: "10px" }}>
                <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                  Director Shareholder Email
                </div>
                <div
                  style={{
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {directorData[0]?.corppass_email || shareHolderEmail || "-"}
                </div>
              </div>
            </div>
          </div> */}
          <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Loan Request
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Loan Type
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {loanType || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Loan Category
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {loanCategory || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Requesting Loan Amount
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {requestingLoanAmount || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Expecting Interest Rate
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {expectingInterestRate || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Loan Period
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {loanPeriod || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Request Principal
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {request_principal || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              {" "}
              Request Monthly Installment
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {request_monthly_installment || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Total Admin Fee By Month
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {total_admin_fee_by_month || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Total Admin Fee
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {total_admin_fee || "-"}
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              Request Revenue
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "500",
                marginTop: "5px",
              }}
            >
              {request_revenue || "-"}
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflowX: "none",
        overflowY: "none",
      }}
    >
      <Modal
        footer={[
          <>
            {screenWidth > 1200 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleSubmit(companyContactNo, companyEmail)}
                >
                  Submit
                </Button>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleSubmit(companyContactNo, companyEmail)}
                >
                  Submit
                </Button>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            )}
          </>,
        ]}
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        Please click the submit button to confirm your application
      </Modal>
      <>
        {screenWidth > 1200 ? (
          <div
            style={{
              width: "14%",
              background: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              padding: "2%",
            }}
          >
            <div
              style={{ color: "#0a3659", fontWeight: "700", fontSize: "30px" }}
            >
              SmartLend
            </div>
            <div
              style={{
                marginTop: "30px",
                fontSize:
                  activeTab === "Business Particulars" ? "20px" : "18px",
                color:
                  activeTab === "Business Particulars"
                    ? "#196cb0"
                    : "rgba(0,0,0,0.6)",
                fontWeight: "600",
                transition: "all 0.2s ease-in-out",
              }}
            >
              Business Particulars
            </div>
            <div
              onClick={() => setActiveTab("Application Information")}
              style={{
                marginTop: "10px",
                fontSize:
                  activeTab === "Application Information" ? "16px" : "14px",
                color:
                  activeTab === "Application Information"
                    ? "#196cb0"
                    : "rgba(0,0,0,0.6)",
                fontWeight: "400",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              • Application Information
            </div>
            <div
              onClick={() => setActiveTab("Financial Profile")}
              style={{
                marginTop: "10px",
                fontSize: activeTab === "Financial Profile" ? "16px" : "14px",
                color:
                  activeTab === "Financial Profile"
                    ? "#196cb0"
                    : "rgba(0,0,0,0.6)",
                fontWeight: "400",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              • Financial Profle
            </div>
            {/* <div
              onClick={() => setActiveTab("Related Personal Information")}
              style={{
                marginTop: "10px",
                fontSize:
                  activeTab === "Related Personal Information"
                    ? "16px"
                    : "14px",
                color:
                  activeTab === "Related Personal Information"
                    ? "#196cb0"
                    : "rgba(0,0,0,0.6)",
                fontWeight: "400",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              • Related Personal Information
            </div> */}
            {/* <div
   onClick={() => setActiveTab("Tax Declaration")}
   style={{
     marginTop: "10px",
     fontSize: activeTab === "Tax Declaration" ? "16px" : "14px",
     color:
       activeTab === "Tax Declaration" ? "#196cb0" : "rgba(0,0,0,0.6)",
     fontWeight: "400",
     transition: "all 0.2s ease-in-out",
     cursor: "pointer",
   }}
 >
   • Tax Declaration
 </div> */}
            {/* <hr style={{ margin: "15px 0px 10px 0px", width: "80%" }} />
 <div
   onClick={() => setActiveTab("Loan Request")}
   style={{
     fontSize: activeTab === "Loan Request" ? "20px" : "18px",
     color: activeTab === "Loan Request" ? "#196cb0" : "rgba(0,0,0,0.6)",
     fontWeight: "600",
     transition: "all 0.2s ease-in-out",
     cursor: "pointer",
   }}
 >
   Loan Request
 </div> */}
            <hr style={{ margin: "15px 0px 10px 0px", width: "80%" }} />
            <div
              onClick={() => setActiveTab("Authorization")}
              style={{
                fontSize: activeTab === "Authorization" ? "20px" : "18px",
                color:
                  activeTab === "Authorization" ? "#196cb0" : "rgba(0,0,0,0.6)",
                fontWeight: "600",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              Authorization
            </div>
            <hr style={{ margin: "15px 0px 10px 0px", width: "80%" }} />
          </div>
        ) : (
          <div
            style={{
              width: "32%",
              background: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              padding: "2%",
            }}
          >
            <div
              style={{
                color: "#0a3659",
                fontWeight: "700",
                fontSize: "21px",
                marginTop: "50px",
              }}
            >
              SmartLend
            </div>
            <div
              style={{
                marginTop: "30px",
                fontSize:
                  activeTab === "Business Particulars" ? "20px" : "18px",
                color:
                  activeTab === "Business Particulars"
                    ? "#196cb0"
                    : "rgba(0,0,0,0.6)",
                fontWeight: "600",
                transition: "all 0.2s ease-in-out",
              }}
            >
              Business Particulars
            </div>
            <div
              onClick={() => setActiveTab("Application Information")}
              style={{
                marginTop: "10px",
                fontSize:
                  activeTab === "Application Information" ? "16px" : "14px",
                color:
                  activeTab === "Application Information"
                    ? "#196cb0"
                    : "rgba(0,0,0,0.6)",
                fontWeight: "400",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              • Application Information
            </div>
            <div
              onClick={() => setActiveTab("Financial Profile")}
              style={{
                marginTop: "10px",
                fontSize: activeTab === "Financial Profile" ? "16px" : "14px",
                color:
                  activeTab === "Financial Profile"
                    ? "#196cb0"
                    : "rgba(0,0,0,0.6)",
                fontWeight: "400",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              • Financial Profle
            </div>
            <div
              onClick={() => setActiveTab("Related Personal Information")}
              style={{
                marginTop: "10px",
                fontSize:
                  activeTab === "Related Personal Information"
                    ? "16px"
                    : "14px",
                color:
                  activeTab === "Related Personal Information"
                    ? "#196cb0"
                    : "rgba(0,0,0,0.6)",
                fontWeight: "400",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              • Related Personal Information
            </div>
            {/* <div
    onClick={() => setActiveTab("Tax Declaration")}
    style={{
      marginTop: "10px",
      fontSize: activeTab === "Tax Declaration" ? "16px" : "14px",
      color:
        activeTab === "Tax Declaration" ? "#196cb0" : "rgba(0,0,0,0.6)",
      fontWeight: "400",
      transition: "all 0.2s ease-in-out",
      cursor: "pointer",
    }}
  >
    • Tax Declaration
  </div> */}
            {/* <hr style={{ margin: "15px 0px 10px 0px", width: "80%" }} />
  <div
    onClick={() => setActiveTab("Loan Request")}
    style={{
      fontSize: activeTab === "Loan Request" ? "20px" : "18px",
      color: activeTab === "Loan Request" ? "#196cb0" : "rgba(0,0,0,0.6)",
      fontWeight: "600",
      transition: "all 0.2s ease-in-out",
      cursor: "pointer",
    }}
  >
    Loan Request
  </div> */}
            <hr style={{ margin: "15px 0px 10px 0px", width: "80%" }} />
            <div
              onClick={() => setActiveTab("Authorization")}
              style={{
                fontSize: activeTab === "Authorization" ? "20px" : "18px",
                color:
                  activeTab === "Authorization" ? "#196cb0" : "rgba(0,0,0,0.6)",
                fontWeight: "600",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              Authorization
            </div>
            <hr style={{ margin: "15px 0px 10px 0px", width: "80%" }} />
          </div>
        )}
      </>
      <>
        {screenWidth > 1200 ? (
          <div
            style={{
              width: "100%",
              background: "#d9eeff",
              overflowY: "hidden",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div style={{ position: "fixed", top: 10, right: 10 }}>
          <CloseIcon
            style={{
              fontSize: "50px",
              color: "rgba(0,0,0,0.5)",
              cursor: "pointer",
            }}
          />
        </div> */}
            <div
              style={{
                width: "80%",
                height: "90%",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  background: "white",
                  height: "5.5%",
                  width: "100%",
                  borderRadius: "15px 15px 0px 0px",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {activeTab === "Business Particulars" ? (
                    <FeedIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  ) : activeTab === "Application Information" ? (
                    <FeedIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  ) : activeTab === "Financial Profile" ? (
                    <RequestQuoteIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  ) : activeTab === "Related Personal Information" ? (
                    <AccountBoxIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  ) : (
                    // ) : activeTab === "Tax Declaration" ? (
                    //   <AccountBalanceIcon
                    //     style={{ marginRight: "10px", color: "#196cb0" }}
                    //   />
                    // ) : activeTab === "Loan Request" ? (
                    //   <RequestQuoteIcon
                    //     style={{ marginRight: "10px", color: "#196cb0" }}
                    //   />
                    <TaskAltIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  )}
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "rgba(0,0,0,0.6)",
                      marginBottom: "0",
                    }}
                  >
                    {activeTab}
                  </p>
                </div>
              </div>
              <div
                style={{
                  background: "white",
                  width: "100%",
                  height: "90%",
                  overflowY: "scroll",
                }}
              >
                {activeTab === "Application Information"
                  ? ApplicationInformation
                  : activeTab === "Financial Profile"
                  ? FinancialProfile
                  // : activeTab === "Related Personal Information"
                  // ? RelatedPersonalInformation
                  : // ) : activeTab === "Tax Declaration" ? (
                  //   TaxDeclaration
                  // ) : activeTab === "Loan Request" ? (
                  //   LoanRequest
                  activeTab === "Authorization"
                  ? Authorization
                  : null}
              </div>
              <div
                style={{
                  background: "white",
                  height: "5.5%",
                  width: "100%",
                  borderRadius: "0px 0px 15px 15px",
                  borderTop: "1px solid rgba(0,0,0,0.1)",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {activeTab !== "Application Information" && (
                  <div
                    onClick={() => handleBackTab(activeTab)}
                    style={{
                      background: "white",
                      color: "#196cb0",
                      border: "2px solid #196cb0",
                      padding: "5px 30px",
                      borderRadius: "20px",
                      marginRight: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Back
                  </div>
                )}
                <div
                  onClick={() =>
                    activeTab === "Authorization"
                      ? showModal()
                      : handleNextTab(activeTab)
                  }
                  style={{
                    background: "#196cb0",
                    color: "white",
                    border: "2px solid #196cb0",
                    padding: "5px 35px",
                    borderRadius: "20px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                >
                  {activeTab === "Authorization" ? "Submit" : "Next"}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              background: "#d9eeff",
              overflowY: "hidden",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div style={{ position: "fixed", top: 10, right: 10 }}>
        <CloseIcon
          style={{
            fontSize: "50px",
            color: "rgba(0,0,0,0.5)",
            cursor: "pointer",
          }}
        />
      </div> */}
            <div
              style={{
                width: "80%",
                height: "90%",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  background: "white",
                  height: "5.5%",
                  width: "100%",
                  borderRadius: "15px 15px 0px 0px",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {activeTab === "Business Particulars" ? (
                    <FeedIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  ) : activeTab === "Application Information" ? (
                    <FeedIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  ) : activeTab === "Financial Profile" ? (
                    <RequestQuoteIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  ) : activeTab === "Related Personal Information" ? (
                    <AccountBoxIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  ) : (
                    // ) : activeTab === "Tax Declaration" ? (
                    //   <AccountBalanceIcon
                    //     style={{ marginRight: "10px", color: "#196cb0" }}
                    //   />
                    // ) : activeTab === "Loan Request" ? (
                    //   <RequestQuoteIcon
                    //     style={{ marginRight: "10px", color: "#196cb0" }}
                    //   />
                    <TaskAltIcon
                      style={{ marginRight: "10px", color: "#196cb0" }}
                    />
                  )}
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "rgba(0,0,0,0.6)",
                      marginBottom: "0",
                    }}
                  >
                    {activeTab}
                  </p>
                </div>
              </div>
              <div
                style={{
                  background: "white",
                  width: "100%",
                  height: "90%",
                  overflowY: "scroll",
                }}
              >
                {activeTab === "Application Information"
                  ? ApplicationInformation
                  : activeTab === "Financial Profile"
                  ? FinancialProfile
                  // : activeTab === "Related Personal Information"
                  // ? RelatedPersonalInformation
                  : // ) : activeTab === "Tax Declaration" ? (
                  //   TaxDeclaration
                  // ) : activeTab === "Loan Request" ? (
                  //   LoanRequest
                  activeTab === "Authorization"
                  ? Authorization
                  : null}
              </div>
              <div
                style={{
                  background: "white",
                  height: "10.5%",
                  width: "100%",
                  borderRadius: "0px 0px 15px 15px",
                  borderTop: "1px solid rgba(0,0,0,0.1)",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {activeTab !== "Application Information" && (
                  <div
                    onClick={() => handleBackTab(activeTab)}
                    style={{
                      background: "white",
                      color: "#196cb0",
                      border: "2px solid #196cb0",
                      padding: "5px 20px",
                      borderRadius: "20px",
                      marginRight: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Back
                  </div>
                )}
                <div
                  onClick={() =>
                    activeTab === "Authorization"
                      ? showModal()
                      : handleNextTab(activeTab)
                  }
                  style={{
                    background: "#196cb0",
                    color: "white",
                    border: "2px solid #196cb0",
                    padding: "5px 20px",
                    borderRadius: "20px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                >
                  {activeTab === "Authorization" ? "Submit" : "Next"}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
export default ModalApplicationExpress;
