import { Card, Tabs, Button, Avatar, Modal } from "antd";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import BasicInformation from "./basic-information";
import Employment from "./employment";
import Property from "./property";
import EntityAddress from "./entity-address";
import EntityProfile from "./entity-profile";
import Grants from "./grants";
import PreviousUEN from "./previous-uen";
import Shareholders from "./shareholders";
import Capital from "./capital";
import FincanceHighlights from "./finance-highlights";
import Finance from "./finance";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import adspot_ico from "../../assets/img/businessman-receiving-money-after-contract-signing.png";

const onChange = (key) => {
  console.log(key);
};
const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { TabPane } = Tabs;
  const history = useHistory();
  const [dataActivityLog, setActivityLog] = useState([]);
  const singpass_sync = Cookies.get("singpass_sync");
  const operations = (
    <>
   {singpass_sync === 1 ? (
    <div>
 <Button onClick={(event) => handleSyncWithSingpass(event)}>
      Sync With Singpass
    </Button>
    </div>
     
    ) : (
      <div>
       
      </div>
    )}
    </>
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [singpassData, setSingpassData] = useState([]);
  const [urlCode, setUrlCode] = useState(null);
  const [sexDetail, setSexDetail] = useState(null);
  const [nric2, setNRIC2] = useState("");
  const [nric, setNRIC] = useState("");
  const [fullName2, setFullName2] = useState("");
  const [fullName, setFullName] = useState("");
  const [sex, setSex] = useState("");
  const [sex2, setSex2] = useState("");
  const [race, setRace] = useState("");
  const [race2, setRace2] = useState("");
  const [nationality, setNationality] = useState("");
  const [nationality2, setNationality2] = useState("");
  const [dob, setDOB] = useState("");
  const [dob2, setDOB2] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [maritalStatus2, setMaritalStatus2] = useState("");

  const [uen, setUEN] = useState("");
  const [uen2, setUEN2] = useState("");
  const [entityName, setEntityName] = useState("");
  const [entityName2, setEntityName2] = useState("");
  const [entityType, setEntityType] = useState("");
  const [entityType2, setEntityType2] = useState("");
  const [entityStatus, setEntityStatus] = useState("");
  const [entityStatus2, setEntityStatus2] = useState("");
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [registeredAddress2, setRegisteredAddress2] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [registrationDate2, setRegistrationDate2] = useState("");
  const [primarySSIC, setPrimarySSIC] = useState("");
  const [primarySSIC2, setPrimarySSIC2] = useState("");
  const [secondarySSIC, setSecondarySSIC] = useState("");
  const [secondarySSIC2, setSecondarySSIC2] = useState("");
  const [appointment1Position, setAppointment1Position] = useState("");
  const [appointment1Position2, setAppointment1Position2] = useState("");
  const [appointment1NRIC, setAppointment1NRIC] = useState("");
  const [appointment1NRIC2, setAppointment1NRIC2] = useState("");
  const [appointment1FullName, setAppointment1FullName] = useState("");
  const [appointment1FullName2, setAppointment1FullName2] = useState("");
  const [appointment1AppointmentDate, setAppointment1AppointmentDate] =
    useState("");
  const [appointment1AppointmentDate2, setAppointment1AppointmentDate2] =
    useState("");

  useEffect(() => {
    // Get current URL
    const currentURL = new URL(window.location.href);

    // Extract 'code' query parameter value
    const codeValue = currentURL.searchParams.get("code");

    if (codeValue) {
      setUrlCode(codeValue);
      setIsModalVisible(true);
    }

    // Define an async function for fetching data
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/singpass/createTokenRequest/${codeValue}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + Cookies.get('token'),
            },
          }
        );
        const json = await response.json();
        console.log("Response:", json);

        if (response.status === 200) {
          let accessToken = json.access_token; // Extract access_token from the response
          try {
            let item = {
              accessToken: accessToken,
            };
            const response2 = await fetch(
              `https://www.smart-lend.com/api/singpass/callEntityPersonAPI`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + Cookies.get('token'),
                },
                body: JSON.stringify(item),
              }
              // console.log("Response:", body);
            );
            const json2 = await response2.json();
            console.log("Response from second API:", json2);

            if (response2.status === 200) {
              let uen = json2.uen;
              let uuid = json2.uuid;
              let validToken = json2.validToken;
              try {
                let item2 = {
                  uen: uen,
                  uuid: uuid,
                  validToken: validToken,
                };
                const response3 = await fetch(
                  `https://www.smart-lend.com/api/singpass/getEntityPersonData`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": "Bearer " + Cookies.get('token'),
                    },
                    body: JSON.stringify(item2),
                  }
                );
                const json3 = await response3.text();
                console.log("Response from third API:", json3);

                if (response3.status == 200) {
                  let entitypersonData = json3;

                  try {
                    let item3 = {
                      entitypersonData: entitypersonData,
                    };
                    const response4 = await fetch(
                      `https://www.smart-lend.com/api/singpass/processEntityPersonData`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          "Authorization": "Bearer " + Cookies.get('token'),
                        },
                        body: JSON.stringify(item3),
                      }
                    );
                    const json4 = await response4.json();
                    setSingpassData([json4]);
                    console.log("Response from fourth API:", json4);
                    const personDetail = json4.person;
                    const entityDetail = json4.entity;
                    setSexDetail("Male");
                    // setPrincipalName(personDetail.name.value);
                    // setRace(personDetail.race.desc)
                    // setDOB(personDetail.dob.value);
                    // setNationality(personDetail.nationality.desc);
                    setNRIC2(personDetail.uinfin.value);
                    setFullName2(personDetail.name.value);
                    setSex2(personDetail.sex.desc);
                    setRace2(personDetail.race.desc);
                    setNationality2(personDetail.nationality.desc);
                    setDOB2(personDetail.dob.value);
                    setMaritalStatus2(personDetail.marital.desc);

                    setUEN2(entityDetail["basic-profile"].uen.value);
                    setEntityName2(
                      entityDetail["basic-profile"]["entity-name"].value
                    );
                    setEntityType2(
                      entityDetail["basic-profile"]["entity-type"].desc
                    );
                    setEntityStatus2(
                      entityDetail["basic-profile"]["entity-status"].value
                    );
                    setRegisteredAddress2(
                      entityDetail.addresses["addresses-list"].standard.desc
                    );
                    setRegistrationDate2(
                      entityDetail["basic-profile"]["registration-date"].value
                    );
                    setPrimarySSIC2(
                      entityDetail["basic-profile"]["primary-activity"].desc
                    );
                    setSecondarySSIC2(
                      entityDetail["basic-profile"]["secondary-activity"].desc
                    );
                    setAppointment1Position2(
                      entityDetail.appointments["appointments-list"].position
                        .desc
                    );
                    setAppointment1NRIC2(
                      entityDetail.appointments["appointments-list"][
                        "person-reference"
                      ].idno.value
                    );
                    setAppointment1FullName2(
                      entityDetail.appointments["appointments-list"][
                        "person-reference"
                      ]["person-name"].value
                    );
                    setAppointment1AppointmentDate2(
                      entityDetail.appointments["appointments-list"][
                        "appointment-date"
                      ].value
                    );

                    
                  } catch (err) {
                    console.log("err:", err);
                  }
                }
              } catch (err) {
                console.log("err:", err);
              }
            }
          } catch (err) {
            console.log("err:", err);
          }
        }
      } catch (err) {
        console.log("err:", err);
      }
    };

    // Call the async function
    fetchData();
  }, []); // Empty dependency array to ensure the effect runs only once on component mount

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = async () => {
    try {
      let item = {
        singpassData: singpassData,
      };
      const response = await fetch(
        `https://www.smart-lend.com/api/singpass/updateSingpassData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + Cookies.get('token'),
          },
          body: JSON.stringify(item), // Removed wrapping of item in an array
        }
      );
      const jsonSingpassData = await response.json();
      console.log("jsonSingpassData:", jsonSingpassData);
    } catch (err) {
      console.log("err handleSave:", err);
    }
  }

  const handleSyncWithSingpass = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/singpass/getAuthoriseApi?purpose=demonstrating MyInfo Business APIs&state=123&redirect=http://localhost:3001/callback`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      // Read the response as text since it's not JSON
      const url = await response.text();
      console.log("URL value: ", url);

      if (url.startsWith("http")) {
        // Simple check to ensure it looks like a URL
        window.location.href = url;
      }
    } catch (err) {
      console.error("Error fetching Singpass URL:", err);
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const processedData = await Promise.all(
          data.map(async (item) => {
            const profilePictureResponse = await fetch(
              `https://www.smart-lend.com/api/loan/loanRequest/viewListProfilePicture?profile_picture=${item.profile_picture}`,
              options
            );

            if (!profilePictureResponse.ok) {
              throw new Error("Failed to fetch profile picture data");
            }

            const blob = await profilePictureResponse.blob();
            const imageUrl = URL.createObjectURL(blob);

            return {
              ...item,
              icon: imageUrl, // use the blob URL as the icon
            };
          })
        );
        const reversedData = processedData.reverse();

        setActivityLog(reversedData);

        
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid",
        borderRadius: "20px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
        {icon ? (
          <img src={icon} className="profile-picture2" />
        ) : (
          <Avatar className="profile-picture2" icon={<UserOutlined />} />
        )}
        <div>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "20px",
              fontWeight: "600",
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "10px",
              fontWeight: "400",
              marginLeft: "10px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid none",
        borderRadius: "20px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <p
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "16px",
          color: "#142C44CC",
          textAlign: "center",
          paddingTop: "15px",
        }}
      >
        {noData}
      </p>
    </div>
  );

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: `Basic Information`,
      children: <BasicInformation singpassData={singpassData} />,
    },
    {
      key: "2",
      label: `Finance`,
      children: <Finance />,
    },
    {
      key: "3",
      label: `Employement`,
      children: <Employment />,
    },
    // {
    //   key: "4",
    //   label: `Property`,
    //   children: <Property />,
    // },
  ];

  const onChange2 = (key) => {
    console.log(key);
  };
  const items2 = [
    {
      key: "1",
      label: `Entity Profile`,
      children: <EntityProfile />,
    },
    {
      key: "2",
      label: `Entity Address`,
      children: <EntityAddress />,
    },
    // {
    //   key: "3",
    //   label: `Previous UEN`,
    //   children: <PreviousUEN />,
    // },
    {
      key: "3",
      label: `Shareholders`,
      children: <Shareholders />,
    },
    {
      key: "4",
      label: `Capital`,
      children: <Capital />,
    },
    // {
    //   key: "6",
    //   label: `Finance Highlights`,
    //   children: <FinanceHighlights />,
    // },
    // {
    //   key: "7",
    //   label: `Grants`,
    //   children: <Grants />,
    // },
  ];

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="4px 20px 20px 20px">
          <Modal
            width="60%"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%", // ensure the container takes up the full width
                }}
              >
                <Button type="primary" onClick={ (event) => handleSave(event)}>
                  Save
                </Button>
              </div>,
            ]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2> Form</h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5>Application pre-filled with SmartLend!</h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Confirm your details below and click "Save"</p>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  border: "1px solid black",
                  borderRadius: "20px",
                  flexDirection: "column",
                  padding: "10px 20px 10px 10px",
                  boxShadow: " 1px 1px 1px 1px grey ",
                  marginRight: "20px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <h5>Personal Information</h5>
                </div>
                <hr style={{ border: "1px solid grey", width: "100%" }} />
                <div style={{ width: "100%" }}>
                  NRIC
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={nric2 || nric}
                      onChange={(event) => setNRIC(event.target.value)}
                      disabled={!!nric2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Full Name
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={fullName2 || fullName}
                      onChange={(event) => setFullName(event.target.value)}
                      disabled={!!fullName2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Sex
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={sex2 || sex}
                      onChange={(event) => setSex(event.target.value)}
                      disabled={!!sex2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Race
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={race2 || race}
                      onChange={(event) => setRace(event.target.value)}
                      disabled={!!race2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Nationality
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={nationality2 || nationality}
                      onChange={(event) => setNationality(event.target.value)}
                      disabled={!!nationality2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Date Of Birth
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={dob2 || dob}
                      onChange={(event) => setDOB(event.target.value)}
                      disabled={!!dob2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Email
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      disabled={!!nationality2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Mobile Number
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      disabled={!!nationality2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Registered Address
                  <div>
                    <textarea
                      rows="2"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      disabled={!!nationality2}
                    ></textarea>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Housing Type
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      disabled={!!nationality2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Marital Status
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={maritalStatus2 || maritalStatus}
                      onChange={(event) => setMaritalStatus(event.target.value)}
                      disabled={!!maritalStatus2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Highest Education Level
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      disabled={!!nationality2}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  border: "1px solid black",
                  borderRadius: "20px",
                  flexDirection: "column",
                  padding: "10px 20px 10px 10px",
                 
                  boxShadow: " 1px 1px 1px 1px grey ",
                }}
              >
                <div style={{ width: "100%" }}>
                  <h5>Corporate Information</h5>
                </div>
                <hr style={{ border: "1px solid grey", width: "100%" }} />
                <div style={{ width: "100%" }}>
                  UEN
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={uen2 || uen}
                      onChange={(event) => setUEN(event.target.value)}
                      disabled={!!uen2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Entity Name
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={entityName2 || entityName}
                      onChange={(event) => setEntityName(event.target.value)}
                      disabled={!!entityName2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Entity Type
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={entityType2 || entityType}
                      onChange={(event) => setEntityType(event.target.value)}
                      disabled={!!entityType2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Entity Status
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={entityStatus2 || entityStatus}
                      onChange={(event) => setEntityStatus(event.target.value)}
                      disabled={!!entityStatus2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Registered Address
                  <div>
                    <textarea
                      rows="2"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={registeredAddress2 || registeredAddress}
                      onChange={(event) =>
                        setRegisteredAddress(event.target.value)
                      }
                      disabled={!!registeredAddress2}
                    ></textarea>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Registration Date
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={registrationDate2 || registrationDate}
                      onChange={(event) =>
                        setRegistrationDate(event.target.value)
                      }
                      disabled={!!registrationDate2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Primary SSIC
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={primarySSIC2 || primarySSIC}
                      onChange={(event) => setPrimarySSIC(event.target.value)}
                      disabled={!!primarySSIC2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Secondary SSIC
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={secondarySSIC2 || secondarySSIC}
                      onChange={(event) => setSecondarySSIC(event.target.value)}
                      disabled={!!secondarySSIC2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Appointment 1 - Position
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={appointment1Position2 || appointment1Position}
                      onChange={(event) =>
                        setAppointment1Position(event.target.value)
                      }
                      disabled={!!appointment1Position2}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Appointment 1 - NRIC
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={appointment1NRIC2 || appointment1NRIC}
                      onChange={(event) =>
                        setAppointment1NRIC(event.target.value)
                      }
                      disabled={!!appointment1NRIC}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Appointment 1 - Full Name
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={appointment1FullName2 || appointment1FullName}
                      onChange={(event) =>
                        setAppointment1FullName(event.target.value)
                      }
                      disabled={!!appointment1FullName}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  Appointment 1 - Appointment Date
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      value={
                        appointment1AppointmentDate2 ||
                        appointment1AppointmentDate
                      }
                      onChange={(event) =>
                        setAppointment1AppointmentDate(event.target.value)
                      }
                      disabled={!!appointment1AppointmentDate2}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <p>Your modal content here</p> */}
          </Modal>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            <Box
              gridColumn="span 9"
              gridRow="span 2"
              // overflow="auto"
              backgroundColor={colors.grey[910]}
              background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
              borderRadius="10px"
              border="1px solid "
              opacity="0.95"
              style={{
                overflowY: "scroll",
                minHeight: "89.7vh",
              }}
            >
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
                border-radius="20px"
                opacity="0.95"
              >
                <Card>
                  <Tabs tabBarExtraContent={operations}>
                    <TabPane type="card" tab="Personal" key="1">
                      <Tabs
                        defaultActiveKey="1"
                        items={items}
                        onChange={onChange}
                      />
                    </TabPane>
                    <TabPane type="card" tab="Business" key="2">
                      <Tabs
                        defaultActiveKey="1"
                        items={items2}
                        onChange={onChange2}
                      />
                    </TabPane>
                  </Tabs>
                </Card>
              </Box>
            </Box>
            <Box
              gridColumn="span 3"
              gridRow="span 2"
              backgroundColor="rgba(153, 178, 205, 0)"
              overflow="auto"
              minHeight="90.5vh"
            >
              {/* <Box
             display="flex"
             justifyContent="space-between"
             alignItems="center"
             // borderBottom={`4px solid ${colors.primary[500]}`}
             // colors={colors.grey[100]}
             marginTop="-17px"
             p="15px"
         >
             <Typography color={colors.grey[920]} variant="h5" fontWeight="800" fontFamily="Manrope, sans-serif" fontSize="20px">
                Ad Spot
             </Typography>
         </Box> */}
              <Box
                gridColumn="span 2"
                opacity="1.00"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="38%"
                position="relative" // ensure the Box is relatively positioned
                marginBottom="1.5vh"
              >
                <img
                  src={adspot_ico}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10%",
                    position: "absolute", // make the image positioned absolutely
                    top: "0",
                    left: "0",
                    zIndex: "1", // layer the image below the button
                  }}
                />

                {/* <Button
   style={{
     position: "absolute", // This makes the button float on top of the image
     top: "280px", // position it at the top of the box
     zIndex: "2", // layer the button above the image
     borderRadius:"20px",
     fontSize:"18px",
     fontWeight:"400",
     fontFamily:"Manrope, sans-serif",
    color:"#115CA6"
   }}
 >
  Apply Now
 </Button> */}
              </Box>
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                backgroundColor="rgba(153, 178, 205, 0)"
                overflow="auto"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // borderBottom={`4px solid ${colors.primary[500]}`}
                  colors={colors.grey[100]}
                  p="10px"
                >
                  <Typography
                    color={colors.grey[920]}
                    variant="h5"
                    fontWeight="800"
                    fontFamily="Manrope, sans-serif"
                    fontSize="20px"
                  >
                    Recent Activities
                  </Typography>
                </Box>
                {dataActivityLog && dataActivityLog.length > 0 ? (
                  dataActivityLog.slice(0, 6).map((item, index) => (
                    <Box
                      gridColumn="span 1"
                      // backgroundColor={colors.grey[910]}
                      display="flex"
                      alignItems="left"
                      justifyContent="left"
                      style={{
                        opacity: index === 4 ? 0.3 : index === 5 ? 0.2 : 1,
                        transitionDelay: `${index * 0.1}s`,
                      }}
                    >
                      <ContainerActivity
                        key={index}
                        icon={item.icon} // use the icon URL from the API
                        title={item.principal_name || "-"}
                        subtitle={item.description || "-"}
                        index={index}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    gridColumn="span 1"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                  >
                    <ContainerActivity2 noData="No Recent Activities Data" />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <div>
          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%", // ensure the container takes up the full width
                }}
              >
                <Button type="primary" onClick={ (event) => handleSave(event)}>
                  Save
                </Button>
              </div>,
            ]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2> Form</h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5>Application pre-filled with SmartLend!</h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Confirm your details below and click "Save"</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                border: "1px solid black",
                borderRadius: "20px",
                flexDirection: "column",
                padding: "10px 20px 10px 10px",
                boxShadow: " 1px 1px 1px 1px grey ",
              }}
            >
              <div style={{ width: "100%" }}>
                <h5>Personal Information</h5>
              </div>
              <hr style={{ border: "1px solid grey", width: "100%" }} />
              <div style={{ width: "100%" }}>
                NRIC
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={nric2 || nric}
                    onChange={(event) => setNRIC(event.target.value)}
                    disabled={!!nric2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Full Name
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={fullName2 || fullName}
                    onChange={(event) => setFullName(event.target.value)}
                    disabled={!!fullName2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Sex
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={sex2 || sex}
                    onChange={(event) => setSex(event.target.value)}
                    disabled={!!sex2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Race
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={race2 || race}
                    onChange={(event) => setRace(event.target.value)}
                    disabled={!!race2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Nationality
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={nationality2 || nationality}
                    onChange={(event) => setNationality(event.target.value)}
                    disabled={!!nationality2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Date Of Birth
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={dob2 || dob}
                    onChange={(event) => setDOB(event.target.value)}
                    disabled={!!dob2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Email
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={nationality2 || nationality}
                    onChange={(event) => setNationality(event.target.value)}
                    disabled={!!nationality2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Mobile Number
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={nationality2 || nationality}
                    onChange={(event) => setNationality(event.target.value)}
                    disabled={!!nationality2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Registered Address
                <div>
                  <textarea
                    rows="2"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={nationality2 || nationality}
                    onChange={(event) => setNationality(event.target.value)}
                    disabled={!!nationality2}
                  ></textarea>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Housing Type
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={nationality2 || nationality}
                    onChange={(event) => setNationality(event.target.value)}
                    disabled={!!nationality2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Marital Status
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={maritalStatus2 || maritalStatus}
                    onChange={(event) => setMaritalStatus(event.target.value)}
                    disabled={!!maritalStatus2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Highest Education Level
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={nationality2 || nationality}
                    onChange={(event) => setNationality(event.target.value)}
                    disabled={!!nationality2}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                border: "1px solid black",
                borderRadius: "20px",
                flexDirection: "column",
                padding: "10px 20px 10px 10px",
                marginTop: "10px",
                boxShadow: " 1px 1px 1px 1px grey ",
              }}
            >
              <div style={{ width: "100%" }}>
                <h5>Corporate Information</h5>
              </div>
              <hr style={{ border: "1px solid grey", width: "100%" }} />
              <div style={{ width: "100%" }}>
                UEN
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={uen2 || uen}
                    onChange={(event) => setUEN(event.target.value)}
                    disabled={!!uen2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Entity Name
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={entityName2 || entityName}
                    onChange={(event) => setEntityName(event.target.value)}
                    disabled={!!entityName2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Entity Type
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={entityType2 || entityType}
                    onChange={(event) => setEntityType(event.target.value)}
                    disabled={!!entityType2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Entity Status
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={entityStatus2 || entityStatus}
                    onChange={(event) => setEntityStatus(event.target.value)}
                    disabled={!!entityStatus2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Registered Address
                <div>
                  <textarea
                    rows="2"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={registeredAddress2 || registeredAddress}
                    onChange={(event) =>
                      setRegisteredAddress(event.target.value)
                    }
                    disabled={!!registeredAddress2}
                  ></textarea>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Registration Date
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={registrationDate2 || registrationDate}
                    onChange={(event) =>
                      setRegistrationDate(event.target.value)
                    }
                    disabled={!!registrationDate2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Primary SSIC
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={primarySSIC2 || primarySSIC}
                    onChange={(event) => setPrimarySSIC(event.target.value)}
                    disabled={!!primarySSIC2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Secondary SSIC
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={secondarySSIC2 || secondarySSIC}
                    onChange={(event) => setSecondarySSIC(event.target.value)}
                    disabled={!!secondarySSIC2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Appointment 1 - Position
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={appointment1Position2 || appointment1Position}
                    onChange={(event) =>
                      setAppointment1Position(event.target.value)
                    }
                    disabled={!!appointment1Position2}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Appointment 1 - NRIC
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={appointment1NRIC2 || appointment1NRIC}
                    onChange={(event) =>
                      setAppointment1NRIC(event.target.value)
                    }
                    disabled={!!appointment1NRIC}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Appointment 1 - Full Name
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={appointment1FullName2 || appointment1FullName}
                    onChange={(event) =>
                      setAppointment1FullName(event.target.value)
                    }
                    disabled={!!appointment1FullName}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                Appointment 1 - Appointment Date
                <div>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid grey",
                    }}
                    value={
                      appointment1AppointmentDate2 ||
                      appointment1AppointmentDate
                    }
                    onChange={(event) =>
                      setAppointment1AppointmentDate(event.target.value)
                    }
                    disabled={!!appointment1AppointmentDate2}
                  />
                </div>
              </div>
            </div>
            {/* <p>Your modal content here</p> */}
          </Modal>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              // padding: "10px 20px 0px 20px",
              minHeightheight: "89.7vh",
              borderRadius: "20px",
              width: "95%",
              backgroundColor: "#FFFFFFB3",
              marginLeft: "10px",
            }}
          >
            <Card>
              <Tabs
                style={{ marginTop: "-15px" }}
                tabBarExtraContent={operations}
              >
                <TabPane type="card" tab="Personal" key="1">
                  <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={onChange}
                  />
                </TabPane>
                <TabPane type="card" tab="Business" key="2">
                  <Tabs
                    defaultActiveKey="1"
                    items={items2}
                    onChange={onChange2}
                  />
                </TabPane>
              </Tabs>
              {/* <Button>
                     Retrieve MyInfo business with singpass
                 </Button> */}
            </Card>
          </div>
          <div opacity="1.00" display="flex" justifyContent="space-between">
            <div style={{ margin: "10px 10px 0px 10px" }}>
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
              >
                Recent Activities
              </Typography>
              <div>
                {dataActivityLog && dataActivityLog.length > 0 ? (
                  dataActivityLog.slice(0, 6).map((item, index) => (
                    <Box
                      gridColumn="span 1"
                      // backgroundColor={colors.grey[910]}
                      display="flex"
                      alignItems="left"
                      justifyContent="left"
                      style={{
                        opacity: index === 4 ? 0.3 : index === 5 ? 0.2 : 1,
                        transitionDelay: `${index * 0.1}s`,
                      }}
                    >
                      <ContainerActivity
                        key={index}
                        icon={item.icon} // use the icon URL from the API
                        title={item.principal_name || "-"}
                        subtitle={item.description || "-"}
                        index={index}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    gridColumn="span 1"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                  >
                    <ContainerActivity2 noData="No Activity Log Data" />
                  </Box>
                )}
              </div>
            </div>
            <div>
              <img
                src={adspot_ico}
                style={{
                  width: "95%",
                  height: "40%",
                  borderRadius: "10%",
                  marginTop: "10px",
                  paddingBottom: "20px",
                  margin: "10px 10px 0px 10px",
                  // position: "absolute",
                  // top: "0",
                  // left: "0",
                  // zIndex: "1",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
