import { useState, useEffect } from "react";
import { Form, Input, Button, Col, Row, Select } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";

const PreviousUEN = () => {
  const { Option } = Select;

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [previousUEN, setPreviousUEN] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        const entityDetail = displaySingpassData.singpass_data.entity;

        // Ensure that you are setting the array of shareholders
        if (
          entityDetail &&
          entityDetail["previous-uens"] &&
          Array.isArray(entityDetail["previous-uens"]["previous-uens-list"])
        ) {
          setPreviousUEN(entityDetail["previous-uens"]["previous-uens-list"]);
          console.log(
            "details",
            entityDetail["previous-uens"]["previous-uens-list"]
          );
        } else {
          // Handle the case where shareholders data is not an array or not available
          setPreviousUEN([]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Form
      style={{ minHeight: "73vh" }}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.List name="previousUEN">
        {(fields, { add, remove }) => (
          <>
            {previousUEN.length > 0 ? (
              previousUEN.map((item, index) => (
                <div key={index}>
                  <Row gutter={24}>
                    <Col span={22}>
                      <Form.Item label="Previous UEN's">
                        <Input
                          style={{ width: "100%" }}
                          value={item["previous-uen"]?.value || ""}
                          disabled={
                            !!item["previous-uen"]?.value ||
                            !item["previous-uen"]?.value
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        padding: "6px 40px",
                        border: "2px solid rgb(14, 90, 165)",
                        color: "rgb(14, 90, 165)",
                        fontWeight: 700,
                        borderRadius: "20px",
                      }}
                    >
                      Edit
                    </div>
                    <div
                      style={{
                        padding: "6px 40px",
                        border: "2px solid rgb(14, 90, 165)",
                        background: "rgb(14, 90, 165)",
                        color: "white",
                        fontWeight: 700,
                        borderRadius: "20px",
                      }}
                    >
                      Save
                    </div>
                  </div> */}
                </div>
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  padding: "0px 0px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "0px 15px",
                    background: "rgba(255,255,255,0.7)",
                    borderRadius: "10px",
                    height: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "0.8em",
                    fontWeight: "600",
                    color: "#00000066",
                  }}
                >
                  You have no previous-uens
                </div>
              </div>
            )}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default PreviousUEN;
