import { useState, useEffect } from "react";
import Cookies from "js-cookie";

const DashboardAds = () => {
  const [displayAds, setDisplayAds] = useState([]);
  const [adImage, setAdImage] = useState(null);
  const [adLink, setAdLink] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/ads/getAdsByScreen?screen=bDashboard",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setDisplayAds(data["ads"]);
        setAdImage(data.ads[0].image);
        setAdLink(data.ads[0].link);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (displayAds.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % displayAds.length);
      }, 3200);

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [displayAds]);

  useEffect(() => {
    if (displayAds.length > 0) {
      console.log(currentIndex);
      setAdImage(displayAds[currentIndex].image);
      setAdLink(displayAds[currentIndex].link);
    }
  }, [currentIndex, displayAds]);
  return (
    <img
      src={adImage}
      onClick={(e) => {
        e.preventDefault();
        if (adLink) {
          let url = adLink;

          // Check if adLink starts with http:// or https://
          if (!/^https?:\/\//i.test(adLink)) {
            url = `https://${adLink}`;
          }

          console.log(url);
          window.open(url, "_blank");
        }
      }}
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        position: "absolute", // make the image positioned absolutely
        top: "0",
        left: "0",
        zIndex: "1", // layer the image below the button
        objectFit: "cover",
        cursor: adLink ? "pointer" : "default",
      }}
    />
  );
};

export default DashboardAds;
