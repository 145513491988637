import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import cookies from "js-cookie";
import moment from "moment";

export default function Example({ dateRange }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/analytics/interestProfit",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("token"),
            },
          }
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  function combineData(amountReceived, dateRange) {
    // Create a new array of objects that includes both amount_receive and amount properties

    const combinedDataMap = new Map();

    amountReceived.forEach((item) => {
      const formattedDate = moment(item.date).format("YYYY-MM-DD");
      if (moment(formattedDate, "YYYY-MM-DD").isValid()) {
        if (!combinedDataMap.has(formattedDate)) {
          combinedDataMap.set(formattedDate, {
            date: formattedDate,
            repay_interest: 0,
          });
        }
        // Add the repay_amount to the existing combined data for the date
        combinedDataMap.get(formattedDate).repay_interest +=
          item.repay_interest;
      }
    });

    // Get the start and end dates from the dateRange prop
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");
    }

    // Convert the combinedDataMap to an array and filter it based on the date range
    const combinedDataArray = Array.from(combinedDataMap.values()).filter(
      (item) => {
        return moment(item.date).isBetween(startDate, endDate, "days", "[]");
      }
    );

    // Filter the combined data based on the date range
    const filteredData = combinedDataArray
      .filter((item) => {
        return moment(item.date).isBetween(startDate, endDate, "day", "[]");
      })
      .sort(
        (a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD")
      );

    // Sort the combined data by date
    filteredData.sort(
      (a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD")
    );

    return filteredData;
  }

  const customLabelAxisTickFormatter = (value) => {
    console.log(value);
    if (value) {
      const dateParts = value.split("-");
      if (dateParts.length === 3) {
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];

        // Map month numbers to month names (e.g., '09' => 'Sep')
        const monthNames = {
          "01": "January",
          "02": "Febuary",
          "03": "March",
          "04": "April",
          "05": "May",
          "06": "June",
          "07": "July",
          "08": "August",
          "09": "September",
          10: "October",
          11: "November",
          12: "December",
        };

        // Format the date (e.g., '19 Sep' or '30 Dec')
        const formattedDate = `${day} ${monthNames[month]}`;

        return formattedDate;
      }

      // Return the original value if it doesn't match the expected format
      return value;
    } else {
      return null;
    }
  };

  function formatNumberWithCommasAndDecimals(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      return "Invalid Number";
    }

    // Use toFixed(2) to round to two decimal places and convert to string
    const formattedNumber = number.toFixed(2);

    // Split the string at the decimal point
    const parts = formattedNumber.split(".");

    // Use regular expression to add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the integer and decimal parts back together with a period
    return parts.join(".");
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            background: "white",
            color: "rgba(0,0,0,0.4)",
            padding: "10px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {customLabelAxisTickFormatter(label)}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#142C44CC",
              fontSize: "13px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            {`$${formatNumberWithCommasAndDecimals(payload[0].value)}`}
          </div>
        </div>
      );
    }

    return null;
  };

  const customYAxisTickFormatter = (value) => {
    if (Math.abs(value) >= 1000000) {
      // Convert to millions (e.g., 3000000 => '3M')
      return (value / 1000000).toFixed(0) + "M";
    } else if (Math.abs(value) >= 1000) {
      // Convert to thousands (e.g., 7000 => '7K')
      return (value / 1000).toFixed(0) + "K";
    } else {
      // No conversion needed
      return value;
    }
  };

  const customXAxisTickFormatter = (value) => {
    if (value) {
      const dateParts = value.split("-");
      if (dateParts.length === 3) {
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];

        // Map month numbers to month names (e.g., '09' => 'Sep')
        const monthNames = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          10: "Oct",
          11: "Nov",
          12: "Dec",
        };

        // Format the date (e.g., '19 Sep' or '30 Dec')
        const formattedDate = `${monthNames[month]} ${day}`;

        return formattedDate;
      }

      // Return the original value if it doesn't match the expected format
      return value;
    } else {
      return null;
    }
  };

  if (data && data["Interest Profit"] && data["Interest Profit"].length !== 0) {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={combineData(data["Interest Profit"], dateRange)}
          margin={{
            left: -20,
            right: 20,
            top: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tick={{
              fontSize: "11px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              color: "rgba(0,0,0,0.4)",
            }}
            tickFormatter={customXAxisTickFormatter}
          />
          <YAxis
            tick={{
              fontSize: "11px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              color: "rgba(0,0,0,0.4)",
            }}
            tickFormatter={customYAxisTickFormatter}
          />
          <Line
            type="monotone"
            dataKey="repay_interest"
            stroke="#3ec258"
            dot={false}
            strokeWidth="2.5px"
          />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "lightgrey",
          textAlign: "center",
          color: "lightgrey",
        }}
      >
        No data to show.
      </div>
    );
  }
}
