import "./App.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import KeyIcon from "@mui/icons-material/Key";
import Cookies from "js-cookie";
import CBS2 from "../../../assets/img/CBS1.png";
import CBS3 from "../../../assets/img/CBS3.jpeg";
import CBS4 from "../../../assets/img/CBS4.jpeg";
import CBS1 from "../../../assets/img/CBS1.png";
import CBS5 from "../../../assets/img/CBS5.png";
import { CircularProgress } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message, Modal } from "antd";
import JSZip from "jszip";
import pdfToText from "react-pdftotext";

const DirectorAuthorization = () => {
  // const navigate = useHistory();
  const history = useHistory();
  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1080);
  const location = useLocation();
  const [directorName, setDirectorName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [screen, setScreen] = useState(0);

  const [promoCode, setPromoCode] = useState(null);
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(null);
  const [getStartedClicked, setGetStartedClicked] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [creditReportFile, setCreditReportFile] = useState([]);
  const [creditFile, setCreditFile] = useState(null);
  const [CBSImpLoan, setCBSImpLoan] = useState(null);
  const [monthlyCommitment, setMonthlyCommitment] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [enquiryDate, setEnquiryDate] = useState(null);
  const [chartMaxValue, setChartMaxValue] = useState(null);
  const [
    cBSImpLoanSupplementaryMonthlyInstallment,
    setCBSImpLoanSupplementaryMonthlyInstallment,
  ] = useState(null);

  const redirectToUrl = (path) => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    let redirectTo = path;

    if (code && state) {
      redirectTo += `?code=${code}&state=${state}`;
    }

    history.push(redirectTo);
  };

  const getPromoCode = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/apply_promo_code",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        setPromoCode(json.promo_code);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (screen === 2) {
      getPromoCode();
    }
  }, [screen]);

  function removeCharactersAroundPage(inputString) {
    // Regular expression to find the word "page" and capture the preceding and following characters
    const regex = /(.{0,19})page(.{0,7})/g; // Adjusted to capture more characters before "page"

    // Replace the matched segments with the word "page" alone
    const result = inputString.replace(regex, (match, p1, p2) => {
      // Determine the number of characters to remove from the beginning and the end
      const charsToRemoveBefore = 19 - 11; // 4 characters before the original 11
      const charsToRemoveAfter = 11 - 4;

      const removedStart =
        p1.length > charsToRemoveBefore
          ? p1.slice(0, -charsToRemoveBefore)
          : "";
      const removedEnd =
        p2.length > charsToRemoveAfter ? p2.slice(charsToRemoveAfter) : "";
      return `${removedStart}page${removedEnd}`;
    });

    return result;
  }

  function splitStringToRows(inputString) {
    // Use a regular expression to match exactly two spaces
    const rows = inputString.split(/(?<! ) {2}(?! )/);

    return rows;
  }

  function extractTextBetweeforloandetails(creditText, beforeText, afterText) {
    // Find the starting index of beforeText in the original text
    if (creditText !== null) {
      const startIndex = creditText.indexOf(beforeText);
      if (startIndex === -1) {
        return "Before text not found";
      }

      // Adjust the starting index to exclude the beforeText
      const adjustedStartIndex = startIndex + beforeText.length;

      // Find the ending index of afterText in the original text
      const endIndex = creditText.indexOf(afterText, adjustedStartIndex);
      if (endIndex === -1) {
        return "After text not found";
      }

      // Extract the text between beforeText and afterText
      const extractedText = creditText.substring(adjustedStartIndex, endIndex);

      return extractedText;
    }
  }

  function extractText(file) {
    pdfToText(file)
      .then((text) => setCreditFile(text))
      .catch((error) => {
        console.error("Failed to extract text from pdf");
      });
  }
  function extractTextBetween(beforeText, afterText) {
    // Find the starting index of beforeText in the original text
    if (creditFile !== null) {
      const startIndex = creditFile.indexOf(beforeText);
      if (startIndex === -1) {
        return "Before text not found";
      }

      // Adjust the starting index to exclude the beforeText
      const adjustedStartIndex = startIndex + beforeText.length;

      // Find the ending index of afterText in the original text
      const endIndex = creditFile.indexOf(afterText, adjustedStartIndex);
      if (endIndex === -1) {
        return "After text not found";
      }

      // Extract the text between beforeText and afterText
      const extractedText = creditFile.substring(adjustedStartIndex, endIndex);

      return extractedText;
    }
  }

  useEffect(() => {
    if (creditFile) {
      const textWithoutPage = removeCharactersAroundPage(
        creditFile.toLowerCase()
      ).replace(/page/g, "");

      const extract = extractTextBetweeforloandetails(
        textWithoutPage,
        "aggregated outstanding balances  month   product type   grantor bank   secured balances  unsecured balances interest bearing  unsecured balances non-interest bearing  exempted unsecured balances  ",
        "total"
      );

      const extract2ForMonthlyCommitment = extractTextBetweeforloandetails(
        textWithoutPage,
        "aggregated monthly instalment",
        "aggregated monthly instalments for preceding 5 months"
      );

      const extract3ForChartingOutstandingBalance =
        extractTextBetweeforloandetails(
          textWithoutPage,
          "aggregated outstanding balances for preceding 5 months",
          "aggregated monthly instalment"
        );

      const extract4ForChartingOutstandingBalance =
        extractTextBetweeforloandetails(
          textWithoutPage,
          "aggregated monthly instalment  month   product type   grantor bank   account type**   property***   non-property   unsecured  exempted unsecured  ",
          "aggregated monthly instalments for preceding 5 months"
        );

      const extractEnquiryDate = extractTextBetweeforloandetails(
        textWithoutPage,
        "enquiry date",
        "reference"
      );

      if (extractEnquiryDate !== "Before text not found") {
        setEnquiryDate(extractEnquiryDate)?.trim();
      }
      function parseData(inputString) {
        // Regular expression to match the date and the relevant value
        const regex =
          /([a-zA-Z]+\s\d{4})\s+all\s+all\s+[\d,]+\.\d{2}\s+([\d,]+\.\d{2})/g;
        const result = [];
        let match;

        // Loop through the matches and create the array of objects
        while ((match = regex.exec(inputString)) !== null) {
          const date = match[1];
          const value = match[2];
          result.push({ date, value });
        }

        return result;
      }
      function getMaxValue(data) {
        return data.reduce((max, current) => {
          // Parse the current value to a float for accurate comparison
          const currentValue = parseFloat(current.value.replace(/,/g, ""));
          return currentValue > max ? currentValue : max;
        }, 0);
      }
      function getNextMonth(data) {
        const monthNames = [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ];

        // Extract the first date from the data
        const firstDate = data?.[0]?.date;
        if (firstDate !== undefined) {
          const [firstMonth, firstYear] = firstDate.split(" ");
          const monthIndex = monthNames.indexOf(firstMonth.toLowerCase());
          const year = parseInt(firstYear, 10);

          // Calculate the next month and year
          const nextMonthIndex = (monthIndex + 1) % 12;
          const nextYear = monthIndex === 11 ? year + 1 : year;
          const nextMonth = monthNames[nextMonthIndex];

          return `${nextMonth} ${nextYear}`;
        }
      }

      let chartDate = parseData(extract3ForChartingOutstandingBalance);
      console.log(chartDate);
      if (chartDate && chartDate.length > 0) {
        chartDate.unshift({
          date: getNextMonth(parseData(extract3ForChartingOutstandingBalance)),
          value: extractTextBetween(
            "Total  ",
            " Aggregated Outstanding Balances for Preceding 5 Months"
          )
            ?.split("   ")[1]
            .trim(),
        });

        setChartData(chartDate.reverse());
        setChartMaxValue(getMaxValue(chartDate));
      }
      // console.log(parseData(extract3ForChartingOutstandingBalance));

      // console.log(
      //   splitStringToRows(extract).filter(
      //     (row) =>
      //       row.includes("private residential purchase") ||
      //       row.includes("motor vehicle loan") ||
      //       row.includes("hdb loan")
      //   )
      // );

      console.log(
        splitStringToRows(extract).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
      );
      const splitExactlyTwoSpaces = (inputString) => {
        // Split the string using the regular expression for exactly two spaces
        const splitArray = inputString.split(/(?<! )  (?! )/);
        return splitArray;
      };
      const combineSingleOrJoint = (splitArray) => {
        const combinedArray = [];

        for (let i = 0; i < splitArray.length; i++) {
          if (
            splitArray[i].startsWith("single") ||
            splitArray[i].startsWith("joint")
          ) {
            if (combinedArray.length > 0) {
              combinedArray[combinedArray.length - 1] += "  " + splitArray[i];
            } else {
              combinedArray.push(splitArray[i]);
            }
          } else {
            combinedArray.push(splitArray[i]);
          }
        }

        return combinedArray;
      };

      const combinePrivateResidentialPurchase = (splitArray) => {
        const combinedArray = [];

        for (let i = 0; i < splitArray.length; i++) {
          if (splitArray[i].includes("private residential purchase")) {
            combinedArray.push(
              splitArray[i] + "  " + (splitArray[i + 1] || "")
            );
            i++; // Skip the next element as it has been combined
          } else {
            combinedArray.push(splitArray[i]);
          }
        }

        return combinedArray;
      };
      console.log(
        combineSingleOrJoint(
          combinePrivateResidentialPurchase(
            splitExactlyTwoSpaces(extract4ForChartingOutstandingBalance)
          )
        ).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
      );

      setCBSImpLoanSupplementaryMonthlyInstallment(
        combineSingleOrJoint(
          combinePrivateResidentialPurchase(
            splitExactlyTwoSpaces(extract4ForChartingOutstandingBalance)
          )
        ).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
      );

      setMonthlyCommitment(
        parseFloat(
          extract2ForMonthlyCommitment
            .trim()
            .split("   ")
            [
              extract2ForMonthlyCommitment.trim().split("   ").length - 4
            ]?.replace(/,/g, "")
        ) +
          parseFloat(
            extract2ForMonthlyCommitment
              .trim()
              .split("   ")
              [
                extract2ForMonthlyCommitment.trim().split("   ").length - 3
              ]?.replace(/,/g, "")
          )
      );

      setCBSImpLoan(
        splitStringToRows(extract).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
      );
    }
  }, [creditFile]);

  const uploadDirectorCreditReport = async () => {
    const zip = new JSZip();

    // concatenate all file lists
    const allFiles = fileList;

    const fetchBlobAndReadAsBase64 = async (file) => {
      const response = await fetch(file.url);
      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer]);
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result.split(",")[1]); // Get base64 part of the data URL
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    };

    const addFilesToZip = async () => {
      for (const file of allFiles) {
        const fileContent = await fetchBlobAndReadAsBase64(file);
        zip.file(file.name, fileContent, { base64: true });
      }

      const content = await zip.generateAsync({ type: "blob" });
      return content;
    };

    addFilesToZip().then(async (content) => {
      const formData = new FormData();
      formData.append("credit_report", content, "credit report.zip");
      const creditReportData = {
        EnquiryDate:
          creditFile && creditFile !== "" && enquiryDate ? enquiryDate : null,
        SecuredCreditLimit:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Secured Credit Limit",
                  "Unsecured Credit Limit"
                ).replace(/,/g, "")
              )
            : null,
        UnsecuredCreditLimit:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Unsecured Credit Limit",
                  "Exempted Credit Limit"
                ).replace(/,/g, "")
              )
            : null,
        CreditScore:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "financial institution.  Score",
                  "Risk Grade"
                )
              )
            : null,
        RiskGrade:
          creditFile && creditFile !== ""
            ? extractTextBetween("Risk Grade ", "Risk Grade Description")
            : null,
        ProbabilityofDefault:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Probability of Default ",
                  " For more details on the description"
                ).replace(/,/g, "")
              )
            : null,
        SecuredCreditUtilized:
          creditFile && creditFile !== ""
            ? extractTextBetween(
                "Total  ",
                " Aggregated Outstanding Balances for Preceding 5 Months"
              )
                ?.split("  ")
                ?.map((num) => parseFloat(num.replace(/,/g, "")))[0]
            : null,
        UnsecuredCreditUtilized:
          creditFile && creditFile !== ""
            ? extractTextBetween(
                "Total  ",
                " Aggregated Outstanding Balances for Preceding 5 Months"
              )
                ?.split("  ")
                ?.map((num) => parseFloat(num.replace(/,/g, "")))[1]
            : null,
        SecuredCreditUtilizationinpercentage:
          creditFile && creditFile !== ""
            ? (
                (extractTextBetween(
                  "Total  ",
                  " Aggregated Outstanding Balances for Preceding 5 Months"
                )
                  ?.split("  ")
                  ?.map((num) => parseFloat(num.replace(/,/g, "")))[0] /
                  parseFloat(
                    extractTextBetween(
                      "Secured Credit Limit",
                      "Unsecured Credit Limit"
                    )?.replace(/,/g, "")
                  )) *
                100
              )?.toFixed(2)
            : null,
        UnsecuredCreditUtilizationinpercentage:
          creditFile && creditFile !== ""
            ? (
                (extractTextBetween(
                  "Total  ",
                  " Aggregated Outstanding Balances for Preceding 5 Months"
                )
                  ?.split("  ")
                  ?.map((num) => parseFloat(num.replace(/,/g, "")))[1] /
                  parseFloat(
                    extractTextBetween(
                      "Unsecured Credit Limit",
                      "Exempted Credit Limit"
                    )?.replace(/,/g, "")
                  )) *
                100
              )?.toFixed(2)
            : null,
        MonthlyCommitment:
          creditFile && creditFile !== "" && monthlyCommitment
            ? monthlyCommitment.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })
            : null,
        NoteworthyOngoingLoans:
          CBSImpLoan && CBSImpLoan.length !== 0 ? CBSImpLoan : null,
        Last6MonthsGraphChartMaxData:
          chartMaxValue && chartData ? chartMaxValue : null,
        Last6MonthsGraphChartData:
          chartMaxValue && chartData ? chartData : null,
        CBSImpLoanSupplementaryInstallment:
          cBSImpLoanSupplementaryMonthlyInstallment
            ? cBSImpLoanSupplementaryMonthlyInstallment
            : null,
      };
      formData.append("credit_report_data", JSON.stringify(creditReportData));
      formData.append("authorisation_id", Cookies.get("expressauth")); // Access loan_id from data.loan_request.loan_id

      try {
        const uploadResponse = await fetch(
          "https://www.smart-lend.com/api/singpass/directorUploadCreditReport",
          {
            method: "POST",
            body: formData,
          }
        );

        const json = await uploadResponse.json();
        console.log(json);
        if (uploadResponse.status === 200) {
          if (json.message === "Credit report uploaded successfully.") {
            setScreen(3);
          }
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const getAuthorisationDetails = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/singpass/getAuthorisationEntry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            authorisation_id: Cookies.get("expressauth"),
          }),
        }
      );

      // Read the response as text since it's not JSON
      const json = await response.json();
      if (response.status === 200) {
        if (
          json.authorisation_details.authorized === 0 &&
          json.authorisation_details.singpass_data === null
        ) {
          console.log("setting screen 1");
          setScreen(1);
          setDirectorName(json.authorisation_details.director_name);
          setCompanyName(json.authorisation_details.company_name);
        } else if (
          json.authorisation_details.credit_report === null &&
          json.authorisation_details.singpass_data !== null
        ) {
          console.log("setting screen 2");
          setScreen(2);
        } else {
          setScreen(3);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAuthorisationName = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/singpass/getAuthorisationEntry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            authorisation_id: Cookies.get("expressauth"),
          }),
        }
      );

      // Read the response as text since it's not JSON
      const json = await response.json();
      if (response.status === 200) {
        if (
          json.authorisation_details.authorized === 0 &&
          json.authorisation_details.singpass_data === null
        ) {
          console.log("setting screen 1");
          setScreen(1);
          setDirectorName(json.authorisation_details.director_name);
          setCompanyName(json.authorisation_details.company_name);
          return json.authorisation_details.director_name;
        } else if (
          json.authorisation_details.credit_report === null &&
          json.authorisation_details.singpass_data !== null
        ) {
          console.log("setting screen 2");
          setScreen(2);
        } else {
          setScreen(3);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (Cookies.get("expressauth")) {
      getAuthorisationDetails();
    } else {
      const searchParams = new URLSearchParams(location.search);
      const directorName = searchParams.get("name");
      const companyName = searchParams.get("companyname");

      if (directorName && companyName) {
        setScreen(1);
        setDirectorName(directorName);
        setCompanyName(companyName);
      }
    }
  }, [Cookies.get("expressauth"), location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const authorisationid = searchParams.get("authorisationid");
    if (authorisationid) {
      Cookies.set("expressauth", authorisationid, { expires: 7 });
      getAuthorisationDetails();
    }
  }, [location.search]);

  useEffect(() => {
    // Get current URL
    const currentURL = new URL(window.location.href);

    // Extract 'code' query parameter value
    const code = currentURL.searchParams.get("code");
    const state = currentURL.searchParams.get("state");
    const expressAuth = Cookies.get("expressauth");

    if (code && state && expressAuth) {
      // Define an async function for fetching data
      const fetchData = async () => {
        let json;

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/singpass/resolveDirectorTokens`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                code: code,
                state: state,
              }),
            }
          );
          const json = await response.json();
          console.log("Response:", json);

          const personDetail = json.person;
          const authorisationName = await getAuthorisationName(); // Assuming getAuthorisationName() is an async function

          console.log(personDetail?.name?.value === authorisationName);
          if (personDetail?.name?.value === authorisationName) {
            try {
              let item = {
                singpass_data: json,
                auhorisation_id: expressAuth,
              };
              const response = await fetch(
                `https://www.smart-lend.com/api/singpass/storeDirectorSingpassData`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(item),
                }
              );
              const json2 = await response.json();
              console.log("Response:", json2);
              if (
                json2.message === "Singpass data backup updated successfully"
              ) {
                const { pathname } = location;
                const newUrl = pathname + location.hash;
                history.replace(newUrl);
                setScreen(2);
              }
            } catch (error) {
              console.error(error);
            }
          } else {
            setScreen(4);
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSyncWithSingpass = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const authoid = searchParams.get("authorisationid");
      if (authoid) {
        Cookies.set("expressauth", authoid, { expires: 7 });
      }
      const response = await fetch(
        `https://www.smart-lend.com/api/singpass/getAuthoriseApiForDirectors`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      // Read the response as text since it's not JSON
      const url = await response.json();
      console.log("URL value: ", url.url);

      if (url.url.startsWith("http")) {
        // Simple check to ensure it looks like a URL
        window.location.href = url.url;
      }
    } catch (err) {
      console.error("Error fetching Singpass URL:", err);
    }
  };

  const [privacyBar, setPrivacybar] = useState(true);
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    setPageLoad(true);
    if (screenWidth > 1200) {
      setPrivacybar(true);
    }
  }, []);

  useEffect(() => {
    if (screenWidth < 1200) {
      setPrivacybar(false);
    }
  }, [screenWidth]);
  return (
    <div style={{ width: "100%", overflowX: "hidden", position: "relative" }}>
      <div
        style={{
          background: "white",
          position: "fixed",
          width: "100%",
          height: "40px",
          display: privacyBar ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: "49",
          background: "rgb(37, 97, 153)",
        }}
      >
        <div
          style={{
            marginLeft: "20px",
            color: "rgba(0,0,0,0.7)",
            fontSize: "14px",
            color: "white",
          }}
        >
          Information is collected in accordance with our Privacy Policy. By
          continuing you agree to our terms.
        </div>
        <CloseIcon
          style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
          onClick={() => setPrivacybar(false)}
        />
      </div>
      {/* Header bar */}
      <div
        style={{
          position: "fixed",
          background: "white",
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
          width: "100%",
          top: privacyBar ? "40px" : "0px",
          zIndex: "50",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "85%",
          }}
        >
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "35px",
                color: "#256199",
              }}
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              SmartLend.
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 400,
                  color: "rgba(0,0,0,0.6)",
                  marginLeft: 10,
                }}
              >
                Director Authorisation
              </span>
            </div>
          </div>
        </div>
      </div>

      {screen === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: screenWidth > 1200 ? "90px" : "150px",
            height: screenWidth > 1200 ? "80vh" : "auto",
          }}
        >
          <CircularProgress
            style={{ color: "rgb(37, 97, 153)" }}
            size={120}
            thickness={5} // Adjust thickness as needed
          />
        </div>
      ) : screen === 1 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: screenWidth > 1200 ? "90px" : "150px",
            height: screenWidth > 1200 ? "90vh" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "80%",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "100%",
                width: screenWidth > 1200 ? "40%" : "100%",
                textAlign: "left",
                marginLeft: pageLoad ? "0px" : "-300px",
                opacity: pageLoad ? "1" : "0",
                transition: "all 0.8s ease-in-out",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    border: "6px solid #256199",
                    borderRadius: 20,
                    width: "200px",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 30,
                    position: "relative",
                  }}
                >
                  <KeyIcon style={{ fontSize: 160, color: "#256199" }} />
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      bottom: -3,
                      transform: "translate(-50%, 50%)",
                      background: "white",
                      padding: "5px 4px",
                      fontWeight: "800",
                      fontSize: "17px",
                      color: "#256199",
                    }}
                  >
                    SmartLend
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "45px",
                    fontWeight: "700",
                    color: "rgba(0,0,0,0.8)",
                    lineHeight: "50px",
                  }}
                >
                  Welcome, {directorName}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "rgba(0,0,0,0.6)",
                  }}
                >
                  {companyName}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "rgba(0,0,0,0.8)",
                    lineHeight: "20px",
                    marginTop: "20px",
                  }}
                >
                  To allow the application of loans in our platform, we require
                  all shareholders of the company {companyName} to provide
                  authorisation before allowing the applied loan to be sent out
                  to all our lenders.
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="menu_button"
                    onClick={(event) => handleSyncWithSingpass(event)}
                    style={{
                      background: "#256199",
                      color: "white",
                      fontSize: "20px",
                      padding: "7px 35px",
                      borderRadius: "30px",
                      cursor: "pointer",
                    }}
                  >
                    Authenticate
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : screen === 2 ? (
        <div
          style={{
            marginTop: screenWidth > 1200 ? "90px" : "150px",
            height: screenWidth > 1200 ? "90vh" : "auto",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "45px",
                fontWeight: "700",
                color: "rgba(0,0,0,0.8)",
                lineHeight: "50px",
              }}
            >
              Let's fetch your Credit Report
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "rgba(0,0,0,0.8)",
                lineHeight: "20px",
                marginTop: "20px",
              }}
            >
              Smart-Lend provides every users a complementary retrieval of their
              credit report upon applying for a loan on Smart-Lend.
            </div>
            {promoCode ? (
              <div style={{ marginTop: 20, display: "flex" }}>
                <div
                  style={{
                    padding: "10px 45px",
                    borderRadius: "8px 0px 0px 8px",
                    border: "1px solid lightgrey",
                  }}
                >
                  {promoCode}
                </div>
                <div
                  onClick={() => {
                    const textToCopy = promoCode; // Replace with the text you want to copy
                    navigator.clipboard
                      .writeText(textToCopy)
                      .then(() => {
                        message.success("Promo code copied to clipboard!");
                      })
                      .catch((err) => {
                        console.error("Failed to copy text: ", err);
                      });
                  }}
                  style={{
                    background: "#196cb0",
                    color: "white",
                    borderRadius: "0px 8px 8px 0px",
                    padding: "10px 25px",
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                >
                  <ContentPasteIcon style={{ color: "white", fontSize: 24 }} />
                </div>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                width: "80%",
                gap: 20,
                textAlign: "center",
              }}
            >
              <div style={{ marginTop: 20, width: "25%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 1
                </div>
                <img
                  onClick={() => setIsPictureModalOpen("1")}
                  src={CBS1}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Copy your free CBS Report Code
                </div>
              </div>
              <div style={{ marginTop: 20, width: "25%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 2
                </div>
                <img
                  src={CBS2}
                  onClick={() => setIsPictureModalOpen("2")}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Sync with Singpass
                </div>
              </div>
              <div style={{ marginTop: 20, width: "25%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 3
                </div>
                <img
                  onClick={() => setIsPictureModalOpen("3")}
                  src={CBS3}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Paste the promo code
                </div>
              </div>
              <div style={{ marginTop: 20, width: "25%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 4
                </div>
                <img
                  onClick={() => setIsPictureModalOpen("4")}
                  src={CBS4}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Download your CBS report by clicking{" "}
                  <span style={{ fontWeight: 700 }}>"SAVE"</span>
                </div>
              </div>
              <div style={{ marginTop: 20, width: "25%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 5
                </div>
                <img
                  src={CBS5}
                  onClick={() => setIsPictureModalOpen("5")}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Click next and upload the report
                </div>
              </div>
            </div>
            {getStartedClicked ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBlock: 10,
                    color: "rgba(0,0,0,0.6)",
                    fontSize: 15,
                    marginTop: 40,
                  }}
                >
                  Upload Credit Report
                </div>
                <Upload
                  fileList={fileList}
                  beforeUpload={(file) => {
                    setFileList([
                      {
                        uid: "-1",
                        name: file.name,
                        status: "done",
                        url: URL.createObjectURL(file),
                      },
                    ]);
                    setCreditReportFile(file);
                    extractText(file);
                    return false;
                  }}
                  onPreview={async (file) => {
                    // Use window.open to open the file URL in a new tab
                    window.open(file.url);
                  }}
                  onRemove={(file) => {
                    setFileList([]); // This assumes you're only handling one file at a time.
                    setCreditReportFile(null);
                    setCreditFile(null);
                  }}
                >
                  <Button
                    style={{
                      width: "300px",
                      borderRadius: "10px",
                      marginBottom: "5px",
                    }}
                    icon={<UploadOutlined />}
                    // onClick={(event) => handleUpload( event)}
                  >
                    Upload
                  </Button>
                </Upload>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    uploadDirectorCreditReport();
                  }}
                  style={{
                    background: "#196cb0",
                    color: "white",
                    border: "2px solid #196cb0",
                    padding: "5px 35px",
                    borderRadius: "20px",
                    cursor: "pointer",
                    marginTop: 20,
                  }}
                >
                  Complete
                </button>
              </div>
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setGetStartedClicked(true);
                  window.open(
                    "https://www.creditbureau.com.sg/SmartLend_Financial.html",
                    "_blank"
                  );
                }}
                style={{
                  background: "#196cb0",
                  color: "white",
                  border: "2px solid #196cb0",
                  padding: "5px 35px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  marginTop: 20,
                }}
              >
                Get Started
              </button>
            )}
          </div>
        </div>
      ) : screen === 3 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: screenWidth > 1200 ? "90px" : "150px",
            height: screenWidth > 1200 ? "90vh" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "80%",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "100%",
                width: screenWidth > 1200 ? "40%" : "100%",
                textAlign: "left",
                marginLeft: pageLoad ? "0px" : "-300px",
                opacity: pageLoad ? "1" : "0",
                transition: "all 0.8s ease-in-out",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    border: "6px solid green",
                    borderRadius: 20,
                    width: "200px",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 30,
                    position: "relative",
                  }}
                >
                  <KeyIcon style={{ fontSize: 160, color: "green" }} />
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      bottom: -3,
                      transform: "translate(-50%, 50%)",
                      background: "white",
                      padding: "5px 4px",
                      fontWeight: "800",
                      fontSize: "17px",
                      color: "green",
                    }}
                  >
                    SmartLend
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "45px",
                    fontWeight: "700",
                    color: "rgba(0,0,0,0.8)",
                    lineHeight: "50px",
                  }}
                >
                  Authorisation Complete
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "rgba(0,0,0,0.8)",
                    lineHeight: "20px",
                    marginTop: "20px",
                  }}
                >
                  Your Authorisation process have been completed, feel free to
                  check out our homepage for more details about our other.
                  services
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="menu_button"
                    onClick={(event) => {
                      Cookies.remove("expressauth");
                      redirectToUrl("/home");
                    }}
                    style={{
                      background: "green",
                      color: "white",
                      fontSize: "20px",
                      padding: "7px 35px",
                      borderRadius: "30px",
                      cursor: "pointer",
                    }}
                  >
                    Return to hompage
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: screenWidth > 1200 ? "90px" : "150px",
            height: screenWidth > 1200 ? "90vh" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "80%",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "100%",
                width: screenWidth > 1200 ? "40%" : "100%",
                textAlign: "left",
                marginLeft: pageLoad ? "0px" : "-300px",
                opacity: pageLoad ? "1" : "0",
                transition: "all 0.8s ease-in-out",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    border: "6px solid red",
                    borderRadius: 20,
                    width: "200px",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 30,
                    position: "relative",
                  }}
                >
                  <KeyIcon style={{ fontSize: 160, color: "red" }} />
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      bottom: -3,
                      transform: "translate(-50%, 50%)",
                      background: "white",
                      padding: "5px 4px",
                      fontWeight: "800",
                      fontSize: "17px",
                      color: "red",
                    }}
                  >
                    SmartLend
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "45px",
                    fontWeight: "700",
                    color: "rgba(0,0,0,0.8)",
                    lineHeight: "50px",
                  }}
                >
                  Authorisation Failed
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "rgba(0,0,0,0.8)",
                    lineHeight: "20px",
                    marginTop: "20px",
                  }}
                >
                  Your Authorisation data does not match the required
                  information, please reach out to the user whome applied for
                  the loan to confirm your name and company name to ensure the
                  match is exact.
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="menu_button"
                    onClick={(event) => {
                      const { pathname } = location;
                      const newUrl = pathname + location.hash;
                      history.replace(newUrl);
                      setScreen(1);
                    }}
                    style={{
                      background: "red",
                      color: "white",
                      fontSize: "20px",
                      padding: "7px 35px",
                      borderRadius: "30px",
                      cursor: "pointer",
                    }}
                  >
                    Retry
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        footer={null}
        open={isPictureModalOpen === null ? false : true}
        onCancel={() => {
          setIsPictureModalOpen(null);
        }}
      >
        {isPictureModalOpen === "1" ? (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS1}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Copy your free CBS Report Promo Code
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Click on the copy icon located beside your free promo code and the
              promo code will automatically be copied into your clipboard.
            </div>
          </div>
        ) : isPictureModalOpen === "2" ? (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS2}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Sync with Singpass
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Follow the steps to provide authorization with your singpass
              account to allow for CBS report retreival.
            </div>
          </div>
        ) : isPictureModalOpen === "3" ? (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS3}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Paste the Promo Code in the field
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Paste the Promo Code that was provided to you earlier in the
              access code field to retreive your free CBS report.
            </div>
          </div>
        ) : isPictureModalOpen === "4" ? (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS4}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Download your CBS Report by Clicking "SAVE"
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Just hit the save button that is highlighted in the green box in
              the image above to download your CBS Report and you can then close
              this tab once the download has completed.
            </div>
          </div>
        ) : (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS5}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Click next and upload the report
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Click next at the bottom right of the screen and you'll be brought
              to the above page where you can upload the CBS Report to complete
              your application.
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};
export default DirectorAuthorization;
