import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Cookies from "js-cookie";
import moment from "moment";

export default function Example({ dateRange }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/analytics/countUser",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
          }
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  function processData(data, dateRange) {
    let usersData = [];
    let countBorrower = 0;
    let countLender = 0;

    // Check if dateRange is defined before trying to access it
    let startDate =
      dateRange && dateRange[0]
        ? dateRange[0].startOf("day").format("YYYY-MM-DD")
        : null;
    let endDate =
      dateRange && dateRange[1]
        ? dateRange[1].endOf("day").format("YYYY-MM-DD")
        : null;

    data[0].borrower.forEach((borrower) => {
      const createdAt = moment(borrower.created_at).format("YYYY-MM-DD");
      // Add a check for startDate and endDate before comparing
      if (
        startDate &&
        endDate &&
        moment(createdAt).isBetween(startDate, endDate, "days", "[]")
      ) {
        countBorrower++;
        usersData.push({
          date: createdAt,
          borrower: countBorrower,
          lender: countLender,
        });
      }
    });

    data[0].lender.forEach((lender) => {
      const createdAt = moment(lender.created_at).format("YYYY-MM-DD");
      // Add a check for startDate and endDate before comparing
      if (
        startDate &&
        endDate &&
        moment(createdAt).isBetween(startDate, endDate, "days", "[]")
      ) {
        countLender++;
        usersData.push({
          date: createdAt,
          borrower: countBorrower,
          lender: countLender,
        });
      }
    });

    // Sort the data by date
    usersData.sort((a, b) => moment(a.date) - moment(b.date));

    return usersData;
  }

  function formatNumberWithCommasAndDecimals(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      return "Invalid Number";
    }

    // Use toFixed(2) to round to two decimal places and convert to string
    const formattedNumber = number.toFixed(2);

    // Split the string at the decimal point
    const parts = formattedNumber.split(".");

    // Use regular expression to add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the integer and decimal parts back together with a period
    return parts.join(".");
  }

  const customLabelAxisTickFormatter = (value) => {
    if (value) {
      const dateParts = value.split("-");
      if (dateParts.length === 3) {
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];

        // Map month numbers to month names (e.g., '09' => 'Sep')
        const monthNames = {
          "01": "January",
          "02": "Febuary",
          "03": "March",
          "04": "April",
          "05": "May",
          "06": "June",
          "07": "July",
          "08": "August",
          "09": "September",
          10: "October",
          11: "November",
          12: "December",
        };

        // Format the date (e.g., '19 Sep' or '30 Dec')
        const formattedDate = `${day} ${monthNames[month]}`;

        return formattedDate;
      }

      // Return the original value if it doesn't match the expected format
      return value;
    } else {
      return null;
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            background: "white",
            color: "rgba(0,0,0,0.4)",
            padding: "10px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {customLabelAxisTickFormatter(label)}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#142C44CC",
              fontSize: "13px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
              width: "100%",
            }}
          >
            <div
              style={{
                background: "rgb(21, 87, 169, 0.4)",
                height: "7px",
                width: "7px",
                borderRadius: "10px",
                marginRight: "8px",
              }}
            ></div>
            {`${payload[1].value} users`}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#142C44CC",
              fontSize: "13px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
              width: "100%",
            }}
          >
            <div
              style={{
                background: "rgb(21, 87, 169)",
                height: "7px",
                width: "7px",
                borderRadius: "10px",
                marginRight: "8px",
              }}
            ></div>
            {`${payload[0].value} users`}
          </div>
        </div>
      );
    }

    return null;
  };
  function combineData(amountReceived, loanAmount, dateRange) {
    // Create a new array of objects that includes both amount_receive and amount properties
    // Create a new map to store combined data by date
    const combinedDataMap = new Map();

    // Process the amountReceived data
    amountReceived.forEach((item) => {
      const formattedDate = moment(item.date).format("YYYY-MM-DD");
      if (moment(formattedDate, "YYYY-MM-DD").isValid()) {
        if (!combinedDataMap.has(formattedDate)) {
          combinedDataMap.set(formattedDate, {
            date: formattedDate,
            repay_amount: 0,
            amount: 0,
          });
        }
        // Add the repay_amount to the existing combined data for the date
        combinedDataMap.get(formattedDate).repay_amount += item.repay_amount;
      }
    });

    // Process the loanAmount data
    loanAmount.forEach((item) => {
      const formattedDate = moment(item.date).format("YYYY-MM-DD");
      if (moment(formattedDate, "YYYY-MM-DD").isValid()) {
        if (!combinedDataMap.has(formattedDate)) {
          combinedDataMap.set(formattedDate, {
            date: formattedDate,
            repay_amount: 0,
            amount: 0,
          });
        }
        // Add the amount to the existing combined data for the date
        combinedDataMap.get(formattedDate).amount += item.amount;
      }
    });

    // Get the start and end dates from the dateRange prop
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");
    }

    // Convert the combinedDataMap to an array and filter it based on the date range
    const combinedDataArray = Array.from(combinedDataMap.values()).filter(
      (item) => {
        return moment(item.date).isBetween(startDate, endDate, "days", "[]");
      }
    );

    // Filter the combined data based on the date range
    const filteredData = combinedDataArray
      .filter((item) => {
        return moment(item.date).isBetween(startDate, endDate, "days", "[]");
      })
      .sort(
        (a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD")
      );

    console.log("combinedData before filtering", combinedDataArray); // Log the combinedData before filtering
    console.log("filteredData value", filteredData);
    // Sort the combined data by date
    filteredData.sort(
      (a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD")
    );

    return filteredData;
  }

  const customXAxisTickFormatter = (value) => {
    if (value) {
      const dateParts = value.split("-");
      if (dateParts.length === 3) {
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];

        // Map month numbers to month names (e.g., '09' => 'Sep')
        const monthNames = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          10: "Oct",
          11: "Nov",
          12: "Dec",
        };

        // Format the date (e.g., '19 Sep' or '30 Dec')
        const formattedDate = `${monthNames[month]} ${day}`;

        return formattedDate;
      }

      // Return the original value if it doesn't match the expected format
      return value;
    } else {
      return null;
    }
  };

  return data ? (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={500}
        height={300}
        data={processData(data, dateRange)}
        margin={{
          top: 20,
          right: 45,
          left: -10,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tick={{
            fontSize: "11px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            color: "rgba(0,0,0,0.4)",
          }}
          tickFormatter={customXAxisTickFormatter}
        />
        <YAxis
          tick={{
            fontSize: "11px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            color: "rgba(0,0,0,0.4)",
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="borrower"
          stroke="rgb(21, 87, 169, 0.4)"
          activeDot={{ r: 8 }}
          strokeWidth="2.5px"
        />
        <Line
          type="monotone"
          dataKey="lender"
          stroke="rgb(21, 87, 169)"
          strokeWidth="2.5px"
        />
      </LineChart>
    </ResponsiveContainer>
  ) : (
    <div>Loading...</div>
  );
}
