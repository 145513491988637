import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import TinyLineChart from "../../components/TinyLineChart";
import HalfPieChart from "../../components/HalfPieChart";
import { Modal, Avatar, Button, message } from "antd";
import Cookies from "js-cookie";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { UserOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import TablePendingAcceptance from "../../components/TablePendingAcceptance";
import defaultIcon from "../../assets/img/Profile.png";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/img/SL-01.png";
import boldFont from "../../assets/font/Poppins-SemiBold.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: boldFont, fontWeight: 700 }, // Bold (700)
  ],
});

const ContainerActivity2 = ({ noData }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "12.5px",
      border: "1px solid none",
      borderRadius: "20px",
      backgroundColor: "#FFFFFFB3",
      height: "60px",
    }}
  >
    <p
      style={{
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        fontSize: "16px",
        color: "#142C44CC",
        textAlign: "center",
        paddingTop: "15px",
      }}
    >
      {noData}
    </p>
  </div>
);

const getInitials = (name) => {
  const textToSplit = name.split(" ");
  const initials =
    textToSplit?.[0]?.[0].toUpperCase() +
    (textToSplit?.[1]?.[0].toUpperCase() || "");
  return initials;
};

const PendingAcceptance = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [data, setData] = useState([]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [offer_amount, setLoanAmount] = useState(0);
  const [type, setLoanType] = useState("simple");
  const [offer_interest_rate, setInterestRate] = useState(0);
  const [offer_processing_fee, setProcessingFee] = useState(0);
  const [total_admin_fee, setTotalAdminFee] = useState(0);
  const [admin_fee, setAdminFee] = useState(0);
  const [offer_period, setSelectedLoanPeriod] = useState(0);
  const [offer_principal, setLoanPrincipal] = useState(0);
  const [offer_monthly_installment, setExpectedMonthlyInstallment] =
    useState(0);
  const [offer_revenue, setGuaranteedRevenue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAcceptOfferOpen, setIsModalAcceptOfferOpen] = useState(false);
  const [offer_id, setOfferId] = useState(null);
  const [add_document_description, setAddDocumentDescription] = useState(null);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [documentDescriptions, setDocumentDescriptions] = useState([""]);
  const [refreshData, setRefreshData] = useState(false);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  const handleChange = (event, index) => {
    const newDescriptions = [...documentDescriptions];
    newDescriptions[index] = event.target.value;
    setDocumentDescriptions(newDescriptions);
  };

  const addMore = () => {
    setDocumentDescriptions([...documentDescriptions, ""]);
  };

  const remove = (indexToRemove) => {
    setDocumentDescriptions(
      documentDescriptions.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    let value = e.target.value;
    setInterestRate(value);
  };

  const handleLoanPeriodChange = (e) => {
    setSelectedLoanPeriod(e.target.value);
  };

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const showModal2 = (loanId) => {
    setModalStates2((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleDownloadAdditionalFile = async (offer_id, loan_id, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewApplicantDocument?offer_id=${offer_id}&loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      if (data && data.data) {
        window.open(data.data, "_blank"); // open the url in a new tab
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showModalRequestDocument = (offer_id, express) => {
    setIsModalOpen(true);
    setOfferId(offer_id);
    setDocumentDescriptions([""]);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalAcceptOffer = (offer_id, express) => {
    setIsModalAcceptOfferOpen(true);
    setOfferId(offer_id);
    setDocumentDescriptions([""]);
  };

  const handleAcceptOfferOk = () => {
    setIsModalAcceptOfferOpen(false);
  };

  const handleAcceptOfferCancel = () => {
    setIsModalAcceptOfferOpen(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const Container = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    label4,
    description4,
    index,
    loan_id,
    offer_id,
    label5,
    description5,
    createdAt,
    item,
  }) => {
    const shouldAddMarginTop = index > 3;
    const [hovered, setHovered] = useState(false);

    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "23.5%" : "calc(50% - 10px)",
            textAlign: "center",
            border: "none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: screenWidth > 1200 ? "40vh" : "auto",
            padding: "15px",
            position: "relative",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "55px",
                  height: "55px",
                  borderRadius: "50%",
                  background: "rgb(140,140,170)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "white",
                  fontSize: 20,
                  fontWeight: 600,
                  position: "relative",
                }}
                onClick={() => {
                  console.log(item);
                  setBadgeVerified(item.badge_verified);
                  const dataToPush = [
                    {
                      name: JSON.parse(item.singpass_data)?.person?.name?.value,
                      cbs_data: item.credit_report_data,
                    },
                  ];
                  for (const dat of item.loan_authorizations) {
                    dataToPush.push({
                      name: dat.director_name,
                      cbs_data: dat.credit_report_data,
                    });
                  }
                  console.log(dataToPush);
                  setAllCBSData(dataToPush);
                  singpassDetails(
                    item.borrower_id,
                    item.authorization_required,
                    item.loan_id,
                    item.keyman
                  );
                }}
              >
                {title ? getInitials(title) : "SL"}
                {item.badge_verified ? (
                  <div
                    className="tooltip-container"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "18px",
                      height: "18px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "rgb(14, 90, 165)",
                    }}
                  >
                    <LocalPoliceIcon
                      className="tooltip-target"
                      style={{ color: "white", fontSize: "14px" }}
                    />
                    <span className="tooltip-text">
                      Verified borrowers have undergone SmartLend's
                      authentication process, demonstrating legitimate business
                      operations. This includes providing evidence such as
                      current tenancy agreements, business premises signage, or
                      recent invoices matching their bank statements
                    </span>
                  </div>
                ) : null}
              </div>
              <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
                <h3
                  style={{
                    fontWeight: "700",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    color: "#142C44CC",
                    textAlign: "left",
                    margin: "0px",
                  }}
                >
                  {isMobile
                    ? title.length > 23
                      ? title.slice(0, 23) + "..."
                      : title
                    : title}
                </h3>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                    margin: "0px",
                  }}
                >
                  {item.badge_verified ? subtitle : null}
                </p>
              </div>
            </div>
            <div
              style={{
                textAlign: "left",
              }}
            >
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "10px 0px 0px 0px",
                }}
              >
                {label}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                  marginTop: "20px",
                }}
              >
                {label1}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description1}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {label4}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description4}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                Processing Fee
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {(item.processing_fee * 100).toFixed(1) + "%"}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {label2}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description2}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                Applied on
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {formatDate(createdAt)}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {label3}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 0px 0px",
                }}
              >
                {description3}
              </p>
              {description3 === "Rejected" ? (
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    fontSize: "16px",
                    color: "rgba(0,0,0,0.5)",
                    textAlign: "left",
                    margin: "-5px 0px 0px 0px",
                  }}
                >
                  {`on ${formatDate(item.updated_at)}`}
                </p>
              ) : null}

              {description3 === "Rejected" && item.reason_id !== null ? (
                <>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                      margin: "10px 0px 0px 0px",
                    }}
                  >
                    Rejected Reason
                  </p>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      fontSize: "16px",
                      color: "rgba(0,0,0,0.5)",
                      textAlign: "left",
                      margin: "0px 0px 10px 0px",
                    }}
                  >
                    {item.reason_id
                      ? item.reason_id === "0"
                        ? "I have a better rate from another lender"
                        : item.reason_id === "1"
                        ? "The offer doesnt meet my requirements"
                        : item.reason_id === "2"
                        ? "The rates are too high"
                        : item.reason_id === "3"
                        ? "I no longer need a loan"
                        : null
                      : null}
                  </p>
                </>
              ) : null}

              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "10px 0px 0px 0px",
                }}
              >
                {label5}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description5}
              </p>
            </div>
          </div>
          <div>
            {description3 === "Rejected" && (
              <div key={loan_id}>
                <button
                  className="all_button"
                  style={{
                    background: "transparent",
                    color: "grey",
                    width: "100%",
                    border: "2px solid lightgrey",
                    borderRadius: "50px",
                    padding: "4px 0px",
                    fontSize: "12px",
                    margin: "0px",
                  }}
                  onClick={(event) => showModal2(loan_id, event)}
                >
                  Reoffer Loan
                </button>
              </div>
            )}
            <button
              className="all_button"
              style={{
                background: "#1557a9",
                color: "white",
                width: "100%",
                border: "none",
                borderRadius: "50px",
                padding: "6px 0px",
                fontSize: "12px",
                margin: "10px 0px 0px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onMouseEnter={() => setHovered(true)} // set the state hovered to true  when mouse enter
              onMouseLeave={() => setHovered(false)} // set the state hovered to false  when mouse leave
              onClick={(event) => showModal(loan_id, event)}
            >
              View Offer
            </button>
            <button
              className="all_button"
              onClick={(event) => {
                event.preventDefault();
                const url = `/l-messages?principelname=${item.principal_name}&userid=${item.user_id}&requestamount=${item.offer_amount}`;
                history.push(url);
              }}
              style={{
                background: "transparent",
                color: "grey",
                width: "100%",
                border: "2px solid lightgrey",
                borderRadius: "15px",
                padding: "4px 20px",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              Message borrower
            </button>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewPendingAcceptance",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const parsedData = data["Pending Loan"].map((item) => {
          const updatedItem = {
            ...item,
            credit_report_data: JSON.parse(item.credit_report_data),
          };

          return updatedItem;
        });

        setData(parsedData);
        console.log("Fetched data:", data["Pending Loan"]); // to ensure data is fetched properly
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [refreshData]);

  useEffect(() => {
    const calculateLoan = () => {
      if (type === "simple") {
        const offer_monthly_installment =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            1.0 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            0.0 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
          0.0;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      } else if (type === "effective") {
        const offer_monthly_installment =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 *
            0.05 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = offer_monthly_installment * 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      }
    };
    calculateLoan();
  }, [
    offer_amount,
    offer_interest_rate,
    offer_period,
    type,
    offer_principal,
    offer_monthly_installment,
    offer_revenue,
    total_admin_fee,
    admin_fee,
  ]);

  useEffect(() => {
    const initialStates = {};
    const initialStates2 = {};
    data.forEach((item) => {
      initialStates[item.loan_id] = false;
      initialStates2[item.loan_id] = false;
    });
    setModalStates(initialStates);
    setModalStates2(initialStates2);
  }, [data]);

  const [modalStates, setModalStates] = useState({});
  const [modalStates2, setModalStates2] = useState({});

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const showModal = async (loanId, event) => {
    event.preventDefault();
    console.log("loanId modal", loanId);
    setModalStates((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));

    const handleClose2 = () => {
      setOpen2(false);
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/loanView?loan_id=${loanId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestMoreDocument = async (
    offer_id,
    add_document_description
  ) => {
    try {
      const filteredDescriptions = add_document_description.filter(
        (description) => description.trim() !== ""
      );

      const response = await fetch(
        `https://www.smart-lend.com/api/loan/requestAddDocument`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify({
            offer_id: offer_id,
            document_names: filteredDescriptions,
          }),
        }
      );
      const data = await response.json();

      if (response.status === 200) {
        message.success("Additional document request successfully!");
        setIsModalOpen(false);
        setRefreshData((prevState) => !prevState); // Change the state, triggering useEffect
      } else if (response.status === 400) {
        message.error("Documents with the same names already exist.");
      }
    } catch (error) {
      console.error("Error creating offer:", error);
      message.error("Error requesting additional document");
    }
  };

  const handleAllowAcceptOffer = async (offer_id) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/allowAcceptOffer?offer_id=${offer_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        message.success("Borrower had been allowed to accept the offer");
        setIsModalAcceptOfferOpen(false);
        setRefreshData((prevState) => !prevState); // Change the state, triggering useEffect
      }
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  const handleReoffer = async (
    loan_id,
    express,
    user_id,
    id,
    principal_name,
    borrower_id
  ) => {
    console.log("loanId value in handleReoffer", loan_id); // Debug statement
    console.log("user_id value in handleReoffer", id); // Debug statement
    // rest of the code
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/updateOffer/${id}?offer_amount=${parseFloat(
          parseFloat(offer_amount).toFixed(2)
        )}&offer_interest_rate=${parseFloat(
          parseFloat(offer_interest_rate / 100)
        )}&offer_period=${parseInt(
          offer_period
        )}&offer_monthly_installment=${parseFloat(
          parseFloat(offer_monthly_installment).toFixed(2)
        )}&offer_revenue=${parseFloat(
          parseFloat(offer_revenue).toFixed(2)
        )}&admin_fee=${parseFloat(
          parseFloat(admin_fee).toFixed(2)
        )}&total_admin_fee=${parseFloat(0)}&offer_principal=${parseFloat(
          parseFloat(offer_principal).toFixed(2)
        )}&processing_fee=${parseFloat(
          parseFloat(offer_processing_fee / 100).toFixed(2)
        )}&status=pending`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        message.success(data.message || "Reoffer make successfully."); // Display success message

        try {
          let item = {
            user_id: user_id.join(","),
            channel: "system",
            subject: "Lender offer",
            message: "Lender had make reoffer",
          };
          const response = await fetch(
            "https://www.smart-lend.com/api/user/notification/sendNotification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify(item),
            }
          );
          const data = await response.json();
          console.log("Reoffer created:", data);
        } catch (error) {
          console.error("Error creating reoffer:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLog`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify({
                user_id: borrower_id,
                user_name: principal_name,
                user_account_type: "borrower",
                description: `You've received a revised loan offer for your loan ${loan_id}.`,
              }),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + Cookies.get("token"));

        const options = {
          method: "GET",
          headers: headers,
        };

        try {
          const response = await fetch(
            "https://www.smart-lend.com/api/loan/viewPendingAcceptance",
            options
          );

          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }

          const data = await response.json();

          setData(data["Pending Loan"]);
          console.log("Fetched data:", data["Pending Loan"]); // to ensure data is fetched properly
        } catch (error) {
          console.error(error);
        }

        setModalStates2((prevStates) => ({
          ...prevStates,
          [loan_id]: false,
        }));
      } else if (response.status === 400) {
        message.error(data.message || "Unable to make reoffer loan."); // Display error message
      } else {
        message.error("An unexpected error has occurred."); // Handle other errors
      }
    } catch (error) {
      console.error("Error creating reoffer:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Reverse the order of the processedData
        const reversedData = data.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "7px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {title ? getInitials(title) : "SL"}
        </div>
        <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: screenWidth > 1200 ? "200px" : "160px",
        marginRight: "0px",
        borderRadius: "20px",
      }}
    >
      <TinyLineChart title="$12,000" subtitle="Net profit to date" />
    </div>
  );

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: screenWidth > 1200 ? "200px" : "160px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "20px",
      }}
    >
      <HalfPieChart title="$802,000" subtitle="Forecast collection" />
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [dppicture, setdppicture] = useState([]);

  const fillAllLenderPpicture = async (index, lenderId) => {
    // try {
    //   const response = await fetch(
    //     `https://www.smart-lend.com/api/user/getLenderPicture?lender_id=${lenderId}`,
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Cookies.get("token")}`,
    //       },
    //     }
    //   );
    //   const blob = await response.blob();
    //   const imageUrl = URL.createObjectURL(blob);
    //   setdppicture((prevDppicture) => {
    //     const updatedDppicture = [...prevDppicture]; // Create a copy of the array
    //     updatedDppicture[index] = { profilepicture: imageUrl }; // Update the specific index
    //     console.log(updatedDppicture);
    //     return updatedDppicture;
    //   });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      data

        .sort((a, b) => {
          if (sortBy === "loan-amount") {
            return a.offer_amount - b.offer_amount;
          } else if (sortBy === "date") {
            return new Date(a.created_at) - new Date(b.created_at);
          } else {
            // Default sorting option
            return new Date(b.created_at) - new Date(a.created_at);
          }
        })
        .map((item, index) => {
          if (item.status !== "accepted" && item.status !== "ongoing") {
            console.log(item);
            fillAllLenderPpicture(index, item.borrower_id);
          }
        });
    }
  }, [data, sortBy]);
  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [needOtherAuthoriser, setNeedOtherAuthoriser] = useState(false);
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [openDataIndex, setOpenDataIndex] = useState(null);
  const [keyman, setKeyman] = useState("");
  const [viewingUser, setViewingUser] = useState(0);
  const [viewingTab, setViewingTab] = useState(0);
  const [liveUserData, setLiveUserData] = useState(null);
  const [singpassData, setSingpassData] = useState(null);
  const [combinedSingpassData, setCombinedSingpassData] = useState([]);
  const [allCBSData, setAllCBSData] = useState([]);
  const [badgeVerified, setBadgeVerified] = useState(false);
  const [keymanDetails, setKeymanDetails] = useState({
    contact: "",
    email: "",
  });

  const availableTab = [
    { name: "Personal Information" },
    { name: "Corporate Information" },
    { name: "NOA History" },
    { name: "Capitals" },
    { name: "Financials" },
    { name: "Shareholders" },
    { name: "Borrower Report" },
  ];
  const singpassDetails = async (
    user,
    other_authorization,
    loan_id,
    keyman
  ) => {
    try {
      setKeyman(keyman);
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getBorrowerSingppass?user_id=${user}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      console.log(json);
      setKeymanDetails({
        contact: json.company_contact,
        email: json.company_email,
      });
      let allSingpassData = [json.singpass_data];
      setDataSingpassModal(true);
      if (other_authorization) {
        console.log("need other authorizer");
        setNeedOtherAuthoriser(true);
        try {
          const response2 = await fetch(
            `https://www.smart-lend.com/api/loanDetails/getLoanAuthorisers`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify({ loan_id: loan_id }),
            }
          );

          const json2 = await response2.json();
          setOtherAuthoriserDetails(json2.authorisers);
          console.log(JSON.parse(json2.authorisers[0].singpass_data));
          console.log(json.singpass_data);
          setLiveUserData(json.singpass_data);
          console.log(json2.authorisers);

          for (const single of json2.authorisers) {
            allSingpassData.push(JSON.parse(single.singpass_data));
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("Doesnt need other authoriser");
        setLiveUserData(json.singpass_data);
        setNeedOtherAuthoriser(false);
        setOtherAuthoriserDetails(null);
      }
      if (json.singpass_data) {
        setSingpassData(json.singpass_data);
        console.log(allSingpassData);
        setCombinedSingpassData(allSingpassData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getShareholderAddress = (data) => {
    const allSingpassData = [singpassData];
    if (otherAuthoriserDetails !== null && otherAuthoriserDetails.length > 0) {
      for (const single of otherAuthoriserDetails) {
        allSingpassData.push(JSON.parse(single.singpass_data));
      }
      const toPush = allSingpassData.filter(
        (sd) => sd?.person?.name?.value === data
      );
      // console.log(toPush);
      return toPush;
    } else {
      return allSingpassData;
    }
  };

  return (
    <Box m="4px 20px 20px 20px">
      <Modal
        width={isMobile ? "100%" : "60%"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={dataSingpassModal}
        onCancel={() => {
          setDataSingpassModal(false);
          setSingpassData(null);
          setOpenDataIndex(null);
          setKeyman("");
        }}
        footer={null}
      >
        {singpassData ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px",
                color: "rgba(20, 44, 68, 0.8)",
                fontWeight: 900,
                fontFamily: "Manrope, sans-serif",
                fontSize: 20,
              }}
            >
              <h2> Borrower information</h2>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  background: "rgba(14, 90, 165, 0.1)",
                  color: "rgba(20, 44, 68, 0.8)",
                  fontWeight: 800,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: 16,
                  padding: "3px 15px",
                  borderRadius: 7,
                }}
              >
                Loan keyman: {keyman ? keyman : ""}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderTop: "1px solid lightgrey",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 25,
                gap: "20px",
                padding: "10px 0px",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              <div
                className="all_button"
                style={{
                  cursor: "pointer",
                  color:
                    viewingUser === 0
                      ? "rgba(20, 44, 68, 0.8)"
                      : "rgba(0,0,0,0.4)",
                  fontSize: viewingUser === 0 ? "14px" : "12px",
                  fontWeight: viewingUser === 0 ? "800" : "400",
                  transition: "0.3s all ease-in-out",
                }}
                onClick={() => {
                  setViewingUser(0);
                  console.log(singpassData);
                  setLiveUserData(singpassData);
                }}
              >
                {singpassData?.person?.name?.value !== undefined
                  ? singpassData?.person?.name?.value
                  : null}
              </div>

              {otherAuthoriserDetails
                ? otherAuthoriserDetails.map((aut, index) => (
                    <div
                      className="all_button"
                      style={{
                        cursor: "pointer",
                        color:
                          viewingUser === index + 1
                            ? "rgba(20, 44, 68, 0.8)"
                            : "rgba(0,0,0,0.4)",
                        fontSize: viewingUser === index + 1 ? "14px" : "12px",
                        fontWeight: viewingUser === index + 1 ? "800" : "400",
                        transition: "0.3s all ease-in-out",
                      }}
                      onClick={() => {
                        setViewingUser(index + 1);
                        console.log(JSON.parse(aut.singpass_data));
                        setLiveUserData(JSON.parse(aut.singpass_data));
                      }}
                      key={index}
                    >
                      {aut.director_name}
                    </div>
                  ))
                : null}
            </div>
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "20px",
                padding: "10px 0px",
                width: "100%",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              {availableTab.map((view, index) => (
                <div
                  onClick={() => {
                    setViewingTab(index);
                  }}
                  className="all_button"
                  style={{
                    cursor: "pointer",
                    color:
                      viewingTab === index
                        ? "rgba(20, 44, 68, 0.8)"
                        : "rgba(0,0,0,0.4)",
                    fontSize: viewingTab === index ? "13px" : "12px",
                    fontWeight: viewingTab === index ? "700" : "400",
                    transition: "0.3s all ease-in-out",
                  }}
                  key={index}
                >
                  {view.name}
                </div>
              ))}
            </div>
            <div
              style={{
                padding: "15px 0px",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              {liveUserData ? (
                viewingTab === 0 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        NRIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          min={0}
                          // className={nricError ? "error-border" : ""}
                          value={liveUserData?.person?.uinfin?.value}
                        />
                        {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Full Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          value={liveUserData?.person?.name?.value}
                          // required
                        />
                        {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Sex
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.sex?.desc}
                          // required
                        />
                        {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Race
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.race?.desc}
                        />
                        {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Nationality
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.nationality?.desc}
                        />
                        {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Date of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.dob?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Country of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.birthcountry?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Residential Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.residentialstatus?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passtype?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passstatus?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Expiry Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.passexpirydate?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Ownership of Private Property Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.ownerprivate?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Email
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          type="email"
                          disabled
                          // required
                          value={liveUserData?.person?.email?.value}
                        />
                        {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Mobile Number
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="number"
                          // required
                          value={liveUserData?.person?.mobileno?.nbr?.value}
                        />
                        {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.person?.regadd
                              ? (liveUserData?.person?.regadd?.unit?.value !==
                                null
                                  ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.floor?.value !==
                                null
                                  ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.block?.value !==
                                null
                                  ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.street?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.street?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.postal?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.country?.desc !==
                                null
                                  ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Marital Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.marital?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Occupation
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.occupation?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employer's name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employment Sector
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.regadd
                              ? (liveUserData?.person?.regadd?.unit?.value !==
                                null
                                  ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.floor?.value !==
                                null
                                  ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.block?.value !==
                                null
                                  ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.street?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.street?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.postal?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.country?.desc !==
                                null
                                  ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Outstanding HDB Loan Balance
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbownership[0]?.outstandingloanbalance?.value?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Type of HDB Dwelling
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.hdbownership[0]?.hdbtype?.desc
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Monthly Loan Installment
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbownership[0]?.monthlyloaninstalment?.value?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Model
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.vehicles[0]?.model?.value
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Make
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.vehicles[0]?.make?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                  </div>
                ) : viewingTab === 1 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        UEN
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]?.uen?.value
                          }
                        />
                        {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-name"]
                              ?.value
                          }
                        />
                        {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-type"]
                              ?.value
                          }
                        />
                        {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "entity-status"
                            ]?.value
                          }
                        />
                        {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                            resize: "none",
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.entity?.addresses["addresses-list"][0]
                              ? (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.unit?.value !== undefined
                                  ? `Unit ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.floor?.value !== undefined
                                  ? `Floor ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.block?.value !== undefined
                                  ? `Block ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.street?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.postal?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.country?.desc !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registration Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "registration-date"
                            ]?.value
                          }
                        />
                        {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Primary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "primary-activity"
                            ]?.desc
                          }
                        />
                        {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Secondary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "secondary-activity"
                            ]?.desc
                          }
                        />
                        {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                      </div>
                    </div>
                    {liveUserData?.entity?.appointments[
                      "appointments-list"
                    ]?.map((appointment, index) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "2px 20px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Position
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={appointment.position.desc}
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"].idno.value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"].uen.value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"][
                                      "person-name"
                                    ].value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"][
                                      "entity-name"
                                    ].value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Appointment Date
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              value={appointment["appointment-date"].value}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : viewingTab === 2 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Employment
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Interest
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Rent
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Tax Clearance
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Trade
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Years of Assessment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.person?.noahistory?.noas?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.amount?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.employment?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.interest?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.rent?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.taxclearance?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.trade?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.yearofassessment?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 3 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Capital Type
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Issued Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Alloted Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.capitals["capitals-list"]?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["capital-type"]?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "issued-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "paid-up-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "share-allotted-amount"
                              ]?.value?.toLocaleString("en-US")}
                            </td>
                            {/* Add other data cells */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 4 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period End Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period Start Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Capital Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Is Audited
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.financials?.highlights?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-end-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-start-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["group-capital-paid-up-capital-amount"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["is-audited"]?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 5 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Allocation
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          NRIC
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.shareholders[
                        "shareholders-list"
                      ] ? (
                        liveUserData?.entity?.shareholders[
                          "shareholders-list"
                        ]?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.allocation?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["person-reference"]?.["person-name"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["person-reference"]?.idno?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].idno.value}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].nationality.desc}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"]["person-name"].value}</td> */}
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["share-type"]?.desc}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              textAlign: "center",
                              padding: "5px 10px",
                            }}
                            colSpan="11"
                          >
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                    <PDFViewer style={{ width: "100%", height: "80vh" }}>
                      <Document>
                        <Page size="A4">
                          {/**Letterhead section */}
                          <View
                            style={{
                              paddingVertical: 15,
                            }}
                          >
                            <View
                              style={{
                                paddingHorizontal: 40,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: 10,
                                // borderBottom: 1,
                                // borderBottomColor: "grey",
                                paddingBottom: 10,
                              }}
                            >
                              <View
                                style={{
                                  padding: 8,
                                  borderWidth: 3,
                                  borderColor: "#2881c4",
                                  borderRadius: "50%",
                                  marginTop: 15,
                                }}
                              >
                                <Image
                                  source={logo}
                                  style={{ width: 50, objectFit: "contain" }}
                                />
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 18,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  SmartLend Financial Pte. Ltd.
                                </Text>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 4,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: -3,
                                  }}
                                >
                                  <Text style={{ fontSize: 7 }}>
                                    UEN: 202327568G
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Tel: +65 88288147
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Email: support@smart-lend.com
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                backgroundColor: "lightgrey",
                                height: 2,
                                width: "100%",
                                marginTop: 20,
                              }}
                            />
                            <View
                              style={{
                                backgroundColor: "#2881c4",
                                height: 4,
                                width: "76%",
                                marginTop: -3,
                                marginLeft: "12%",
                                marginBottom: 15,
                              }}
                            />
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Company Profile
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  padding: 8,
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                }}
                              >
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Name:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "entity-name"
                                        ]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      UEN:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"]
                                          ?.uen?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Registration Date:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["registration-date"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Primary Activity:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "primary-activity"
                                        ]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Ownership:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["ownership"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Status:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["entity-status"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Constitution:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["company-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{ flexDirection: "row", gap: 10 }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Verification By Smartlend:
                                    </Text>
                                    <Text style={{ fontSize: 7 }}>
                                      {badgeVerified
                                        ? "Verified"
                                        : "Un-verified"}
                                    </Text>
                                  </View>
                                </View>
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      ACRA Address:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.addresses[
                                        "addresses-list"
                                      ][0]
                                        ? (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.unit?.value !== undefined
                                            ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.floor?.value !== undefined
                                            ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.block?.value !== undefined
                                            ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.street?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.postal?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.country?.desc !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                            : "")
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Capital Type:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {
                                        singpassData?.entity?.["capitals"]?.[
                                          "capitals-list"
                                        ]?.[0]?.["capital-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Paid Up Capital:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "paid-up-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Share Allocated Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "share-allotted-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Issued Capital Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "issued-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Email:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {getShareholderAddress(keyman)?.[0]
                                        ?.person?.email?.value !== undefined
                                        ? getShareholderAddress(keyman)?.[0]
                                            ?.person?.email?.value
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Mobile:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {getShareholderAddress(keyman)?.[0]
                                        ?.person?.mobileno?.nbr?.value !==
                                      undefined
                                        ? getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.prefix?.value +
                                          getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.areacode
                                            ?.value +
                                          "-" +
                                          getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.nbr?.value
                                        : ""}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Financial Highlight
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Revenue</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit pre-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit post-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Start date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>End date</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.financials?.highlights?.map(
                                  (data, index) => (
                                    <View
                                      key={index}
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor:
                                          index % 2
                                            ? "rgba(199, 220, 236, 0.2)"
                                            : "transparent",
                                        padding: 4,
                                      }}
                                    >
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-revenue"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-before-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-after-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-start-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-end-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                  )
                                )}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Grants
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Category</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Functional Area</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Status</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Approved Amount</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.grants?.[
                                  "grants-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["development-category"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["functional-area"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>{data?.["grant-type"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["grant-status"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.[
                                          "approved-amount"
                                        ]?.value?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Officer(S) / Owners
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Officer Name</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Position</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Appointment Date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.appointments?.[
                                  "appointments-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "25%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.["idno"]
                                            ?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>{data?.["position"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["appointment-date"]?.value}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Shareholders
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Shareholder Name / Address</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "18%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Share Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>No of Share</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Date of birth</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Corppass Contact</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.shareholders?.[
                                  "shareholders-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "25%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.[
                                          "person-name"
                                        ]?.value
                                          ? data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value +
                                            " (" +
                                            getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.sex?.code +
                                            ")"
                                          : data?.["entity-reference"]?.[
                                              "entity-name"
                                            ]?.value + " (Corporate)"}
                                      </Text>
                                      <Text>
                                        {getShareholderAddress(
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        )?.[0]?.person?.regadd
                                          ? (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.unit
                                              ?.value !== null
                                              ? `Unit ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.unit
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.floor
                                              ?.value !== null
                                              ? `Floor ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.floor
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.block
                                              ?.value !== null
                                              ? `Block ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.block
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.street
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.street
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.postal
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.postal
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.country
                                              ?.desc !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd
                                                    ?.country?.desc
                                                }.`
                                              : "")
                                          : ""}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.["idno"]
                                          ?.value
                                          ? data?.["person-reference"]?.["idno"]
                                              ?.value
                                          : data?.["entity-reference"]?.["uen"]
                                              ?.value + " (UEN)"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "18%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["share-type"]?.desc}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["allocation"]?.value}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "15%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.dob?.value
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.email?.value
                                        }
                                      </Text>
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.mobileno?.nbr?.value
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                          </View>
                          <View></View>
                        </Page>
                        {combinedSingpassData
                          ? combinedSingpassData.map((data, index) => (
                              <Page key={index} size="A4">
                                {/**Letterhead section */}
                                <View
                                  style={{
                                    paddingVertical: 15,
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Personal Information"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        padding: 8,
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                      }}
                                    >
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Ownership of Private Residential
                                            Property:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {data?.person?.hdbownership
                                              ? data?.person?.hdbownership
                                                  ?.length > 0
                                                ? "Yes"
                                                : "No"
                                              : "No"}
                                          </Text>
                                        </View>
                                        {data?.person?.hdbownership
                                          ? data?.person?.hdbownership?.length >
                                            0
                                            ? data?.person?.hdbownership?.map(
                                                (hdb, index3) => (
                                                  <View key={index3}>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                        marginTop: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB {index3 + 1}
                                                      </Text>
                                                    </View>

                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB Ownership:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.address
                                                          ? (hdb?.address?.unit
                                                              ?.value !== null
                                                              ? `Unit ${hdb?.address?.unit?.value}, `
                                                              : "") +
                                                            (hdb?.address?.floor
                                                              ?.value !== null
                                                              ? `Floor ${hdb?.address?.floor?.value}, `
                                                              : "") +
                                                            (hdb?.address?.block
                                                              ?.value !== null
                                                              ? `Block ${hdb?.address?.block?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.street
                                                              ?.value !== null
                                                              ? `${hdb?.address?.street?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.postal
                                                              ?.value !== null
                                                              ? `${hdb?.address?.postal?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.country
                                                              ?.desc !== null
                                                              ? `${hdb?.address?.country?.desc}.`
                                                              : "")
                                                          : ""}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Outstanding HDB Loan
                                                        Balance:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {hdb?.outstandingloanbalance?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Type of HDB:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.hdbtype?.desc}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Monthly Loan
                                                        Installment:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )
                                              )
                                            : "No"
                                          : "No"}
                                      </View>
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Contact:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.mobileno?.nbr?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Email:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.email?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Marital Status:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.marital?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Vehicles:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.vehicles?.length > 0
                                              ? data?.person?.vehicles?.map(
                                                  (veh, index4) => (
                                                    <Text
                                                      key={index4}
                                                      style={{
                                                        fontSize: 7,
                                                        width: "65%",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {veh?.make?.value +
                                                          ": " +
                                                          veh?.model?.value}
                                                      </Text>
                                                    </Text>
                                                  )
                                                )
                                              : "None"}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Notice of Assessments"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                        width: "100%",
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor: "#c7dcec",
                                          opacity: 0.7,
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Year of Assesment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Amount</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Employment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Interest</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Rent</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Trade</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Tax Clearance</Text>
                                        </View>
                                      </View>
                                      {data?.person?.noahistory?.noas?.map(
                                        (data2, index2) => (
                                          <View
                                            key={index2}
                                            style={{
                                              flexDirection: "row",
                                              width: "100%",
                                              backgroundColor:
                                                index2 % 2
                                                  ? "rgba(199, 220, 236, 0.2)"
                                                  : "transparent",
                                              padding: 4,
                                              gap: 10,
                                            }}
                                          >
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {
                                                  data2?.["yearofassessment"]
                                                    ?.value
                                                }
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "amount"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "employment"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "interest"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "rent"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "trade"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.["taxclearance"]?.value}
                                              </Text>
                                            </View>
                                          </View>
                                        )
                                      )}
                                    </View>
                                  </View>
                                  {allCBSData?.filter(
                                    (dat2) =>
                                      dat2?.name === data?.person?.name?.value
                                  )?.[0] ? (
                                    <View
                                      style={{
                                        marginTop: 15,
                                        paddingHorizontal: 40,
                                      }}
                                    >
                                      <View
                                        style={{
                                          backgroundColor: "#c7dcec",
                                          alignItems: "center",
                                          padding: 6,
                                          borderTopLeftRadius: 7,
                                          borderTopRightRadius: 7,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 10,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {data?.person?.name?.value +
                                            " | Credit Bureau | Personal Commitement"}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Credit Score:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.CreditScore
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Probability of Default:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.ProbabilityofDefault
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(Number(
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.UnsecuredCreditUtilizationinpercentage
                                              ) || 0) + "%"}
                                            </Text>
                                          </View>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Risk Grade:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.RiskGrade
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(Number(
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.SecuredCreditUtilizationinpercentage
                                              ) || 0) + "%"}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Monthly Commitment/Installment
                                              Secured:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(
                                                Number(
                                                  allCBSData?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]?.cbs_data
                                                    ?.MonthlyCommitment
                                                ) || 0
                                              )?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "SGD",
                                              })}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            backgroundColor: "#c7dcec",
                                            opacity: 0.7,
                                            padding: 4,
                                            gap: 10,
                                          }}
                                        >
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Loan / Product Type</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Grantor / Bank</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Outstanding Balance</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Monthly Installment</Text>
                                          </View>
                                        </View>

                                        {allCBSData
                                          ?.filter(
                                            (dat2) =>
                                              dat2?.name ===
                                              data?.person?.name?.value
                                          )?.[0]
                                          ?.cbs_data?.NoteworthyOngoingLoans?.map(
                                            (imp, index10) => (
                                              <View
                                                key={index10}
                                                style={{
                                                  flexDirection: "row",
                                                  width: "100%",
                                                  backgroundColor:
                                                    index10 % 2
                                                      ? "rgba(199, 220, 236, 0.4)"
                                                      : "rgba(199, 220, 236, 0.15)",
                                                  padding: 4,
                                                  gap: 10,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp.includes(
                                                      "private residential purchase",
                                                      ""
                                                    )
                                                      ? "Private Residential Purchase"
                                                      : imp.includes(
                                                          "motor vehicle loan",
                                                          ""
                                                        )
                                                      ? "Motor Vehicle Loan"
                                                      : imp.includes(
                                                          "hdb loan",
                                                          ""
                                                        )
                                                      ? "HDB Loan"
                                                      : ""}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp
                                                      .replace(
                                                        "private residential purchase",
                                                        ""
                                                      )
                                                      .replace(
                                                        "motor vehicle loan",
                                                        ""
                                                      )
                                                      .replace("hdb loan", "")
                                                      .trim()
                                                      .split(" ")[0]
                                                      .toUpperCase()}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {parseFloat(
                                                      imp
                                                        .split("   ")
                                                        [
                                                          imp.split("   ")
                                                            .length - 4
                                                        ].replace(/,/g, "")
                                                    ).toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency: "SGD",
                                                    })}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.CBSImpLoanSupplementaryInstallment?.[
                                                      index10
                                                    ]
                                                      ? (
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 4
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          ) +
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 3
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          )
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )
                                                      : null}
                                                  </Text>
                                                </View>
                                              </View>
                                            )
                                          )}
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                          borderBottomWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Last 6 Months (Unsecured) Credit
                                            Utilization Graph:
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "100%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <View
                                              style={{
                                                width: "5%",
                                                position: "relative",
                                              }}
                                            >
                                              <View style={{ height: "15px" }}>
                                                <Text
                                                  style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.Last6MonthsGraphChartMaxData?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{ height: "220px" }}
                                              ></View>
                                            </View>
                                            <View
                                              style={{
                                                width: "95%",
                                              }}
                                            >
                                              <View
                                                style={{
                                                  width: "100%",
                                                  flexDirection: "row",
                                                  marginTop: "25px",
                                                  borderBottom:
                                                    "1px solid lightgrey",
                                                  height: "200px",
                                                  alignItems: "flex-end",
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    height: "200px",
                                                    borderLeft:
                                                      "1px solid lightgrey",
                                                  }}
                                                />
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat30, index30) => (
                                                      <View
                                                        key={index30}
                                                        style={{
                                                          marginLeft: "4%",
                                                          marginRight: "4%",
                                                          width: "8%",
                                                          borderTopLeftRadius:
                                                            "4px",
                                                          borderTopRightRadius:
                                                            "4px",
                                                          height: `${
                                                            (parseFloat(
                                                              dat30.value.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                            ) /
                                                              allCBSData?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]?.cbs_data
                                                                ?.Last6MonthsGraphChartMaxData) *
                                                            100
                                                          }%`,
                                                          backgroundColor:
                                                            "rgba(217, 238, 255, 1)",
                                                        }}
                                                      ></View>
                                                    )
                                                  )}
                                              </View>
                                              <View
                                                style={{
                                                  marginTop: "8px",
                                                  flexDirection: "row",
                                                  fontSize: 7,
                                                }}
                                              >
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat, index) => (
                                                      <View
                                                        key={index}
                                                        style={{
                                                          width: "16%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {dat.date
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            dat.date.slice(1)}
                                                        </Text>
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {parseFloat(
                                                            dat.value.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ).toLocaleString(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "SGD",
                                                            }
                                                          )}
                                                        </Text>
                                                      </View>
                                                    )
                                                  )}
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  ) : null}
                                </View>
                              </Page>
                            ))
                          : null}
                      </Document>
                    </PDFViewer>
                  </div>
                )
              ) : null}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Modal>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn={isMobile ? "span 12" : "span 9"}
          gridRow="span 2"
          borderRadius="10px"
          opacity="0.95"
          paddingBottom="100px"
          style={{
            overflowY: "scroll",
            height: isMobile ? "80vh" : "90vh",
            overflowX: "hidden",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px 15px 50px 15px",
          }}
        >
          <Box gridColumn="span 4" gridRow="span 2" opacity="0.95">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "100%" }}
              borderRadius="10px"
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Pending Acceptance
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "250px",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "7px",
                    marginLeft: "10px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Sort by:
                </label>
                <div style={{ width: "60%" }}>
                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    <option value="default">Date & Time</option>
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
            </Box>
            <Box gridColumn="span 4">
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "grey",
                  gap: "15px",
                  marginTop: "15px",
                }}
              >
                {data && data.length > 0 ? (
                  data
                    .sort((a, b) => {
                      if (sortBy === "loan-amount") {
                        return a.offer_amount - b.offer_amount;
                      } else if (sortBy === "date") {
                        return new Date(a.created_at) - new Date(b.created_at);
                      } else {
                        // Default sorting option
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                    })
                    .map((item, index) =>
                      item.status === "accepted" ? null : item.status ===
                        "ongoing" ? null : (
                        <Container
                          key={index}
                          icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                          title={item.principal_name || "-"}
                          subtitle={
                            item.express === 1
                              ? "Express Applicant"
                              : "Approved Borrower"
                          }
                          label="Loan Amount"
                          description={
                            item.offer_amount
                              ? item.offer_amount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"
                          }
                          label1="Monthly Receivables"
                          createdAt={item.created_at}
                          description1={
                            item.offer_monthly_installment
                              ? item.offer_monthly_installment.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )
                              : "-"
                          }
                          label2="Period"
                          description2={item.offer_period + " Months" || "-"}
                          label3="Loan Status"
                          description3={
                            item.status === "not_available"
                              ? "Not Available"
                              : item.status === "pending_document_request"
                              ? "Pending Document Request"
                              : item.status
                              ? item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)
                              : "-"
                          }
                          label4="Interest Rate Per Month"
                          description4={
                            (item.offer_interest_rate * 100).toFixed(1) +
                              " %" || "-"
                          }
                          index={index} // pass the index prop to each Container instance
                          loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                          offer_id={item.id}
                          open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                          onClickModal={(event) =>
                            showModal(item.loan_id, event)
                          } // pass the showModal function to each Container instance
                          cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                          item={item}
                        />
                      )
                    )
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      No loans pending acceptance
                    </div>
                  </div>
                )}
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <Modal
                      style={{}}
                      open={modalStates[item.loan_id]}
                      onCancel={() =>
                        setModalStates((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      width={isMobile ? "100%" : "60vw"}
                      footer={[]}
                    >
                      <div
                        style={{
                          color: "#142C44CC",
                          fontFamily: "Manrope,sans-serif",
                          fontSize: "18px",
                          fontWeight: 800,
                        }}
                      >
                        Applicant Details
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 12,
                          borderRadius: "10px",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Name
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.principal_name || "-"}
                          </p>
                        </div>
                        <div style={{ width: "50%" }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan ID
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.loan_id || "-"}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div
                        style={{
                          color: "#142C44CC",
                          fontFamily: "Manrope,sans-serif",
                          fontSize: "18px",
                          fontWeight: 800,
                        }}
                      >
                        Loan Offer
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          marginTop: 12,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Interest Rate Per Month
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {(item.offer_interest_rate * 100).toFixed(1) +
                              "%" || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Period
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.offer_period + " Month" || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Status
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.status === "pending_document_request"
                              ? "Pending Document Request"
                              : item.status
                              ? item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)
                              : "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Category
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.type === "simple"
                              ? "Unsecured Business Term Loan"
                              : "-" || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Amount
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.offer_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            }) || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Processing Fee
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {(item.processing_fee * 100).toFixed(1) + "%" ||
                              "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Created At
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {formatDate(item.created_at) || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Updated At
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {formatDate(item.updated_at) || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Offer Principal
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.offer_principal.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            }) || "-"}
                          </p>
                        </div>
                        <div style={{ width: "32%" }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Offer Revenue
                          </label>
                          <br />
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "grey",
                            }}
                          >
                            {item.offer_revenue.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            }) || "-"}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              color: "#142C44CC",
                              fontFamily: "Manrope,sans-serif",
                              fontSize: "18px",
                              fontWeight: 800,
                            }}
                          >
                            Requested Files
                          </div>
                          <br />
                          <TablePendingAcceptance
                            parentData={data}
                            loan_id={item.loan_id}
                          />
                        </div>
                      </div>

                      <hr />
                      {item.status === "pending_document_request" && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <div style={{}}>
                            <button
                              style={{
                                background: "rgb(21,87,169)",
                                color: "white",
                                border: "none",
                                borderRadius: "50px",
                                padding: "6px 25px",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onMouseEnter={() => setHovered2(true)} // set the state hovered to true  when mouse enter
                              onMouseLeave={() => setHovered2(false)} // set the state hovered to false  when mouse leave
                              onClick={(event) =>
                                showModalAcceptOffer(item.id, event)
                              }
                            >
                              Allow Accept Offer
                            </button>
                          </div>

                          <div style={{}}>
                            <button
                              style={{
                                background: "transparent",
                                color: "grey",
                                border: "2px solid lightgrey",
                                borderRadius: "50px",
                                padding: "6px 25px",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onMouseEnter={() => setHovered3(true)} // set the state hovered to true  when mouse enter
                              onMouseLeave={() => setHovered3(false)} // set the state hovered to false  when mouse leave
                              onClick={(event) =>
                                showModalRequestDocument(item.id, event)
                              }
                            >
                              Request More File
                            </button>
                          </div>
                        </div>
                      )}
                    </Modal>
                  ))}

                <Modal
                  footer={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 16,
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={() =>
                          handleRequestMoreDocument(
                            offer_id,
                            documentDescriptions
                          )
                        }
                      >
                        Submit
                      </Button>
                      <Button type="default" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </div>,
                  ]}
                  open={isModalOpen}
                  // onOk={handleOk}
                  onCancel={handleCancel}
                >
                  {" "}
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontWeight: "700",
                      marginTop: "10px",
                    }}
                  >
                    Please Insert The Document Name
                  </div>
                  <div>
                    {documentDescriptions.map((description, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          width: "90%",
                          marginTop: "15px",
                        }}
                      >
                        <div style={{ width: "30%" }}>
                          <div
                            style={{
                              color: "rgba(0,0,0,0.5)",
                              fontWeight: "700",
                              marginTop: "10px",
                            }}
                          >
                            Document Name
                          </div>
                        </div>
                        <div style={{ width: "60%" }}>
                          <div>
                            <input
                              style={{
                                width: "90%",
                                padding: "8px",
                                borderRadius: "12px",
                                marginTop: "5px",
                                outline: "none",
                                border: "1px solid rgba(0,0,0,0.2)",
                              }}
                              value={description}
                              placeholder="Document Name"
                              onChange={(event) => handleChange(event, index)}
                            />
                          </div>
                        </div>
                        {documentDescriptions.length > 1 && (
                          <div style={{ width: "10%", textAlign: "right" }}>
                            <Button
                              type="default"
                              onClick={() => remove(index)}
                              style={{
                                backgroundColor: "none",
                                border: "none",
                                color: "#FF0000",
                                boxShadow: "none",
                                marginTop: "10px",
                              }}
                              icon={<MinusOutlined />}
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    ))}
                    <div>
                      <Button
                        type="default"
                        onClick={addMore}
                        style={{
                          backgroundColor: "none",
                          border: "none",
                          color: "#0E5AA5",
                          marginLeft: "25%",
                          boxShadow: "none",
                          marginTop: "5px",
                        }}
                        icon={<PlusOutlined style={{ marginTop: "-10px" }} />}
                      >
                        Add more
                      </Button>
                    </div>
                  </div>
                </Modal>
                <Modal
                  footer={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 16,
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={() => handleAllowAcceptOffer(offer_id)}
                      >
                        Yes
                      </Button>
                      <Button type="default" onClick={handleAcceptOfferCancel}>
                        No
                      </Button>
                    </div>,
                  ]}
                  open={isModalAcceptOfferOpen}
                  // onOk={handleOk}
                  onCancel={handleAcceptOfferCancel}
                >
                  {" "}
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontWeight: "700",
                      marginTop: "10px",
                    }}
                  >
                    Do you want to allow the borrower to accept the offer?
                  </div>
                </Modal>

                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <Modal
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                      open={modalStates2[item.loan_id]}
                      onCancel={() =>
                        setModalStates2((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      footer={[
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 16,
                          }}
                        >
                          <Button
                            type="primary"
                            onClick={() => {
                              handleReoffer(
                                item.loan_id,
                                item.express,
                                item.user_id,
                                item.id,
                                item.principal_name,
                                item.borrower_id
                              );
                            }}
                          >
                            Reoffer Loan
                          </Button>
                        </div>,
                      ]}
                    >
                      <div>
                        <div
                          style={{
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 700,
                            fontSize: 20,
                          }}
                        >
                          Reoffer to {item.principal_name || "-"}
                        </div>
                        <div style={{ display: "flex", marginTop: 10 }}>
                          <div
                            style={{
                              background: "rgba(153, 178, 205, 0.67)",
                              padding: "5px 15px",
                              borderRadius: "6px",
                              color: "white",
                              fontSize: 12,
                              fontWeight: 400,
                            }}
                          >
                            Loan ID: {item.loan_id}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_amount">Loan Amount</label>
                          <br />
                          <input
                            type="number"
                            id="offer_amount"
                            name="offer_amount"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanAmountChange}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_interest_rate">
                            Interest Rate (%) Per Month
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_interest_rate"
                            name="offer_interest_rate"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            min={0}
                            max={100}
                            onChange={handleInterestRateChange}
                          />
                          {" %"}
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_period">
                            Loan Period (Month)
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_period"
                            name="offer_period"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanPeriodChange}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_processing_fee">
                            Processing Fee (%)
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_processing_fee"
                            name="offer_processing_fee"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            value={parseFloat(offer_processing_fee)}
                            onChange={(e) => setProcessingFee(e.target.value)}
                            min={0}
                            max={100}
                          />{" "}
                          %
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="type">Loan Type</label>
                          <br />
                          <select
                            id="type"
                            name="type"
                            style={{
                              borderRadius: "10px",
                              width: "73%",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanTypeChange}
                          >
                            <option value="simple">
                              Unsecured Business Term Loan
                            </option>
                            {/* <option value="effective">
                              Effective Loan
                            </option> */}
                          </select>
                        </div>
                      </div>

                      <hr />

                      <p>
                        Processing Fees:{" "}
                        {Number(
                          offer_amount * (offer_processing_fee / 100)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                      <p>
                        Monthly Instalment payable:{" "}
                        {Number(offer_monthly_installment).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "SGD",
                          }
                        )}
                      </p>
                      <p>
                        Guaranteed Revenue:{" "}
                        {Number(
                          offer_revenue +
                            offer_amount * (offer_processing_fee / 100)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                      <p>
                        Platform Fee:{" "}
                        {Number(offer_amount * 0.015).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                    </Modal>
                  ))}
              </div>
            </Box>
          </Box>
        </Box>
        {!isMobile ? (
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            // backgroundColor="rgba(153, 178, 205, 0)"
            overflow="auto"
            height="90vh"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "10px" }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Analytics
              </Typography>
            </Box>
            <Box
              gridColumn="span 2"
              opacity="1.00"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="200px"
              // marginBottom="10px"
            >
              <Box
                width="49%"
                opacity="1.00"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="170px"
              >
                <ContainerLineChart></ContainerLineChart>
              </Box>
              <Box
                width="49%"
                opacity="1.00"
                display="flex"
                alignItems="left"
                justifyContent="left"
                height="200px"
                marginLeft="10px"
              >
                <ContainerPieChart></ContainerPieChart>
              </Box>
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor="rgba(153, 178, 205, 0)"
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginBottom: "10px" }}
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Activity Log
                </Typography>
              </Box>
              {dataActivityLog && dataActivityLog.length > 0 ? (
                dataActivityLog.slice(0, 6).map((item, index) => (
                  <Box
                    gridColumn="span 1"
                    // backgroundColor={colors.grey[910]}
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    style={{
                      opacity:
                        index === 4
                          ? 0.6
                          : index === 5
                          ? 0.2
                          : index === 3
                          ? 0.9
                          : 1,
                      transitionDelay: `${index * 0.1}s`,
                    }}
                  >
                    <ContainerActivity
                      key={index}
                      title={item.principal_name || "-"}
                      subtitle={item.description || "-"}
                      index={index}
                    />
                  </Box>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "0px 0px",
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "48vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No Activity Log
                  </div>
                </div>
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default PendingAcceptance;
