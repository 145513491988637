import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Integrated from "./img/integrated_singpass.png";
import Apply from "./img/borrowers_apply.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Typography, IconButton } from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import "./App.css";

const WhySmartLend = () => {
  const history = useHistory();
  const [pageLoad, setPageLoad] = useState(false);

  useEffect(() => {
    setPageLoad(true);
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const faqs = [
    {
      id: 1,
      title: "Who is Premelo for?",
      answer: (
        <p>
          Premelo is designed for anyone who needs to manage tasks, projects,
          and teams, including freelancers, small businesses, and large
          enterprises.
        </p>
      ),
    },
    {
      id: 2,
      title: "How do I get started with Premelo?",
      answer: (
        <p>
          Getting started with Premelo is easy. Simply sign up for a free
          account and start exploring our features. You can upgrade to our
          premium subscription, Premelo Plus, to unlock even more features and
          benefits.
        </p>
      ),
    },
    {
      id: 3,
      title: "Can I use Premelo on my mobile device?",
      answer: (
        <p>
          Yes, we offer mobile view for mobile devices, simply go to your
          favourite browser and open up premelo.app, so you can manage your
          tasks and projects on-the-go.
        </p>
      ),
    },
    {
      id: 4,
      title: "Do you offer customer support?",
      answer: (
        <p>
          Yes, we offer customer support to all of our users. You can contact us
          through our Help Center, or by emailing us directly at
          info@premelo.com.
        </p>
      ),
    },
    {
      id: 5,
      title: "How will Premelo help me in managing my work in a project?",
      answer: (
        <p>
          Premelo is a powerful project management tool designed to help you
          manage your work, team, and tasks, all in one place. With Premelo, you
          can easily create and assign tasks, track progress, and collaborate
          with your team in real-time. Our intuitive interface makes it easy to
          get started, and our robust features ensure that you can customize
          Premelo to meet the unique needs of your business. Whether you're
          managing deadlines, tracking milestones, or keeping everyone in the
          loop, Premelo has got you covered.
        </p>
      ),
    },
    {
      id: 6,
      title:
        "What is the difference of Premelo and other task management platform",
      answer: (
        <div>
          Premelo offers a number of features and benefits that distinguish it
          from other task management platforms. Here are a few examples:
          <ul>
            <li>
              Customization - With Premelo Plus, you can fully customize your
              project management platform to reflect your branding and
              personality. This includes the ability to create custom workflows,
              change color themes, and even connect to your custom domain.
            </li>
            <li>
              Internal Repositories - Premelo allows you to upload and organize
              files related to your tasks, so you can keep everything in one
              place and access it whenever you need it.
            </li>
            <li>
              Centralized Messaging - With Premelo Plus, you can connect your
              WhatsApp, Facebook, and Instagram accounts to communicate with
              your clients and teammates directly from the platform.
            </li>
            <li>
              Social Media Connect - You can also schedule and post content on
              your social media accounts directly from Premelo Plus.
            </li>
          </ul>
          Overall, Premelo offers a more comprehensive and customizable solution
          for managing your tasks, projects, and team.
        </div>
      ),
    },
  ];
  const filteredFaqs = faqs.filter((faq) =>
    faq.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const FAQItem = ({ id, title, answer }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "0.01rem solid #d5dee0",
          }}
          onClick={handleToggle}
          className="mui-button"
        >
          <IconButton onClick={handleToggle}>
            <ChevronRight
              style={{
                rotate: isCollapsed ? "0deg" : "90deg",
                transition: "all 0.3s ease-in-out",
              }}
            />
          </IconButton>
          <div
            variant="subtitle1"
            style={{
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            {title}
          </div>
        </div>
        {!isCollapsed && (
          <div
            style={{
              marginLeft: "40px",
              paddingTop: "6px",
              fontSize: "15px",
              fontWeight: "400",
              paddingBottom: "20px",
            }}
          >
            {answer}
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      {/* Header bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <div
            style={{
              width: "25%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend
          </div>
          <div
            style={{
              width: "75%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Home
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/whysmartlend");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "700",
                color: "#256199",
                marginRight: "10px",
              }}
            >
              Why SmartLend?
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Partner With Us
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              News & Events
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Contact Us
            </div>
            <hr
              style={{
                width: "2px",
                height: "20px",
                margin: "0px",
                outline: "none",
                border: "none",
                background: "rgba(0,0,0,0.3)",
              }}
            />
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signup");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#256199",
                border: "2px solid #256199",
                padding: "5px 30px",
                borderRadius: "15px",
              }}
            >
              Sign Up
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "white",
                border: "2px solid #256199",
                background: "#256199",
                padding: "5px 40px",
                borderRadius: "15px",
              }}
            >
              Login
            </div>
          </div>
        </div>
      </div>

      {/* Section one */}
      <div
        style={{
          background: "#256199",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          marginLeft: pageLoad ? "0px" : "-300px",
          opacity: pageLoad ? "1" : "0",
          transition: "all 0.8s ease-in-out",
        }}
      >
        <div style={{ padding: "3% 0px" }}>
          <div
            style={{
              color: "white",
              fontWeight: "700",
              fontSize: "70px",
            }}
          >
            The #1 Online Loan
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "700",
              fontSize: "70px",
              marginTop: "-20px",
            }}
          >
            Platform Available to Singaporeans
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "400",
              fontSize: "18px",
            }}
          >
            SmartLend connects private companies to you allowing you to reach a
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "400",
              fontSize: "18px",
            }}
          >
            wider audience fast!
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                textAlign: "center",
                color: "#256199",
                background: "white",
                fontSize: "20px",
                padding: "7px 35px",
                borderRadius: "30px",
                width: "17%",
              }}
            >
              Apply now
            </div>
          </div>
          <div style={{ marginTop: "6px", fontSize: "12px", color: "white" }}>
            Try it now, no details needed
          </div>
        </div>
      </div>

      {/* Section two */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "4% 0px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            alignItems: "center",
          }}
        >
          <div style={{ width: "50%" }}>
            <div style={{ fontSize: "17px", color: "#256199" }}>
              Flexible and intuitive
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "35px",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              <div style={{ fontWeight: "800" }}>Customize</div>
              <div style={{ fontWeight: "600", marginLeft: "10px" }}>
                your loan to meet
              </div>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "35px",
                fontWeight: "600",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              Financial Needs
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgba(0,0,0,0.7)",
                fontWeight: "400",
                marginTop: "14px",
              }}
            >
              <div>
                SmartLend allows you to apply your loan and set your ideal
                interest rates likewise, lenders will be able to make an offer
                to you with a variety of choices on your hands, allowing you to
                make smarter financial decisions.
              </div>
            </div>
          </div>
          <div style={{ width: "40%", marginLeft: "30px" }}>
            <div
              style={{
                width: "100%",
                background: "#256199",
                height: "45px",
                borderRadius: "20px 20px 0px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  width: "9px",
                  height: "9px",
                  background: "rgba(256,256,256, 0.2)",
                  margin: "0px 3px 0px 20px",
                }}
              ></div>
              <div
                style={{
                  borderRadius: "50%",
                  width: "9px",
                  height: "9px",
                  background: "rgba(256,256,256, 0.2)",
                  margin: "0px 3px",
                }}
              ></div>
              <div
                style={{
                  borderRadius: "50%",
                  width: "9px",
                  height: "9px",
                  background: "rgba(256,256,256, 0.2)",
                  margin: "0px 3px",
                }}
              ></div>
            </div>
            <img
              src={Apply}
              style={{
                objectFit: "cover",
                width: "100%",
                boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                borderRadius: "0px 0px 20px 20px",
              }}
            />
          </div>
        </div>
      </div>

      {/* Section three */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "4% 0px",
          alignItems: "center",
          marginTop: "-150px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            alignItems: "center",
          }}
        >
          <div style={{ width: "40%", marginRight: "30px" }}>
            <div
              style={{
                width: "100%",
                background: "#256199",
                height: "45px",
                borderRadius: "20px 20px 0px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  width: "9px",
                  height: "9px",
                  background: "rgba(256,256,256, 0.2)",
                  margin: "0px 3px 0px 20px",
                }}
              ></div>
              <div
                style={{
                  borderRadius: "50%",
                  width: "9px",
                  height: "9px",
                  background: "rgba(256,256,256, 0.2)",
                  margin: "0px 3px",
                }}
              ></div>
              <div
                style={{
                  borderRadius: "50%",
                  width: "9px",
                  height: "9px",
                  background: "rgba(256,256,256, 0.2)",
                  margin: "0px 3px",
                }}
              ></div>
            </div>
            <img
              src={Integrated}
              style={{
                objectFit: "cover",
                width: "100%",
                boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                borderRadius: "0px 0px 20px 20px",
              }}
            />
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ fontSize: "17px", color: "#256199" }}>
              Optimized Security
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "35px",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              <div style={{ fontWeight: "800" }}>Singpass</div>
              <div style={{ fontWeight: "600", marginLeft: "10px" }}>
                Integrated to ensure
              </div>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "35px",
                fontWeight: "600",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              information's security
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgba(0,0,0,0.7)",
                fontWeight: "400",
                marginTop: "14px",
              }}
            >
              <div>
                SmartLend allows you to apply your loan and set your ideal
                interest rates likewise, lenders will be able to make an offer
                to you with a variety of choices on your hands, allowing you to
                make smarter financial decisions.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section four */}
      {/* <div
        style={{
          padding: "60px 0px",
          marginTop: "",
          background: "rgba(0,0,0,0.05)",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: "45px",
            fontWeight: "700",
            color: "rgba(0,0,0,0.8)",
            // lineHeight: "100px",
          }}
        >
          Trusted by 180,000 companies
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "60%",
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "400",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </div>
        </div>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "60%",
              marginTop: "30px",
              gap: "30px",
            }}
          >
            <img
              src={""}
              style={{ objectFit: "cover", width: "200px", height: "160px" }}
            />
            <img
              src={""}
              style={{ objectFit: "cover", width: "200px", height: "160px" }}
            />
            <img
              src={""}
              style={{ objectFit: "cover", width: "200px", height: "160px" }}
            />
            <img
              src={""}
              style={{ objectFit: "cover", width: "200px", height: "160px" }}
            />
            <img
              src={""}
              style={{ objectFit: "cover", width: "200px", height: "160px" }}
            />
            <img
              src={""}
              style={{ objectFit: "cover", width: "200px", height: "160px" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <div
            className="menu_button"
            onClick={() => {
              history.push("/auth/signin");
              window.scrollTo(0, 0);
            }}
            style={{
              textAlign: "center",
              background: "#256199",
              color: "white",
              fontSize: "20px",
              padding: "7px 35px",
              borderRadius: "30px",
              width: "20%",
            }}
          >
            Get Started
          </div>
        </div>
      </div> */}

      {/* Section FAQ */}
      {/* <div style={{ padding: "60px 0px" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "45px",
            fontWeight: "700",
            color: "rgba(0,0,0,0.8)",
            // lineHeight: "100px",
          }}
        >
          Frequently Asked Questions
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "40px",
          }}
        >
          <div style={{ width: "50%" }}>
            {filteredFaqs.map((faq) => (
              <FAQItem
                key={faq.id}
                id={faq.id}
                title={faq.title}
                answer={faq.answer}
              />
            ))}
          </div>
        </div>
      </div> */}

      {/* Footer section */}
      <div
        style={{
          background: "rgba(0,0,0,0.1)",
          padding: "40px 150px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "60%" }}>
          <div
            style={{
              width: "40%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend
          </div>
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Get more, Get fast.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "15px" }}
          >
            Singapore HQ
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "3px" }}
          >
            114 Lavender St., #07-77 CT Hub 2, Singapore 338729
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "15px" }}
          >
            Folow us and Stay updated
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "15px",
            }}
          >
            <FacebookIcon
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,0.8)",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,0.8)",
                cursor: "pointer",
              }}
            />
            <TwitterIcon
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,0.8)",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div style={{ width: "15%" }}>
          <div style={{ fontWeight: "600", fontSize: "20px" }}>Sitemap</div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              marginTop: "15px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/home");
              window.scrollTo(0, 0);
            }}
          >
            Home
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/whysmartlend");
              window.scrollTo(0, 0);
            }}
          >
            Why SmartLend
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/partner-with-us");
              window.scrollTo(0, 0);
            }}
          >
            Partner With Us
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/frequentlyaskedquestions");
              window.scrollTo(0, 0);
            }}
          >
            Frequently Asked Questions
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/news-events");
              window.scrollTo(0, 0);
            }}
          >
            News & Events
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/contactus");
              window.scrollTo(0, 0);
            }}
          >
            Contact Us
          </div>
        </div>
        <div style={{ width: "15%" }}>
          <div style={{ fontWeight: "600", fontSize: "20px" }}>Enterprise</div>
          <div
            style={{ fontWeight: "300", fontSize: "14px", marginTop: "15px" }}
          >
            SmartLend for Business
          </div>
          <div
            style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
          >
            SmartAds
          </div>
          <div
            style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
          >
            Express Loan
          </div>
        </div>
      </div>
      <div
        style={{
          background: "rgba(0,0,0,0.85)",
          justifyContent: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
        }}
      >
        <div
          style={{
            width: "30%",
            color: "rgba(256,256,256,.9)",
            fontSize: "14px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Terms and Conditions</div>
          <div style={{ width: "10px" }}>•</div>
          <div>© SmartLend Pte Ltd 2023</div>
          <div style={{ width: "10px" }}>•</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};
export default WhySmartLend;
