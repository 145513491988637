import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import adspot_ico from "../../assets/img/businessman-receiving-money-after-contract-signing.png";
import {
  Form,
  Input,
  Select,
  Button,
  Col,
  Row,
  Avatar,
  Upload,
  Card,
  Tabs,
  notification,
  DatePicker,
  Space,
  InputNumber,
} from "antd";
import { useState, useEffect } from "react";
import moment from "moment";
import { Collapse } from "antd";
import TableRepayment from "../../components/TableRepayment";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import _ from "lodash";
import { message, Modal } from "antd";
import { UserOutlined, QuestionOutlined } from "@ant-design/icons";
import ModalApplication from "./modal-application";
import ModalApplicationExpress from "./modal-application-express";
import singlogo from "../../pages/smart-lend-frontend/src/img/singpass_logo.png";

const { Option } = Select;

const onChange = (key) => {
  console.log(key);
};

const ApplyLoan = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { TabPane } = Tabs;
  const [nric, setNric] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [requestingLoanAmount, setRequestLoanAmount] = useState("");
  const [expectingInterestRate, setExpectingInterestRate] = useState("");
  const [loanPeriod, setLoanPeriod] = useState(1);
  const [gender, setGender] = useState("");
  const [loanType, setLoanType] = useState("simple");
  const [loanCategory, setLoanCategory] = useState("business");
  const [publishPeriod, setPublishPeriod] = useState("");
  const [nationality, setNationality] = useState("");
  const [countryofbirth, setCountryOfBirth] = useState("");
  const [employmentsector, setEmploymentSector] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [registeredaddress, setRegisteredAddress] = useState("");
  const [data, setData] = useState([]);
  const [dataAcceptedLoan, setDataAcceptedLoan] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const { Panel } = Collapse;
  const history = useHistory();
  const [other, setOther] = useState();
  const [address, setAddress] = useState();
  const [outstandingLoan, setOutstandingLoan] = useState();
  const [repaymentData, setRepaymentData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [dataActivityLog, setActivityLog] = useState([]);
  const [selectedBorowerId, setselectedBorrowerId] = useState(null);
  const [selectedLenderId, setSelectedLenderId] = useState(null);

  //loan calculator variable
  const [total_admin_fee, setTotalAdminFee] = useState("");
  const [adminFee, setAdminFeeSelected] = useState(0.03);
  const [admin_fee, setAdminFee] = useState();
  const [request_principal, setLoanPrincipal] = useState();
  const [request_monthly_installment, setExpectedMonthlyInstallment] =
    useState();
  const [request_revenue, setGuaranteedRevenue] = useState();
  const [request_revenue_by_month, setRequestRevenueByMonth] = useState();
  const [total_admin_fee_by_month, setTotalAdminFeeByMonth] = useState();
  const [inputsFilled, setInputsFilled] = useState(false); // For the condition to make sure all three inputs are filled
  const [displayAds, setDisplayAds] = useState([]);
  const [adImage, setAdImage] = useState(null);
  const [adLink, setAdLink] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const form = {
      adminFee: adminFee,
      requestingLoanAmount: requestingLoanAmount,
      expectingInterestRate: expectingInterestRate,
      loanPeriod: loanPeriod,
    };
    if (
      requestingLoanAmount !== "" &&
      expectingInterestRate !== "" &&
      loanPeriod
    ) {
      Cookies.set("applyLoanForm", JSON.stringify(form));
    }
  }, [adminFee, requestingLoanAmount, expectingInterestRate, loanPeriod]);

  const [previouslyUnsubmitted, setPreviouslyUnsubmitted] = useState(false);
  const [previouslyUnsubmittedModal, setPreviouslyUnsubmittedModal] =
    useState(false);

  useEffect(() => {
    if (Cookies.get("applyLoanForm")) {
      setPreviouslyUnsubmittedModal(true);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/ads/getAdsByScreen?screen=bApplyLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setDisplayAds(data["ads"]);
        setAdImage(data.ads[0].image);
        setAdLink(data.ads[0].link);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (displayAds.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % displayAds.length);
      }, 3200);

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [displayAds]);

  useEffect(() => {
    if (displayAds.length > 0) {
      console.log(currentIndex);
      setAdImage(displayAds[currentIndex].image);
      setAdLink(displayAds[currentIndex].link);
    }
  }, [currentIndex, displayAds]);

  const handleApplyLoan = (event) => {
    event.preventDefault();
    history.push("/b-apply-loan");
  };
  const sync_singpass = Cookies.get("sync_singpass");
  // const handleInterestRateChange = (e) => {
  //     let value = e.target.value;
  //     setInterestRate(value);
  // };

  // const handleLoanPeriodChange = (e) => {
  // setSelectedLoanPeriod(e.target.value);
  // };

  // const handleLoanAmountChange = (e) => {
  // setLoanAmount(e.target.value);
  // };

  // const handleLoanTypeChange = (e) => {
  //     setLoanType(e.target.value);
  //   };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenExpress, setIsModalOpenExpress] = useState(false);
  const [isModal3, setIsModal3] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    getPromoCode();
    getKeymanChoices();
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal2 = () => {
    setIsModalOpenExpress(true);
  };

  const handleOk2 = () => {
    setIsModalOpenExpress(false);
  };

  const handleCancel2 = () => {
    setIsModalOpenExpress(false);
  };

  const showModal3 = () => {
    setIsModal3(true);
  };

  const handleOk3 = () => {
    setIsModal3(false);
  };

  const handleCancel3 = () => {
    setIsModal3(false);
  };

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const onChangeHandler = (label, value) => {
    if (isTableVisible) {
      message.info("Please refresh the Repayment Schedule.");
      setIsTableVisible(false);
    }

    if (label === "loanAmount") {
      setRequestLoanAmount(value);
    } else if (label === "interestRate") {
      setExpectingInterestRate(value);
    } else if (label === "loanPeriod") {
      setLoanPeriod(value);
    }
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const ContainerActivity = ({ title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "7px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {title ? getInitials(title) : "SL"}
        </div>
        <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid none",
        borderRadius: "20px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <p
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "16px",
          color: "#142C44CC",
          textAlign: "center",
          paddingTop: "15px",
        }}
      >
        {noData}
      </p>
    </div>
  );

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 9,
      span: 24,
    },
  };

  const onChange = (key) => {
    console.log(key);
  };

  const onChange2 = (key) => {
    console.log(key);
  };

  const [promoCode, setPromoCode] = useState(null);
  const [keymanChoices, setKeymanChoices] = useState([]);
  const [existingLoanData, setExistingLoanData] = useState(false);
  const [reminder, setReminder] = useState(false);

  const getKeymanChoices = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/getCompanyShareholders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        const uniqueDirectors = [...new Set(json.other_directors)];
        setKeymanChoices(uniqueDirectors);
        console.log(uniqueDirectors);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPromoCode = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/apply_promo_code",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        setPromoCode(json.promo_code);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCalculateLoan = async () => {
    setIsTableVisible(true);
    try {
      let item = {
        request_amount: parseFloat(requestingLoanAmount),
        request_interest_rate: parseFloat(request_revenue_by_month),
        request_period: parseInt(loanPeriod),
        request_monthly_installment: parseFloat(request_monthly_installment),
        type: loanType,
        admin_fee: parseFloat(total_admin_fee_by_month),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/calculateLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();

      const formattedData = data.map((item) => ({
        iteration: item["Iteration"],
        monthlyInstallment: Number(item["Monthly_Installment"]),
        interest: Number(item["Interest"]),
        principal: Number(item["Principal"]),
        finalBalance: Number(item["Final_Balance"]),
        cumulativeInterest: Number(item["Cumulative_Interest"]),
        adminFee: Number(item["Admin_Fee"]),
      }));

      setData(formattedData);

      console.log("Calculate Loan created:", data);

      // Update your UI or close the modal
    } catch (error) {
      console.error("Error Calculating Loan:", error);
    }
  };

  const fetchSingpassData = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/singpass/getSingpassData",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        // Handle HTTP error responses (e.g., 404, 500)
        throw new Error(`HTTP Error: ${response.status}`);
      }

      const json = await response.json();
      console.log(json.last_updated_at);

      // Compare dates
      const lastUpdateDate = new Date(json.last_updated_at);
      const currentDate = new Date();
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(currentDate.getMonth() - 1);

      if (lastUpdateDate < oneMonthAgo) {
        setReminder(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchExistingLoanData = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/pendingLoan/viewPendingLoan",
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      let pendingLoanToPush = false;

      for (const loan of data["Pending Loans"]) {
        if (
          loan.status === "pending" ||
          loan.status === "offered" ||
          loan.status === "accepted"
        ) {
          const lastUpdateDate = new Date(loan.created_at);
          const currentDate = new Date();
          const oneMonthAgo = new Date();
          oneMonthAgo.setMonth(currentDate.getMonth() - 1);

          if (lastUpdateDate > oneMonthAgo) {
            pendingLoanToPush = true;
          }
        }
      }

      console.log(pendingLoanToPush);
      setExistingLoanData(pendingLoanToPush);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const processedData = await Promise.all(
          data.map(async (item) => {
            const profilePictureResponse = await fetch(
              `https://www.smart-lend.com/api/loan/loanRequest/viewListProfilePicture?profile_picture=${item.profile_picture}`,
              options
            );

            if (!profilePictureResponse.ok) {
              throw new Error("Failed to fetch profile picture data");
            }

            const blob = await profilePictureResponse.blob();
            const imageUrl = URL.createObjectURL(blob);

            return {
              ...item,
              icon: imageUrl, // use the blob URL as the icon
            };
          })
        );
        const reversedData = processedData.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
    fetchExistingLoanData();
    fetchSingpassData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/pendingLoan/viewAcceptedLoanBorrower",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setDataAcceptedLoan(data["Accepted Loan"]);
        // setSelectedLenderId
        setselectedBorrowerId(data["Accepted Loan"][0].borrower_id);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const calculateLoan = () => {
    // Check if all three fields have a value. If not, return
    if (
      requestingLoanAmount === null ||
      requestingLoanAmount === "" ||
      expectingInterestRate === null ||
      expectingInterestRate === "" ||
      loanPeriod === null ||
      loanPeriod === ""
    )
      return;

    if (loanType === "simple") {
      console.log("value requesting loan amount", requestingLoanAmount);
      const request_monthly_installment =
        requestingLoanAmount / loanPeriod +
        requestingLoanAmount * (expectingInterestRate / 100);
      const request_principal = request_monthly_installment * loanPeriod || 0;
      const total_admin_fee =
        (requestingLoanAmount +
          requestingLoanAmount * (expectingInterestRate / 100)) *
        0;
      const request_revenue =
        request_principal - requestingLoanAmount - total_admin_fee || 0;
      const request_revenue_by_month = expectingInterestRate / 100;
      const total_admin_fee_by_month = total_admin_fee / loanPeriod;
      const admin_fee =
        (requestingLoanAmount / loanPeriod +
          requestingLoanAmount * (expectingInterestRate / 100)) *
        0;
      setAdminFee(admin_fee.toFixed(2));
      setLoanPrincipal(request_principal.toFixed(2));
      setExpectedMonthlyInstallment(request_monthly_installment.toFixed(2));
      setGuaranteedRevenue(request_revenue.toFixed(2));
      setRequestRevenueByMonth(request_revenue_by_month.toFixed(2));
      setTotalAdminFee(total_admin_fee.toFixed(2));
      setTotalAdminFeeByMonth(total_admin_fee.toFixed(2));
    }
  };

  useEffect(() => {
    // Check if all three inputs are filled
    if (
      requestingLoanAmount !== null &&
      requestingLoanAmount !== "" &&
      expectingInterestRate !== null &&
      expectingInterestRate !== "" &&
      loanPeriod !== null &&
      loanPeriod !== ""
    ) {
      setInputsFilled(true);
    } else {
      setInputsFilled(false);
      // Reset the values to default when any of the inputs is empty
      setAdminFee(0);
      setLoanPrincipal(0);
      setExpectedMonthlyInstallment(0);
      setGuaranteedRevenue(0);
      setTotalAdminFee(0);
    }
  }, [requestingLoanAmount, expectingInterestRate, loanPeriod]);

  useEffect(() => {
    // Only perform the calculation when all inputs are filled
    if (inputsFilled) {
      calculateLoan();
    }
  }, [
    requestingLoanAmount,
    expectingInterestRate,
    loanPeriod,
    loanType,
    request_principal,
    request_monthly_installment,
    request_revenue,
    total_admin_fee,
    admin_fee,
    adminFee,
    inputsFilled,
  ]);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [syncWithSingpass, setSyncWithSingpass] = useState(0);

  const checkSingpassDetails = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/checkSingpassSync",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setSyncWithSingpass(data.singpass_sync);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkSingpassDetails();
  }, []);

  return (
    <Box m="4px 20px 20px 20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn={isMobile ? "span 12" : "span 9"}
          gridRow="span 2"
          // overflow="auto"
          borderRadius="10px"
          border="1px solid "
          opacity="0.95"
          style={{
            overflowY: "hidden",
            height: "90vh",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            width: "100%",
            border: "none",
          }}
        >
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            border-radius="20px"
            opacity="0.95"
            style={{
              overflowY: "scroll",
              height: "90vh",
              background: "transparent",
              border: "none",
              width: "100%",
              padding: "20px 20px 120px 20px",
            }}
          >
            {reminder ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "rgba(20, 44, 68, 0.8)",
                  fontWeight: 800,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "60%",
                      textAlign: "center",
                      lineHeight: "24px",
                    }}
                  >
                    Your singpass data is outdated, please resync your account
                    to proceed applying for a loan.
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      onClick={() => {
                        window.location.href = "/stg-b-profile";
                      }}
                      style={{
                        border: "2px solid rgba(20, 44, 68, 0.8)",
                        borderRadius: "10px",
                        padding: "10px 20px",
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                        cursor: "pointer",
                        fontSize: 15,
                      }}
                    >
                      Sync With
                      <img
                        src={singlogo}
                        style={{
                          objectFit: "cover",
                          width: "100px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : syncWithSingpass ? (
              existingLoanData ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "rgba(20, 44, 68, 0.8)",
                    fontWeight: 800,
                    fontFamily: "Manrope, sans-serif",
                    fontSize: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: "60%",
                        textAlign: "center",
                        lineHeight: "24px",
                      }}
                    >
                      It seems you currently have loans pending, please wait at
                      least 30 days before trying to apply for a new loan
                    </div>
                  </div>
                </div>
              ) : (
                <Form
                  style={{
                    padding: "0px",
                  }}
                  {...layout}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    borderRadius="10px"
                    margin="0px"
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                    >
                      Loan Application
                    </Typography>
                  </Box>
                  <hr
                    style={{
                      color: "#142C44CC",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  <Row>
                    <Col span={isMobile ? 12 : 8}>
                      <Form.Item
                        label="Loan Category"
                        name="loanType"
                        style={{}}
                      >
                        <Select
                          value={loanType}
                          style={{ width: "90%" }}
                          onChange={(value) => setLoanType(value)}
                          defaultValue={"simple"}
                          on
                          allowClear
                        >
                          <Option value="simple">
                            Unsecured Business Term Loan
                          </Option>
                          {/* <Option value="effective">Effective</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={isMobile ? 12 : 8}>
                      <Form.Item
                        label={
                          <>
                            Expected Processing Fee
                            <tooltip
                              placement="topRight"
                              title="Communicate your preferred processing fees to give lenders insight into your expectations. Please be
                                aware that lenders maintain discretion in finalizing processing fee. Additionally, choosing a higher
                                processing fees can broaden your pool of available lenders."
                            >
                              <button
                                className="all_button"
                                style={{
                                  borderRadius: "15px",
                                  height: "15px",
                                  width: "15px",
                                  background: "transparent",
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "0px 0px 5px 5px",
                                  alignItems: "center",
                                }}
                              >
                                <QuestionOutlined style={{ fontSize: "8px" }} />
                              </button>
                            </tooltip>
                          </>
                        }
                        name="processingFee"
                        style={{}}
                      >
                        <Select
                          value={adminFee}
                          style={{ width: "90%" }}
                          onChange={(value) => setAdminFeeSelected(value)}
                          defaultValue={"0.03"}
                          on
                          allowClear
                        >
                          <Option value="0.03">1-3%</Option>
                          <Option value="0.05">3.1-5%</Option>
                          <Option value="0.07">5.1-7%</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={isMobile ? 12 : 8}>
                      <Form.Item
                        label={
                          <>
                            Requested Loan Amount
                            <tooltip
                              placement="topRight"
                              title="The loan amount you request affects which lenders can view your application. A lower amount may attract more lenders, while a higher amount might limit options but could suit larger financial needs."
                            >
                              <button
                                className="all_button"
                                style={{
                                  borderRadius: "15px",
                                  height: "15px",
                                  width: "15px",
                                  background: "transparent",
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "0px 0px 5px 5px",
                                  alignItems: "center",
                                }}
                              >
                                <QuestionOutlined style={{ fontSize: "8px" }} />
                              </button>
                            </tooltip>
                          </>
                        }
                        name="requestingLoanAmount"
                      >
                        <InputNumber
                          style={{ width: "90%" }}
                          value={requestingLoanAmount}
                          onChange={(e) => {
                            onChangeHandler();
                            setRequestLoanAmount(e);
                          }}
                          formatter={(value) =>
                            value
                              ? `$ ${value}`
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  .toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })
                              : ""
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={isMobile ? 12 : 8}>
                      <Form.Item
                        label={
                          <>
                            Interest Rate (Month) (%)
                            <tooltip
                              placement="topRight"
                              title="Selecting a higher expected interest rate may increase your loan options. More lenders can view applications with higher interest rates, potentially offering you a wider range of loan choices."
                            >
                              <button
                                className="all_button"
                                style={{
                                  borderRadius: "15px",
                                  height: "15px",
                                  width: "15px",
                                  background: "transparent",
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "0px 0px 5px 5px",
                                  alignItems: "center",
                                }}
                              >
                                <QuestionOutlined style={{ fontSize: "8px" }} />
                              </button>
                            </tooltip>
                          </>
                        }
                        name="expecting_interest_rate"
                      >
                        <Select
                          style={{ width: "90%" }}
                          value={expectingInterestRate}
                          onChange={(value) => {
                            onChangeHandler();
                            setExpectingInterestRate(value);
                          }}
                        >
                          <Option value="2">1.1%-2%</Option>
                          <Option value="3">2.1%-3%</Option>
                          <Option value="5">3.1%-5%</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={isMobile ? 12 : 8}>
                      <Form.Item
                        label={
                          <>
                            Loan Period (Month)
                            <tooltip
                              placement="topRight"
                              title="Your chosen loan period affects your options. Shorter terms (3-12 months) may attract more lenders and offer lower total interest, but with higher monthly payments. Alternative lenders typically prefer this range."
                            >
                              <button
                                className="all_button"
                                style={{
                                  borderRadius: "15px",
                                  height: "15px",
                                  width: "15px",
                                  background: "transparent",
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "0px 0px 5px 5px",
                                  alignItems: "center",
                                }}
                              >
                                <QuestionOutlined style={{ fontSize: "8px" }} />
                              </button>
                            </tooltip>
                          </>
                        }
                        name="loanPeriod"
                      >
                        {/* <Select
                            style={{ width: "90%" }}
                            value={loanPeriod}
                            onChange={(value) => {
                              onChangeHandler();
                              setLoanPeriod(value);
                            }}
                          >
                            <Option value="12">6 Months-12 Months</Option>
                            <Option value="18">12 Months-18 Months</Option>
                            <Option value="24">18 Months-24 Months</Option>
                            <Option value="30">24 Months-30 Months</Option>
                            <Option value="36">30 Months-36 Months</Option>
                          </Select> */}

                        <InputNumber
                          style={{ width: "90%" }}
                          value={loanPeriod}
                          onChange={(value) => {
                            onChangeHandler();
                            setLoanPeriod(value);
                          }}
                          formatter={(value) =>
                            parseFloat(value) === 1
                              ? `${value} month`
                              : `${value} months`
                          }
                          parser={(value) => value.replace(" months", "")}
                          min={1}
                        />
                        {loanPeriod && parseFloat(loanPeriod) >= 13 ? (
                          <div
                            style={{
                              fontSize: 12,
                              lineHeight: "14px",
                              marginTop: "10px",
                            }}
                          >
                            Note: When you apply more then 12 months your
                            lending options are more limited.
                          </div>
                        ) : null}
                      </Form.Item>
                    </Col>
                  </Row>
                  {loanCategory === "other" && (
                    <Col span={24}>
                      <Form.Item
                        label="Reason for Loan Request"
                        name="reasonforloanrequest"
                      >
                        <Input
                          style={{ width: "90%" }}
                          value={other}
                          onChange={(e) => setOther(e)}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {loanCategory === "property" && (
                    <Row>
                      <Col span={16}>
                        <Form.Item label="Address" name="address">
                          <Input
                            style={{ width: "95%" }}
                            value={address}
                            onChange={(e) => setAddress(e)}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Outstanding Loan"
                          name="outstandingLoan"
                        >
                          <InputNumber
                            style={{ width: "90%" }}
                            value={outstandingLoan}
                            onChange={(e) => setOutstandingLoan(e)}
                            formatter={(value) =>
                              value
                                ? `$ ${value}`
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    .toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "SGD",
                                    })
                                : ""
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <hr
                    style={{
                      color: "#142C44CC",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  <Row style={{ marginBottom: isMobile ? "10px" : "10px" }}>
                    <Col span={isMobile ? 16 : 8} style={{ display: "flex" }}>
                      Total cost of Financing:
                      <tooltip
                        placement="topRight"
                        title="Total Financing Cost = Loan Amount + Processing Fee + Total Interest
This sum represents your complete loan expense over the entire repayment period."
                      >
                        <button
                          className="all_button"
                          style={{
                            borderRadius: "15px",
                            height: "15px",
                            width: "15px",
                            background: "transparent",
                            border: "1px solid rgba(0,0,0,0.6)",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0px 0px 5px 5px",
                            alignItems: "center",
                          }}
                        >
                          <QuestionOutlined style={{ fontSize: "8px" }} />
                        </button>
                      </tooltip>
                    </Col>
                    <Col span={8}>
                      {!isNaN(
                        parseFloat(request_principal) +
                          parseFloat(requestingLoanAmount) *
                            parseFloat(adminFee)
                      ) && isFinite(request_principal) ? (
                        <p style={{ margin: 0 }}>
                          {Number(
                            parseFloat(request_principal) +
                              parseFloat(requestingLoanAmount) *
                                parseFloat(adminFee)
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </p>
                      ) : (
                        <p style={{ margin: 0 }}>$0.00</p>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: isMobile ? "10px" : "10px" }}>
                    <Col span={isMobile ? 16 : 8} style={{ display: "flex" }}>
                      Expected Disbursed Amount:
                      <tooltip
                        placement="topRight"
                        title="This is the amount you'll actually receive.
Calculation: Total Requested Loan Amount - Processing Fee"
                      >
                        <button
                          className="all_button"
                          style={{
                            borderRadius: "15px",
                            height: "15px",
                            width: "15px",
                            background: "transparent",
                            border: "1px solid rgba(0,0,0,0.6)",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0px 0px 5px 5px",
                            alignItems: "center",
                          }}
                        >
                          <QuestionOutlined style={{ fontSize: "8px" }} />
                        </button>
                      </tooltip>
                    </Col>
                    <Col span={8}>
                      {isNaN(total_admin_fee) ||
                      isFinite(total_admin_fee) ||
                      total_admin_fee === 0 ? (
                        <p style={{ margin: 0 }}>
                          {Number(
                            requestingLoanAmount * (1 - adminFee)
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </p>
                      ) : (
                        <p style={{ margin: 0 }}>Require input</p>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: isMobile ? "10px" : "10px" }}>
                    <Col span={isMobile ? 16 : 8} style={{ display: "flex" }}>
                      Expected Processing Fee:
                      <tooltip
                        placement="topRight"
                        title="This is the most amount for processing fee that will be incurred on your loan."
                      >
                        <button
                          className="all_button"
                          style={{
                            borderRadius: "15px",
                            height: "15px",
                            width: "15px",
                            background: "transparent",
                            border: "1px solid rgba(0,0,0,0.6)",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0px 0px 5px 5px",
                            alignItems: "center",
                          }}
                        >
                          <QuestionOutlined style={{ fontSize: "8px" }} />
                        </button>
                      </tooltip>
                    </Col>
                    <Col span={8}>
                      {isNaN(total_admin_fee) ||
                      isFinite(total_admin_fee) ||
                      total_admin_fee === 0 ? (
                        <p style={{ margin: 0 }}>
                          {Number(
                            requestingLoanAmount * adminFee
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </p>
                      ) : (
                        <p style={{ margin: 0 }}>Require input</p>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: isMobile ? "10px" : "10px" }}>
                    <Col span={isMobile ? 16 : 8} style={{ display: "flex" }}>
                      Expected Monthly Instalment:
                      <tooltip
                        placement="topRight"
                        title="This is your estimated monthly payment.
Calculation: (Requested Loan Amount + Total Interest Payable) ÷ Loan Period (in
months)"
                      >
                        <button
                          className="all_button"
                          style={{
                            borderRadius: "15px",
                            height: "15px",
                            width: "15px",
                            background: "transparent",
                            border: "1px solid rgba(0,0,0,0.6)",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0px 0px 5px 5px",
                            alignItems: "center",
                          }}
                        >
                          <QuestionOutlined style={{ fontSize: "8px" }} />
                        </button>
                      </tooltip>
                    </Col>
                    <Col span={8}>
                      {isNaN(request_monthly_installment) ||
                      isFinite(request_monthly_installment) ||
                      request_monthly_installment === 0 ? (
                        <p style={{ margin: 0 }}>
                          {Number(request_monthly_installment).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        </p>
                      ) : (
                        <p style={{ margin: 0 }}>Require input</p>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: isMobile ? "10px" : "10px" }}>
                    <Col span={isMobile ? 16 : 8} style={{ display: "flex" }}>
                      Total Interest Payable:
                      <tooltip
                        placement="topRight"
                        title="We use the upper limit of your expected interest rate range. For example, if you selected 0-1%, we calculate as:
1% (per month) x Loan Amount x Loan Period (in months)"
                      >
                        <button
                          className="all_button"
                          style={{
                            borderRadius: "15px",
                            height: "15px",
                            width: "15px",
                            background: "transparent",
                            border: "1px solid rgba(0,0,0,0.6)",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0px 0px 5px 5px",
                            alignItems: "center",
                          }}
                        >
                          <QuestionOutlined style={{ fontSize: "8px" }} />
                        </button>
                      </tooltip>
                    </Col>
                    <Col span={8}>
                      {isNaN(request_revenue) ||
                      isFinite(request_revenue) ||
                      request_revenue === 0 ? (
                        <p style={{ margin: 0 }}>
                          {Number(request_revenue).toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </p>
                      ) : (
                        <p style={{ margin: 0 }}>Require input</p>
                      )}
                    </Col>
                  </Row>
                  <hr
                    style={{
                      color: "#142C44CC",
                      height: "2px",
                      width: "100%",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 20,
                    }}
                  >
                    <div>
                      <Button
                        type="default"
                        htmlType="button"
                        onClick={showModal3}
                        disabled
                        style={{
                          borderRadius: "20px",
                          fontSize: "14px",
                          fontWeight: "700",
                          fontFamily: "Poppins, sans-serif",
                          padding: "5px 25px",
                          color: "#FFFF",
                          background: "rgb(150,150,150)",
                        }}
                      >
                        Express Application
                      </Button>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                          width: "100%",
                          margin: "0px",
                          color: "rgb(100,100,100)",
                        }}
                      >
                        Coming soon
                      </div>
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={showModal}
                      style={{
                        borderRadius: "20px",
                        fontSize: "14px",
                        fontWeight: "700",
                        fontFamily: "Poppins, sans-serif",
                        padding: "5px 25px",
                        marginRight: "25px",
                      }}
                    >
                      Send Application
                    </Button>
                    <Modal
                      width="30%"
                      style={{ padding: "20px 40px" }}
                      footer={[
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 16,
                          }}
                        >
                          <Button
                            type="primary"
                            onClick={() => {
                              console.log(
                                JSON.parse(Cookies.get("applyLoanForm"))
                              );
                              setAdminFee(
                                JSON.parse(Cookies.get("applyLoanForm"))
                                  .adminFee
                              );
                              setRequestLoanAmount(
                                JSON.parse(Cookies.get("applyLoanForm"))
                                  .requestingLoanAmount
                              );
                              setExpectingInterestRate(
                                JSON.parse(Cookies.get("applyLoanForm"))
                                  .expectingInterestRate
                              );
                              setLoanPeriod(
                                JSON.parse(Cookies.get("applyLoanForm"))
                                  .loanPeriod
                              );

                              showModal();
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            type="default"
                            onClick={() => {
                              Cookies.remove("applyLoanForm");
                              Cookies.remove("CBSImpLoan");
                              Cookies.remove("monthlyCommitment");
                              Cookies.remove("chartData");
                              Cookies.remove("enquiryDate");
                              Cookies.remove("chartMaxValue");
                              Cookies.remove(
                                "cBSImpLoanSupplementaryMonthlyInstallment"
                              );
                              Cookies.remove("companyKeyman");
                              Cookies.remove("creditFile");
                              Cookies.remove("noFinancialReport");
                              Cookies.remove("corporateStructure");
                              Cookies.remove("fileList");
                              Cookies.remove("fileList2");
                              Cookies.remove("fileList3");
                              Cookies.remove("fileList4");
                              Cookies.remove("fileList5");
                              Cookies.remove("fileList6");
                              Cookies.remove("fileList7");
                              Cookies.remove("fileList8");
                              Cookies.remove("fileList9");
                              Cookies.remove("creditReportFile");
                              setPreviouslyUnsubmittedModal(false);
                            }}
                          >
                            No
                          </Button>
                        </div>,
                      ]}
                      open={previouslyUnsubmittedModal}
                      // onOk={handleOk}
                      onCancel={() => {
                        setPreviouslyUnsubmittedModal(false);
                      }}
                    >
                      {Cookies.get("applyLoanForm") ? (
                        <div style={{ paddingRight: "15px" }}>
                          We noticed you've started a loan request earlier for{" "}
                          {JSON.parse(
                            Cookies.get("applyLoanForm")
                          )?.requestingLoanAmount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                          , would you like to continue where you left off?
                        </div>
                      ) : null}
                    </Modal>
                    <Modal
                      width="100%"
                      bodyStyle={{ padding: 0, height: "100vh" }} // remove padding and make body full height
                      style={{ top: 0, padding: 0 }} // position the modal at the top and remove padding
                      wrapClassName="full-screen-modal" // add a custom class name}
                      footer={[]}
                      open={isModalOpen}
                      // onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <ModalApplication
                        loanType={loanType}
                        loanCategory={loanCategory}
                        requestingLoanAmount={requestingLoanAmount}
                        expectingInterestRate={expectingInterestRate}
                        loanPeriod={loanPeriod}
                        request_principal={request_principal}
                        request_monthly_installment={
                          request_monthly_installment
                        }
                        total_admin_fee_by_month={total_admin_fee_by_month}
                        total_admin_fee={total_admin_fee}
                        request_revenue={request_revenue}
                        adminFee={adminFee}
                        promoCode={promoCode}
                        keymanChoices={keymanChoices}
                      />
                    </Modal>
                    <Modal
                      width="100%"
                      bodyStyle={{ padding: 0, height: "100vh" }} // remove padding and make body full height
                      style={{ top: 0, padding: 0 }} // position the modal at the top and remove padding
                      wrapClassName="full-screen-modal" // add a custom class name}
                      footer={[]}
                      open={isModalOpenExpress}
                      // onOk={handleOk}
                      onCancel={handleCancel2}
                    >
                      <ModalApplicationExpress
                        loanType={loanType}
                        loanCategory={loanCategory}
                        requestingLoanAmount={requestingLoanAmount}
                        expectingInterestRate={expectingInterestRate}
                        loanPeriod={loanPeriod}
                        request_principal={request_principal}
                        request_monthly_installment={
                          request_monthly_installment
                        }
                        total_admin_fee_by_month={total_admin_fee_by_month}
                        total_admin_fee={total_admin_fee}
                        request_revenue={request_revenue}
                      />
                    </Modal>
                    <Modal
                      footer={[
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 16,
                          }}
                        >
                          <Button type="primary" onClick={() => showModal2()}>
                            Yes
                          </Button>
                          <Button
                            type="default"
                            onClick={() => handleCancel3()}
                          >
                            No
                          </Button>
                        </div>,
                      ]}
                      open={isModal3}
                      // onOk={showModal2}
                      onCancel={handleCancel3}
                    >
                      Are you sure you want to apply the express loan?
                    </Modal>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Collapse
                      defaultActiveKey={[0]}
                      ghost
                      style={{ width: "100%" }}
                    >
                      <Panel
                        header="View your Repayment Schedule"
                        key="1"
                        onClick={handleCalculateLoan}
                      >
                        {isTableVisible && (
                          <TableRepayment data={data} setData={setData} />
                        )}
                      </Panel>
                    </Collapse>
                  </div>
                </Form>
              )
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "rgba(20, 44, 68, 0.8)",
                  fontWeight: 800,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "60%",
                      textAlign: "center",
                      lineHeight: "24px",
                    }}
                  >
                    To begin your loan application, please connect your account
                    with SingPass
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      onClick={() => {
                        window.location.href = "/stg-b-profile";
                      }}
                      style={{
                        border: "2px solid rgba(20, 44, 68, 0.8)",
                        borderRadius: "10px",
                        padding: "10px 20px",
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                        cursor: "pointer",
                        fontSize: 15,
                      }}
                    >
                      Sync With
                      <img
                        src={singlogo}
                        style={{
                          objectFit: "cover",
                          width: "100px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Box>
        </Box>
        {!isMobile ? (
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            backgroundColor="rgba(153, 178, 205, 0)"
            overflow="auto"
            minHeight="90.5vh"
          >
            {/* <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        // borderBottom={`4px solid ${colors.primary[500]}`}
                        // colors={colors.grey[100]}
                        marginTop="-17px"
                        p="15px"
                    >
                        <Typography color={colors.grey[920]} variant="h5" fontWeight="800" fontFamily="Manrope, sans-serif" fontSize="20px">
                            Ad Spot
                        </Typography>
                    </Box> */}
            <Box
              gridColumn="span 2"
              opacity="1.00"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="34%"
              position="relative" // ensure the Box is relatively positioned
            >
              <img
                src={adImage}
                onClick={(e) => {
                  e.preventDefault();
                  if (adLink) {
                    let url = adLink;

                    // Check if adLink starts with http:// or https://
                    if (!/^https?:\/\//i.test(adLink)) {
                      url = `https://${adLink}`;
                    }

                    console.log(url);
                    window.open(url, "_blank");
                  }
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  position: "absolute", // make the image positioned absolutely
                  top: 0,
                  left: 0,
                  zIndex: "1", // layer the image below the button
                  objectFit: "cover",
                  cursor: adLink ? "pointer" : "default",
                }}
              />

              {/* <Button
              style={{
                position: "absolute", // This makes the button float on top of the image
                top: "280px", // position it at the top of the box
                zIndex: "2", // layer the button above the image
                borderRadius: "20px",
                fontSize: "18px",
                fontWeight: "400",
                fontFamily: "Manrope, sans-serif",
                color: "#115CA6",
              }}
              onClick={(event) => handleApplyLoan(event)}
            >
              Apply Now
            </Button> */}
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor="rgba(153, 178, 205, 0)"
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[100]}
                p="10px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Recent Activities
                </Typography>
              </Box>

              {dataActivityLog && dataActivityLog.length > 0 ? (
                dataActivityLog.slice(0, 6).map((item, index) => (
                  <div
                    style={{
                      opacity:
                        index === 4
                          ? 0.6
                          : index === 5
                          ? 0.2
                          : index === 3
                          ? 0.9
                          : 1,
                      transitionDelay: `${index * 0.1}s`,
                    }}
                  >
                    <ContainerActivity
                      key={index}
                      icon={item.icon} // use the icon URL from the API
                      title={
                        item.user_id[0] === "L"
                          ? item.principal_name
                          : item.entity_name
                          ? item.entity_name
                          : item.principal_name || "-"
                      }
                      subtitle={item.description || "-"}
                      index={index}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "0px 0px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "350px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No recent activities
                  </div>
                </div>
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ApplyLoan;
