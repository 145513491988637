import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { tokens } from "../theme";
import cookies from 'js-cookie';
import moment from "moment";


const BarChart = ({ isDashboard = false, dateRange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [tickValues, setTickValues] = useState([]);

  // Function to count unique borrowers by employment_sector
  function countBorrowersBySector(industryAnalytics) {
    const borrowersBySector = industryAnalytics.reduce((acc, curr) => {
      const sector = curr.employment_sector;
      const borrower = curr.borrower;

      if (!acc[sector]) {
        acc[sector] = new Set();
      }

      acc[sector].add(borrower);
      return acc;
    }, {});

    const numberOfBorrowersBySector = {};

    for (const sector in borrowersBySector) {
      numberOfBorrowersBySector[sector] = borrowersBySector[sector].size;
    }

    return numberOfBorrowersBySector;
  }

  useEffect(() => {
    const fetchData = async () => {

      const response = await fetch(
        "https://www.smart-lend.com/api/analytics/industryAnalytics",
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + cookies.get("token")
          },
        }
      );
      const result = await response.json();
      const industryAnalyticsData = result['Industry Analytics'];
      const updatedFilteredData = combineData(dateRange, industryAnalyticsData, countBorrowersBySector);
      // const borrowerCounts = countBorrowersBySector(industryAnalyticsData); // Call the function here
      console.log(" value updatedFilteredData", updatedFilteredData);

      setTickValues([...new Set(updatedFilteredData.map((d) => d.borrower))]);
      setData(updatedFilteredData);
    };

    fetchData();
  }, [dateRange]);

  function combineData(dateRange, industryAnalyticsData, countBorrowersBySector) {
    console.log(" value industryAnalyticsData", industryAnalyticsData);
    // Create a new array of objects that includes both amount_receive and amount properties
    const combinedData = [];

    industryAnalyticsData.forEach((item) => {
      combinedData.push({ Date: moment(item.Date).format("YYYY-MM-DD"), employment_sector: item.employment_sector });
    });


    // Get the start and end dates from the dateRange prop
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let filteredData;
    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");

      // Filter the combined data based on the date range
      filteredData = combinedData.filter((item) => {
        return moment(item.date).isBetween(startDate, endDate, "day", "[]");
      }).sort((a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD"));
    } else {
      filteredData = industryAnalyticsData;
    }

    // Call countBorrowersBySector and update filteredData with borrower counts
    const borrowerCounts = countBorrowersBySector(filteredData);
    const updatedFilteredData = Object.entries(borrowerCounts).map(([key, value]) => ({
      employment_sector: key,
      borrower: value,
    }));

    return updatedFilteredData;
  }

  return (
    <ResponsiveBar
      data={data}
      enableGridY={true}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        tooltip: {
          container: {
            background: '#ffffff',
            color: '#0E5AA5',
            fontSize: '14px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
          },
        },
      }}
      keys={["borrower"]}
      indexBy="employment_sector"
      margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
      padding={0.9}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      defs={[
        {
          id: "gradient",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#0083b0" },
            { offset: 100, color: "#0E5AA5" },
          ],
        },
      ]}
      fill={[{ match: "*", id: "gradient" }]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 2,
        tickPadding: 2,
        tickRotation: 0,

      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,

        tickValues: tickValues,
        tickFormat: (value) => value.toFixed(0)
      }}

      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      isInteractive={true}
      yScale="auto"
      tooltip={({ value, indexValue, data }) => (
        <div
        style={{
          padding: "10px",
          border: "none",
          borderRadius: "10px",
          fontFamily: "Manrope, sans-serif",
          fontWeight: "800",
          fontSize: "10px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          background: "white",
          color: "rgba(0,0,0,0.4)",
        }}
      >
        <div>{indexValue}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#142C44CC",
            fontSize: "13px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
          }}
        >
          {value} Clients
        </div>
      </div>
      )}
    />
  );
};

export default BarChart;
