import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import Quill from "react-quill";
import ".././assets/css/app.css";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";

const NewsAndEvents = () => {
  const [screenWidth, setScreenWidth] = useState("1920");
  const [richText, setRichText] = useState(
    "<p>Type your news here, if you would like to add an image, navigate to the space you'd like to place your image and click on the image button on the toolbar.</p>"
  );

  const [newsandevents, setnewsandevents] = useState([]);

  const [activenande, setactivenande] = useState(null);

  const [fileUploading, setFileUploading] = useState(false);

  const createPost = async (data) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/news_and_events/createOrUpdate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        console.log(json);
        const updatingAnE = [...newsandevents];
        updatingAnE.push(json.data);
        setnewsandevents(updatingAnE);
        setactivenande(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updatePost = async (data) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/news_and_events/createOrUpdate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        console.log(json);
        const updatingAnE = [...newsandevents];
        const aneindex = updatingAnE.findIndex((ane) => ane.id === data.id);
        updatingAnE[aneindex] = json.data;
        setnewsandevents(updatingAnE);
        setactivenande(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllNews = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/news_and_events/getAll",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setnewsandevents(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllNews();
  }, []);

  const handleImageUploadElement = async (e) => {
    setFileUploading(true);
    console.log(e.target.files[0]);
    const files = e.target.files;

    const formData = new FormData();

    formData.append("file", files[0]);

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        const imageUrl = data.url;
        setactivenande({ ...activenande, thumbnail: imageUrl });
        setFileUploading(false);
      } else {
        // Handle non-OK response status
        console.error("Failed to upload files");
        setFileUploading(false);
      }
    } catch (error) {
      console.error(error);
      setFileUploading(false);
    }
  };

  const handleClickImageInput = () => {
    const fileInput = document.getElementById("imageInput2");
    fileInput.click();
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    // Intercept image insertion and modify image HTML
    clipboard: {
      matchVisual: false,
    },
  };

  // Modify HTML of the inserted image
  const handleImageInserted = (node) => {
    if (node.tagName === "IMG") {
      node.style.width = "100%";
    }
  };

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {activenande !== null ? (
        <div style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                marginBottom: "15px",
                cursor: "pointer",
              }}
              onClick={() => setactivenande(null)}
            >
              Create a News/Events
            </div>
            <div
              style={{
                background: "rgb(14, 90, 165)",
                padding: "8px 25px",
                borderRadius: 8,
                color: "white",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                cursor: "pointer",
              }}
              onClick={() => {
                if (activenande.id) {
                  // Update
                  updatePost(activenande);
                } else {
                  // Publish
                  createPost(activenande);
                }
              }}
            >
              {activenande.id ? "Update" : "Publish"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              padding: "20px 0px",
              gap: "2%",
            }}
          >
            <div style={{ width: "32%" }}>
              <div>Content Title</div>
              <div>
                <input
                  placeholder={"News title ..."}
                  style={{
                    background: "transparent",
                    borderRadius: 8,
                    border: "1px solid grey",
                    outline: "none",
                    width: "calc(100% - 30px)",
                    padding: "8px 15px",
                    marginTop: 10,
                  }}
                  onChange={(e) => {
                    const updatingactiveaande = { ...activenande };
                    updatingactiveaande.content_title = e.target.value;
                    setactivenande(updatingactiveaande);
                  }}
                  value={activenande.content_title || ""}
                />
              </div>
            </div>
            <div style={{ width: "32%" }}>
              <div>Type of Content</div>
              <div>
                <select
                  onChange={(e) => {
                    const updatingactiveaande = { ...activenande };
                    updatingactiveaande.type_of_content = e.target.value;
                    setactivenande(updatingactiveaande);
                  }}
                  value={activenande.type_of_content || ""}
                  style={{
                    background: "transparent",
                    borderRadius: 8,
                    border: "1px solid grey",
                    outline: "none",
                    width: "calc(100% - 30px)",
                    padding: "8px 15px",
                    marginTop: 10,
                  }}
                >
                  <option>News</option>
                  <option>Events</option>
                </select>
              </div>
              <div style={{ marginTop: "15px" }}>Shown Publish Date</div>
              <div>
                <input
                  onChange={(e) => {
                    const updatingactiveaande = { ...activenande };
                    updatingactiveaande.publish_date = e.target.value;
                    setactivenande(updatingactiveaande);
                  }}
                  value={activenande.publish_date || ""}
                  type="date"
                  style={{
                    background: "transparent",
                    borderRadius: 8,
                    border: "1px solid grey",
                    outline: "none",
                    width: "calc(100% - 30px)",
                    padding: "8px 15px",
                    marginTop: 10,
                  }}
                />
              </div>
            </div>
            <div style={{ width: "32%" }}>
              <div>News/Events Thumbnail</div>
              <input
                id="imageInput2"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleImageUploadElement(e);
                }}
              />
              <div
                style={{
                  width: "20vw",
                  height: "15vw",
                  borderRadius: 8,
                  overflow: "hidden",
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: activenande.thumbnail
                    ? "none"
                    : "10px solid lightgrey",
                  outline: "1px solid grey",
                  overflow: "hidden",
                }}
                onClick={handleClickImageInput}
              >
                {activenande.thumbnail ? (
                  <img
                    src={activenande.thumbnail}
                    style={{
                      width: "20vw",
                      height: "15vw",
                      objectFit: "cover",
                    }}
                  />
                ) : fileUploading ? (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    }}
                  >
                    <CircularProgress color="primary" size={50} style={{}} />
                  </div>
                ) : (
                  <CropOriginalIcon
                    style={{ color: "lightgrey", fontSize: 110 }}
                  />
                )}
              </div>
            </div>
          </div>
          <ReactQuill
            theme="snow"
            onChange={(content, delta, source, editor) => {
              const updatingactiveaande = { ...activenande };
              updatingactiveaande.content = content;
              setactivenande(updatingactiveaande);
            }}
            value={activenande.content || ""}
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
            ]}
            modules={modules}
            // Hook into image insertion event
            onEditorChange={setRichText}
            onEditorCreate={(editor) => {
              editor
                .getModule("clipboard")
                .on("paste-image", handleImageInserted);
            }}
          />
        </div>
      ) : (
        <div style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                marginBottom: "15px",
              }}
            >
              News & Events
            </div>
            <div
              style={{
                background: "rgb(14, 90, 165)",
                padding: "8px 25px",
                borderRadius: 8,
                color: "white",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                cursor: "pointer",
              }}
              onClick={() =>
                setactivenande({
                  content_title: "",
                  type_of_content: "News",
                  publish_date: null,
                  thumbnail: null,
                  content: "",
                  status: true,
                })
              }
            >
              New Post
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "95%",
              flexWrap: "wrap",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 10,
            }}
          >
            {newsandevents.map((nae, index) => (
              <div
                key={index}
                onClick={() => {
                  console.log(nae);
                  setactivenande(nae);
                }}
                style={{
                  background: nae.status ? "#0e5aa52A" : "rgba(0,0,0,0.1)",
                  borderRadius: 8,
                  padding: 10,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    background: "rgba(256,256,256,0.5)",
                    width: "18vw",
                    height: "12vw",
                    borderRadius: 6,
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={nae.thumbnail}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      color: "#142C44CC",
                      fontSize: "15px",
                      fontFamily: "Manrope,sans-serif",
                      fontWeight: 800,
                      marginBottom: "15px",
                    }}
                  >
                    {nae.content_title.length > 35
                      ? nae.content_title.slice(0, 35) + "..."
                      : nae.content_title}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background: "rgb(14, 90, 165)",
                        padding: "3px 15px",
                        borderRadius: 8,
                        color: "white",
                        fontFamily: "Manrope,sans-serif",
                        fontWeight: 800,
                        fontSize: 12,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        const updatingStatus = { ...nae };
                        updatingStatus.status = !nae.status;
                        console.log(updatingStatus);
                        updatePost(updatingStatus);
                      }}
                    >
                      {nae.type_of_content}
                    </div>
                    <div
                      style={{
                        color: "rgb(14, 90, 165)",
                        fontFamily: "Manrope,sans-serif",
                        fontWeight: 800,
                        fontSize: 10,
                      }}
                    >
                      {nae.publish_date}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsAndEvents;
