import { Form, Input, Select, Button, Col, Row } from 'antd';
import { DatePicker, Space } from 'antd';
import React, {useState, useEffect} from 'react';

const { Option } = Select;
const onChange = (date, dateString) => {
    console.log(date, dateString);
};

const FinancialHighlights = () => {
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
        vertical: true,
    };

    const tailLayout = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [screenWidth, setScreenWidth] = useState("1920");

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      // Call handleResize initially to set the initial screen width
      handleResize();
  
      // Add event listener for resize
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
        <>
        {screenWidth > 1200 ? (
 <Form style={{ minHeight: "73vh" }}
 {...layout}
 name="basic"
 initialValues={{
     remember: true,
 }}
 onFinish={onFinish}
 onFinishFailed={onFinishFailed}
>

 <Row>
     <Col span={8}>
         <Form.Item
             label="Current Period Start Date"
             name="currentPeriodStartDate"
         >
             <h2 style={{fontSize: screenWidth > 1200 ? "" : "14px"}}>DD/MM/YYYY</h2>
         </Form.Item>
     </Col>
     <Col span={8}>
         <Form.Item
             label="Current Period End Date"
             name="currentPeriodEndDate"
         >

             <h2 style={{fontSize: screenWidth > 1200 ? "" : "14px"}}>DD/MM/YYYY</h2>
         </Form.Item>
     </Col>

 </Row>



 <Row>
     <Col span={8}>
         <Form.Item
             label="Is Audited"
             name="isAudited"
         >
             <Select style={{ width: '90%' }}>
                 <Option value="John Smith">John Smith</Option>
                 <Option value="Jane Doe">Jane Doe</Option>
                 <Option value="Bob Johnson">Bob Johnson</Option>
             </Select>
         </Form.Item>
     </Col>
     <Col span={8}>
         <Form.Item
             label="Currency"
             name="currency"
         >

             <Select style={{ width: '90%' }}>
                 <Option value="John Smith">John Smith</Option>
                 <Option value="Jane Doe">Jane Doe</Option>
                 <Option value="Bob Johnson">Bob Johnson</Option>
             </Select>
         </Form.Item>
     </Col>

 </Row>
 <Row>
     <h6>Company</h6>
 </Row>
 <Row>
     <hr style={{ color: "grey", width: "90%" }} />
 </Row>
 <Row>

     <Col span={8}>
         <Form.Item
             label="Company Revenue"
             name="companyRevenue"
         >

             <Input style={{ width: '90%' }} />
         </Form.Item>
     </Col>
     <Col span={8}>
         <Form.Item
             label="Company Profit Loss Before Tax"
             name="companyProfitLossBeforeTax"
         >
             <DatePicker style={{ width: '90%' }} onChange={onChange} />
         </Form.Item>
     </Col>
     <Col span={8}>
         <Form.Item
             label="Company Profit Loss After Tax"
             name="companyProfitLossAfterTax"
         >
             <DatePicker style={{ width: '90%' }} onChange={onChange} />
         </Form.Item>
     </Col>
 </Row>

 <Row>
     <h6>Group</h6>
 </Row>
 <Row>
     <hr style={{ color: "grey", width: "90%" }} />
 </Row>
 <Row>

     <Col span={8}>
         <Form.Item
             label="Group Revenue"
             name="groupRevenue"
         >

             <Input style={{ width: '90%' }} />
         </Form.Item>
     </Col>
     <Col span={8}>
         <Form.Item
             label="Group Profit Up Capital Amount"
             name="groupProfitUpCapital"
         >
             <DatePicker style={{ width: '90%' }} onChange={onChange} />
         </Form.Item>
     </Col>
 </Row>
 <Row>

     <Col span={8}>
         <Form.Item
             label="Company Profit Loss Before Tax"
             name="companyProfitLossBeforeTax"
         >

             <Input style={{ width: '90%' }} />
         </Form.Item>
     </Col>
     <Col span={8}>
         <Form.Item
             label="Company Profit Loss After Tax"
             name="companyProfitLossAfterTax"
         >
             <DatePicker style={{ width: '90%' }} onChange={onChange} />
         </Form.Item>
     </Col>
 </Row>


 <Form.Item {...tailLayout}>
     <Button type="default" htmlType="button">
         Edit
     </Button>
     <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }}>
         Save
     </Button>
 </Form.Item>
</Form>
        ) : (
            <Form style={{ minHeight: "73vh" }}
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >

            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Current Period Start Date"
                        name="currentPeriodStartDate"
                    >
                        <h2 style={{fontSize: screenWidth > 1200 ? "" : "14px"}}>DD/MM/YYYY</h2>
                    </Form.Item>
                </Col>
               

            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Current Period End Date"
                        name="currentPeriodEndDate"
                    >

                        <h2 style={{fontSize: screenWidth > 1200 ? "" : "14px"}}>DD/MM/YYYY</h2>
                    </Form.Item>
                </Col>
            </Row>



            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Is Audited"
                        name="isAudited"
                    >
                        <Select style={{ width: '90%' }}>
                            <Option value="John Smith">John Smith</Option>
                            <Option value="Jane Doe">Jane Doe</Option>
                            <Option value="Bob Johnson">Bob Johnson</Option>
                        </Select>
                    </Form.Item>
                </Col>
             

            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Currency"
                        name="currency"
                    >

                        <Select style={{ width: '90%' }}>
                            <Option value="John Smith">John Smith</Option>
                            <Option value="Jane Doe">Jane Doe</Option>
                            <Option value="Bob Johnson">Bob Johnson</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <h6>Company</h6>
            </Row>
            <Row>
                <hr style={{ color: "grey", width: "90%" }} />
            </Row>
            <Row>

                <Col span={24}>
                    <Form.Item
                        label="Company Revenue"
                        name="companyRevenue"
                    >

                        <Input style={{ width: '90%' }} />
                    </Form.Item>
                </Col>
               
                
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Company Profit Loss Before Tax"
                        name="companyProfitLossBeforeTax"
                    >
                        <DatePicker style={{ width: '90%' }} onChange={onChange} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Company Profit Loss After Tax"
                        name="companyProfitLossAfterTax"
                    >
                        <DatePicker style={{ width: '90%' }} onChange={onChange} />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <h6>Group</h6>
            </Row>
            <Row>
                <hr style={{ color: "grey", width: "90%" }} />
            </Row>
            <Row>

                <Col span={24}>
                    <Form.Item
                        label="Group Revenue"
                        name="groupRevenue"
                    >

                        <Input style={{ width: '90%' }} />
                    </Form.Item>
                </Col>
             
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Group Profit Up Capital Amount"
                        name="groupProfitUpCapital"
                    >
                        <DatePicker style={{ width: '90%' }} onChange={onChange} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>

                <Col span={24}>
                    <Form.Item
                        label="Company Profit Loss Before Tax"
                        name="companyProfitLossBeforeTax"
                    >

                        <Input style={{ width: '90%' }} />
                    </Form.Item>
                </Col>
               
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Company Profit Loss After Tax"
                        name="companyProfitLossAfterTax"
                    >
                        <DatePicker style={{ width: '90%' }} onChange={onChange} />
                    </Form.Item>
                </Col>
            </Row>


            <Form.Item {...tailLayout}>
                <Button type="default" htmlType="button">
                    Edit
                </Button>
                <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }}>
                    Save
                </Button>
            </Form.Item>
        </Form>
        )}
        </>
    );
};

export default FinancialHighlights;

