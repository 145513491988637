import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Col, Row } from "antd";
import Cookies from "js-cookie";
import { DatePicker, Space } from "antd";
const { Option } = Select;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const FinancialHighlights = () => {
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const [fincanceHightlights, setFinanceHightlights] = useState([]);

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        const entityDetail = displaySingpassData.singpass_data.entity;

        console.log("details 1", entityDetail);

        // Ensure that you are setting the array of shareholders
        if (
          entityDetail &&
          entityDetail.financials &&
          Array.isArray(entityDetail.financials["highlights"])
        ) {
          setFinanceHightlights(entityDetail.financials["highlights"]);
          console.log("details", entityDetail.financials["highlights"]);
        } else {
          // Handle the case where financials data is not an array or not available
          setFinanceHightlights([]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Form
      style={{ minHeight: "73vh" }}
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {fincanceHightlights.length > 0 ? (
        fincanceHightlights.map((item, index) => (
          <React.Fragment key={index}>
            <Row>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Current Period Start Date">
                  <h2 style={{ fontSize: screenWidth > 1200 ? "" : "14px" }}>
                    {item["current-period-start-date"]?.value || "-"}
                  </h2>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Current Period End Date">
                  <h2 style={{ fontSize: screenWidth > 1200 ? "" : "14px" }}>
                    {item["current-period-end-date"]?.value || "-"}
                  </h2>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Is Audited">
                  <Select
                    style={{ width: "90%" }}
                    value={item["is-audited"]?.value}
                    disabled={
                      !!item["is-audited"]?.value || !item["is-audited"]?.value
                    }
                  >
                    <Option value="John Smith">John Smith</Option>
                    <Option value="Jane Doe">Jane Doe</Option>
                    <Option value="Bob Johnson">Bob Johnson</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Currency">
                  <Select
                    style={{ width: "90%" }}
                    value={item.currency?.desc}
                    disabled={!!item.currency?.desc || !item.currency?.desc}
                  >
                    <Option value="John Smith">John Smith</Option>
                    <Option value="Jane Doe">Jane Doe</Option>
                    <Option value="Bob Johnson">Bob Johnson</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <h6>Company</h6>
            </Row>
            <Row>
              <hr style={{ color: "grey", width: "90%" }} />
            </Row>
            <Row>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Company Revenue">
                  <Input
                    style={{ width: "90%" }}
                    value={item["company-revenue"]?.value}
                    disabled={
                      !!item["company-revenue"]?.value ||
                      !item["company-revenue"]?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Company Profit Loss Before Tax">
                  <Input
                    style={{ width: "90%" }}
                    value={item["company-profit-loss-before-tax"]?.value}
                    disabled={
                      !!item["company-profit-loss-before-tax"]?.value ||
                      !item["company-profit-loss-before-tax"]?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Company Profit Loss After Tax">
                  <Input
                    style={{ width: "90%" }}
                    value={item["company-profit-loss-after-tax"]?.value}
                    disabled={
                      !!item["company-profit-loss-after-tax"]?.value ||
                      !item["company-profit-loss-after-tax"]?.value
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <h6>Group</h6>
            </Row>
            <Row>
              <hr style={{ color: "grey", width: "90%" }} />
            </Row>
            <Row>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Group Revenue">
                  <Input
                    style={{ width: "90%" }}
                    value={item["group-revenue"]?.value}
                    disabled={
                      !!item["group-revenue"]?.value ||
                      !item["group-revenue"]?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Group Profit Up Capital Amount">
                  <Input
                    style={{ width: "90%" }}
                    value={item["group-capital-paid-up-capital-amount"]?.value}
                    disabled={
                      !!item["group-capital-paid-up-capital-amount"]?.value ||
                      !item["group-capital-paid-up-capital-amount"]?.value
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Company Profit Loss Before Tax">
                  <Input
                    style={{ width: "90%" }}
                    value={item["group-profit-loss-before-tax"]?.value}
                    disabled={
                      !!item["group-profit-loss-before-tax"]?.value ||
                      !item["group-profit-loss-before-tax"]?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Company Profit Loss After Tax">
                  <Input
                    style={{ width: "90%" }}
                    value={item["group-profit-loss-after-tax"]?.value}
                    disabled={
                      !!item["group-profit-loss-after-tax"]?.value ||
                      !item["group-profit-loss-after-tax"]?.value
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <hr style={{ color: "grey", width: "90%" }} />
            </Row>
          </React.Fragment>
        ))
      ) : (
        <div
          style={{
            width: "100%",
            padding: "0px 0px",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: "0px 15px",
              background: "rgba(255,255,255,0.7)",
              borderRadius: "10px",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Manrope, sans-serif",
              fontSize: "0.8em",
              fontWeight: "600",
              color: "#00000066",
            }}
          >
            You have no finance highlights
          </div>
        </div>
      )}
      {/* <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div> */}
    </Form>
  );
};

export default FinancialHighlights;
