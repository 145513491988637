import { Form, Input, Select, Button, Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
const { Option } = Select;
// const onChange = (date, dateString) => {
//     console.log(date, dateString);
// };

const EntityProfile = () => {
  const [uen, setUEN] = useState("");
  const [primaryActivity, setPrimaryActivity] = useState("");
  const [entityName, setEntityName] = useState("");
  const [entityType, setEntityType] = useState("");
  const [entityStatus, setEntityStatus] = useState("");
  const [businessConstitution, setBusinessConstitution] = useState("");
  const [ownership, setOwnership] = useState("");
  const [countryOfIncorporation, setCountryOfIncorporation] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [businessExpiryDate, setBusinessExpiryDate] = useState("");
  const [companyType, setCompanyType] = useState("");

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        //  const personDetail = displaySingpassData.singpass_data[0].person;
        // const entityDetail = displaySingpassData.singpass_data[0].entity;
        // const entityDetailBasicProfile = displaySingpassData.singpass_data[0].entity["basic-profile"];

        const personDetail = displaySingpassData.singpass_data.person;
        const entityDetail = displaySingpassData.singpass_data.entity;
        const entityDetailBasicProfile =
          displaySingpassData.singpass_data.entity["basic-profile"];
        console.log(
          "entityDetailBasicProfile value:",
          entityDetailBasicProfile
        );
        setUEN(entityDetailBasicProfile.uen.value);
        setPrimaryActivity(entityDetailBasicProfile["primary-activity"].desc);
        setEntityName(entityDetailBasicProfile["entity-name"].value);
        setEntityType(entityDetailBasicProfile["entity-type"].desc);
        setEntityStatus(entityDetailBasicProfile["entity-status"].value);
        setBusinessConstitution(entityDetailBasicProfile["company-type"].desc);
        setOwnership(entityDetailBasicProfile.ownership.desc);
        setCountryOfIncorporation(
          entityDetailBasicProfile["country-of-incorporation"].desc
        );
        setRegistrationDate(
          entityDetailBasicProfile["registration-date"].value
        );
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Form
      style={{ minHeight: "73vh" }}
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={isMobile ? 24 : 16}>
          <Form.Item label="UEN">
            <Input
              style={{ width: "95%" }}
              value={uen}
              disabled={!!uen || !uen}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Primary Activity">
            <Input
              style={{ width: "90%" }}
              value={primaryActivity}
              disabled={!!primaryActivity || !primaryActivity}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Entity Name">
            <Select
              style={{ width: "90%" }}
              value={entityName}
              disabled={!!entityName || !entityName}
            >
              <Option value="John Smith">John Smith</Option>
              <Option value="Jane Doe">Jane Doe</Option>
              <Option value="Bob Johnson">Bob Johnson</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Entity Type">
            <Select
              style={{ width: "90%" }}
              value={entityType}
              disabled={!!entityType || !entityType}
            >
              <Option value="Singapore Citizen">Singapore Citizen</Option>
              <Option value="Permanent Resident">Permanent Resident</Option>
              <Option value="Foreigner">Foreigner</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Entity Status">
            <Select
              style={{ width: "90%" }}
              value={entityStatus}
              disabled={!!entityStatus || !entityStatus}
            >
              <Option value="Singaporean">Singaporean</Option>
              <Option value="Malaysian">Malaysian</Option>
              <Option value="Chinese">Chinese</Option>
              <Option value="Indian">Indian</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Business Constitution">
            <Select
              style={{ width: "90%" }}
              value={businessConstitution}
              onChange={(value) => setBusinessConstitution(value)}
              disabled={!!businessConstitution || !businessConstitution}
            >
              <Option value="John Smith">John Smith</Option>
              <Option value="Jane Doe">Jane Doe</Option>
              <Option value="Bob Johnson">Bob Johnson</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Ownership">
            <Input
              style={{ width: "90%" }}
              value={ownership}
              disabled={!!ownership || !ownership}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Country of incorporation">
            <Select
              style={{ width: "90%" }}
              value={countryOfIncorporation}
              disabled={!!countryOfIncorporation || !countryOfIncorporation}
            >
              <Option value="Singaporean">Singaporean</Option>
              <Option value="Malaysian">Malaysian</Option>
              <Option value="Chinese">Chinese</Option>
              <Option value="Indian">Indian</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Registration Date">
            <Input
              style={{ width: "100%" }}
              value={registrationDate}
              disabled={!!registrationDate || !registrationDate}
            />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item style={{ marginLeft: '20%' }}
              label="Business Expiry Date"
              name="businessExpiryDate"
          >
           
             
                  <DatePicker style={{ width: '100%' }} onChange={onChange} />
            
         
          </Form.Item>
      </Col> */}
      </Row>
      {/* <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div> */}
    </Form>
  );
};

export default EntityProfile;
