import { Form, Input, Select, Button, Col, Row } from 'antd';
import { DatePicker, Space } from 'antd';
import { useState } from "react";
import { useEffect } from "react";
const { Option } = Select;
const onChange = (date, dateString) => {
    console.log(date, dateString);
};

const Employment = () => {
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
        vertical: true,
    };

    const tailLayout = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [screenWidth, setScreenWidth] = useState("1920");

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      // Call handleResize initially to set the initial screen width
      handleResize();
  
      // Add event listener for resize
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
        <>
        {screenWidth > 1200 ? (
          <Form
            style={{ minHeight: "73vh" }}
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={12}>
                <Form.Item label="Employer Name" name="employerName">
                  <Input type="text" style={{ width: "95%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: "5%" }}
                  label="Occupation"
                  name="occupation"
                >
                  <Input type="text" style={{ width: "95%" }} />
                </Form.Item>
              </Col>
            </Row>
  
            <Form.Item {...tailLayout}>
              <Button type="default" htmlType="button">
                Edit
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "10px" }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Form
            style={{ minHeight: "73vh" }}
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={24}>
                <Form.Item label="Employer Name" name="employerName">
                  <Input type="text" style={{ width: "90%" }} />
                </Form.Item>
              </Col>
              
            </Row>
            <Row>
            <Col span={24}>
                <Form.Item
                
                  label="Occupation"
                  name="occupation"
                >
                  <Input type="text" style={{ width: "90%" }} />
                </Form.Item>
              </Col>
            </Row>
  
            <Form.Item {...tailLayout}>
              <Button type="default" htmlType="button">
                Edit
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "10px" }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </>
    );
};

export default Employment;

