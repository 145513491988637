import React from "react";

const LandingPagelayout = ({ children }) => {
  return (
    <div className="landing-page-layout">
      <div className="landing-page-content">{children}</div>
      
    </div>
  );
};

export default LandingPagelayout;
