import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { tokens } from "../theme";
import cookies from "js-cookie";
import moment from "moment";

const ClientBarChart = ({ isDashboard = false, dateRange, props }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [tickValues, setTickValues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/analytics/analyticsClient",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
        }
      );
      const result = await response.json();
      const clientsData = result["Clients"];
      const filteredData = combineData(dateRange, clientsData);
      setData(filteredData);

      const maxCount = Math.max(...filteredData.map((item) => item.count));
      setTickValues([...Array(maxCount + 1).keys()]);
    };

    fetchData();
  }, [dateRange]);

  function combineData(dateRange, clientsData) {
    console.log(" value clientsData", clientsData);

    const combinedData = [];

    clientsData.forEach((item) => {
      combinedData.push({
        date: moment(item.date).format("YYYY-MM-DD"),
        client: item.client,
      });
    });

    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let filteredData;
    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");

      filteredData = combinedData
        .filter((item) => {
          return moment(item.date).isBetween(startDate, endDate, "day", "[]");
        })
        .sort(
          (a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD")
        );
    } else {
      filteredData = combinedData;
    }

    const countPerDate = filteredData.reduce((accumulator, item) => {
      if (accumulator[item.date]) {
        accumulator[item.date] += 1;
      } else {
        accumulator[item.date] = 1;
      }
      return accumulator;
    }, {});

    const finalData = Object.entries(countPerDate).map(([date, count]) => ({
      date,
      count,
    }));

    return finalData;
  }

  function calculateAverageClients(clientsData) {
    const monthCount = {}; // To store the number of occurrences of each month
    const monthSum = {}; // To store the sum of clients for each month

    clientsData.forEach(({ month, client }) => {
      if (monthCount[month]) {
        monthCount[month] += 1;
        monthSum[month] += client;
      } else {
        monthCount[month] = 1;
        monthSum[month] = client;
      }
    });

    const averageClientsPerMonth = {};

    for (const month in monthCount) {
      averageClientsPerMonth[month] = Math.round(
        monthSum[month] / monthCount[month]
      );
    }

    return averageClientsPerMonth;
  }

  const barColor = (data) => {
    const percentage = data.count / (tickValues.length - 1);
    const gradient = `linear-gradient(to right, #0083b0 ${
      percentage * 100
    }%, #0E5AA5 ${percentage * 100}%)`;
    return gradient;
  };

  const customXAxisTickFormatter = (value) => {
    const dateParts = value.split("-");
    if (dateParts.length === 3) {
      const day = dateParts[2];
      const month = dateParts[1];
      const year = dateParts[0];

      // Map month numbers to month names (e.g., '09' => 'Sep')
      const monthNames = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      };

      // Format the date (e.g., '19 Sep' or '30 Dec')
      const formattedDate = `${monthNames[month]} ${day}`;

      return formattedDate;
    }

    // Return the original value if it doesn't match the expected format
    return value;
  };

  return (
    <ResponsiveBar
      data={data}
      enableGridY={true}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        tooltip: {
          container: {
            background: "#ffffff",
            color: "#0E5AA5",
            fontSize: "14px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            borderRadius: "40px",
          },
        },
      }}
      keys={["count"]}
      indexBy="date"
      margin={{ top: 10, right: 10, bottom: 50, left: 15 }}
      padding={0.8}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      defs={[
        {
          id: "gradient",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#115CA6" },
            { offset: 100, color: "#115CA6" },
          ],
        },
      ]}
      fill={[{ match: "*", id: "gradient" }]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 2,
        tickPadding: 2,
        tickRotation: 0,
        legend: isDashboard ? undefined : "month",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "client",
        legendPosition: "middle",
        legendOffset: 32,
        tickValues: tickValues,
        tickFormat: (value) => value.toFixed(0),
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      yScale="auto"
      tooltip={({ value, indexValue, data }) => (
        <div
          style={{
            padding: "10px",
            border: "none",
            borderRadius: "10px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            background: "white",
            color: "rgba(0,0,0,0.4)",
          }}
        >
          <div>{customXAxisTickFormatter(indexValue)}</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#142C44CC",
              fontSize: "13px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            {value} Clients
          </div>
        </div>
      )}
    />
  );
};

export default ClientBarChart;
