import { Form, Input, Select, Button, Col, Row } from 'antd';
import { DatePicker, Space } from 'antd';
import {useState, useEffect} from 'react';
const { Option } = Select;
const onChange = (date, dateString) => {
    console.log(date, dateString);
};

const EntityProfile = () => {
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
        vertical: true,
    };

    const tailLayout = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [screenWidth, setScreenWidth] = useState("1920");

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      // Call handleResize initially to set the initial screen width
      handleResize();
  
      // Add event listener for resize
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
        <>
        {screenWidth > 1200 ? (
          <Form style={{minHeight:"73vh"}}
          {...layout}
          name="basic"
          initialValues={{
              remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
              <Col span={16}>
                  <Form.Item label="UEN" name="uen" rules={[{ required: true, message: 'Please input your UEN!' }]}>
                      <Input style={{ width: '95%' }} />
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item   
                      label="Primary Activity"
                      name="primaryActivity"
                      rules={[
                          {
                              required: true,
                              message: 'Please input your registered address!',
                          },
                      ]}
                  >
                      <Input style={{ width: '90%' }} />
                  </Form.Item>
              </Col>
          </Row>
        
        
          
        
          <Row>
              <Col span={8}>
                  <Form.Item
                      label="Entity Name"
                      name="entityName"
                  >
                      <Select style={{ width: '90%' }}>
                          <Option value="John Smith">John Smith</Option>
                          <Option value="Jane Doe">Jane Doe</Option>
                          <Option value="Bob Johnson">Bob Johnson</Option>
                      </Select>
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item
                      label="Entity Type"
                      name="entityType"
                  >
                      <Select style={{ width: '90%' }}>
                          <Option value="Singapore Citizen">Singapore Citizen</Option>
                          <Option value="Permanent Resident">Permanent Resident</Option>
                          <Option value="Foreigner">Foreigner</Option>
                      </Select>
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item
                      label="Entity Status"
                      name="entityStatus"
                  >
                      <Select style={{ width: '90%' }}>
                          <Option value="Singaporean">Singaporean</Option>
                          <Option value="Malaysian">Malaysian</Option>
                          <Option value="Chinese">Chinese</Option>
                          <Option value="Indian">Indian</Option>
                          <Option value="Other">Other</Option>
                      </Select>
                  </Form.Item>
              </Col>
          </Row>
          <Row>
              <Col span={8}>
                  <Form.Item
                      label="Business Constitution"
                      name="businessConstitution"
                  >
                      <Select style={{ width: '90%' }}>
                          <Option value="John Smith">John Smith</Option>
                          <Option value="Jane Doe">Jane Doe</Option>
                          <Option value="Bob Johnson">Bob Johnson</Option>
                      </Select>
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item
                      label="Ownership"
                      name="ownership"
                  >
        
                      <Input style={{ width: '90%' }}/>
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item
                      label="Country of incorporation"
                      name="countryOfIncorporation"
                  >
                      <Select style={{ width: '90%' }}>
                          <Option value="Singaporean">Singaporean</Option>
                          <Option value="Malaysian">Malaysian</Option>
                          <Option value="Chinese">Chinese</Option>
                          <Option value="Indian">Indian</Option>
                          <Option value="Other">Other</Option>
                      </Select>
                  </Form.Item>
              </Col>
          </Row>
          <Row>
              <Col span={12}>
                  <Form.Item 
                      label="Registration Date"
                      name="registrationDate"
                  >
                    
                      
                          <DatePicker style={{ width: '100%' }} onChange={onChange} />
                     
                  
                  </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item style={{ marginLeft: '20%' }}
                      label="Business Expiry Date"
                      name="businessExpiryDate"
                  >
                   
                     
                          <DatePicker style={{ width: '100%' }} onChange={onChange} />
                    
                 
                  </Form.Item>
              </Col>
          </Row>
        
          <Form.Item {...tailLayout}>
              <Button type="default" htmlType="button">
                  Edit
              </Button>
              <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }}>
                  Save
              </Button>
          </Form.Item>
        </Form>
        ) : (
            <Form style={{minHeight:"73vh"}}
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Row>
                <Col span={24}>
                    <Form.Item label="UEN" name="uen" rules={[{ required: true, message: 'Please input your UEN!' }]}>
                        <Input style={{ width: '90%' }} />
                    </Form.Item>
                </Col>
             
            </Row>
         <Row>
         <Col span={24}>
                    <Form.Item   
                        label="Primary Activity"
                        name="primaryActivity"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your registered address!',
                            },
                        ]}
                    >
                        <Input style={{ width: '90%' }} />
                    </Form.Item>
                </Col>
         </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Entity Name"
                        name="entityName"
                    >
                        <Select style={{ width: '90%' }}>
                            <Option value="John Smith">John Smith</Option>
                            <Option value="Jane Doe">Jane Doe</Option>
                            <Option value="Bob Johnson">Bob Johnson</Option>
                        </Select>
                    </Form.Item>
                </Col>
              
               
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Entity Type"
                        name="entityType"
                    >
                        <Select style={{ width: '90%' }}>
                            <Option value="Singapore Citizen">Singapore Citizen</Option>
                            <Option value="Permanent Resident">Permanent Resident</Option>
                            <Option value="Foreigner">Foreigner</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Entity Status"
                        name="entityStatus"
                    >
                        <Select style={{ width: '90%' }}>
                            <Option value="Singaporean">Singaporean</Option>
                            <Option value="Malaysian">Malaysian</Option>
                            <Option value="Chinese">Chinese</Option>
                            <Option value="Indian">Indian</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Business Constitution"
                        name="businessConstitution"
                    >
                        <Select style={{ width: '90%' }}>
                            <Option value="John Smith">John Smith</Option>
                            <Option value="Jane Doe">Jane Doe</Option>
                            <Option value="Bob Johnson">Bob Johnson</Option>
                        </Select>
                    </Form.Item>
                </Col>
          
                
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Ownership"
                        name="ownership"
                    >
        
                        <Input style={{ width: '90%' }}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item
                        label="Country of incorporation"
                        name="countryOfIncorporation"
                    >
                        <Select style={{ width: '90%' }}>
                            <Option value="Singaporean">Singaporean</Option>
                            <Option value="Malaysian">Malaysian</Option>
                            <Option value="Chinese">Chinese</Option>
                            <Option value="Indian">Indian</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item 
                        label="Registration Date"
                        name="registrationDate"
                    >
                      
                        
                            <DatePicker style={{ width: '90%' }} onChange={onChange} />
                       
                    
                    </Form.Item>
                </Col>
               
            </Row>
            <Row>
            <Col span={24}>
                    <Form.Item 
                        label="Business Expiry Date"
                        name="businessExpiryDate"
                    >
                     
                       
                            <DatePicker style={{ width: '90%' }} onChange={onChange} />
                      
                   
                    </Form.Item>
                </Col>
            </Row>
         
            <Form.Item {...tailLayout}>
                <Button type="default" htmlType="button">
                    Edit
                </Button>
                <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }}>
                    Save
                </Button>
            </Form.Item>
        </Form>
        )}
        </>
    );
};

export default EntityProfile;

