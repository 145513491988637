import { Form, Input, Select, Button, Col, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';


import { DatePicker, Space } from 'antd';
const { Option } = Select;
const onChange = (date, dateString) => {
    console.log(date, dateString);
};

const Shareholders = () => {

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
        vertical: true,
    };

    const tailLayout = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [screenWidth, setScreenWidth] = useState("1920");

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      // Call handleResize initially to set the initial screen width
      handleResize();
  
      // Add event listener for resize
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
        <>
        {screenWidth > 1200 ? (
         <Form style={{ minHeight: "73vh" }}
         {...layout}
         name="basic"
         initialValues={{
             remember: true,
         }}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
        >
        
        
         <Form
             style={{ minHeight: "73vh" }}
             {...layout}
             name="basic"
             initialValues={{
                 remember: true,
             }}
             onFinish={onFinish}
             onFinishFailed={onFinishFailed}
         >
             <Row gutter={24}>
                 <Col span={8}>
                     <Form.Item
                         name='shareholderNRIC'
                         label="Shareholder NRIC"
                     >
                         <Input style={{ width: '90%' }} />
                     </Form.Item>
                 </Col>
                 <Col span={8}>
                     <Form.Item
                         
                         name='shareholderName'
                        
                         label="Shareholder Name"
                         
                     >
                         <Input style={{ width: '90%' }} />
                     </Form.Item>
                 </Col>
                 <Col span={8}>
                     <Form.Item style={{ marginTop: "40px" }}
                         label=""
        
                         name="shareholderName2"
                     >
                         <Input style={{ width: '90%' }} />
                     </Form.Item>
                 </Col>
        
             </Row>
             <Row gutter={16}>
                 <Col span={8}>
                     <Form.Item
                        
                         name= 'nationality/citizenship'
                       
                         label="Nationality / Citizenship"
                     >
                         <Select style={{ width: '90%' }}>
                             <Option value="Singaporean">Singaporean</Option>
                             <Option value="Malaysian">Malaysian</Option>
                             <Option value="Chinese">Chinese</Option>
                             <Option value="Indian">Indian</Option>
                             <Option value="Other">Other</Option>
                         </Select>
                     </Form.Item>
                 </Col>
                 <Col span={8}>
                     <Form.Item
                       
                         name= 'corpassEmail'
                        
                         label="Corpass Email"
                     >
                         <Input style={{ width: '90%' }} />
                     </Form.Item>
                 </Col>
                 <Col span={8}>
                     <Form.Item
                       
                         name='corpassMobileNumber'
                       
                         label="Corpass Mobile Number"
                     >
                         <Input style={{ width: '90%' }} />
                     </Form.Item>
                 </Col>
             </Row>
             <Row gutter={16}>
                 <Col span={8}>
                     <     Form.Item
                        
                         name= 'shareType'
                        
                         label="Share Type"
                     >
                         <Select style={{ width: '90%' }}>
                             <Option value="John Smith">John Smith</Option>
                             <Option value="Jane Doe">Jane Doe</Option>
                             <Option value="Bob Johnson">Bob Johnson</Option>
                         </Select>
                     </Form.Item>
                 </Col>
                 <Col span={8}>
                     <Form.Item
                         
                         name= 'allocation'
                        
                         label="Allocation"
                     >
                         <Input style={{ width: '90%' }} />
                     </Form.Item>
                 </Col>
                 <Col span={8}>
                     <Form.Item
                        
                         name= 'currency'
                         
                         label="Currency"
                     >
                         <Select style={{ width: '90%' }}>
                             <Option value="Singaporean">Singaporean</Option>
                             <Option value="Malaysian">Malaysian</Option>
                             <Option value="Chinese">Chinese</Option>
                             <Option value="Indian">Indian</Option>
                             <Option value="Other">Other</Option>
                         </Select>
                     </Form.Item>
                 </Col>
             </Row>
             <hr style={{ color: 'grey', width: '100%' }} />
        
        
             <Form.List name="shareholders">
                 {(fields, { add, remove }) => (
                     <>
                         {fields.map((field, index) => (
                             <div key={field.key}>
                                 <Row gutter={24}>
                                     <Col span={8}>
                                         <Form.Item
                                             {...field}
                                             name={[field.name, 'shareholderNRIC']}
                                             fieldKey={[field.fieldKey, 'shareholderNRIC']}
                                             label="Shareholder NRIC"
                                             
                                         >
                                             <Select style={{ width: '90%' }}>
                                                 <Option value="John Smith">John Smith</Option>
                                                 <Option value="Jane Doe">Jane Doe</Option>
                                                 <Option value="Bob Johnson">Bob Johnson</Option>
                                             </Select>
                                         </Form.Item>
                                     </Col>
                                     <Col span={8}>
                                         <Form.Item
                                             {...field}
                                             name={[field.name, 'shareholderName']}
                                             fieldKey={[field.fieldKey, 'shareholderName']}
                                             label="Shareholder Name"
                                            
                                         >
                                             <Input style={{ width: '90%' }} />
                                         </Form.Item>
                                     </Col>
                                     <Col span={8}>
                                         <Form.Item style={{ marginTop: "40px" }}
                                             label=""
        
                                             name="shareholderName2"
                                         >
                                             <Input style={{ width: '90%' }} />
                                         </Form.Item>
                                     </Col>
        
                                 </Row>
                                 <Row gutter={16}>
                                     <Col span={8}>
                                         <Form.Item
                                             {...field}
                                             name={[field.name, 'nationality/citizenship']}
                                             fieldKey={[field.fieldKey, 'nationality/citizenship']}
                                             label="Nationality / Citizenship"
                                         >
                                             <Select style={{ width: '90%' }}>
                                                 <Option value="Singaporean">Singaporean</Option>
                                                 <Option value="Malaysian">Malaysian</Option>
                                                 <Option value="Chinese">Chinese</Option>
                                                 <Option value="Indian">Indian</Option>
                                                 <Option value="Other">Other</Option>
                                             </Select>
                                         </Form.Item>
                                     </Col>
                                     <Col span={8}>
                                         <Form.Item
                                             {...field}
                                             name={[field.name, 'corpassEmail']}
                                             fieldKey={[field.fieldKey, 'corpassEmail']}
                                             label="Corpass Email"
                                         >
                                             <Input style={{ width: '90%' }} />
                                         </Form.Item>
                                     </Col>
                                     <Col span={8}>
                                         <Form.Item
                                             {...field}
                                             name={[field.name, 'corpassMobileNumber']}
                                             fieldKey={[field.fieldKey, 'corpassMobileNumber']}
                                             label="Corpass Mobile Number"
                                         >
                                             <Input style={{ width: '90%' }} />
                                         </Form.Item>
                                     </Col>
                                 </Row>
                                 <Row gutter={16}>
                                     <Col span={8}>
                                         <     Form.Item
                                             {...field}
                                             name={[field.name, 'shareType']}
                                             fieldKey={[field.fieldKey, 'shareType']}
                                             label="Share Type"
                                         >
                                             <Select style={{ width: '90%' }}>
                                                 <Option value="John Smith">John Smith</Option>
                                                 <Option value="Jane Doe">Jane Doe</Option>
                                                 <Option value="Bob Johnson">Bob Johnson</Option>
                                             </Select>
                                         </Form.Item>
                                     </Col>
                                     <Col span={8}>
                                         <Form.Item
                                             {...field}
                                             name={[field.name, 'allocation']}
                                             fieldKey={[field.fieldKey, 'allocation']}
                                             label="Allocation"
                                         >
                                             <Input style={{ width: '90%' }} />
                                         </Form.Item>
                                     </Col>
                                     <Col span={8}>
                                         <Form.Item
                                             {...field}
                                             name={[field.name, 'currency']}
                                             fieldKey={[field.fieldKey, 'currency']}
                                             label="Currency"
                                         >
                                             <Select style={{ width: '90%' }}>
                                                 <Option value="Singaporean">Singaporean</Option>
                                                 <Option value="Malaysian">Malaysian</Option>
                                                 <Option value="Chinese">Chinese</Option>
                                                 <Option value="Indian">Indian</Option>
                                                 <Option value="Other">Other</Option>
                                             </Select>
                                         </Form.Item>
                                     </Col>
                                 </Row>
                                 <hr style={{ color: 'grey', width: '100%' }} />
                                 <Col span={8}>
                                     {index >= 0 && (
                                         <Button
                                             type="primary"
                                             danger
                                             style={{ marginTop: '30px', marginBottom: '25px' }}
                                             onClick={() => remove(field.name)}
                                         >
                                             Remove
                                         </Button>
                                     )}
                                 </Col>
                             </div>
                         ))}
                         <Form.Item>
                             <Button type="default" onClick={() => add()} style={{backgroundColor:"none", border:"none", color:"#0E5AA5"}}  icon={<PlusOutlined />}>
                                 Add Shareholder
                             </Button>
                         </Form.Item>
                     </>
                 )}
             </Form.List>
             <Form.Item >
             <Button type="default" htmlType="button">
                 Edit
             </Button>
             <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }}>
                 Save
             </Button>
         </Form.Item>
         </Form>
        
        
        </Form>
        ) : (
            <Form style={{ minHeight: "73vh" }}
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
        
        
            <Form
                style={{ minHeight: "73vh" }}
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row >
                    <Col span={24}>
                        <Form.Item
                            name='shareholderNRIC'
                            label="Shareholder NRIC"
                        >
                            <Input style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                  
                  
        
                </Row>
                <Row>
                <Col span={24}>
                        <Form.Item
                            
                            name='shareholderName'
                           
                            label="Shareholder Name"
                            
                        >
                            <Input style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                <Col span={24}>
                        <Form.Item 
                            label=""
        
                            name="shareholderName2"
                        >
                            <Input style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                           
                            name= 'nationality/citizenship'
                          
                            label="Nationality / Citizenship"
                        >
                            <Select style={{ width: '90%' }}>
                                <Option value="Singaporean">Singaporean</Option>
                                <Option value="Malaysian">Malaysian</Option>
                                <Option value="Chinese">Chinese</Option>
                                <Option value="Indian">Indian</Option>
                                <Option value="Other">Other</Option>
                            </Select>
                        </Form.Item>
                    </Col>
               
                  
                </Row>
                <Row>
                <Col span={24}>
                        <Form.Item
                          
                            name= 'corpassEmail'
                           
                            label="Corpass Email"
                        >
                            <Input style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                <Col span={24}>
                        <Form.Item
                          
                            name='corpassMobileNumber'
                          
                            label="Corpass Mobile Number"
                        >
                            <Input style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <     Form.Item
                           
                            name= 'shareType'
                           
                            label="Share Type"
                        >
                            <Select style={{ width: '90%' }}>
                                <Option value="John Smith">John Smith</Option>
                                <Option value="Jane Doe">Jane Doe</Option>
                                <Option value="Bob Johnson">Bob Johnson</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                 
                  
                </Row>
                <Row>
                <Col span={24}>
                        <Form.Item
                            
                            name= 'allocation'
                           
                            label="Allocation"
                        >
                            <Input style={{ width: '90%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                <Col span={24}>
                        <Form.Item
                           
                            name= 'currency'
                            
                            label="Currency"
                        >
                            <Select style={{ width: '90%' }}>
                                <Option value="Singaporean">Singaporean</Option>
                                <Option value="Malaysian">Malaysian</Option>
                                <Option value="Chinese">Chinese</Option>
                                <Option value="Indian">Indian</Option>
                                <Option value="Other">Other</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <hr style={{ color: 'grey', width: '100%' }} />
        
        
                <Form.List name="shareholders">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div key={field.key}>
                                    <Row >
                                        <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'shareholderNRIC']}
                                                fieldKey={[field.fieldKey, 'shareholderNRIC']}
                                                label="Shareholder NRIC"
                                                
                                            >
                                                <Select style={{ width: '90%' }}>
                                                    <Option value="John Smith">John Smith</Option>
                                                    <Option value="Jane Doe">Jane Doe</Option>
                                                    <Option value="Bob Johnson">Bob Johnson</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                      
                                       
        
                                    </Row>
                                    <Row>
                                    <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'shareholderName']}
                                                fieldKey={[field.fieldKey, 'shareholderName']}
                                                label="Shareholder Name"
                                               
                                            >
                                                <Input style={{ width: '90%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col span={24}>
                                            <Form.Item 
                                                label=""
        
                                                name="shareholderName2"
                                            >
                                                <Input style={{ width: '90%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'nationality/citizenship']}
                                                fieldKey={[field.fieldKey, 'nationality/citizenship']}
                                                label="Nationality / Citizenship"
                                            >
                                                <Select style={{ width: '90%' }}>
                                                    <Option value="Singaporean">Singaporean</Option>
                                                    <Option value="Malaysian">Malaysian</Option>
                                                    <Option value="Chinese">Chinese</Option>
                                                    <Option value="Indian">Indian</Option>
                                                    <Option value="Other">Other</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                      
                                       
                                    </Row>
                                    <Row>
                                    <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'corpassEmail']}
                                                fieldKey={[field.fieldKey, 'corpassEmail']}
                                                label="Corpass Email"
                                            >
                                                <Input style={{ width: '90%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'corpassMobileNumber']}
                                                fieldKey={[field.fieldKey, 'corpassMobileNumber']}
                                                label="Corpass Mobile Number"
                                            >
                                                <Input style={{ width: '90%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col span={24}>
                                            <     Form.Item
                                                {...field}
                                                name={[field.name, 'shareType']}
                                                fieldKey={[field.fieldKey, 'shareType']}
                                                label="Share Type"
                                            >
                                                <Select style={{ width: '90%' }}>
                                                    <Option value="John Smith">John Smith</Option>
                                                    <Option value="Jane Doe">Jane Doe</Option>
                                                    <Option value="Bob Johnson">Bob Johnson</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                       
                                       
                                    </Row>
                                    <Row>
                                    <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'allocation']}
                                                fieldKey={[field.fieldKey, 'allocation']}
                                                label="Allocation"
                                            >
                                                <Input style={{ width: '90%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'currency']}
                                                fieldKey={[field.fieldKey, 'currency']}
                                                label="Currency"
                                            >
                                                <Select style={{ width: '90%' }}>
                                                    <Option value="Singaporean">Singaporean</Option>
                                                    <Option value="Malaysian">Malaysian</Option>
                                                    <Option value="Chinese">Chinese</Option>
                                                    <Option value="Indian">Indian</Option>
                                                    <Option value="Other">Other</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <hr style={{ color: 'grey', width: '100%' }} />
                                    <Col span={8}>
                                        {index >= 0 && (
                                            <Button
                                                type="primary"
                                                danger
                                                style={{ marginTop: '30px', marginBottom: '25px' }}
                                                onClick={() => remove(field.name)}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </Col>
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="default" onClick={() => add()} style={{backgroundColor:"none", border:"none", color:"#0E5AA5"}}  icon={<PlusOutlined />}>
                                    Add Shareholder
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item >
                <Button type="default" htmlType="button">
                    Edit
                </Button>
                <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }}>
                    Save
                </Button>
            </Form.Item>
            </Form>
        
           
        </Form>
        )}
        </>
    );
};

export default Shareholders;

