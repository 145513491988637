import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  Icon,
  FormControl,
  FormLabel,
  Slider,
} from "@mui/material";
import { tokens } from "../../../src/theme";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

const Subscription = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const history = useHistory();
  const [screenWidth, setScreenWidth] = useState("1920");
  const [subscriptionPlan, setSubsciptionPlan] = useState(null);

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/subcription/getSubscription",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        setSubsciptionPlan(JSON.parse(json.subscription));

        if (response.status === 401) {
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const options = { day: "numeric", month: "short", year: "numeric" };
  const currentDate = new Date();
  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="4px 20px 20px 20px">
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={colors.grey[910]}
            minHeight="88.7vh"
            borderRadius="10px"
            opacity="0.95"
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "80%",
                  margin: "40px 0px 20px 0px",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    width: "70%",
                    color: "#142C44CC",
                    fontSize: "20px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    margin: "0px",
                  }}
                >
                  Choose Your Plan
                </h4>
                <div
                  style={{
                    padding: "7px 20px",
                    background: "rgba(255,255,255,0.7)",
                    borderRadius: "10px",
                    color: "#142C44CC",
                    textAlign: "right",
                    position: "relative",
                  }}
                >
                  {subscriptionPlan ? (
                    <div
                      className="blink"
                      style={{
                        position: "absolute",
                        top: 15,
                        left: 15,
                        background:
                          subscriptionPlan.validity &&
                          new Date(subscriptionPlan.validity) < currentDate
                            ? "red"
                            : "green",
                        boxShadow:
                          subscriptionPlan.validity &&
                          new Date(subscriptionPlan.validity) < currentDate
                            ? "0px 0px 10px red"
                            : "0px 0px 10px green",
                        height: "7px",
                        width: "7px",
                        borderRadius: "10px",
                      }}
                    ></div>
                  ) : null}
                  <div>Subscription</div>
                  <div
                    style={{
                      width: "100%",
                      color: "#142C44CC",
                      fontSize: "20px",
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      margin: "0px",
                    }}
                  >
                    {subscriptionPlan
                      ? `${subscriptionPlan.plan} Plan`
                      : "You have no plans"}
                  </div>
                  {subscriptionPlan ? (
                    subscriptionPlan.validity &&
                    new Date(subscriptionPlan.validity) < currentDate ? (
                      <div
                        style={{
                          color: "red",
                          margin: "18px 0px 0px 0px",
                          fontSize: "12px",
                          fontWeight: "600",
                          margin: "0px 0px 0px 0px",
                        }}
                      >
                        Subscription Expired
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "grey",
                          margin: "18px 0px 0px 0px",
                          fontSize: "12px",
                          margin: "0px 0px 0px 0px",
                        }}
                      >
                        Valid till:{" "}
                        {new Date(subscriptionPlan.validity).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </div>
                    )
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                }}
              >
                <Box
                  backgroundColor={colors.grey[910]}
                  opacity="1.00"
                  alignItems="center"
                  width="48%"
                  borderRadius="10px"
                  style={{ padding: "50px 0px 50px 0px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h3
                      style={{
                        color: "#142C44CC",
                        justifyContent: "center",
                        fontSize: "24px",
                        fontSize: "20px",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                      }}
                    >
                      GOLD PLAN
                    </h3>
                    <h2
                      style={{
                        color: "#142C44CC",
                        fontSize: "32px",
                        marginTop: "-10px",
                        fontSize: "60px",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                      }}
                    >
                      $0.00
                    </h2>
                    <h3
                      style={{
                        color: "#142C44CC",
                        fontSize: "24px",
                        marginTop: "-10px",
                        fontSize: "16px",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "500",
                      }}
                    >
                      / monthly
                    </h3>
                    <hr style={{ color: "#142C44CC", width: "300px" }} />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        1.25% of Approved Loan Amount: A competitive fee
                        structure based on the loan amount you approve, ensuring
                        a transparent and scalable pricing model.
                      </p>
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        Empower your lending strategy with exclusive access to
                        comprehensive analytics tools for your loan portfolio.
                        Unlock valuable insights into market behavior and
                        dynamics
                      </p>
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        Priority Customer Support: Enjoy prioritized access to
                        our dedicated customer support team, ensuring prompt
                        assistance and resolution for any inquiries or concerns.
                      </p>
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        Notification Alerts: Receive timely alerts and updates
                        related to your lending activities, keeping you informed
                        about important developments and opportunities.
                      </p>
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        Free Credit Bureau Report for all Borrowers signed with
                        our platform
                      </p>
                    </div>
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "#142C44CC",
                        border: "1px solid #415468",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        marginTop: "20px",
                        fontSize: "18px",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "600",
                      }}
                    >
                      Choose Plan
                    </button>
                  </div>
                </Box>
                <Box
                  backgroundColor={colors.grey[910]}
                  opacity="1.00"
                  alignItems="center"
                  width="48%"
                  borderRadius="10px"
                  style={{ padding: "30px 0px 30px 0px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h3
                      style={{
                        color: "#142C44CC",
                        justifyContent: "center",
                        fontSize: "24px",
                        fontSize: "20px",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                      }}
                    >
                      PLATINUM PLAN
                    </h3>
                    <h3
                      style={{
                        color: "#142C44CC",
                        fontSize: "24px",
                        marginTop: "-10px",
                        fontSize: "16px",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "500",
                      }}
                    >
                      Coming Soon
                    </h3>
                    <hr style={{ color: "#142C44CC", width: "300px" }} />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        Unlock unparalleled benefits with our Platinum Plan,
                        offering everything included in the Gold Plan and more.
                        Stay ahead of the curve with:
                      </p>
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        Early Access to Our Product Launches such as New
                        Property Equity Term Loan (Coming Soon): Be at the
                        forefront of innovative opportunities with exclusive
                        early access to our upcoming property equity term loan
                        product. Seize the advantage in diversifying your
                        portfolio and exploring new avenues for successful
                        lending.
                      </p>
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        Market Check on Borrowers: Gain a competitive edge with
                        our unique market check feature, offering valuable
                        insights into borrowers on our platform such as their
                        lending and repayment history
                      </p>
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        Access to Express Lending Features: Gain access to a
                        curated pool of pre-qualified Small and Medium
                        Enterprises (SMEs) borrowers who have successfully
                        passed our rigorous internal credit criteria. This
                        ensures that you are presented with lending
                        opportunities from businesses with a strong financial
                        standing.
                      </p>
                      <p
                        style={{
                          color: "#142C44CC",
                          fontSize: "14px",
                          margin: "10px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        AI Communication Tools: Experience seamless engagement
                        with borrowers' bank statements using our advanced AI
                        communication tools. Quickly gain at-a-glance insights,
                        identify potential red flags, and effortlessly
                        understand cash flow patterns. Eliminate manual data
                        entry, including total deposit/withdrawal figures and
                        loan checks, streamlining your lending process for
                        efficiency and accuracy
                      </p>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              padding: "0px 20px",
              height: "100vh",
              borderRadius: "20px",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                paddingTop: "10px",
              }}
            >
              <h4
                style={{
                  marginBottom: 30,
                  width: "80%",
                  color: "#142C44CC",
                  fontSize: "20px",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                }}
              >
                Choose Your Plan
              </h4>
            </div>
            <div
              style={{
                backgroundColor: "#FFFFFFB3",
                opacity: "1.00",
                alignItems: "center",
                width: "100%",
                height: "500px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    color: "#142C44CC",
                    justifyContent: "center",
                    fontSize: "24px",
                    marginTop: "50px",
                    fontSize: "20px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                  }}
                >
                  BASIC
                </h3>
                <h2
                  style={{
                    color: "#142C44CC",
                    fontSize: "32px",
                    marginTop: "-10px",
                    fontSize: "60px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                  }}
                >
                  $0.00
                </h2>
                <h3
                  style={{
                    color: "#142C44CC",
                    fontSize: "24px",
                    marginTop: "-10px",
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                  }}
                >
                  / monthly
                </h3>
                <hr style={{ color: "#142C44CC", width: "300px" }} />
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                </div>
                <button
                  style={{
                    backgroundColor: "white",
                    color: "#142C44CC",
                    border: "1px solid #415468",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    marginTop: "20px",
                    fontSize: "18px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                  }}
                >
                  Choose Plan
                </button>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#FFFFFFB3",
                opacity: "1.00",
                alignItems: "center",
                width: "100%",
                height: "500px",
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    color: "#142C44CC",
                    justifyContent: "center",
                    fontSize: "24px",
                    marginTop: "50px",
                    fontSize: "20px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                  }}
                >
                  PREMIUM
                </h3>
                <h2
                  style={{
                    color: "#142C44CC",
                    fontSize: "32px",
                    marginTop: "-10px",
                    fontSize: "60px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                  }}
                >
                  $0.00
                </h2>
                <h3
                  style={{
                    color: "#142C44CC",
                    fontSize: "24px",
                    marginTop: "-10px",
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                  }}
                >
                  / monthly
                </h3>
                <hr style={{ color: "#142C44CC", width: "300px" }} />
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                </div>
                <button
                  style={{
                    backgroundColor: "white",
                    color: "#142C44CC",
                    border: "1px solid #415468",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    marginTop: "20px",
                    fontSize: "18px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                  }}
                >
                  Choose Plan
                </button>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#FFFFFFB3",
                opacity: "1.00",
                alignItems: "center",
                width: "100%",
                height: "500px",
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    color: "#142C44CC",
                    justifyContent: "center",
                    fontSize: "24px",
                    marginTop: "50px",
                    fontSize: "20px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                  }}
                >
                  PLATINUM
                </h3>
                <h2
                  style={{
                    color: "#142C44CC",
                    fontSize: "32px",
                    marginTop: "-10px",
                    fontSize: "60px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                  }}
                >
                  $0.00
                </h2>
                <h3
                  style={{
                    color: "#142C44CC",
                    fontSize: "24px",
                    marginTop: "-10px",
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                  }}
                >
                  / monthly
                </h3>
                <hr style={{ color: "#142C44CC", width: "300px" }} />
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                  <p
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      margin: "10px",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Lorum Ipsum dolor is amet 2
                  </p>
                </div>
                <button
                  style={{
                    backgroundColor: "white",
                    color: "#142C44CC",
                    border: "1px solid #415468",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    marginTop: "20px",
                    fontSize: "18px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                  }}
                >
                  Choose Plan
                </button>
              </div>
            </div>
            <div>
              <p style={{ color: "transparent" }}>-</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
