import { Form, Input, Select, Button, Col, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { DatePicker, Space } from "antd";
const { Option } = Select;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const Shareholders = () => {
  const [shareholders, setShareholders] = useState([]);
  const [nric, setNric] = useState("");
  const [name, setName] = useState("");
  const [nationality, setNationality] = useState("");
  const [corpassEmail, setCorpassEmail] = useState("");
  const [corpassMobileNumber, setCorpassMobileNumber] = useState("");
  const [shareType, setShareType] = useState("");
  const [allocation, setAllocation] = useState("");
  const [currency, setCurrency] = useState("");

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        const entityDetail = displaySingpassData.singpass_data.entity;

        // Ensure that you are setting the array of shareholders
        if (
          entityDetail &&
          entityDetail.shareholders &&
          Array.isArray(entityDetail.shareholders["shareholders-list"])
        ) {
          setShareholders(entityDetail.shareholders["shareholders-list"]);
          console.log(
            "details",
            entityDetail.shareholders["shareholders-list"]
          );
        } else {
          // Handle the case where shareholders data is not an array or not available
          setShareholders([]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Form
      style={{ minHeight: "73vh" }}
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form
        style={{ minHeight: "73vh" }}
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {shareholders.length > 0 ? (
          shareholders.map((item, index) => (
            <React.Fragment key={index}>
              <Row gutter={24}>
                <Col span={isMobile ? 11 : 8}>
                  <Form.Item label="Shareholder NRIC">
                    <Input
                      style={{ width: "90%" }}
                      value={item["person-reference"]?.idno?.value || ""}
                      disabled={
                        !!item["person-reference"]?.idno?.value ||
                        !item["person-reference"]?.idno?.value
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={isMobile ? 11 : 8}>
                  <Form.Item label="Shareholder Name">
                    <Input
                      style={{ width: "90%" }}
                      value={
                        item["person-reference"]?.["person-name"]?.value || ""
                      }
                      disabled={
                        !!item["person-reference"]?.["person-name"]?.value ||
                        !item["person-reference"]?.["person-name"]?.value
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={isMobile ? 11 : 8}>
                  <Form.Item label="Nationality / Citizenship">
                    <Select
                      style={{ width: "90%" }}
                      value={item["person-reference"]?.nationality?.desc}
                      disabled={
                        !!item["person-reference"]?.nationality?.desc ||
                        !item["person-reference"]?.nationality?.desc
                      }
                    >
                      <Option value="Singaporean">Singaporean</Option>
                      <Option value="Malaysian">Malaysian</Option>
                      <Option value="Chinese">Chinese</Option>
                      <Option value="Indian">Indian</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={isMobile ? 11 : 8}>
                  <Form.Item label="Corppass Email">
                    <Input
                      style={{ width: "90%" }}
                      value={item["corppass-email" || ""]?.value}
                      disabled={
                        !!item["corppass-email"]?.value ||
                        !item["corppass-email"]?.value
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={isMobile ? 11 : 8}>
                  <Form.Item label="Corppass Mobile Number">
                    <Input
                      style={{ width: "90%" }}
                      value={item["corppass-mobileno"]?.value}
                      disabled={
                        !!item["corppass-mobileno"]?.value ||
                        !item["corppass-mobileno"]?.value
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={isMobile ? 11 : 8}>
                  <Form.Item label="Share Type">
                    <Select
                      style={{ width: "90%" }}
                      value={item["share-type"]?.desc}
                      disabled={
                        !!item["share-type"]?.desc || !item["share-type"]?.desc
                      }
                    >
                      <Option value="John Smith">John Smith</Option>
                      <Option value="Jane Doe">Jane Doe</Option>
                      <Option value="Bob Johnson">Bob Johnson</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={isMobile ? 11 : 8}>
                  <Form.Item label="Allocation">
                    <Input
                      style={{ width: "90%" }}
                      value={item.allocation?.value}
                      disabled={
                        !!item.allocation?.value || !item.allocation?.value
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={isMobile ? 11 : 8}>
                  <Form.Item label="Currency">
                    <Select
                      style={{ width: "90%" }}
                      value={item.currency?.desc}
                      disabled={!!item.currency?.desc || !item.currency?.desc}
                    >
                      <Option value="Singaporean">Singaporean</Option>
                      <Option value="Malaysian">Malaysian</Option>
                      <Option value="Chinese">Chinese</Option>
                      <Option value="Indian">Indian</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <hr style={{ color: "grey", width: "100%" }} />
            </React.Fragment>
          ))
        ) : (
          <div
            style={{
              width: "100%",
              padding: "0px 0px",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: "0px 15px",
                background: "rgba(255,255,255,0.7)",
                borderRadius: "10px",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Manrope, sans-serif",
                fontSize: "0.8em",
                fontWeight: "600",
                color: "#00000066",
              }}
            >
              You have no shareholders
            </div>
          </div>
        )}
      </Form>
      {/* <div style={{ display: "flex", gap: "10px", marginTop: "-80px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div> */}
    </Form>
  );
};

export default Shareholders;
