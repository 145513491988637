
import React from "react";


const ContactUs = () => {

    return (

<h1>This will be the dashboard</h1>
    )
}
export default ContactUs;