import React, { useState, useRef, useEffect } from "react";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { cloneDeep, isEqual } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import InboxIcon from "@mui/icons-material/Inbox";

const AdminMessaging = () => {
  const [myId, setMyId] = useState("");
  const scrollContainerRef = useRef();

  const [screenWidth, setScreenWidth] = useState("1920");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const archiveChat = async (chatId) => {
    try {
      console.log(chatId);
      const response = await fetch(
        "https://www.smart-lend.com/api/message/archiveMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ chatId: chatId }),
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        console.log(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [messages, setMessages] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [parameters, setParameters] = useState({});
  const [messageHover, setMessageHover] = useState(null);
  const [showArchive, setShowArchive] = useState(false);
  const broadcastArrays = [
    {
      user_id1: "A00001",
      user_id2: "AB",
      message: [],
    },
    {
      user_id1: "A00001",
      user_id2: "AL",
      message: [],
    },
    {
      user_id1: "A00001",
      user_id2: "AB",
      message: [],
    },
  ];

  const ReturnUserId = (message) => {
    if (message.user_id1 === myId) {
      return message.user_id2;
    } else {
      return message.user_id1;
    }
  };

  function convertDateString(inputString) {
    // Parse the input string to obtain a Date object
    const dateObject = new Date(inputString);

    // Format the date
    const optionsDate = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = dateObject.toLocaleDateString("en-US", optionsDate);

    // Format the time
    const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = dateObject.toLocaleTimeString("en-US", optionsTime);

    return { date: formattedDate, time: formattedTime };
  }

  const inputRef = useRef(null);

  useEffect(() => {
    // Access the current property of the ref to get the DOM element
    const scrollContainer = scrollContainerRef.current;

    if (activeConversation) {
      if (
        scrollContainerRef &&
        scrollContainer &&
        scrollContainer.scrollHeight
      ) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
    // Set scrollTop to the maximum scroll height
  }, [messages, activeConversation]); // Run the effect only once on mount

  const getMessage = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/message/getMessage",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      let messagessss = [];
      if (response.status === 200) {
        setMyId(json.user_id);
        if (json.data.length > 0) {
          messagessss = [...json.data];
        }

        const queryParams = new URLSearchParams(location.search);
        const params = {};
        for (const [key, value] of queryParams.entries()) {
          params[key] = value;
        }
        if (params.principelname) {
          setParameters(params);
          console.log(params);
          const newMessage = {
            user_id1: params.userid,
            message: [],
            receiverName: params.principelname,
          };
          messagessss.push(newMessage);
        }
        // Remove parameters from the URL
        const cleanUrl = window.location.pathname;
        history.replace(cleanUrl);
        setMessages(messagessss);
        if (params.principelname) {
          console.log(messagessss.length - 1);
          if (params.requestamount) {
            setMessageInput(
              `Hey ${
                params.principelname
              }! I would like to discuss your loan request of ${parseFloat(
                params.requestamount
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}.`
            );
          } else if (params.offeramount) {
            setMessageInput(
              `Hey ${
                params.principelname
              }! I would like to discuss your loan offer that you offerd of ${parseFloat(
                params.offeramount
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}.`
            );
          }
          setActiveConversation(messagessss.length - 1);
          inputRef.current.focus();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createUpdateMessage = async (data) => {
    try {
      console.log(data);
      const response = await fetch(
        "https://www.smart-lend.com/api/message/createMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(data),
        }
      );

      const json = await response.json();
      if (response.status === 200) {
        console.log(json);
        const dataToPush = {
          receiverName: json.receiverName,
          id: json.data.id,
          message: JSON.parse(json.data.message),
          user_id1: json.data.user_id1,
          user_id2: json.data.user_id2,
          created_at: json.data.created_at,
          updated_at: json.data.updated_at,
          archive: json.data.archive,
        };
        console.log(dataToPush);

        const messageIndex = messages.findIndex(
          (mes) => mes.id === dataToPush.id
        );

        if (messageIndex > -1) {
          const updatingMessage = [...messages];
          updatingMessage[messageIndex] = dataToPush;
          setMessages(updatingMessage);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMessage();
  }, []);

  const checkReadId = (data) => {
    // Extract data from the object
    const { read_id1, read_id2, user_id1, user_id2, receiver } = data;

    if (user_id1 === receiver) {
      return read_id1;
    } else {
      return read_id2;
    }
  };

  const retrieveMessage = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/message/getMessage",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      if (response.status === 200) {
        const data = [...json.data];
        if (json.data.length > 0) {
          if (!isEqual(data, messages)) {
            console.log(data);
            setMessages(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!parameters.principelname) {
      const intervalId = setInterval(() => {
        retrieveMessage();
      }, 4000);

      return () => clearInterval(intervalId);
    }
  }, [parameters]);

  const checkParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;
    }
    if (params.principelname) {
      setParameters(params);
      console.log(params);
      const newMessage = {
        user_id1: params.userid,
        message: [],
        receiverName: params.principelname,
      };
      setMessages([...messages, newMessage]);
      console.log([...messages, newMessage]);
    }
    // Remove parameters from the URL
    const cleanUrl = window.location.pathname;
    history.replace(cleanUrl);
  };

  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        display: "flex",
        gap: "5px",
        alignItems: "flex-start",
        justifyContent: "space-around",
      }}
    >
      {screenWidth > 1200 ? (
        <div
          style={{
            width: screenWidth > 1200 ? "24%" : "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                marginBottom: "15px",
              }}
            >
              Your Messages
            </div>
            <div
              className="mui_button"
              onClick={() => {
                setShowArchive(!showArchive);
              }}
              style={{
                background: showArchive ? "rgb(21, 87, 169)" : "white",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "7px",
              }}
            >
              <InboxIcon
                style={{
                  color: showArchive ? "white" : "rgb(21, 87, 169)",
                  fontSize: 30,
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "relative",
              background: "rgba(255,255,255,0.6)",
              color: "rgba(70,70,70)",
              borderRadius: "10px",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search conversations"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                padding: "10px",
                width: "92%",
              }}
            />
            <SearchIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translate(0%,-50%)",
              }}
            />
          </div>
          <div
            style={{
              height: "78%",
              display: "flex",
              flexDirection: "column",
              justifyContent: messages.length > 0 ? "flex-start" : "center",
              alignItems: "flex-start",
            }}
          >
            {messages.length > 0 ? (
              messages
                .filter((mes) =>
                  mes.receiverName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((mes, index) => (
                  <div
                    key={index}
                    className="mui_button"
                    style={{
                      width: "100%",
                      display:
                        (showArchive === false && mes.archive === 1) ||
                        (showArchive === false && mes.archive === true)
                          ? "none"
                          : "flex",
                      color:
                        activeConversation === index
                          ? "white"
                          : "rgba(80,80,80)",
                      marginBottom: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      onMouseOver={() => {
                        setMessageHover(index);
                      }}
                      onMouseLeave={() => {
                        setMessageHover(null);
                      }}
                      onClick={() => {
                        console.log(mes);
                        if (
                          mes.archive === null ||
                          mes.archive === false ||
                          mes.archive === 0
                        ) {
                          const messageIndex = messages.findIndex(
                            (mess) => mess.id === mes.id
                          );
                          if (messageIndex > -1) {
                            const updatingMessage = [...messages];
                            updatingMessage[messageIndex].archive = true;
                            setMessages(updatingMessage);
                          }
                        } else {
                          const messageIndex = messages.findIndex(
                            (mess) => mess.id === mes.id
                          );
                          if (messageIndex > -1) {
                            const updatingMessage = [...messages];
                            updatingMessage[messageIndex].archive = false;
                            setMessages(updatingMessage);
                          }
                        }
                        archiveChat(mes.id);
                      }}
                      style={{
                        height: "100%",
                        position: "absolute",
                        right: 0,
                        width: "20%",
                        top: 0,
                        // background: "red",
                        zIndex: 2,
                      }}
                    />
                    <div
                      style={{
                        height: "90%",
                        position: "absolute",
                        right: "1%",
                        width: "20%",
                        top: "5%",
                        background:
                          mes.archive === true || mes.archive === 1
                            ? "green"
                            : "red",
                        transition: "0.3s all ease-in-out",
                        zIndex: 0,
                        borderRadius: "0px 10px 10px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "inset 3px 3px 10px rgba(0,0,0,0.4)",
                      }}
                    >
                      <InboxIcon
                        style={{
                          color: "white",
                          fontSize: 32,
                          marginLeft: "25%",
                        }}
                      />
                    </div>
                    <div
                      onClick={() => setActiveConversation(index)}
                      style={{
                        background:
                          mes.archive === true || mes.archive === 1
                            ? "rgba(230,230,230,1)"
                            : activeConversation === index
                            ? "rgb(21, 87, 169)"
                            : "rgba(255,255,255,1)",
                        borderRadius: "10px",
                        padding: "10px 15px",
                        width:
                          mes.receiver !== "ALL" &&
                          mes.receiver !== "AB" &&
                          mes.receiver !== "AL" &&
                          messageHover === index
                            ? "85%"
                            : "100%",
                        height: "100%",
                        position: "relative",
                        cursor: "pointer",
                        transition: "0.3s all ease-in-out",
                        zIndex: 1,
                      }}
                    >
                      {checkReadId(mes) ? (
                        <div
                          className="blink"
                          style={{
                            position: "absolute",
                            width: 7,
                            height: 7,
                            borderRadius: "50%",
                            background:
                              activeConversation === index ? "white" : "green",
                            bottom: 14,
                            right: 14,
                          }}
                        />
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: "14px" }}>
                          {mes.receiverName === "Unknown" &&
                          mes.receiver === "ALL"
                            ? "Broadcast All"
                            : mes.receiverName === "Unknown" &&
                              mes.receiver === "AB"
                            ? "Broadcast Borrowers"
                            : mes.receiverName === "Unknown" &&
                              mes.receiver === "AL"
                            ? "Broadcast Lenders"
                            : mes.receiverName.length > 14
                            ? mes.receiverName.slice(0, 14) + "..."
                            : mes.receiverName}
                        </div>
                        <div
                          style={{
                            color:
                              activeConversation === index
                                ? "white"
                                : "rgba(0,0,0,0.4)",
                            fontSize: "10px",
                          }}
                        >
                          {mes.message
                            ? mes.message.length > 0
                              ? convertDateString(
                                  mes.message[mes.message.length - 1].id
                                ).time
                              : ""
                            : ""}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          color:
                            activeConversation === index
                              ? "white"
                              : "rgba(0,0,0,0.4)",
                        }}
                      >
                        {mes.message
                          ? mes.message.length > 0
                            ? mes.message[mes.message.length - 1].text?.length >
                              40
                              ? mes.message[mes.message.length - 1].text.slice(
                                  0,
                                  40
                                ) + "..."
                              : mes.message[mes.message.length - 1].text
                            : ""
                          : ""}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <UpcomingIcon
                    style={{ fontSize: "120px", color: "rgba(130,130,130)" }}
                  />
                </div>
                <div style={{ color: "rgba(150,150,150)", fontSize: "15px" }}>
                  No messages found
                </div>
              </div>
            )}
          </div>
        </div>
      ) : !messages[activeConversation] ? (
        <div
          style={{
            width: screenWidth > 1200 ? "24%" : "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              marginBottom: "15px",
            }}
          >
            Your Messages
          </div>
          <div
            style={{
              position: "relative",
              background: "rgba(255,255,255,0.6)",
              color: "rgba(70,70,70)",
              borderRadius: "10px",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search conversations"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                padding: "10px",
                width: "92%",
              }}
            />
            <SearchIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translate(0%,-50%)",
              }}
            />
          </div>
          <div
            style={{
              height: "78%",
              display: "flex",
              flexDirection: "column",
              justifyContent: messages.length > 0 ? "flex-start" : "center",
            }}
          >
            {messages.length > 0 ? (
              messages
                .filter((mes) =>
                  mes.receiverName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((mes, index) => (
                  <div
                    key={index}
                    onClick={() => setActiveConversation(index)}
                    className="mui_button"
                    style={{
                      background: "rgba(255,255,255,0.8)",
                      borderRadius: "10px",
                      padding: "10px 15px",
                      width: "100%",
                      color: "rgba(80,80,80)",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: "14px" }}>
                        {mes.receiverName === "Unknown" &&
                        mes.receiver === "ALL"
                          ? "Broadcast All"
                          : mes.receiverName === "Unknown" &&
                            mes.receiver === "AB"
                          ? "Broadcast Borrowers"
                          : mes.receiverName === "Unknown" &&
                            mes.receiver === "AL"
                          ? "Broadcast Lenders"
                          : mes.receiverName.length > 14
                          ? mes.receiverName.slice(0, 14) + "..."
                          : mes.receiverName}
                      </div>
                      <div
                        style={{ color: "rgba(0,0,0,0.4)", fontSize: "10px" }}
                      >
                        {mes.message
                          ? mes.message.length > 0
                            ? convertDateString(
                                mes.message[mes.message.length - 1].id
                              ).time
                            : ""
                          : ""}
                      </div>
                    </div>
                    <div style={{ fontSize: 10, color: "rgba(0,0,0,0.4)" }}>
                      {mes.message
                        ? mes.message.length > 0
                          ? mes.message[mes.message.length - 1].text.length > 40
                            ? mes.message[mes.message.length - 1].text.slice(
                                0,
                                40
                              ) + "..."
                            : mes.message[mes.message.length - 1].text
                          : ""
                        : ""}
                    </div>
                  </div>
                ))
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <UpcomingIcon
                    style={{ fontSize: "120px", color: "rgba(130,130,130)" }}
                  />
                </div>
                <div style={{ color: "rgba(150,150,150)", fontSize: "15px" }}>
                  No messages found
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {screenWidth > 1200 ? (
        <>
          <hr
            style={{
              background: "rgba(255,255,255,0.9)",
              border: "none",
              outline: "none",
              width: "2.5px",
              height: "95%",
            }}
          />
          <div
            style={{
              width: "70%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: messages[activeConversation]
                ? "flex-start"
                : "center",
            }}
          >
            {messages[activeConversation] ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "rgba(70,70,70)",
                }}
              >
                <div
                  ref={scrollContainerRef}
                  style={{ height: "calc(100% - 50px)", overflowY: "auto" }}
                >
                  {messages[activeConversation].message
                    ? messages[activeConversation].message.map(
                        (convo, index) => (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent:
                                convo.from === myId ? "flex-start" : "flex-end",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 10px 25px 10px",
                                borderRadius: "10px",
                                background:
                                  convo.from === myId
                                    ? "rgba(255,255,255,0.8)"
                                    : "rgb(14, 90, 165,0.5)",
                                marginBottom: "10px",
                                color:
                                  convo.from === myId ? "inherit" : "white",
                                minWidth: "30%",
                                maxWidth: "75%",
                                position: "relative",
                              }}
                            >
                              {convo.text}
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "7px",
                                  right: "10px",
                                  fontSize: "10px",
                                  color:
                                    convo.from === myId
                                      ? "rgba(0,0,0,0.4)"
                                      : "rgba(255,255,255,0.6)",
                                }}
                              >
                                {convertDateString(convo.id).time}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (messageInput !== "" && messageInput !== null) {
                      const conversationIndex = messages.findIndex(
                        (mes) => mes.id === messages[activeConversation].id
                      );
                      if (conversationIndex !== -1) {
                        const updatingMessage = [...messages];
                        const messageToPush = {
                          id: `${new Date()}`,
                          text: messageInput,
                          from: myId,
                          // from: `123${myId}`,
                        };
                        console.log(messageToPush);
                        if (updatingMessage[conversationIndex].message) {
                          updatingMessage[conversationIndex].message.push(
                            messageToPush
                          );
                        } else {
                          updatingMessage[conversationIndex].message = [
                            messageToPush,
                          ];
                        }
                        console.log(messages[activeConversation]);
                        createUpdateMessage(messages[activeConversation]);
                        setMessages(updatingMessage);
                      }
                      setMessageInput("");
                    }
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                    background: "rgba(255,255,255,0.8)",
                    borderRadius: "10px",
                  }}
                >
                  <input
                    ref={inputRef}
                    placeholder="Type your message here"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    style={{
                      outline: "none",
                      border: "none",
                      background: "transparent",
                      padding: "12px 8px 12px 15px",
                      borderRadius: "10px",
                      width: "95%",
                      position: "relative",
                      zIndex: 1,
                    }}
                  />
                  <button
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      right: "10px",
                      outline: "none",
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <SendIcon />
                  </button>
                </form>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <UpcomingIcon
                    style={{ fontSize: "120px", color: "rgba(130,130,130)" }}
                  />
                </div>
                <div style={{ color: "rgba(150,150,150)", fontSize: "15px" }}>
                  No conversation selected
                </div>
              </div>
            )}
          </div>
        </>
      ) : messages[activeConversation] ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: messages[activeConversation]
              ? "flex-start"
              : "center",
          }}
        >
          {messages[activeConversation] ? (
            <>
              <div
                style={{
                  height: "5%",
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  paddingBottom: 20,
                }}
              >
                <ArrowBackIosIcon
                  style={{ color: "#142C44CC", cursor: "pointer" }}
                  onClick={() => setActiveConversation(null)}
                />
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "20px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                    margin: 0,
                  }}
                >
                  {messages[activeConversation].receiverName === "Unknown" &&
                  messages[activeConversation].receiver === "ALL"
                    ? "Broadcast All"
                    : messages[activeConversation].receiverName === "Unknown" &&
                      messages[activeConversation].receiver === "AB"
                    ? "Broadcast Borrowers"
                    : messages[activeConversation].receiverName === "Unknown" &&
                      messages[activeConversation].receiver === "AL"
                    ? "Broadcast Lenders"
                    : messages[activeConversation].receiverName.length > 14
                    ? messages[activeConversation].receiverName.slice(0, 14) +
                      "..."
                    : messages[activeConversation].receiverName}
                </div>
              </div>
              <div
                style={{
                  height: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "rgba(70,70,70)",
                }}
              >
                <div
                  ref={scrollContainerRef}
                  style={{ height: "calc(100% - 50px)", overflowY: "auto" }}
                >
                  {messages[activeConversation].message
                    ? messages[activeConversation].message.map(
                        (convo, index) => (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent:
                                convo.from === myId ? "flex-start" : "flex-end",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 10px 25px 10px",
                                borderRadius: "10px",
                                background:
                                  convo.from === myId
                                    ? "rgba(255,255,255,0.8)"
                                    : "rgb(14, 90, 165,0.5)",
                                marginBottom: "10px",
                                color:
                                  convo.from === myId ? "inherit" : "white",
                                minWidth: "65%",
                                maxWidth: "95%",
                                position: "relative",
                              }}
                            >
                              {convo.text}
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "7px",
                                  right: "10px",
                                  fontSize: "10px",
                                  color:
                                    convo.from === myId
                                      ? "rgba(0,0,0,0.4)"
                                      : "rgba(255,255,255,0.6)",
                                }}
                              >
                                {convertDateString(convo.id).time}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (messageInput !== "" && messageInput !== null) {
                      const conversationIndex = messages.findIndex(
                        (mes) => mes.id === messages[activeConversation].id
                      );
                      if (conversationIndex !== -1) {
                        const updatingMessage = [...messages];
                        const messageToPush = {
                          id: `${new Date()}`,
                          text: messageInput,
                          from: myId,
                          // from: `123${myId}`,
                        };
                        console.log(messageToPush);
                        if (updatingMessage[conversationIndex].message) {
                          updatingMessage[conversationIndex].message.push(
                            messageToPush
                          );
                        } else {
                          updatingMessage[conversationIndex].message = [
                            messageToPush,
                          ];
                        }
                        console.log(updatingMessage);
                        createUpdateMessage(messages[activeConversation]);
                        setMessages(updatingMessage);
                      }
                      setMessageInput("");
                    }
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                    background: "rgba(255,255,255,0.8)",
                    borderRadius: "10px",
                  }}
                >
                  <input
                    ref={inputRef}
                    placeholder="Type your message here"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    style={{
                      outline: "none",
                      border: "none",
                      background: "transparent",
                      padding: "12px 8px 12px 15px",
                      borderRadius: "10px",
                      width: "95%",
                      position: "relative",
                      zIndex: 1,
                    }}
                  />
                  <button
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      right: "10px",
                      outline: "none",
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <SendIcon />
                  </button>
                </form>
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <UpcomingIcon
                  style={{ fontSize: "120px", color: "rgba(130,130,130)" }}
                />
              </div>
              <div style={{ color: "rgba(150,150,150)", fontSize: "15px" }}>
                No conversation selected
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AdminMessaging;
