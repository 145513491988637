import React, { useEffect, useState } from "react";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import cookies from "js-cookie";

const ViewFeedback = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const getFeedbacks = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/feedback/getFeedback",
        {
          method: "GET",
        }
      );
      let data = [];

      if (response.status === 200) {
        const json = await response.json();
        const jsonData = json.feedback;

        // Sort the feedback by `updated_at` in descending order
        jsonData.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );

        for (const feed of jsonData) {
          const submissionData = JSON.parse(feed.submission_data);
          const dataToPush = {
            id: feed.id,
            status_1: feed.status_1,
            status_2: feed.status_2 || "",
            status_3: feed.status_3 || "",
            category: submissionData.category,
            image: submissionData.image,
            message: submissionData.message,
            title: submissionData.title || "",
            description: submissionData.description || "",
            instruction: submissionData.instruction || "",
          };
          data.push(dataToPush);
        }
        console.log(data);
        setFeedbackData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, []);
  const [openedData, setOpenedData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleCreateFeedback = async (feedbackForm) => {
    try {
      let dataTosend = {
        submission_data: JSON.stringify({
          category: feedbackForm.category,
          image: feedbackForm.image,
          message: feedbackForm.message,
          title: feedbackForm.title,
          description: feedbackForm.description,
          instruction: feedbackForm.instruction,
        }),
        id: feedbackForm.id,
        status_1: feedbackForm.status_1,
        status_2: feedbackForm.status_2,
        status_3: feedbackForm.status_3,
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/user/feedback/createFeedback",
        {
          method: "POST",
          body: JSON.stringify(dataTosend),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        console.log(json);
        const data = json.updated_data;
        const dataIndex = feedbackData.findIndex((fd) => fd.id === data.id);

        const submissionData = JSON.parse(data.submission_data);
        const dataToPush = {
          id: data.id,
          status_1: data.status_1,
          status_2: data.status_2 || "",
          status_3: data.status_3 || "",
          category: submissionData.category,
          image: submissionData.image,
          message: submissionData.message,
          title: submissionData.title || "",
          description: submissionData.description || "",
          instruction: submissionData.instruction || "",
        };
        const updatingFeedbackData = [...feedbackData];
        updatingFeedbackData[dataIndex] = dataToPush;
        setFeedbackData(updatingFeedbackData);

        setModalIsOpen(false);
        message.success("Feedback has been updated!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Modal
        open={modalIsOpen === "Requested"}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        width={"66%"}
        footer={null}
      >
        {openedData ? (
          <div>
            <div
              style={{
                color: "#142C44CC",
                fontWeight: 800,
                fontSize: "18px",
                fontFamily: "Manrope, sans-serif",
              }}
            >
              User Requested
            </div>
            <div
              style={{ display: "flex", width: "100%", marginBottom: "10px" }}
            >
              {openedData.image ? (
                <div style={{ width: "50%", height: "200px" }}>
                  <img
                    src={openedData.image || ""}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ) : null}
              <div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Request category
                </div>
                <div style={{ fontSize: "12px", fontWeight: 400 }}>
                  {openedData.category}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Request message
                </div>
                <div style={{ fontSize: "12px", fontWeight: 400 }}>
                  {openedData.message}
                </div>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const updatingOpenedData = { ...openedData };
                handleCreateFeedback(updatingOpenedData);
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Ticket Status
                  </div>
                  <select
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      borderRadius: "8px",
                      width: "90%",
                      border: "1px solid lightgrey",
                      padding: "5px 15px",
                    }}
                    value={openedData.status_2}
                    onChange={(e) => {
                      const updatingfdData = { ...openedData };
                      updatingfdData.status_2 = e.target.value;
                      setOpenedData(updatingfdData);
                    }}
                  >
                    <option value={""}>Requested</option>
                    <option value={"ready"}>Approved</option>
                    <option value={"ongoing"}>Start Development</option>
                    <option value={"complete"}>Development Completed</option>
                  </select>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Ticket title
                  </div>
                  <input
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      borderRadius: "8px",
                      width: "90%",
                      border: "1px solid lightgrey",
                      padding: "5px 15px",
                    }}
                    value={openedData.title}
                    onChange={(e) => {
                      const updatingfdData = { ...openedData };
                      updatingfdData.title = e.target.value;
                      setOpenedData(updatingfdData);
                    }}
                    required
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Ticket description
                  </div>
                  <textarea
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      borderRadius: "8px",
                      width: "90%",
                      border: "1px solid lightgrey",
                      padding: "5px 15px",
                      resize: "none",
                      height: "100px",
                    }}
                    value={openedData.description}
                    onChange={(e) => {
                      const updatingfdData = { ...openedData };
                      updatingfdData.description = e.target.value;
                      setOpenedData(updatingfdData);
                    }}
                    required
                  />
                </div>
                {openedData.status_2 === "complete" ? (
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Ticket instructions
                    </div>
                    <textarea
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        borderRadius: "8px",
                        width: "90%",
                        border: "1px solid lightgrey",
                        padding: "5px 15px",
                        resize: "none",
                        height: "100px",
                      }}
                      value={openedData.instruction}
                      onChange={(e) => {
                        const updatingfdData = { ...openedData };
                        updatingfdData.instruction = e.target.value;
                        setOpenedData(updatingfdData);
                      }}
                      required
                    />
                  </div>
                ) : null}
              </div>
              <button
                style={{
                  background: "rgb(17, 92, 166)",
                  color: "white",
                  padding: "7px 30px",
                  border: "none",
                  marginTop: "10px",
                  borderRadius: "8px",
                }}
                type="submit"
              >
                Approve
              </button>
            </form>
          </div>
        ) : null}
      </Modal>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              marginBottom: "15px",
            }}
          >
            Feedbacks
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <div
          style={{
            height: "80vh",
            background: "rgba(256,256,256,0.4)",
            width: "25%",
            borderRadius: "8px",
            padding: "5px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "15px",
              fontWeight: "800",
              fontFamily: "Manrope, sans-serif",
              marginBottom: "8px",
            }}
          >
            Requested
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {feedbackData.map((fd, index) =>
              fd.status_2 === "" ? (
                <div
                  key={index}
                  style={{
                    padding: "7px",
                    background: "rgba(256,256,256,1)",
                    marginBottom: "7px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalIsOpen("Requested");
                    setOpenedData(fd);
                  }}
                >
                  <div
                    style={{
                      color: "#142C44CC",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    {fd.category}
                  </div>
                  <div style={{ color: "rgb(80,80,80)", fontSize: "10px" }}>
                    {fd.message}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
        <div
          style={{
            height: "80vh",
            background: "rgba(256,256,256,0.4)",
            width: "25%",
            borderRadius: "8px",
            padding: "5px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "15px",
              fontWeight: "800",
              fontFamily: "Manrope, sans-serif",
              marginBottom: "8px",
            }}
          >
            Approved
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {feedbackData.map((fd, index) =>
              fd.status_2 === "ready" ? (
                <div
                  key={index}
                  style={{
                    padding: "7px",
                    background: "rgba(256,256,256,1)",
                    marginBottom: "7px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalIsOpen("Requested");
                    setOpenedData(fd);
                  }}
                >
                  <div
                    style={{
                      color: "#142C44CC",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    {fd.title}
                  </div>
                  <div style={{ color: "rgb(80,80,80)", fontSize: "10px" }}>
                    {fd.description}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
        <div
          style={{
            height: "80vh",
            background: "rgba(256,256,256,0.4)",
            width: "25%",
            borderRadius: "8px",
            padding: "5px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "15px",
              fontWeight: "800",
              fontFamily: "Manrope, sans-serif",
              marginBottom: "8px",
            }}
          >
            Ongoing Development
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {feedbackData.map((fd, index) =>
              fd.status_2 === "ongoing" ? (
                <div
                  key={index}
                  style={{
                    padding: "7px",
                    background: "rgba(256,256,256,1)",
                    marginBottom: "7px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalIsOpen("Requested");
                    setOpenedData(fd);
                  }}
                >
                  <div
                    style={{
                      color: "#142C44CC",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    {fd.category}
                  </div>
                  <div style={{ color: "rgb(80,80,80)", fontSize: "10px" }}>
                    {fd.message}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
        <div
          style={{
            height: "80vh",
            background: "rgba(256,256,256,0.4)",
            width: "25%",
            borderRadius: "8px",
            padding: "5px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "15px",
              fontWeight: "800",
              fontFamily: "Manrope, sans-serif",
              marginBottom: "8px",
            }}
          >
            Completed
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {feedbackData.map((fd, index) =>
              fd.status_2 === "complete" ? (
                <div
                  key={index}
                  style={{
                    padding: "7px",
                    background: "rgba(256,256,256,1)",
                    marginBottom: "7px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalIsOpen("Requested");
                    setOpenedData(fd);
                  }}
                >
                  <div
                    style={{
                      color: "#142C44CC",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    {fd.category}
                  </div>
                  <div style={{ color: "rgb(80,80,80)", fontSize: "10px" }}>
                    {fd.message}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFeedback;
