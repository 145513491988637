import { Space, Table, Tag, Radio, Checkbox, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const TablePendingAcceptance = ({ parentData, loan_id, onSelectionChange }) => {
  const [data, setData] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isAllChecked, setAllChecked] = useState(false);

  const handleRowSelect = (record, selected) => {
    if (selected) {
      setSelectedDocuments((prev) => [...prev, record.document]);
    } else {
      setSelectedDocuments((prev) =>
        prev.filter((doc) => doc !== record.document)
      );
    }
  };

  // ...

  useEffect(() => {
    console.log("loanId value", loan_id);
    const flatData = parentData.flatMap(
      (item) =>
        item.add_document?.map((doc) => ({
          ...doc,
          borrower_id: item.borrower_id,
          loan_id: item.loan_id,
        })) || []
    );

    // Filter only data with the same loan_id
    const filteredData = flatData.filter((item) => item.loan_id === loan_id);

    console.log("Filtered data:", filteredData); // to ensure data is filtered properly
    setData(filteredData);
  }, [parentData, loan_id]); // Add loan_id to the dependencies array

  // ...

  const handleSelectAll = (selected) => {
    console.log("parentData value", data);
    if (selected) {
      setAllChecked(true);
      setSelectedDocuments(data.map((doc) => doc.document));
    } else {
      setAllChecked(false);
      setSelectedDocuments([]);
    }
  };

  const handleDownloadAdditionalFile = async (event) => {
    try {
      let item = {
        documents: selectedDocuments,
      };
      // Fetch the zip file from the backend
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/viewApplicantDocument",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a Blob from the response
      const blob = await response.blob();

      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "downloaded.zip"; // Specify the desired file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading zip file:", error);
    }

    setSelectedDocuments([]);
  };

  const columns = [
    {
      title: (
        <Checkbox
          checked={isAllChecked}
          onChange={(e) => handleSelectAll(e.target.checked)}
        ></Checkbox>
      ),
      dataIndex: "document",
      render: (_, record) => (
        <Checkbox
          checked={selectedDocuments.includes(record.document)}
          onChange={(e) => handleRowSelect(record, e.target.checked)}
        />
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "11px",
            opacity: ".6",
          }}
        >
          Document Name
        </span>
      ),
      dataIndex: "document_name",
      key: "document_name",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >{`${text || "-"}`}</span>
      ),
    },

    {
      title: (
        <Button
          type="primary"
          disabled={!selectedDocuments.length} // Button will be disabled if selectedDocuments array is empty
          onClick={(event) =>
            handleDownloadAdditionalFile(
              selectedDocuments.id,
              selectedDocuments.loan_id,
              event
            )
          }
        >
          Download
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="borrower_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: "0px 20px",
        }}
      />
    </div>
  );
};

export default TablePendingAcceptance;
